import React, { useState, useEffect } from 'react'
import { renderFormatMsg, toastSuccess, toastError } from '../../shared/funs';
import { Form } from 'react-final-form';
// import BackIcon from "../../images/back-icon.svg";
// import { Link } from 'react-router-dom';
// import { getClientData, updateClientData } from '../../services/toolsAndSettingsPagesApi'
import ActiveFromAndToDate from '../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import PageHeading from '../../components/PageHeading/PageHeading';
import InputField from '../../components/Fields/InputField';
import { BaseService } from '../../services/BaseService';
export default function EditClient(props) {
  // eslint-disable-next-line
  const [isEdit, setEdit] = useState(false)
  const [formValue, setFormValue] = useState(null)
  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      setEdit(true);
      let param = { "page": 1, "display": 1, "search": props.match.params.clientId }
      // getClientData(param).then(res => {
      //   setFormValue(res.clients[0])
      // }).catch(err => {
      //   console.info('err => ', err);
      // })

      async function fetchUserData() {
        try {
          let res = await (new BaseService('/Clients')).getAll('', param)
          setFormValue(res.clients[0])
        } catch (error) {
          console.info('err => ', error);
        }
      }
      fetchUserData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleEditForm = async (data) => {
    console.log('data =>', data);

    const object = {
      "clientId": data.clientId,
      "clientName": data.clientName,
      "activeFrom": data.activeFrom,
      "activeTo": data.activeTo
    }

    try {
      await (new BaseService('/Clients')).update(data.clientId, object)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/client")
    } catch (error) {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
    // updateClientData(object).then(res => {
    // }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
  }

  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/client"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{renderFormatMsg("EditClient", "Edit Client")}</span>
        </div>
      </div> */}
      <PageHeading backURL="/client" headingId={"EditClient"} />
      <div className="card p-3">
        <Form onSubmit={handleEditForm}
          initialValues={formValue}
        >
          {({ handleSubmit, form, submitting, errors, pristine, values }) => {
            return (
              <>
                <form onSubmit={handleSubmit} className="row align-items-end form">
                  {/* <Field name="clientId">
                    {({ input, meta }) => (
                      <div className="form-group col-sm-6">
                        <label> {renderFormatMsg("clientPageTable.ClientID", "ClientId")} </label>
                        <input {...input} type="text" readOnly disabled className="form-control" />
                        {meta.error && meta.touched && (<span>{meta.error}</span>)}
                      </div>
                    )}
                  </Field> */}
                  <InputField
                    colClass="col-lg-6"
                    name={"clientId"}
                    type="text"
                    id="clientPageTable.ClientID"
                    defaultLabel="ClientId"
                    className="read-only form-control"
                    disabled
                  />
                  {/* <Field name="clientName">
                    {({ input, meta }) => (
                      <div className="form-group col-sm-6">
                        <label> {renderFormatMsg("clientPageTable.ClientName", "Client Name")} </label>
                        <input {...input} type="text" placeholder="Client Name" className="form-control" />
                        {meta.error && meta.touched && (<span>{meta.error}</span>)}
                      </div>
                    )}
                  </Field> */}

                  <InputField
                    colClass="col-lg-6"
                    name={"clientName"}
                    type="text"
                    id="clientPageTable.ClientName"
                    defaultLabel="Client Name"
                  />
                  <div className="col-6">
                    <ActiveFromAndToDate />
                  </div>
                  <div className="col-6">
                  </div>
                  <div className="col-12 d-flex">
                    <div className="submit_btn d-flex justify-content-end w-100 mx-3 mt-1">
                      <button type="submit" className="btn btn-primary" >
                        {renderFormatMsg("Save", "Save")}
                      </button>
                    </div>
                  </div>
                </form>

              </>
            );
          }}
        </Form>
      </div>
    </div >

  )
}
