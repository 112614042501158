import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
// import { getProductDistributionOverview } from '../../services/sidebarApi';
import { getSeasonsDetails } from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import { renderFormatMsg, checkPermission, permissionErrorCheck, findPermission } from '../../shared/funs';
import Error from '../../components/Error/Error';
import { AppContext } from '../../context/AppContext';
import SearchBox from '../../components/SearchBox/SearchBox';
import { get } from 'lodash';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ProductDistributionOverview = () => {
  const [productDistributionOverview, setProductDistributionOverview] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [searchValue, setSearchValue] = useState("")
  const [selectedSeason, setSelectedSeason] = useState()
  const [seaonDataStore, setSeaonDataStore] = useState([])
  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  let dataArray = get(userDetails, "accesses")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("PRODUCT_SETTING").screenId))
  }, [dataArray])


  useEffect(() => {
    //to get only ACTIVE seasonData
    getSeasonsDetails({ "activeOnly": true }).then(res => {
      setSeaonDataStore(res.seasons)
    }).catch(err => {
      setError(permissionErrorCheck(err))
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = async (page = 0, search = "", season = "") => {
    setSearchValue(search)
    let param = { "page": page + 1, "display": PAGE_SIZE, "search": search, "season": season }
    setLoading(true)
    try {
      let res = await (new BaseService('/Distribution')).getAll('', param)
      setLoading(false)
      setProductDistributionOverview(res.options)
      setError(false)

      setTotalPage(res.totalPage)
    } catch (error) {
      setLoading(false)
      setError(permissionErrorCheck(error))
    }
    // getProductDistributionOverview(param).then(res => {
    //   setLoading(false)
    //   setProductDistributionOverview(res.options)
    //   setTotalPage(res.totalPage)
    // }).catch(err => {
    //   setLoading(false)
    //   setError(permissionErrorCheck(err))
    // })
  }


  const columns = [{
    Header: <div className="text-left"> {renderFormatMsg("productDistributionOverviewTable.ProductId", "Product ID")}</div>,
    accessor: 'customerOptionId',
    width: 350,
    //resizable: false,
    style: { justifyContent: "flex-start" }
  }, {
    Header: <div className="text-left"> {renderFormatMsg("productDistributionOverviewTable.ProductName", "Product Name")}</div>,
    accessor: 'optionName',
    minWidth: 130,
    maxWidth: 200,
    //resizable: false,
    style: { justifyContent: "flex-start" }
  },
  {
    Header: <div className="text-left"> {renderFormatMsg("productDistributionOverviewTable.BlockedStores", "Blocked Stores")}</div>,
    accessor: 'blockedStores',
    minWidth: 120,
    maxWidth: 200,
    //resizable: false,
    style: { justifyContent: "flex-start" }
  },
  {
    Header: renderFormatMsg("Performance", "Performance"),
    accessor: 'Performance',
    //resizable: false,
    width: 128,
    Cell: (data) =>
      <Link to={`/product-performance/${data.original.optionId}`}  >
        {renderFormatMsg("Performance", "Performance")}
      </Link>
  }]

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      //resizable: false,
      width: 100,
      Cell: (data) =>
        <Link to={`/optionDistribution/productSettings/${data.original.optionId}`} className={`edit-icon ${!pageReadOnly.write && 'disabled-text'}`} >
          {renderFormatMsg("Edit", "Edit")}
        </Link>
    })
  }

  columns.push({
    Header: renderFormatMsg("Block", "Block"),
    accessor: 'Block',
    //resizable: false,
    width: 100,
    Cell: (data) => <Link to={`/optionDistribution/optionDistributionDetail/${data.original.optionId}/${(selectedSeason && selectedSeason.seasonId && selectedSeason.seasonId) || ""}`} className="edit-icon">
      {renderFormatMsg("Block", "Block")}
    </Link>
  })

  const fetchData = (state, instance) => {
    //getting change page number from state
    getData(state.page, searchValue, "")
    setPageIndex(state.page)
  }
  const handleSeasonData = (season) => {
    setSelectedSeason(season)
    getData(0, "", season.seasonId)
  }
  const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value, "") }
  console.log('productDistributionOverview =>', productDistributionOverview);

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("productDistributionOverviewTable.Heading", "Product Distribution")}
        {!error && <div className="right-content select-box ml-auto">
          <DropDownCustom name="name" classes={"p-2"} selectedData={(selectedSeason && selectedSeason.name) || "Season"} dropdownData={seaonDataStore} handleData={handleSeasonData} />
        </div>
        }
      </div> */}
      <PageHeading headingId="productDistributionOverviewTable.Heading" isLoading={isLoading} >
        {!error && <div className="right-content select-box ml-auto">
          <DropDownCustom name="name" classes={"p-2"} selectedData={(selectedSeason && selectedSeason.name) || "Season"} dropdownData={seaonDataStore} handleData={handleSeasonData} />
        </div>
        }
      </PageHeading>
      <div className="card">
        {!error && <div className="card-header">
          <SearchBox searchHandler={SearchHandler} />
        </div>}
        {error ? <Error msg={error} /> : <ReactTable className="prod_overview_dt" data={productDistributionOverview} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />}
      </div>
    </div>
  )
}
export default React.memo(ProductDistributionOverview);
