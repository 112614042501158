import React, { useState, useContext, useEffect } from 'react'
import { get } from "lodash"
// import { getOutletCalendars, updateOutletCalendar } from '../../services/toolsAndSettingsPagesApi';
import { renderFormatMsg, toastSuccess, toastError, checkPermission, permissionErrorCheck, findPermission } from "../../shared/funs";
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import { AppContext } from "../../context/AppContext";
import Error from "../../components/Error/Error";
import SearchBox from '../../components/SearchBox/SearchBox';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ReplenishmentCalendar = (props) => {
    const { userDetails } = useContext(AppContext)
    const [outletCalData, setOutletCalData] = useState([])
    const [isLoading, setLoading] = useState(false)
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [error, setError] = useState(false)
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

    let dataArray = get(userDetails, "accesses")

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("REPLENISHMENT_CALENDAR").screenId))
    }, [dataArray])

    const getData = async (page = 0, search = "") => {
        setSearchValue(search)
        let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
        setLoading(true)
        // getOutletCalendars(param).then(res => {
        //     setOutletCalData(res.outletCalendars)
        //     setTotalPage(res.totalPage)
        //     setLoading(false)
        // }).catch(err => {
        //     setLoading(false)
        //     setError(permissionErrorCheck(err))
        // })
        try {
            let res = await (new BaseService('/OutletCalendars')).getAll('', param)
            setOutletCalData(res.outletCalendars)
            setTotalPage(res.totalPage)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(permissionErrorCheck(error))
        }
    }
    const handleChecked = async (e, outletCalendarId) => {
        let tempData = outletCalData && outletCalData.length > 0 ? [...outletCalData] : []
        let payload = { outletCalendarId, [e.target.name]: e.target.checked }
        setLoading(true)

        tempData.forEach(currentData => {
            if (currentData.outletCalendarId === outletCalendarId) {
                currentData[e.target.name] = e.target.checked
            }
        })
        // updateOutletCalendar(payload).then(res => {
        //     toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"));
        //     setOutletCalData(tempData)
        //     setLoading(false)
        // }).catch(err => {
        //     toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"));
        // })

        try {
            await (new BaseService('/OutletCalendars')).update(outletCalendarId, payload)
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"));
            setOutletCalData(tempData)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"));
        }
    }

    const columns = [{
        Header: <div className="text-left">{renderFormatMsg("replenishmentTable.StoreID", "Store ID")}</div>,
        accessor: 'customerOutletId',
        //resizable: false,
        maxWidth: 320,
        // width: 324,
        style: { justifyContent: "flex-start" }
    }, {
        Header: <div className="text-left">
            {renderFormatMsg("StoreName", "Store Name")}</div>,
        accessor: 'outletName',
        maxWidth: 320,

        // minWidth: 250,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    }, {
        Header: renderFormatMsg("replenishmentTable.Mon", "Mon"),
        accessor: 'activeMonday',
        //resizable: false,
        width: 55,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="activeMonday"
                checked={props.original.activeMonday}
                onChange={(e) => handleChecked(e, props.original.outletCalendarId)} />
            <label for="checkbox1"></label>
        </div>
    }, {
        Header: renderFormatMsg("replenishmentTable.Tue", "Tue"),
        accessor: 'activeTuesday',
        //resizable: false,
        width: 55,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="activeTuesday" checked={props.original.activeTuesday}
                onChange={(e) => handleChecked(e, props.original.outletCalendarId)} />
            <label for="checkbox2"></label>
        </div>

    }, {
        Header: renderFormatMsg("replenishmentTable.Wed", "Wed"),
        accessor: 'activeWednesday',
        //resizable: false,
        width: 55,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="activeWednesday" checked={props.original.activeWednesday}
                onChange={(e) => handleChecked(e, props.original.outletCalendarId)} />
            <label for="checkbox2"></label>
        </div>
    }, {
        Header: renderFormatMsg("replenishmentTable.Thu", "Thu"),
        accessor: 'activeThursday',
        //resizable: false,
        width: 55,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="activeThursday" checked={props.original.activeThursday}
                onChange={(e) => handleChecked(e, props.original.outletCalendarId)} />
            <label for="checkbox2"></label>
        </div>
    }, {
        Header: renderFormatMsg("replenishmentTable.Fri", "Fri"),
        accessor: 'activeFriday',
        //resizable: false,
        width: 55,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="activeFriday" checked={props.original.activeFriday}
                onChange={(e) => handleChecked(e, props.original.outletCalendarId)} />
            <label for="checkbox2"></label>
        </div>
    }, {
        Header: renderFormatMsg("replenishmentTable.Sat", "Sat"),
        accessor: 'activeSaturday',
        //resizable: false,
        width: 55,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="activeSaturday" checked={props.original.activeSaturday}
                onChange={(e) => handleChecked(e, props.original.outletCalendarId)} />
            <label for="checkbox2"></label>
        </div>
    }, {
        Header: renderFormatMsg("replenishmentTable.Sun", "Sun"),
        accessor: 'activeSunday',
        //resizable: false,
        width: 55,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="activeSunday" checked={props.original.activeSunday}
                onChange={(e) => handleChecked(e, props.original.outletCalendarId)} />
            <label for="checkbox2"></label>
        </div>
    }, {
        Header: renderFormatMsg("Blocked", "Blocked"),
        //resizable: false,
        width: 87,
        Cell: (props) => <div className="checkbox">
            <input disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} type="checkbox" name="outletBlocked" checked={props.original.outletBlocked} readOnly
            />
            <label for="checkbox2"></label>
        </div>
    }]
    const fetchData = (state, instance) => {
        getData(state.page, searchValue)
        setPageIndex(state.page)
    }

    const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }

    return (
        <div className="main-content">
            <PageHeading headingId="replenishmentTable.Heading" isLoading={isLoading} />
            <div className="card">

                {error ? <Error msg={error} /> : <>
                    <div className="card-header">
                        <SearchBox searchHandler={SearchHandler} />
                    </div>
                    <ReactTable data={outletCalData} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
                </>
                }
            </div>
        </div >
    )
}
export default React.memo(ReplenishmentCalendar)
