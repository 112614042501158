import React, { useEffect, useState } from 'react'
import {
  Form,
  // Field
} from 'react-final-form';
import { get, find } from "lodash";
import { renderFormatMsg, toastSuccess, toastError } from '../../shared/funs';
// import { Link } from 'react-router-dom';
// import BackIcon from "../../images/back-icon.svg";
import {
  // addSeasonType, updateSeasonType,getSeasonType
  getBrand,
} from '../../services/toolsAndSettingsPagesApi';
import {
  // InputGroup,
  OverlayTrigger, Tooltip
} from 'react-bootstrap';
import { INFO } from '../../constant';
import { injectIntl } from 'react-intl';
// import FieldErrorMessage from '../../components/FieldErrorMessage';
import InputField from '../../components/Fields/InputField';
import SelectField from '../../components/Fields/SelectField';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const HandleSeasonType = (props) => {

  // console.info('props =>', props);


  const [seasonType, setSeasonType] = useState(null)
  const [salesChannelSeasonType, setSalesChannelSeasonType] = useState(null)
  // eslint-disable-next-line
  const [error, setError] = useState("")
  const [brands, setBrands] = useState([])

  useEffect(() => {
    // getBrand().then(res => {
    //   setBrands(res.brands)
    // }).catch(error => console.log('error => ', error))

    async function fetchBrandData() {
      try {
        let res = await (new BaseService('/Brand')).getAll('')
        // console.info('resBrand =>', res);

        setBrands(res.brands)
      } catch (error) {
        console.log('error => ', error)
      }
    }
    fetchBrandData();
  }, [])


  var heading = "AddSeasonType";
  var values = {
    name: "",
    brandId: "",
    clientId: "",
    preSeasonMaxReplDays: "",
    preSeasonMinProbability: "",
    preSeasonInitialReplDays: "",
    preSeasonRelevantSalesDays: "",
    mainSeasonMaxReplDays: "",
    mainSeasonMinProbability: "",
    mainSeasonInitialReplDays: "",
    mainSeasonRelevantSalesDays: "",
    postSeasonMaxReplDays: "",
    postSeasonMinProbability: "",
    postSeasonInitialReplDays: "",
    postSeasonRelevantSalesDays: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "EditSeasonType.Heading";
    values = {
      // name: get(seasonType, "name"),
      name: get(salesChannelSeasonType, "salesChannel.name"),
      brandId: get(seasonType, "brandId"),
      clientId: get(salesChannelSeasonType, "clientId"),
      channelId: get(salesChannelSeasonType, "salesChannel.channelId"),
      salesChannelSeasonTypeId: get(salesChannelSeasonType, "salesChannelSeasonTypeId"),
      // pre
      preSeasonMaxReplDays: get(salesChannelSeasonType, "payload.preSeasonMaxReplDays", ""),
      preSeasonMinProbability: get(salesChannelSeasonType, "payload.preSeasonMinProbability", ""),
      preSeasonInitialReplDays: get(salesChannelSeasonType, "payload.preSeasonInitialReplDays", ""),
      preSeasonRelevantSalesDays: get(salesChannelSeasonType, "payload.preSeasonRelevantSalesDays", ""),
      // main
      mainSeasonMaxReplDays: get(salesChannelSeasonType, "payload.mainSeasonMaxReplDays", ""),
      mainSeasonMinProbability: get(salesChannelSeasonType, "payload.mainSeasonMinProbability", ""),
      mainSeasonInitialReplDays: get(salesChannelSeasonType, "payload.mainSeasonInitialReplDays", ""),
      mainSeasonRelevantSalesDays: get(salesChannelSeasonType, "payload.mainSeasonRelevantSalesDays", ""),
      // post
      postSeasonMaxReplDays: get(salesChannelSeasonType, "payload.postSeasonMaxReplDays", ""),
      postSeasonMinProbability: get(salesChannelSeasonType, "payload.postSeasonMinProbability", ""),
      postSeasonInitialReplDays: get(salesChannelSeasonType, "payload.postSeasonInitialReplDays", ""),
      postSeasonRelevantSalesDays: get(salesChannelSeasonType, "payload.postSeasonRelevantSalesDays", "")
    }
  }

  useEffect(() => {

    async function fetchData() {
      try {
        let resST = await (new BaseService('/SeasonTypes')).getAll('', { search: props.match.params.seasonTypeId })

        let res = await (new BaseService('/SeasonTypes/SalesChannels/')).getAll(props.match.params.salesChannelSeasonTypeId)

        let seasonType = find(resST.seasonTypes, (data) => data.seasonTypeId === props.match.params.seasonTypeId);

        let salesChannelSeasonType = find(res.salesChannelSeasonTypeRes, (data) => data.salesChannelSeasonTypeId === props.match.params.salesChannelSeasonTypeId);
        console.info('salesChannelSeasonType =======>', salesChannelSeasonType);

        setSalesChannelSeasonType(salesChannelSeasonType)
        setSeasonType(seasonType)
      } catch (error) {
        setError(error)
      }
    }
    fetchData();

    // getSeasonType({ search: props.match.params.seasonTypeId })
    //   .then(res => {
    //     let seasonType = find(res.seasonTypes, (data) => data.seasonTypeId === props.match.params.seasonTypeId);
    //     setSeasonType(seasonType)
    //   }).catch(err => setError(err))

    // }, [props.match.params.seasonTypeId])
  }, [props.match.params.salesChannelSeasonTypeId])

  const handleAddForm = async (data) => {

    let payload = {
      name: data.name,
      payload: {
        preSeasonMaxReplDays: parseInt(data.preSeasonMaxReplDays),
        preSeasonInitialReplDays: parseInt(data.preSeasonInitialReplDays),
        preSeasonRelevantSalesDays: parseInt(data.preSeasonRelevantSalesDays),
        mainSeasonMaxReplDays: parseInt(data.mainSeasonMaxReplDays),
        mainSeasonInitialReplDays: parseInt(data.mainSeasonInitialReplDays),
        mainSeasonRelevantSalesDays: parseInt(data.mainSeasonRelevantSalesDays),
        postSeasonMaxReplDays: parseInt(data.postSeasonMaxReplDays),
        postSeasonInitialReplDays: parseInt(data.postSeasonInitialReplDays),
        postSeasonRelevantSalesDays: parseInt(data.postSeasonRelevantSalesDays),
        preSeasonMinProbability: parseInt(data.preSeasonMinProbability),
        postSeasonMinProbability: parseInt(data.postSeasonMinProbability),
        mainSeasonMinProbability: parseInt(data.mainSeasonMinProbability)
      },
      brandId: data.brandId,
    }

    try {
      (new BaseService('/SeasonTypes')).create(payload)
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/seasons")
    } catch (error) {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    }
    // await addSeasonType(payload).then(resp => {
    //   toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
    //   props.history.push("/seasonType")
    // }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
  }

  const handleEditForm = async (data) => {

    // console.info('data =>', data);

    let payload = {
      name: data.name,
      channelId: data.channelId,
      // channelId: props.match.params.channelId,
      seasonTypeId: props.match.params.seasonTypeId,
      salesChannelSeasonTypeId: props.match.params.salesChannelSeasonTypeId,
      payload: {
        preSeasonMaxReplDays: parseInt(data.preSeasonMaxReplDays),
        preSeasonInitialReplDays: parseInt(data.preSeasonInitialReplDays),
        preSeasonRelevantSalesDays: parseInt(data.preSeasonRelevantSalesDays),
        mainSeasonMaxReplDays: parseInt(data.mainSeasonMaxReplDays),
        mainSeasonInitialReplDays: parseInt(data.mainSeasonInitialReplDays),
        mainSeasonRelevantSalesDays: parseInt(data.mainSeasonRelevantSalesDays),
        postSeasonMaxReplDays: parseInt(data.postSeasonMaxReplDays),
        postSeasonInitialReplDays: parseInt(data.postSeasonInitialReplDays),
        postSeasonRelevantSalesDays: parseInt(data.postSeasonRelevantSalesDays),
        preSeasonMinProbability: parseInt(data.preSeasonMinProbability),
        postSeasonMinProbability: parseInt(data.postSeasonMinProbability),
        mainSeasonMinProbability: parseInt(data.mainSeasonMinProbability)
      },
      brandId: data.brandId,
      clientId: data.clientId
    }

    console.info('payload =>', payload);


    try {
      (new BaseService('/SeasonTypes/SalesChannels')).update(props.match.params.salesChannelSeasonTypeId, payload)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      // props.history.push("/seasonType")
      props.history.push(`/seasonType/${props.match.params.seasonTypeId}`)
    } catch (error) {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }

    // updateSeasonType(payload).then(resp => {
    //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    //   props.history.push("/seasonType")
    // }).catch(err => {
    //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    // })
  }

  const validation = (data) => {

    let errors = {}
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (!data.brandId || data.brandId === "") {
      errors.brandId = "Required";
    }
    if (!data.preSeasonMaxReplDays || data.preSeasonMaxReplDays === "") {
      errors.preSeasonMaxReplDays = "Required";
    }
    if (!data.preSeasonMinProbability || data.preSeasonMinProbability === "") {
      errors.preSeasonMinProbability = "Required";
    }
    if (!data.preSeasonInitialReplDays || data.preSeasonInitialReplDays === "") {
      errors.preSeasonInitialReplDays = "Required";
    }
    if (!data.preSeasonRelevantSalesDays || data.preSeasonRelevantSalesDays === "") {
      errors.preSeasonRelevantSalesDays = "Required";
    }
    if (!data.mainSeasonMaxReplDays || data.mainSeasonMaxReplDays === "") {
      errors.mainSeasonMaxReplDays = "Required";
    }
    if (!data.mainSeasonMinProbability || data.mainSeasonMinProbability === "") {
      errors.mainSeasonMinProbability = "Required";
    }
    if (!data.mainSeasonInitialReplDays || data.mainSeasonInitialReplDays === "") {
      errors.mainSeasonInitialReplDays = "Required";
    }
    if (!data.postSeasonMaxReplDays || data.postSeasonMaxReplDays === "") {
      errors.postSeasonMaxReplDays = "Required";
    }
    if (!data.mainSeasonRelevantSalesDays || data.mainSeasonRelevantSalesDays === "") {
      errors.mainSeasonRelevantSalesDays = "Required";
    }
    if (!data.postSeasonMinProbability || data.postSeasonMinProbability === "") {
      errors.postSeasonMinProbability = "Required";
    }
    if (!data.postSeasonInitialReplDays || data.postSeasonInitialReplDays === "") {
      errors.postSeasonInitialReplDays = "Required";
    }
    if (!data.postSeasonRelevantSalesDays || data.postSeasonRelevantSalesDays === "") {
      errors.postSeasonRelevantSalesDays = "Required";
    }

    return errors
  }

  const formatMessage = (id) => props.intl.formatMessage({ id });

  const generateBrandOptions = (options) => {
    var _options = [<option option value={""} > Select Brand</option>]
    options.map(({ name, brandId }, index) =>
      _options.push(<option value={brandId}>{name}</option>)
    )

    return _options;
  }

  return (
    <div className="main-content seasonWrapper">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/seasonType"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      {/* <PageHeading backURL="/seasonType" headingId={heading} /> */}
      {/* <PageHeading backURL={`/seasonType/salesChannel/${props.match.params.seasonTypeId}`} headingId={heading} /> */}
      <PageHeading backURL={`/seasonType/${props.match.params.seasonTypeId}`} headingId={heading} />
      <div className="card p-3">
        <Form
          onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
          initialValues={values} validate={(data) => validation(data)}>
          {({ handleSubmit, form, errors, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                {/* <pre>{
                  JSON.stringify(values, null, 2)
                }</pre> */}
                <div className="form">
                  {/* line 1 */}
                  <div className="row">
                    {/* <div className="col-12 col-lg"> */}
                    {/* <Field name="name">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("Name", "Name")} </label>
                            <input {...input} type="text" placeholder={formatMessage("Name")} className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field> 
                    </div>
                      */}

                    {/* <div className="col-12 col-lg">
                      <Field name="brandId">
                        {({ input, meta }) =>
                          <div className="form-group">
                            <label> {renderFormatMsg("Brand", "Brand")}</label>
                            <select {...input} className="form-control" >
                              {
                                generateBrandOptions(brands)
                              }
                            </select>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        }
                      </Field>
                    </div> */}
                    <InputField colClass="col-12 col-lg" readOnly={true} name={"name"} type="text" id="Name" defaultLabel="Name" placeholder={formatMessage("Name")} className="read-only form-control" />
                    <SelectField
                      colClass="col-12 col-lg"
                      readOnly={true}
                      name="brandId"
                      options={generateBrandOptions(brands)}
                      id="Brand"
                      defaultLabel={"Brand"}
                      className="read-only form-control"
                    />
                  </div>
                  {/* line 2 */}

                  {/* pre */}
                  <div className="card mt-3">
                    <div className="cardHeading"><h6 className="">{renderFormatMsg("PreSeason", "Pre Season")}</h6></div>
                    <div className="card-body pb-2">
                      <div className="row ">
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass={`col-11 pr-0`}
                              name={"preSeasonMaxReplDays"}
                              type="number"
                              id="preSeasonMaxReplDays"
                              defaultLabel="Max. Repl. Days"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("MaxReplDaysPostSeason")}
                            />
                            {/*<div className="col-11 pr-0">
                               <Field name="preSeasonMaxReplDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("preSeasonMaxReplDays", "Max. Repl. Days")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("preSeasonMaxReplDays")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                            </div>*/}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("maxReplenishmentDays", INFO.MAX_REPLENISHMENT_DAYS)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"preSeasonMinProbability"}
                              type="text"
                              id="MinSalesProbabilityPreSeason"
                              defaultLabel="Min. Sales Probability"
                              placeholder={formatMessage("MinSalesProbabilityPreSeason")}
                              iconText="%"
                              max={100}
                              min={0}
                            />
                            {/*<div className="col-11 pr-0">                              
                               <Field name="preSeasonMinProbability">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                     <label>
                                      {renderFormatMsg("MinSalesProbabilityPreSeason", "Min. Sales Probability")}
                                    </label>
                                    <InputGroup className="aa">
                                      <input {...input} type="text" max={100} min={0} placeholder={formatMessage("MinSalesProbabilityPreSeason")} className="form-control" />
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>%</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>

                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                            </div>*/}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("minSalesProbability", INFO.MIN_SALES_PROBABILITY)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* line 3 */}
                      <div className="row ">
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            {/* <div className="col-11 pr-0">
                              <Field name="preSeasonInitialReplDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("MaxReplPeriodPreSeason", "Initial Repl. period")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("MaxReplPeriodPreSeason")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                            </div>*/}

                            <InputField
                              colClass="col-11 pr-0"
                              name={"preSeasonInitialReplDays"}
                              type="number"
                              id="MaxReplPeriodPreSeason"
                              defaultLabel="Initial Repl. period"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("SalesPeriodForForecastPreSeason")}
                            />
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="right"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("initialReplPeriod", INFO.INITIAL_REPL_PERIOD)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>

                          </div>
                        </div>
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"preSeasonRelevantSalesDays"}
                              type="number"
                              id="SalesPeriodForForecastPreSeason"
                              defaultLabel="Sales period for forecast"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("MaxReplPeriodPreSeason")}
                            />
                            {/* <div className="col-11 pr-0">
                              <Field name="preSeasonRelevantSalesDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("SalesPeriodForForecastPreSeason", "Sales period for forecast")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("SalesPeriodForForecastPreSeason")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                            </div>*/}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("salesPeriodForForecast", INFO.SALES_PERIOD_FOR_FORECAST)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* line 4 */}
                    </div>
                  </div>

                  {/* main */}
                  <div className="card mt-3">
                    <div className="cardHeading"><h6 className="">{renderFormatMsg("MainSeason", "Main Season")}</h6></div>
                    <div className="card-body pb-2">

                      <div className="row">
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"mainSeasonMaxReplDays"}
                              type="number"
                              id="MaxReplDaysMainSeason"
                              defaultLabel="Max. Repl. Days"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("MaxReplDaysMainSeason")}
                            />
                            <div className="">


                              {/* <Field name="mainSeasonMaxReplDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("MaxReplDaysMainSeason", "Max. Repl. Days")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("MaxReplDaysMainSeason")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> */}
                            </div>
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("maxReplenishmentDays", INFO.MAX_REPLENISHMENT_DAYS)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            {/*<div className="col-11 pr-0">
                               <Field name="mainSeasonMinProbability">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("MinSalesProbabilityMainSeason", "Min. Sales Probability")} </label>

                                    <InputGroup className="mb-2">
                                      <input {...input} type="number" max={100} min={0} placeholder={formatMessage("MinSalesProbabilityMainSeason")} className="form-control" />
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>%</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                            </div>*/}
                            <InputField
                              colClass="col-11 pr-0"
                              name={"mainSeasonMinProbability"}
                              type="number"
                              id="MinSalesProbabilityMainSeason"
                              defaultLabel="Min. Sales Probability"
                              placeholder={formatMessage("MinSalesProbabilityMainSeason")}
                              iconText="%"
                              max={100}
                              min={0}
                            />
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("minSalesProbability", INFO.MIN_SALES_PROBABILITY)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                      {/* line 5 */}
                      <div className="row">
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"mainSeasonInitialReplDays"}
                              type="number"
                              id="MaxReplPeriodMainSeason"
                              defaultLabel="Initial Repl. period"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("MaxReplPeriodMainSeason")}
                            />
                            {/*<div className="col-11 pr-0">
                              <Field name="mainSeasonInitialReplDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("MaxReplPeriodMainSeason", "Initial Repl. period")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("MaxReplPeriodMainSeason")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                            </div>*/}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("initialReplPeriod", INFO.INITIAL_REPL_PERIOD)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"mainSeasonRelevantSalesDays"}
                              type="number"
                              id="SalesPeriodForForecastMainSeason"
                              defaultLabel="Sales period for forecast"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("SalesPeriodForForecastMainSeason")}
                            />

                            {/* 
                            <div className="col-11 pr-0">
                              <Field name="mainSeasonRelevantSalesDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("SalesPeriodForForecastMainSeason", "Sales period for forecast")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("SalesPeriodForForecastMainSeason")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field>
                            </div>
                               */}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("salesPeriodForForecast", INFO.SALES_PERIOD_FOR_FORECAST)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  {/* line 6 */}

                  {/* Post */}
                  <div className="card mt-3">
                    <div className="cardHeading"><h6 className="">{renderFormatMsg("PostSeason", "Post Season")}</h6></div>
                    <div className="card-body pb-2">
                      <div className="row">
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"postSeasonMaxReplDays"}
                              type="number"
                              id="MaxReplDaysPostSeason"
                              defaultLabel="Max. Repl. Days"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("MaxReplDaysPostSeason")}
                            />
                            {/* 
                            <div className="col-11 pr-0">

                              <Field name="postSeasonMaxReplDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("MaxReplDaysPostSeason", "Max. Repl. Days")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("MaxReplDaysPostSeason")}
                                      className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                            </div>
                              */}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("maxReplenishmentDays", INFO.MAX_REPLENISHMENT_DAYS)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"postSeasonMinProbability"}
                              type="number"
                              id="MinSalesProbabilityPostSeason"
                              defaultLabel="Min. Sales Probability"
                              placeholder={formatMessage("MinSalesProbabilityPostSeason")}
                              iconText="%"
                              max={100}
                              min={0}
                            />
                            {/* 
                            <div className="col-11 pr-0">
                              <Field name="postSeasonMinProbability">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("MinSalesProbabilityPostSeason", "Min. Sales Probability")} </label>
                                    <InputGroup className="mb-2">
                                      <input {...input} type="number" max={100} min={0} placeholder={formatMessage("MinSalesProbabilityPostSeason")} className="form-control" />
                                      <InputGroup.Prepend>
                                        <InputGroup.Text>%</InputGroup.Text>
                                      </InputGroup.Prepend>
                                    </InputGroup>
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field>
                            </div>
                              */}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("minSalesProbability", INFO.MIN_SALES_PROBABILITY)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>

                      {/* line 7 */}
                      <div className="row">
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"postSeasonInitialReplDays"}
                              type="number"
                              id="MaxReplPeriodPostSeason"
                              defaultLabel="Initial Repl. period"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("MaxReplPeriodPostSeason")}
                            // iconText="%"
                            />
                            {/* 
                                          <div className="col-11 pr-0">
                              <Field name="postSeasonInitialReplDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("MaxReplPeriodPostSeason", "Initial Repl. period")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("MaxReplPeriodPostSeason")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                          </div>
                              */}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("initialReplPeriod", INFO.INITIAL_REPL_PERIOD)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-lg">
                          <div className="row align-items-center">
                            <InputField
                              colClass="col-11 pr-0"
                              name={"postSeasonRelevantSalesDays"}
                              type="number"
                              id="SalesPeriodForForecastPostSeason"
                              defaultLabel="Sales period for forecast"
                              iconText={formatMessage("days")}
                              placeholder={formatMessage("SalesPeriodForForecastPostSeason")}
                            />
                            {/* 
                                      <div className="col-11 pr-0">
                              <Field name="postSeasonRelevantSalesDays">
                                {({ input, meta }) => (
                                  <div className="form-group">
                                    <label> {renderFormatMsg("SalesPeriodForForecastPostSeason", "Sales period for forecast")} </label>
                                    <input {...input} type="number" placeholder={formatMessage("SalesPeriodForForecastPostSeason")} className="form-control" />
                                    <FieldErrorMessage meta={meta} />
                                  </div>
                                )}
                              </Field> 
                          </div>
                              */}
                            <div className="col-1 p-0">
                              <OverlayTrigger
                                placement="top"
                                delay={{ show: 100, hide: 400 }}
                                overlay={(props) => (
                                  <Tooltip id="button-tooltip" {...props}>
                                    {renderFormatMsg("salesPeriodForForecast", INFO.SALES_PERIOD_FOR_FORECAST)}
                                  </Tooltip>
                                )}
                              >
                                <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                              </OverlayTrigger>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                </div>
                <div className="row d-flex justify-content-end mt-2">
                  <div className="col-12 col-lg-auto text-right">
                    <div className="submit_btn">
                      <button type="submit" className="btn btn-primary" > {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </div >
  )
}
export default injectIntl(React.memo(HandleSeasonType))
