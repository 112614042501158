import React, { useState } from 'react'
import { renderFormatMsg } from '../../shared/funs';
import { getProductSizeName } from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../ReactTable/ReactTable';
import Error from '../Error/Error';

const SizeBowl = ({ values, form }) => {
    const [size, setSize] = useState([])
    // eslint-disable-next-line
    const [sizes, setSizes] = useState(values.Size ? values.Size : [])
    const [error, setError] = useState(false)
    const handleChecked = (name, data) => {
        sizes.findIndex(d => d.sizeId === data.optionSizeId) >= 0 ? sizes.splice(sizes.findIndex(item =>
            item.sizeId === data.optionSizeId), 1) : sizes.push({ [name]: data.optionSizeId })
        form.change("size", [...new Set([...sizes])]);
    }
    const columns = [
        {
            Header: <div className="text-left"> {renderFormatMsg("SizeCurveName", "Size Curve Name")} </div>,
            accessor: 'name',
            style: { justifyContent: "flex-start" }
        }, {
            Header: renderFormatMsg("ListOfSizes", "List of Sizes"),
            accessor: 'sizeList',
            //resizable: false,
            width: 350,
            style: { display: "flex", justifyContent: "flex-start" },
            Cell: props => props.original.sizeList.join(", ")
        },
        {
            Header: renderFormatMsg("Include", "Include"),
            accessor: 'Include',
            //resizable: false,
            width: 130,
            Cell: (props) => <div className="checkbox">
                <input type="checkbox" name="sizeId"
                    checked={sizes.findIndex(d => d.sizeId === props.original.optionSizeId) >= 0 ? true : false} onChange={(e) => handleChecked(e.target.name, props.original)}
                />
                <label for="checkbox"></label>
            </div>
        }
    ]
    const fetchData = (state, instance) => {
        getProductSizeName({ "fromDefault": true }).then(res => {
            setSize(res.length > 0 ? res : [])
        }).catch(err => {
            setError(err);
            console.info("error => ", error)
        })
    }
    return (
        <div className="card">
            {error ? <Error /> : <ReactTable data={size} columns={columns} onFetchData={fetchData} />}
        </div>
    )
}

export default React.memo(SizeBowl)
