import React, { useState, useEffect } from 'react'
import { get } from "lodash"
import moment from 'moment';
import { PAGE_SIZE, DATE_FORMAT } from '../../constant';
import { renderFormatMsg, toastSuccess, toastError } from '../../shared/funs';
import { Form } from 'react-final-form';
import ActiveFromAndToDate from '../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import Error from '../../components/Error/Error';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';
import SelectField from '../../components/Fields/SelectField';


const HandleSalesChannels = (props) => {
    const [isLoading, setLoading] = useState(false)
    const [channel, setChannel] = useState(null)
    const [error, setError] = useState(false)
    const [brandOptions, setBrandOptions] = useState([])


    let heading = "AddNewSalesChannels"
    var values = {
        name: "",
        brandId: "",
        activeFrom: "",
        activeTo: ""
    }

    if (props.location.pathname.includes("Edit")) {
        heading = "EditSalesChannels"
        values = {
            name: get(channel, "name"),
            brandId: get(channel, "brandId"),
            activeFrom: get(channel, "activeFrom"),
            activeTo: get(channel, "activeTo")
        }
    }


    useEffect(() => {
        if (props.location.pathname.includes("Edit")) {
            let param = { "page": 1, "display": PAGE_SIZE, "search": props.match.params.channelId }
            async function fetchChannelData() {
                try {
                    let res = await (new BaseService('/SalesChannels')).getAll('', param)
                    setChannel(res.salesChannelRes[0])
                    setLoading(false)
                } catch (error) {
                    setLoading(false)
                    setError(error)
                }
            }
            fetchChannelData();
        }

        async function fetchBrandData() {
            try {
                let res = await (new BaseService('/Brand/brand')).getAll('')
                setBrandOptions(res.brands)
            } catch (error) {
                console.log('error => ', error)
            }
        }
        fetchBrandData();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])


    const validation = (data) => {
        let errors = {}
        if (!data.name || data.name === "") {
            errors.name = "Required";
        }
        if (!data.brandId || data.brandId === "") {
            errors.brandId = "Required";
        }

        if (!data.activeFrom || data.activeFrom === "") {
            errors.activeFrom = "Required";
        }
        if (!data.activeTo || data.activeTo === "") {
            errors.activeTo = "Required";
        }
        return errors
    }

    const handleForm = async (data) => {

        let payload = {
            "name": get(data, "name"),
            "brandId": get(data, "brandId"),
            "activeFrom": moment(get(data, "activeFrom")).format(DATE_FORMAT),
            "activeTo": moment(get(data, "activeTo")).format(DATE_FORMAT)
        }
        if (props.location.pathname.includes("Add")) {
            try {
                (new BaseService('/SalesChannels')).create(payload)
                toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
                props.history.push("/sales-channels")
            } catch (error) {
                toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
            }
        } else {
            payload.channelId = props.match.params.channelId
            try {
                (new BaseService('/SalesChannels')).update(props.match.params.channelId, payload)
                toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
                props.history.push("/sales-channels")
            } catch (error) {
                toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
            }
        }
    }

    const generateBrandOptions = (options) => {
        var _options = [<option option value={""} > Select Brand</option>]
        options.map(({ name, brandId }, index) =>
            _options.push(<option value={brandId}>{name}</option>)
        )

        return _options;
    }


    return (
        <div className="main-content">
            <PageHeading backURL="/sales-channels" headingId={heading} isLoading={isLoading} />
            <div className="card p-3">
                {error ? <Error msg={error} /> : <Form onSubmit={handleForm} initialValues={values} validate={validation}>
                    {({ handleSubmit, form, submitting, pristine, values }) => {
                        return (
                            <form onSubmit={handleSubmit} className="">
                                <div className="form">
                                    <div className="row">
                                        <InputField
                                            colClass="col-lg-6"
                                            name={"name"}
                                            type="text"
                                            id="ChannelName"
                                            defaultLabel="Channel Name"
                                            placeholder={"Channel Name"}
                                        />
                                        <SelectField
                                            colClass="col-lg-5"
                                            name="brandId"
                                            options={generateBrandOptions(brandOptions)}
                                            id="Brand"
                                            defaultLabel={"Brand"}
                                        />
                                        <div className="col-lg-6"></div>
                                    </div>
                                </div>
                                <ActiveFromAndToDate />
                                <div className="col-12 text-right">
                                    <div className="submit_btn">
                                        <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                                            ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Form>}
            </div>
        </div>
    )
}
export default React.memo(HandleSalesChannels)
