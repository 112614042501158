import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
// import { getSeasonsDetails, updateSeasonsDetails } from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE, FIELD_ACCESS_FUNCTION } from '../../constant';
import Calendar from "../../components/Calendar";
import { get, debounce } from 'lodash';
import { AppContext } from "../../context/AppContext";
import { renderFormatMsg, toastSuccess, toastError, checkPermission, updateActiveFromAndToRow, DateRangeStatus, permissionErrorCheck, findPermission } from '../../shared/funs';
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const Seasons = (props) => {
  const { userDetails } = useContext(AppContext)
  const [seasonsData, setSeasonsData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  const [permissionForCategory2, setPermissionCategory2] = useState({ read: true, write: false })

  let dataArray = get(userDetails, "accesses")
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("STORE_CATEGORY").screenId))
    setPermissionCategory2(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.SEASON_FORM_CATEGORY_2))

  }, [dataArray])
  const getData = async (page = 0, search = "") => {
    setSearchValue(search)
    setLoading(true)
    try {
      let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
      let res = await (new BaseService('/Seasons')).getAll('', param)
      setSeasonsData(res.seasons)
      setTotalPage(res.totalPage)
      setLoading(false)

    } catch (error) {
      setLoading(false)
      setError(permissionErrorCheck(error))
    }
    // getSeasonsDetails(param).then(res => {
    // }).catch(err => {
    // })
  }

  const sendQuery = async (object, data) => {
    try {
      (new BaseService('/Seasons')).update(object.seasonId, object)
      setSeasonsData(data.data)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    } catch (error) {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
    // updateSeasonsDetails(object).then(res => {
    //   setSeasonsData(data.data)
    //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    // }).catch(error => {
    //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    // })
  };

  const delayedQuery = debounce((q, data) => sendQuery(q, data), 1000);

  const handleChangeDate = async (date, details, key) => {
    var data = updateActiveFromAndToRow(seasonsData, key, date, { "seasonId": details.seasonId });
    delayedQuery(data.payload, data)
  }

  const columns = [
    //   {
    //   Header: <div className="text-left"> {renderFormatMsg("seasonsTable.SeasonCode", "Season Code")} </div>,
    //   accessor: 'seasonId',
    //   //resizable: false,
    //   width: 280,
    //   style: { justifyContent: "flex-start" }
    // },
    {
      Header: <div className="text-left"> {renderFormatMsg("seasonsTable.SeasonName", "Season Name")} </div>,
      accessor: 'name',
      //resizable: false,
      width: 300,
      maxWidth: 350,
      style: { justifyContent: "flex-start" },
      Cell: props => <div title={props.original.name}>{props.original.name}</div>
    }, {
      Header: renderFormatMsg("seasonsTable.Year", "Year"),
      accessor: 'year',
      width: 80,
      //resizable: false,
    }, {
      Header: renderFormatMsg("seasonsTable.ActivePhase", "Active Phase"),
      accessor: 'activePhase',
      width: 180,
      //resizable: false,
    }, {
      Header: renderFormatMsg("ActiveFrom", "Active From"),
      accessor: 'activeFrom',
      //resizable: false,
      width: 158,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar disabled={(pageReadOnly.write && permissionForCategory2.write) ? false : true} className={!(permissionForCategory2.write && pageReadOnly.write) && "read-only"} name="activeFrom" selectedDate={new Date(props.original.activeFrom)} maxDate={new Date(props.original.activeTo)} onSelect={date => handleChangeDate(date, props.original, "activeFrom")} /></div>
    }, {
      Header: renderFormatMsg("ActiveTo", "Active to"),
      accessor: 'activeTo',
      //resizable: false,
      width: 158,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar disabled={(pageReadOnly.write && permissionForCategory2.write) ? false : true} className={!(permissionForCategory2.write && pageReadOnly.write) && "read-only"} name="activeTo" selectedDate={new Date(props.original.activeTo)} minDate={new Date(props.original.activeFrom)} onSelect={date => handleChangeDate(date, props.original, "activeTo")} /></ div>
    }]
  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      //resizable: false,
      width: 80,
      Cell: props => <Link to={`seasons/Edit/${props.original.seasonId}`} className="edit-icon"
      >{renderFormatMsg("Edit", "Edit")}</Link>
    })
  }

  const fetchData = (state, instance) => {
    //getting change page number from state
    getData(state.page, searchValue)
    setPageIndex(state.page)
  }

  const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("seasonsTable.Heading", "Seasons")}
      </div> */}
      <PageHeading headingId="seasonsTable.Heading" isLoading={isLoading} />
      <div className="card">
        {error ? <Error msg={error} /> : <>
          <div className="card-header">
            <SearchBox searchHandler={SearchHandler} />
            {pageReadOnly.write &&
              <LinkButton to={`/seasons/Add`} id="AddSeason" defaultText="Add season" />
            }
          </div>
          <ReactTable data={seasonsData} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
        </>
        }
      </div>
    </div>
  )
}
export default React.memo(Seasons)
