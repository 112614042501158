import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import { get } from "lodash"

import {
    // getOutletCategories, deleteOutletCategory
} from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import { AppContext } from "../../context/AppContext";
import {
    renderFormatMsg, checkPermission, toastError, toastSuccess,
    findPermission,
    // permissionErrorCheck,
} from '../../shared/funs';
import Error from "../../components/Error/Error"
import LinkButton from '../../components/Buttons/LinkButton';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const StoreCategory = () => {
    const { userDetails } = useContext(AppContext)
    const [outletCategories, setOutletCategories] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [error,] = useState(false)
    const [deletedId, setDeletedId] = useState(null)
    const [show, setShow] = useState(false);
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
    const [isLoading, setLoading] = useState(false)

    let dataArray = get(userDetails, "accesses")
    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("STORE_CATEGORY").screenId))
    }, [dataArray])

    const handleClose = () => setShow(!show);

    const columns = [
        {
            Header: <div className="text-left">{renderFormatMsg("storeCategoryTable.CategoryName", "Category Name")} </div>,
            accessor: 'name',
            minWidth: 150,
            width: 200,
            style: { justifyContent: "flex-start" }
        },

    ]
    if (pageReadOnly.write) {
        columns.push({
            Header: renderFormatMsg("Edit", "Edit"),
            accessor: 'Edit',
            //resizable: false,
            // width: 130,
            maxWidth: 150,
            minWidth: 100,
            width: 100,
            Cell: (data) => <Link to={`/storeCategory/Edit/${data.original.outletCategoryId}`} className="edit-icon"> {renderFormatMsg("Edit", "Edit")} </Link>
        }, {
            Header: renderFormatMsg("Delete", "Delete"),
            accessor: 'Delete',
            //resizable: false,
            // width: 100,
            maxWidth: 150,
            minWidth: 100,
            width: 100,
            style: { textAlign: "right" },
            Cell: props => <Link to="#" onClick={() => { handleClose(); setDeletedId(props.original.outletCategoryId) }} className="delete-icon"> {renderFormatMsg("Delete", "Delete")} </Link>
        })
    }
    const getData = ({ page = 0 }) => {
        let param = { "page": page + 1, "display": PAGE_SIZE }
        setLoading(true)
        // getOutletCategories(param).then(res => {
        //     setLoading(false)
        //     setOutletCategories(res.outletCategories.length > 0 ? res.outletCategories : [])
        //     setTotalPage(res.totalPage)
        // }).catch(err => {
        //     setLoading(false)
        //     setError(permissionErrorCheck(err))
        // })

        async function fetchOutletCategoriesData() {
            try {
                let res = await (new BaseService('/OutletCategories')).getAll('', param)
                setOutletCategories(res.outletCategories)
                setLoading(false)
                setTotalPage(res.totalPage)
            } catch (error) {
                setLoading(false)
                console.log('error => ', error)
            }
        }
        fetchOutletCategoriesData();

    }
    const fetchData = (state, instance) => {
        //getting change page number from state
        getData({ page: state.page })
    }
    const handleDelete = async () => {
        // await deleteOutletCategory(deletedId).then(async res => {
        //     await getData({ page: 0 })
        //     handleClose()
        //     toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
        // }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
        try {
            await (new BaseService('/OutletCategories')).delete(deletedId)
            handleClose()
            let storeCategories = [...outletCategories];
            storeCategories = storeCategories.filter(sCategory => sCategory.outletCategoryId !== deletedId)
            setOutletCategories(storeCategories)
            toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
        } catch (error) {
            toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail"))
        }
    }
    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("storeCategoryTable.Heading", "Store Categories")}
            </div> */}
            <PageHeading headingId="storeCategoryTable.Heading" isLoading={isLoading} />
            <div className="card">
                {pageReadOnly.write && <div className="card-header">
                    <LinkButton classes={"ml-auto"} to="/storeCategory/Add" id="storeCategoryTable.AddStoreCategory" defaultText="Add Store Category" />
                </div>
                }
                {error ? <Error msg={error} /> : <ReactTable data={outletCategories} columns={columns} onFetchData={fetchData} pages={totalPage} />}
            </div>
            <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
        </div>
    )
}
export default React.memo(StoreCategory);
