import React, { Component } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import Scrollbars from 'react-custom-scrollbars';

// a little function to help us with reordering the result
const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

/**
 * Moves an item from one list to another list.
 */
const move = (source, destination, droppableSource, droppableDestination) => {
  const sourceClone = Array.from(source);
  const destClone = Array.from(destination);
  const [removed] = sourceClone.splice(droppableSource.index, 1);

  destClone.splice(droppableDestination.index, 0, removed);

  const result = {};
  result[droppableSource.droppableId] = sourceClone;
  result[droppableDestination.droppableId] = destClone;

  return result;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  // some basic styles to make the notAssigned look a bit nicer
  userSelect: 'none',
  padding: grid * 2,
  outline: "none",
  margin: `0 0 ${grid}px 0`,

  // change background colour if dragging
  background: isDragging ? 'rgb(255, 255, 255)' : '#eeeeee',

  // styles we need to apply on draggables
  ...draggableStyle
});

const getListStyle = isDraggingOver => ({
  // background: !isDraggingOver ? '#d3d3d382' : '#dddddd',
  background: '#dddddd',
  padding: grid,
  height: 400,
  // overflowY: "scroll"
  // width: "33%"
});

class ScreenDragNDrop extends Component {
  state = {
    notAssigned: [],
    readOnly: [],
    readWrite: [],
  };

  /**
   * A semi-generic way to handle multiple lists. Matches
   * the IDs of the notAssigned container to the names of the
   * source arrays stored in the state.
   */
  id2List = {
    notAssigned: 'notAssigned',
    readOnly: 'readOnly',
    readWrite: 'readWrite',
  };

  getList = id => this.state[this.id2List[id]];

  onDragEnd = result => {
    const { source, destination } = result;

    // dropped outside the list
    if (!destination) {
      return;
    }

    // when destination and source are same
    if (source.droppableId === destination.droppableId) {
      // reorder the list
      const items = reorder(
        this.getList(source.droppableId),
        source.index,
        destination.index
      );

      let state = {};

      if (source.droppableId === 'notAssigned') {
        state = { notAssigned: items };
      }
      if (source.droppableId === 'readOnly') {
        state = { readOnly: items };
      }
      if (source.droppableId === 'readWrite') {
        state = { readWrite: items };
      }

      this.setState(state);
    } else {
      const result = move(
        this.getList(source.droppableId),
        this.getList(destination.droppableId),
        source,
        destination
      );

      this.setState({ ...result }, () => {
        this.props.handleScreenAssign({
          notAssigned: this.state.notAssigned,
          readOnly: this.state.readOnly,
          readWrite: this.state.readWrite
        })
      });
    }
  };

  componentWillReceiveProps(nextProps) {
    this.setState({
      notAssigned: nextProps.screenAssigned.notAssigned,
      readOnly: nextProps.screenAssigned.readOnly,
      readWrite: nextProps.screenAssigned.readWrite,
    })
  }

  render() {
    const { notAssigned = [], readOnly = [], readWrite = [] } = this.state

    return (
      <DragDropContext onDragEnd={this.onDragEnd}>
        <div style={{ width: "33%" }}>
          <h6 className="text-center">Not Assigned</h6>
          <Droppable droppableId="notAssigned">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                <Scrollbars  >
                  {notAssigned.map((item, index) => (
                    <Draggable
                      key={index}
                      // draggableId={item.screenId}
                      draggableId={`notAssigned-${index}`}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                          <div className="row">
                            <div className="col-1">
                              <i className={item.icon}></i>
                            </div>
                            <div className="col-10">
                              {item.screenName}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </Scrollbars>
              </div>

            )}
          </Droppable>
        </div>
        <div style={{ width: "33%" }}>
          <h6 className="text-center">Read Only</h6>
          <Droppable droppableId="readOnly">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                <Scrollbars  >
                  {readOnly.map((item, index) => (
                    <Draggable
                      key={index}
                      // draggableId={item.screenId}
                      draggableId={`readOnly-${index}`}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                          <div className="row">
                            <div className="col-1">
                              <i className={item.icon}></i>
                            </div>
                            <div className="col-10">
                              {item.screenName}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Scrollbars>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
        <div style={{ width: "33%" }}>
          <h6 className="text-center">Read Write</h6>
          <Droppable droppableId="readWrite">
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}>
                <Scrollbars  >
                  {readWrite.map((item, index) => (
                    <Draggable
                      key={index}
                      draggableId={`readWrite-${index}`}
                      index={index}>
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          {...provided.dragHandleProps}
                          style={getItemStyle(
                            snapshot.isDragging,
                            provided.draggableProps.style
                          )}>
                          <div className="row">
                            <div className="col-1">
                              <i className={item.icon}></i>
                            </div>
                            <div className="col-10">
                              {item.screenName}
                            </div>
                          </div>
                        </div>
                      )}
                    </Draggable>
                  ))}
                </Scrollbars>
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </div>
      </DragDropContext>
    );
  }
}
export default ScreenDragNDrop
