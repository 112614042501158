import React, { useState, useEffect, useContext } from 'react'
// import { Link } from "react-router-dom"
import { get } from "lodash"
import moment from 'moment';
import {
  // getOutletData, getOutletCategories, updateOutletData, addOutletData, getCity,
  // getBrand
} from '../../../services/toolsAndSettingsPagesApi';
import { DATE_FORMAT, FIELD_ACCESS_FUNCTION, URL_PATH } from '../../../constant';
import { renderFormatMsg, toastSuccess, toastError, selectStyle, checkPermission } from '../../../shared/funs';
// import BackIcon from "../../../images/back-icon.svg";
import { Field, Form } from 'react-final-form';
import ActiveFromAndToDate from '../../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import Error from '../../../components/Error/Error';
import { AppContext } from '../../../context/AppContext';
import FieldErrorMessage from '../../../components/FieldErrorMessage';
import InputField from '../../../components/Fields/InputField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';


const HandleSeason = (props) => {

  const [outlet, setOutlet] = useState(null)
  const [city, setCity] = useState([])
  const [outletCategories, setOutletCategories] = useState([])
  const [brands, setBrands] = useState([])
  const [salesChannelRes, setChannels] = useState([])
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { userDetails } = useContext(AppContext)
  const [permissionForCategory1, setPermissionCategory1] = useState({ read: true, write: true })
  const [permissionForCategory2, setPermissionCategory2] = useState({ read: true, write: true })
  let dataArray = get(userDetails, "accesses");
  let PAGE_SIZE = 100;
  useEffect(() => {
    setPermissionCategory1(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.OUTLET_FORM_CATEGORY_1))
    setPermissionCategory2(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.OUTLET_FORM_CATEGORY_2))
  }, [dataArray])

  var heading = "HandleOutlet.Add.Heading"
  var values = {
    outletName: "",
    city: "",
    cityId: "",
    customerOutletId: "",
    // salesChannelName: "",
    activeFrom: "",
    activeTo: "",
    outletCategoryId: "",
    brandId: "",
    channelId: "",
    maxPiecesForReceiving: "",
    maximumCapacityForSpringSummer: "",
    maximumCapacityForAutumnWinter: "",
    maximumReceivingCapacityForSpringSummer: "",
    maximumReceivingCapacityForAutumnWinter: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "HandleOutlet.Edit.Heading";
    console.info(`outlet => `, outlet);
    values = {
      outletName: get(outlet, "outletName"),
      city: JSON.stringify({ name: get(outlet, "city"), cityId: get(outlet, "cityId") }),
      activeFrom: get(outlet, "activeFrom"),
      activeTo: get(outlet, "activeTo"),
      outletCategoryId: get(outlet, "outletCategoryId"),
      brandId: get(outlet, "brandId"),
      channelId: get(outlet, "salesChannel.channelId"),
      customerOutletId: get(outlet, "customerOutletId"),
      // salesChannelName: get(outlet, "salesChannel.name"),
      maxPiecesForReceiving: get(outlet, "payload.maxPiecesForReceiving"),
      maximumCapacityForSpringSummer: get(outlet, "payload.maximumCapacityForSpringSummer"),
      maximumCapacityForAutumnWinter: get(outlet, "payload.maximumCapacityForAutumnWinter"),
      maximumReceivingCapacityForSpringSummer: get(outlet, "payload.maximumReceivingCapacityForSpringSummer"),
      maximumReceivingCapacityForAutumnWinter: get(outlet, "payload.maximumReceivingCapacityForAutumnWinter")
    }
  } else {
  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      // getOutletData({ search: props.match.params.outletId }).then(res => {
      //   setOutlet(res.outlets.length > 0 ? res.outlets[0] : "")
      //   setLoading(false)
      // }).catch(err => {
      //   setLoading(false)
      //   setError(err)
      // })

      async function fetchData() {
        try {
          let res = await (new BaseService(URL_PATH.OUTLET)).getAll('', { search: props.match.params.outletId })
          setOutlet(res.outlets.length > 0 ? res.outlets[0] : "")
          setLoading(false)
        } catch (error) {
          setLoading(false)
          setError(error)
        }
      }
      fetchData();
    }
    // getCity({ fromDefault: true }).then(res => setCity(res)).catch(error => { console.log('error => ', error) })
    async function fetchCityData() {
      try {
        let res = await (new BaseService(URL_PATH.CITY)).getAll('', { fromDefault: true })
        setCity(res)
      } catch (error) {
        console.log('error => ', error)
      }
    }
    fetchCityData();

    // getOutletCategories().then(res => {
    //   setOutletCategories(res.outletCategories)
    // }).catch(error => console.log('error => ', error))
    // getBrand().then(res => {
    //   setBrands(res.brands)
    // }).catch(error => console.log('error => ', error))
    async function fetchBrandData() {
      try {
        let res = await (new BaseService(URL_PATH.BRAND)).getAll('')
        setBrands(res.brands)
      } catch (error) {
        console.log('error => ', error)
      }
    }
    fetchBrandData();

    async function fetchChannelData() {
      try {
        let res = await (new BaseService(URL_PATH.SALESCHANNELS)).getAll('')
        setChannels(res.salesChannelRes)
      } catch (error) {
        console.log('error => ', error)
      }
    }
    fetchChannelData();

    async function fetchOutletCategoriesData() {
      let param = { "page": 1, "display": PAGE_SIZE }
      try {
        let res = await (new BaseService(URL_PATH.OUTLET_CATEGORY)).getAll('', param)
        setOutletCategories(res.outletCategories)
      } catch (error) {
        console.log('error => ', error)
      }
    }
    fetchOutletCategoriesData();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const validation = (data) => {
    let errors = {}

    if (permissionForCategory1.write) {
      if (!data.customerOutletId || data.customerOutletId === "") {
        errors.customerOutletId = "Required";
      }
      if (!data.outletName || data.outletName === "") {
        errors.outletName = "Required";
      }
      if (!data.city || data.city === "") {
        errors.city = "Required";
      }
      if (!data.brandId || data.brandId === "") {
        errors.brandId = "Required";
      }
      if (!data.channelId || data.channelId === "") {
        errors.channelId = "Required";
      }
      if (!data.outletCategoryId || data.outletCategoryId === "") {
        errors.outletCategoryId = "Required";
      }
      if (!data.activeFrom || data.activeFrom === "") {
        errors.activeFrom = "Required";
      }
      if (!data.activeTo || data.activeTo === "") {
        errors.activeTo = "Required";
      }
    }

    return errors
  }

  const handleAddForm = async (data) => {
    var city = JSON.parse(data.city)
    let payload = {
      "outletName": data.outletName,
      "city": city.name,
      "cityId": city.cityId,
      "payload": {
        "maximumCapacityForSpringSummer": data.maximumCapacityForSpringSummer,
        "maximumCapacityForAutumnWinter": data.maximumCapacityForAutumnWinter,
        "maximumReceivingCapacityForSpringSummer": data.maximumReceivingCapacityForSpringSummer,
        "maximumReceivingCapacityForAutumnWinter": data.maximumReceivingCapacityForAutumnWinter,
      },
      "activeFrom": data.activeFrom,
      "activeTo": data.activeTo,
      "customerOutletId": data.customerOutletId,
      "outletCategoryId": data.outletCategoryId,
      "brandId": data.brandId,
      "channelId": data.channelId
    }

    // await addOutletData(payload).then(res => {
    //   toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
    //   props.history.push("/outlet")
    // }).catch(err => {
    //   toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    // }
    // )

    try {
      (new BaseService('/Outlets')).create(payload)
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/outlet")
    } catch (error) {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    }
  }
  const handleEditForm = async (data) => {
    console.info(`data.city => `, data.city);
    var _city = JSON.parse(data.city)
    console.info(`_city => `, _city);
    let payload = {
      "outletId": props.match.params.outletId,
      "outletName": data.outletName,
      "city": _city.name,
      "cityId": _city.cityId,
      "activeFrom": moment(data.activeFrom).format(DATE_FORMAT),
      "activeTo": moment(data.activeTo).format(DATE_FORMAT),
      "customerOutletId": data.customerOutletId,
      // "salesChannelName": data.salesChannelName,
      "brandId": data.brandId,
      "channelId": data.channelId,
      "payload": {
        "maximumCapacityForSpringSummer": data.maximumCapacityForSpringSummer,
        "maximumCapacityForAutumnWinter": data.maximumCapacityForAutumnWinter,
        "maximumReceivingCapacityForSpringSummer": data.maximumReceivingCapacityForSpringSummer,
        "maximumReceivingCapacityForAutumnWinter": data.maximumReceivingCapacityForAutumnWinter
      },
      "outletCategoryId": data.outletCategoryId,
    }
    try {
      (new BaseService('/Outlets')).update(props.match.params.outletId, payload)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/outlet")
    } catch (error) {
      toastSuccess(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
    // await updateOutletData(payload).then(res => {
    //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    //   props.history.push("/outlet")
    // }).catch(err => {
    //   toastSuccess(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    // })
  }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <Link className="back-btn" to="/outlet"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/outlet" headingId={heading} isLoading={isLoading} />
      <div className="card p-3">
        {error ? <Error msg={error} /> :
          <Form onSubmit={props.location.pathname.includes("Edit") ? handleEditForm : handleAddForm} initialValues={values} validate={validation}>
            {({ handleSubmit, form, submitting, pristine, values, errors }) => {
              return (
                <form onSubmit={handleSubmit} className="">
                  <div className="form">
                    <div className="row">
                      <pre>
                        {/* {
                          JSON.stringify(values)
                        } */}
                      </pre>
                      {/* <div className="col-lg-6">
                        <Field name="customerOutletId">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label>{renderFormatMsg("HandleOutlet.Add.OutletId", "Outlet Id")}</label>
                              <input readOnly={!permissionForCategory1.write} {...input} type="text" placeholder="Outlet Id" className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}

                      <InputField
                        colClass="col-lg-6"
                        name={"customerOutletId"}
                        type="text"
                        id="HandleOutlet.Add.OutletId"
                        defaultLabel="Outlet Id"
                        placeholder={"Outlet Id"}
                        readOnly={!permissionForCategory1.write}
                        className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`}
                      />

                      <InputField
                        colClass="col-lg-6"
                        name={"outletName"}
                        type="text"
                        id="outletTable.OutletName"
                        defaultLabel="Outlet Id"
                        placeholder={"Outlet Name"}
                        readOnly={!permissionForCategory1.write}
                        className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`}
                      />

                      {/* <div className="col-lg-6">
                        <Field name="outletName">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label>
                                {renderFormatMsg("outletTable.OutletName", "Outlet Name")}
                              </label>
                              <input readOnly={!permissionForCategory1.write} {...input} type="text" placeholder="Outlet Name" className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <Field name="city" component="select" options={city && city}>
                          {({ input, meta, options }) =>
                            <div className="form-group">
                              <label> {renderFormatMsg("outletTable.City", "City")}</label>
                              <select {...input} disabled={!permissionForCategory1.write} className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} style={selectStyle(input)} name={get(options, "name")}>
                                <option value="" selected>Select City</option>
                                {options && options.map((x, i) => {
                                  return <option selected={input.value && JSON.parse(input.value).name === x.name} key={i} value={JSON.stringify({ name: x.name, cityId: x.cityId })}>{x.name}</option>
                                })}
                              </select>
                              <FieldErrorMessage meta={meta} />
                            </div>
                          }
                        </Field>
                      </div>

                      <div className="col-lg-6"></div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <Field name="brandId" component="select" options={brands && brands}>
                          {({ input, meta, options }) =>
                            <div className="form-group">
                              <label> {renderFormatMsg("HandleOutlet.brandId", "Brand")}</label>
                              <select disabled={!permissionForCategory1.write} style={selectStyle(input)} className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} name={options && options.name} onChange={(value) => input.onChange(value)}>
                                <option value={0}>Select Brand</option> {options && options.map((x, i) => {
                                  return <option selected={input.value === x.brandId} key={i} value={x.brandId}>{x.name}
                                  </option>
                                })}
                              </select>
                              <FieldErrorMessage meta={meta} />
                            </div>
                          }
                        </Field>
                      </div>

                      <div className="col-lg-6">
                        <Field name="outletCategoryId" component="select" options={outletCategories && outletCategories}>
                          {({ input, meta, options }) =>
                            <div className="form-group">
                              <label> {renderFormatMsg("HandleOutlet.outletCategoryId", "Store Category")}</label>
                              <select disabled={!permissionForCategory1.write} className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} style={selectStyle(input)}
                                name={options && options.name}
                                onChange={(value) => input.onChange(value)}>
                                <option value={0}>Select Category</option>
                                {options && options.map((x, i) => {
                                  return (
                                    <option selected={input.value === x.outletCategoryId} key={i} value={x.outletCategoryId}>{x.name}</option>
                                  )
                                })}
                              </select>
                              <FieldErrorMessage meta={meta} />
                            </div>
                          }
                        </Field>
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-lg-6">
                        <Field name="channelId" component="select" options={salesChannelRes && salesChannelRes}>

                          {({ input, meta, options }) =>
                            <div className="form-group">
                              <label> {renderFormatMsg("HandleOutlet.channelId", "Sales Channel")}</label>
                              <select disabled={!permissionForCategory1.write} style={selectStyle(input)} className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} name={options && options.name} onChange={(value) => input.onChange(value)}>
                                <option value={0}>Select Sales Channel</option> {options && options.map((x, i) => {
                                  return <option selected={input.value === x.channelId} key={i} value={x.channelId}>{x.name}
                                  </option>
                                })}
                              </select>
                              <FieldErrorMessage meta={meta} />
                            </div>
                          }
                        </Field>
                      </div>

                    </div>

                    <ActiveFromAndToDate readOnly={!permissionForCategory1.write} className={`${!permissionForCategory1.write ? "read-only" : ""}`} />

                    <div className="row">
                      {/* <div className="col-lg-6">
                        <Field name="maximumReceivingCapacityForSpringSummer">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("HandleOutlet.MaxPiecesForReceivingSpringSummer", "Maximum Receiving Capacity for Spring/Summer")}</label>
                              <input readOnly={!permissionForCategory2.write} className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`}  {...input} type="number" placeholder="Maximum Receiving Capacity for Spring/Summer" />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}

                      <InputField
                        colClass="col-lg-6"
                        name={"maximumReceivingCapacityForSpringSummer"}
                        type="text"
                        id="HandleOutlet.MaxPiecesForReceivingSpringSummer"
                        defaultLabel="Maximum Receiving Capacity for Spring/Summer"
                        placeholder={"Maximum Receiving Capacity for Spring/Summer"}
                        readOnly={!permissionForCategory2.write}
                        iconText={renderFormatMsg("pcs", "pcs")}
                        className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`}
                      />

                      <InputField
                        colClass="col-lg-6"
                        name={"maximumReceivingCapacityForAutumnWinter"}
                        type="text"
                        id="HandleOutlet.MaxPiecesForReceivingAutomnWinter"
                        defaultLabel="Maximum Receiving Capacity for Automn/Winter"
                        placeholder={"Maximum Receiving Capacity for Automn/Winter"}
                        readOnly={!permissionForCategory2.write}
                        iconText={renderFormatMsg("pcs", "pcs")}
                        className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`}
                      />

                      {/* <div className="col-lg-6">
                        <Field name="maximumReceivingCapacityForAutumnWinter">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("HandleOutlet.MaxPiecesForReceivingAutomnWinter", "Maximum Receiving Capacity for Automn/Winter")}</label>
                              <input readOnly={!permissionForCategory2.write} {...input} type="number" placeholder="Maximum Receiving Capacity for Automn/Winter " className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                    </div>

                    <div className="row">
                      <InputField
                        colClass="col-lg-6"
                        name={"maximumCapacityForSpringSummer"}
                        type="text"
                        id="HandleOutlet.MaxPieceCapacitySS"
                        defaultLabel="Maximum Pieces for Spring/Summer Season"
                        placeholder={"Maximum Pieces for Spring/Summer Season"}
                        readOnly={!permissionForCategory2.write}
                        iconText={renderFormatMsg("pcs", "pcs")}
                        className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`}
                      />
                      {/* <div className="col-lg-6">
                        <Field name="maximumCapacityForSpringSummer">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("HandleOutlet.MaxPieceCapacitySS", "Maximum Pieces for Spring/Summer Season")}</label>
                              <input readOnly={!permissionForCategory2.write} {...input} type="number" placeholder="Maximum Pieces for Spring / Summer Season" className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div>
                       */}

                      <InputField
                        colClass="col-lg-6"
                        name={"maximumCapacityForAutumnWinter"}
                        type="number"
                        id="HandleOutlet.MaxPieceCapacityAW"
                        defaultLabel="Maximum Capacity for Autumn/Winter Season"
                        placeholder={"Maximum Capacity for Autumn/Winter Season"}
                        readOnly={!permissionForCategory2.write}
                        iconText={renderFormatMsg("pcs", "pcs")}
                        className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`}
                      />


                      {/* <div className="col-lg-6">
                        <Field name="maximumCapacityForAutumnWinter">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("HandleOutlet.MaxPieceCapacityAW", "Maximum Capacity for Autumn/Winter Season")}</label>
                              <input readOnly={!permissionForCategory2.write} {...input} type="number" placeholder="Maximum Capacity for Autumn/Winter Season" className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                    </div>


                  </div>
                  <div className="col-12 text-right">
                    <div className="submit_btn">
                      <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                        ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>}
      </div>
    </div>
  )
}
export default React.memo(HandleSeason)
