import React, { useContext, useEffect, useState } from "react";
import { getAllNotifications, blockedNotification } from "../../services/notificationAPI";
import { displayMessage, renderFormatMsg, toastError } from "../../shared/funs";
import Error from "../../components/Error/Error";
import moment from "moment";
import { AppContext } from "../../context/AppContext";
import ReactTable from "../../components/ReactTable/ReactTable";
import PageHeading from "../../components/PageHeading/PageHeading";

const Notifications = (props) => {
  const [notifications, setNotifications] = useState([]);
  // eslint-disable-next-line no-unused-vars
  const [hasMore, setHasMore] = useState(true);
  const [page, setPage] = useState(1);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false)
  const [totalPage, setTotalPage] = useState(0)
  const { userDetails } = useContext(AppContext);

  useEffect(() => {
    const params = {
      page: page,
      display: 10
    }

    getAllNotificationsHandler(params)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page]);

  const getAllNotificationsHandler = (params) => {
    setLoading(true);
    getAllNotifications(params)
      .then((resp) => {
        if (resp.notifications.length) {
          setNotifications(resp.notifications);
          setHasMore(page < resp.totalPage);
          setTotalPage(resp.totalPage)
          setLoading(false);
        } else {
          setHasMore(false);
          setTotalPage(resp.totalPage)
          setLoading(false);

        }
      })
      .catch((error) => {
        setHasMore(false);
        setLoading(false);
        setError(error)
        console.info("error => ", error)
      });
  }


  const handleBlockedNotification = async (notificationTypeId) => {
    await blockedNotification(notificationTypeId)
      .then((response) => {
        // setNotifications(
        //   notifications: notifications.filter(n => n.notificationId !== notificationTypeId)
        // )
      })
      .catch((error) => {
        toastError("Something went wrong!");
        console.info("error => ", error)
      });
  };


  // const dismissNotification = (id) => {
  //   addUpdateUserNotification(id)
  //     .then((response) => {
  //       getAllNotifications().then((res) => {
  //         // this.setState({
  //         //   notifications: this.state.notifications.filter(n => n.notificationId !== id)
  //         // })
  //       });
  //     })
  //     .catch((error) => {
  //       console.info("error => ", error)
  //     });
  // };

  const columns = [
    {
      //resizable: false,
      style: { display: "flex", justifyContent: "flex-start" },
      Cell: props => <div className="alert notification-list" role="alert">
        <div className="d-flex justify-content-between float-right">
          <span className="calendar font-italic float-left"><i className="icon-calendar">&nbsp;</i>
            {moment(props.original.timestamp).format(userDetails && userDetails.longDateFormat ? userDetails.longDateFormat : "dd.MM.YYYY HH:mm aa")}
          </span>

        </div>
        <h6 className="alert-heading heading mt-2">{displayMessage(props.original.headline, userDetails.shortDateFormat)}</h6>
        <p
          className="m-0 notification-body"

          dangerouslySetInnerHTML={{
            __html:
              props.original && displayMessage(props.original.payload.messageToDisplay, userDetails.shortDateFormat),
          }}
        ></p>
        <div className="detail-box">
          <span href="#" className="dismiss"
            onClick={() =>
              handleBlockedNotification(props.original.notificationTypeId)
            }
          >{renderFormatMsg("header.NotificationDetail.DontReportAgain", "DON´T REPORT THIS AGAIN")} </span>
        </div>
        {/* <hr /> */}
        {/* <p className="mb-0">Whenever you need to, be sure to use margin utilities to keep things nice and tidy.</p> */}
      </div>
    }
  ]

  const fetchData = (state, instance) => {
    setPage(state.page + 1)
  }

  return (
    <div className="main-content my-notification">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("MyNotifications", "My Notifications")}
      </div> */}
      <PageHeading headingId="MyNotifications" isLoading={isLoading} />
      {error ? <Error /> :
        notifications.length > 0 &&
        <ReactTable
          data={notifications}
          minRows={4}
          columns={columns}
          onFetchData={fetchData}
          pages={totalPage}
        />
      }
    </div>
  );
};

export default React.memo(Notifications);
