import React, { useState, useEffect } from 'react'
// import { Link } from 'react-router-dom';
import ReactTable from '../../../components/ReactTable/ReactTable';
import { renderFormatMsg, toastError, toastSuccess } from '../../../shared/funs';
// import { getUserRoleAssignData, updateUserRoleAssignment, getUserData, getUserRoles } from '../../../services/toolsAndSettingsPagesApi';
// import BackIcon from "../../../images/back-icon.svg";
import { PAGE_SIZE } from '../../../constant';
import Error from "../../../components/Error/Error";
import SearchBox from '../../../components/SearchBox/SearchBox';
import { get } from 'lodash';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';

const UserRoleAssignment = (props) => {
	const [userRoleAssignData, setUserRoleAssignData] = useState([])
	const [searchValue, setSearchValue] = useState("")
	const [totalPage,] = useState(0)
	const [pageIndex, setPageIndex] = useState(0)
	const [isLoading, setLoading] = useState(false)
	const [selectedUser, setSelectedUser] = useState(null)
	const [error, setError] = useState(false)

	useEffect(() => {
		// try {
		// 	getUserData({ "search": props.match.params.userId }).then(res => {
		// 		setSelectedUser(res.users.length > 0 ? res.users[0] : "")
		// 	})
		// } catch (err) {
		// 	console.info("UserRoleAssignment err => ", err)
		// }

		async function fetchUserData() {
			try {
				let res = await (new BaseService('/Users')).getAll('', { "search": props.match.params.userId })
				setSelectedUser(res.users.length > 0 ? res.users[0] : "")
				setLoading(false)
			} catch (error) {
				console.info("UserRoleAssignment err => ", error)
			}
		}
		fetchUserData();

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])
	const getData = async (page = 0, search = "") => {
		setSearchValue(search)
		let param = {
			"userId": props.match.params.userId,
			"page": page + 1,
			"display": PAGE_SIZE,
			"search": search
		}


		try {
			let res = await (new BaseService('/ClientRoles')).getAll('', param)
			let resp = await (new BaseService('/userroles')).getAll('', { "userid": props.match.params.userId, })
			res.clientRoleResponse.forEach(role => {
				var data = resp.userRole.find(o => o.roleId === role.roleId)
				if (data) { role.assigned = data.assigned }
			})
			setUserRoleAssignData(res.clientRoleResponse)
		} catch (error) {
			console.log('ClientRoles error =>', error);
			setError(error)

		}

		// getUserRoleAssignData(param).then(res => {
		// 	getUserRoles({
		// 		"userid": props.match.params.userId,
		// 	}).then(resp => {
		// 		res.clientRoleResponse.forEach(role => {
		// 			var data = resp.userRole.find(o => o.roleId === role.roleId)
		// 			if (data) { role.assigned = data.assigned }
		// 		})
		// 		setUserRoleAssignData(res.clientRoleResponse)
		// 	}).catch(e => {
		// 		console.info(`getUserRoles e => `, e);
		// 	})
		// 	setTotalPage(res.totalPage)
		// }).catch(err => {
		// 	setError(err)
		// })
	}

	const handleChecked = async (e, roleId) => {
		setLoading(true);
		let tempData = userRoleAssignData && userRoleAssignData.length > 0 ? [...userRoleAssignData] : [];
		let payload = {
			userId: get(selectedUser, "userId"),
			roleId: roleId, [e.target.name]: e.target.checked
		}
		setLoading(true)
		tempData.forEach(currentData => {
			if (currentData.roleId === roleId) {
				currentData[e.target.name] = e.target.checked
			}
		})
		// updateUserRoleAssignment(payload).then(res => {
		// 	setUserRoleAssignData(tempData)
		// 	toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"));
		// 	setLoading(false)
		// }).catch(e => {
		// 	console.info(`e => `, e);
		// 	toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"));
		// })

		try {
			await (new BaseService('/UpdateUserRole')).update('', payload)
			setUserRoleAssignData(tempData)
			toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"));
			setLoading(false)
		} catch (error) {
			console.log('error =>', error);
			toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"));
		}
	}
	const columns = [{
		Header: <div className="text-left"> {renderFormatMsg("userRoleAssignmentTable.RoleId", "Role ID")}</div>,
		accessor: 'roleId',
		//resizable: false,
		width: 290,
		style: { justifyContent: "flex-start" }
	}, {
		Header: <div className="text-left"> {renderFormatMsg("userRoleAssignmentTable.RoleName", "Role Name")}</div>,
		accessor: 'name',
		width: 150,
		//resizable: false,
		style: { justifyContent: "flex-start" }
	}, {
		Header: <div className="text-left">
			{renderFormatMsg("userRoleAssignmentTable.RoleDescription", "Role Description")}</div>,
		accessor: 'description',
		style: { justifyContent: "flex-start" },
		minWidth: 250,
		//resizable: false,
	}, {
		Header: renderFormatMsg("userRoleAssignmentTable.Assigned", "Assigned"),
		accessor: 'assigned',
		//resizable: false,
		width: 140,
		Cell: props => <div className="checkbox">
			<input type="checkbox" name="assigned" id="checkbox" checked={props.original.assigned}
				onChange={(e) => handleChecked(e, props.original.roleId)}
			/>
			<label for="checkbox2"></label>
		</div>
	}]
	const fetchData = (state, instance) => {
		//getting change page number from state
		getData(state.page, searchValue)
		setPageIndex(state.page)
	}
	const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }
	return (
		<div className="main-content">
			{/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
				<div className="heading">
					<Link className="back-btn" to="/user"><img src={BackIcon} alt="BackIcon" /></Link>
					<span>{renderFormatMsg("userRoleAssignmentTable.Heading", "User Role Assignment")}</span>
				</div>
				<div className="right-content select-box ml-auto">
					{renderFormatMsg("userRoleAssignmentTable.User", "User")}
       : <p> {`${get(selectedUser, "firstName")} ${get(selectedUser, "lastName")}`} <i className="icon-down-arrow"></i> </p>
				</div>
			</div> */}
			<PageHeading backURL="/user" headingId={"userRoleAssignmentTable.Heading"} isLoading={isLoading} >
				<div className="right-content select-box ml-auto">
					{renderFormatMsg("userRoleAssignmentTable.User", "User")}
					: <p> {`${get(selectedUser, "firstName")} ${get(selectedUser, "lastName")}`} <i className="icon-down-arrow"></i> </p>
				</div>
			</PageHeading>

			<div className="card">
				<div className="card-header">
					<SearchBox searchHandler={SearchHandler} />
				</div>
				{
					error ? <Error /> :
						<ReactTable
							data={userRoleAssignData}
							columns={columns}
							onFetchData={fetchData}
							pages={totalPage}
							page={pageIndex}
						/>
				}
			</div>
		</div >
	)
}
export default React.memo(UserRoleAssignment)
