import React, { useEffect } from 'react'
// import { Link } from 'react-router-dom'
import { renderFormatMsg } from '../../shared/funs'
// import BackIcon from "../../images/back-icon.svg";
import Calendar from '../../components/Calendar';
import "./style.scss"
import { useState } from 'react';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import { Accordion, Card } from 'react-bootstrap';
import ReactTable from '../../components/ReactTable/ReactTable';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ReplenishmentQuantities = (props) => {
  const [selectedDate, setDate] = useState(new Date())

  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState(false)
  const [replishmentQty, setReplishmentQty] = useState(null)
  const [sizeBowl, setSizeBowl] = useState(null)
  const [skuDetails, setSkuDetails] = useState([])
  const [isLoading, setLoading] = useState(false)


  useEffect(() => {
    let param = { "exportPackageId": props.match.params.exportPackageId, optionId: props.match.params.optionId, outletId: props.match.params.outletId }
    setLoading(true)
    async function fetchSKUData() {
      try {
        let res = await (new BaseService('/Replenishment/OrderQuantity')).getAll('', param)
        setLoading(false)
        setReplishmentQty(res)
        setSizeBowl(res.sizeBowl)
        setSkuDetails(res.skuDetails)
      } catch (error) {
        setLoading(false)
        setError(error)
      }
    }
    fetchSKUData()
    // getReplenishmentQuantities(param).then(res => {
    //   setLoading(false)
    //   setReplishmentQty(res)
    //   setSizeBowl(res.sizeBowl)
    //   setSkuDetails(res.skuDetails)
    // }).catch(err => {
    //   setLoading(false)
    //   setError(err)
    // })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [
    {
      Header: <div className="text-left"> {renderFormatMsg("replenishmentQuantities.ClientSKUId", "Client SKU Id")}</div>,
      accessor: 'clientSKUId',
      //resizable: false,
      width: 200,
      // sortable: true,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left"> {renderFormatMsg("Type", "Type")}</div>,
      //resizable: false,
      width: 180,
      // sortable: true,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left"> {renderFormatMsg("replenishmentQuantities.Comment", "Comment")}</div>,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
  ]
  const TheadComponent = props => null;
  return (
    <div className="main-content">
      <div className={"d-flex align-items-center"}>
        {/* <div className="heading">
          <Link className="back-btn" onClick={() => props.history.goBack()}><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{renderFormatMsg("replenishmentQuantities.Heading", "Replenishment Quantities")}</span>
        </div> */}
        <PageHeading backURL="/optionDistribution" onClick={() => props.history.goBack()} headingId={"productDistributionOverviewTable.Heading"} isLoading={isLoading}>

          <div className="right-content select-box ml-auto align-items-center">
            <span className="text">{renderFormatMsg("Store", "Store")} : &nbsp; </span>
            <DropDownCustom name={false} selectedData={`Moscow`} dropdownData={false} handleData={false} />
            <span className="text">{renderFormatMsg("Custom", "Custom")} : &nbsp; </span>
            <div className="calendarBox">
              <Calendar update={true} popperPlacement="top-end" name="activeFrom"
                selectedDate={selectedDate}
                onSelect={data => setDate(data)}
              />
            </div>
            <span className="arrow-icon" style={{ transform: 'rotate(180deg)', cursor: "pointer" }}
            // onClick={() => dateChangeHandler(-1)}
            >
              <i className="icon-right-arrow"></i></span>&nbsp;&nbsp;
            <span className="arrow-icon" style={{ cursor: "pointer" }}
            // onClick={() => dateChangeHandler(1)}
            >
              <i className="icon-right-arrow"></i></span>
          </div>
        </PageHeading>
      </div>
      <div className="card p-3">
        <div className="row pb-2 pr-3">
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="option-detail">
              <h6> {renderFormatMsg("OptionId", "Option ID")}</h6>
              <input type="text" className="form-control read-only" readOnly value={replishmentQty && replishmentQty.clientOptionId} />
            </div>
          </div>
          <div className="col-sm-12 d-md-none">
            <hr className="d-md-none" />
          </div>
          <div className="col-sm-12 col-md-6 col-lg-6 col-xl-6">
            <div className="option-detail">
              <h6> {renderFormatMsg("OptionName", "Option Name")}</h6>
              <input type="text" className="form-control read-only" readOnly value={replishmentQty && replishmentQty.optionName} />
            </div>
          </div>
          <div className="col-sm-12">
            <hr />
          </div>
          <div className="col-sm-12 col-md-12 col-lg-12 col-xl-6">
            <div className="option-detail">
              <h6> {renderFormatMsg("WeeklyProductSales", "Weekly Product Sales")}</h6>
              <input type="text" className="form-control read-only" readOnly value={replishmentQty && replishmentQty.weeklyProductSales} />
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-sm-12">
            <hr />
          </div>
        </div>


        {sizeBowl && Object.keys(sizeBowl).length > 0 && <div className="row">
          <div className="col-lg-12">
            <div className="form-group">
              <label> {renderFormatMsg("SizeBowl", "Size Bowl")}</label>
              <div className="radio-group justify-content-around">
                {
                  Object.keys(sizeBowl).map((key, i) =>
                    <div className="radio-box" key={i}>
                      <label for={key}>
                        <span>{key}</span>
                        <input type="text" readOnly name={`${key}`} defaultValue={sizeBowl[key]} className={`form-control m-auto`} />
                      </label>
                    </div>
                  )
                }
              </div>
            </div>
          </div>
        </div>}
      </div>

      <div className="card p-3">
        <div className="col-sm-12">
          <h5 className="Demand-count">{renderFormatMsg("Explainations", "Explainations")}</h5>
        </div>
        <ReactTable
          collapseOnDataChange={false}
          data={skuDetails}
          columns={columns}
          showPagination={false}
          minRows={0}

          SubComponent={row => {
            const columns = [
              {
                Header: <div className="text-left"> </div>,
                width: 200,
                style: { justifyContent: "flex-start" }
              },
              {
                accessor: "type",
                style: { justifyContent: "flex-start", alignItems: "flex-start" },
                width: 180,
                // Cell: props => <div>{props.original.type || "N/A"}</div>
              },
              {
                accessor: "comment",
                style: { justifyContent: "flex-start", textAlign: "start", width: "400px !important" },
                Cell: props => <div style={{
                  wordBreak: "break-word",
                  width: "100%",
                  whiteSpace: "normal"
                }}>{props.original.comment || "N/A"}</div>
              }
            ];

            return (
              <div style={{ marginLeft: 35 }}>
                <div className="distribution-bg">
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <ReactTable TheadComponent={TheadComponent} minRows={0} data={row.original.explainations || []} columns={columns} showPagination={false} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              </div>
            );
          }}
        />
      </div>



    </div>
  )
}

export default ReplenishmentQuantities
