import React, { useState, useEffect, useContext } from 'react'
import moment from 'moment';
import { updateOptions, getOptionCategory, getOptions } from '../../../services/sidebarApi';
import { getSeasonsDetails, validateProductIds } from '../../../services/toolsAndSettingsPagesApi';
import { get } from "lodash"
import {
  COLOR_STYLE_FOR_TAG_INPUT, DATE_FORMAT, FIELD_ACCESS_FUNCTION,
  // PAGE_SIZE
} from '../../../constant';
// import BackIcon from "../../../images/back-icon.svg";
import { Form, Field } from 'react-final-form';
import {
  renderFormatMsg, toastSuccess, toastError,
  // selectStyle,
  checkPermission
} from '../../../shared/funs';
// import { Link } from 'react-router-dom';
import Calendar from '../../../components/Calendar';
import DropDownCustom from '../../../components/DropDowns/DropDownCustom';
import { AppContext } from '../../../context/AppContext';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import ProductIdsToolTip from '../../../components/ProductIdsToolTip';
import FieldErrorMessage from '../../../components/FieldErrorMessage';
import InputField from '../../../components/Fields/InputField';
import SelectField from '../../../components/Fields/SelectField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';
import { InputGroup } from 'react-bootstrap';


const ProductSettings = (props) => {
  const [selectedOption, setSelectedOption] = useState({})
  const [category,] = useState([])
  const [sizeBowlList, setSizeBowlList] = useState({})
  const [seaonDataStore, setSeaonDataStore] = useState([])
  const [sizeBowl, setSizeBowl] = useState(null)
  const [brands, setBrands] = useState([])
  const { userDetails } = useContext(AppContext);
  const [permissionForCategory1, setPermissionCategory1] = useState({ read: true, write: false })
  const [permissionForCategory2, setPermissionCategory2] = useState({ read: true, write: true })
  const [selectedPromoIds, setSelectedPromoIds] = useState([])
  const [options,] = useState([])
  const [productCategory, setProductCategory] = useState([])


  const animatedComponents = makeAnimated();

  let dataArray = get(userDetails, "accesses");
  useEffect(() => {
    setPermissionCategory1(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.PRODUCT_FORM_CATEGORY_1))
    setPermissionCategory2(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.PRODUCT_FORM_CATEGORY_2))
  }, [dataArray])

  useEffect(() => {
    var div_1 = document.querySelector(".card");
    div_1.classList.add("test");
    try {
      // getOptionCategory("").then(res => {
      //   setCategory(res.category.length > 0 ? res.category : [])
      // })



      getOptions({ "search": props.match.params.optionId }).then(res => {
        setSelectedOption(res.options && res.options.length > 0 ? res.options[0] : "");
        var productIds = get(res.options[0], "payload.productIds", []);
        var _productIds = productIds.map(v => { return { label: v.trim(), value: v.trim().toLowerCase() }; });
        validateIds(_productIds)
        if (res.options.length > 0) {
          getOptionCategory(get(res.options.length > 0 ? res.options[0] : "", "categoryId")).then(res => {
            setSizeBowl(res.category && res.category.length > 0 ? res.category[0] : null)
          }).catch(err => {
            setSizeBowl({})
          })
          getSeasonsDetails({ display: 9999999 }).then(seasonRes => {
            let seasonData = seasonRes.seasons || []
            if (seasonData.length) {
              setSeaonDataStore(seasonData)
            }
          })

          async function fetchData() {
            try {
              let optionCategories = await (new BaseService('/OptionCategories')).getAll('', { display: 9999999 })
              setProductCategory(optionCategories.category);
            } catch (error) {
            }
          }
          fetchData()
        }
        setSizeBowlList(res.options.length > 0 ? res.options[0].payload.size : {})
      })

      async function fetchBrandData() {
        try {
          let res = await (new BaseService('/Brand')).getAll('')
          setBrands(res.brands)
        } catch (error) {
          console.log('error => ', error)
        }
      }
      fetchBrandData();
    } catch (error) {
      console.info("error => ", error)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  const validation = (data) => {
    let errors = {}
    if (!data.optionName || data.optionName === "") {
      errors.optionName = "Required";
    }
    if (!data.seasonId || data.seasonId === "") {
      errors.seasonId = "Required";
    }
    if (!data.clientOptionId || data.clientOptionId === "") {
      errors.clientOptionId = "Required";
    }
    return errors
  }
  const handleFormSubmit = async (data) => {
    var sizeObject = {};
    var productIds = []

    selectedPromoIds && selectedPromoIds.forEach(({ status, value }) => {
      if (status !== 0) {
        productIds.push(value)
      }
    })

    sizeBowl && sizeBowl.size.length > 0 && sizeBowl.size.forEach(key => { sizeObject[key] = sizeBowlList[key] })
    let object = {
      "clientOptionId": data.clientOptionId,
      "capsule": data.capsule,
      payload: {
        "firstPrice": data.firstPrice,
        "basePrice": data.basePrice,
        "salesPrice": data.currentPrice,
        "preferredMinimumDisplay": data.preferredMinimumDisplay,
        "hardMinimumDisplay": data.hardMinimumDisplay,
        "finalPushDate": moment(data.finalPushDate).format(DATE_FORMAT),
        "size": sizeObject,
        "productIds": productIds,
      },
      "categoryId": data.categoryId,
      "seasonId": data.seasonId,
      "brand": data.brandId,

      "optionId": props.match.params.optionId,
      "optionName": data.optionName,
    }

    updateOptions(object).then(res => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"));
      props.history.push("/optionDistribution")
    }).catch(error => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      console.info("error => ", error)
    })
  }

  var myInitData = {};
  if (selectedOption) {
    myInitData = {
      clientOptionId: get(selectedOption, "clientOptionId"),
      optionName: get(selectedOption, "optionName"),
      capsule: get(selectedOption, "capsule"),
      categoryId: get(selectedOption, "categoryId"),
      seasonId: get(selectedOption, "seasonId"),
      brandId: get(selectedOption, "brand.brandId"),
      finalPushDate: get(selectedOption, "payload.finalPushDate", false) ? get(selectedOption, "payload.finalPushDate") : new Date(),
      hardMinimumDisplay: get(selectedOption, "payload.hardMinimumDisplay", false) ? get(selectedOption, "payload.hardMinimumDisplay") : "",
      preferredMinimumDisplay: get(selectedOption, "payload.preferredMinimumDisplay", false) ? get(selectedOption, "payload.preferredMinimumDisplay") : "",
      firstPrice: get(selectedOption, "payload.firstPrice", false) ? get(selectedOption, "payload.firstPrice") : "",
      basePrice: get(selectedOption, "payload.basePrice", false) ? get(selectedOption, "payload.basePrice") : "",
      currentPrice: get(selectedOption, "payload.salesPrice", false) ? get(selectedOption, "payload.salesPrice") : "",
      size: get(selectedOption, "payload.size", false) ? get(selectedOption, "payload.size") : ""
    }
  }

  // const handleCategory = async (categoryId, form = false) => {
  //   setSizeBowl({})
  //   getOptionCategory(categoryId).then(res => {
  //     setSizeBowl(res.category && res.category.length > 0 ? res.category[0] : null)
  //   }).catch(err => { setSizeBowl({}); console.log('getOptionCategory err => ', err) })
  // }

  const onChangeHandler = (e, form) => {
    var size = form.getState().initialValues.size ? form.getState().initialValues.size : {}
    size[e.target.name] = e.target.value;
    form.change("size", size)
    setSizeBowlList(size)
  }


  const validateIds = (newValue) => {
    var temp = [];
    newValue && newValue.forEach(o => {
      // temp.push({ Value: o.value })
      temp.push(o.value)
    })

    var obj = {
      Value: temp
    }
    if (newValue.brandId) {
      obj.BrandId = newValue.brandId
    }


    validateProductIds(obj).then((result) => {
      console.info(`validateProductIds result => `, result);
      var temp = result.map((o, index) => {
        let color = "#846aff";
        switch (o.status) {
          case 0:
            // not exist
            color = "#ff00008c"
            break;
          case 1:
            // exits and not active
            color = "#ff98009c"
            break;
          case 2:
            // exits and active
            // color = "#4caf50"
            color = "#4caf50c2"
            break;
          default:
            break;
        }
        return {
          color: color,
          label: o.checked_Option,
          value: o.checked_Option,
          status: o.status,
        }
      })

      setSelectedPromoIds(temp)
    }).catch((err) => {
      console.info(`validateProductIds err => `, err);
    });
  }

  const handleChange = (newValue, onChange) => {
    onChange(newValue)
    setSelectedPromoIds(newValue)
    validateIds(newValue)
  };

  const handleInputChange = (value, onChange) => {
    if (value.includes(' ')) {
      const existingValues = selectedPromoIds || []
      // const existingValues = Object.assign([], selectedPromoIds) || []
      //handleChangeComment
      const splitValues = value.split(' ').map(v => { return { label: v.trim(), value: v.trim().toLowerCase() }; });
      splitValues.forEach(v => {
        // Ignore currently selected values
        if (v.value && existingValues.findIndex(o => o.value === v.value) < 0) {
          existingValues.push(v);
        }
        // Add this value to our array that we'll send to change handler
      });

      var productIds = [...new Set(existingValues)];
      validateIds(productIds)

      return '';
    }
    // if there are no commas to process, just pass through the input value
    return value;
  }

  const generateBrandOptions = (options) => {
    var _options = [<option option value={""} > Select Brand</option>]
    options.map(({ name, brandId }, index) =>
      _options.push(<option value={brandId}>{name}</option>)
    )

    return _options;
  }


  const generateSeasonOptions = (options) => {
    var _options = [<option option value={""} > Select Season</option>]
    options.map(({ name, seasonId }, index) =>
      _options.push(<option value={seasonId}>{name}</option>)
    )

    return _options;
  }

  const generateProductCategoryOptions = (options) => {
    var _options = [<option option value={""} > Select Product Category</option>]
    options.map(({ categoryId, categoryName }, index) =>
      _options.push(<option value={categoryId}>{categoryName}</option>)
    )

    return _options;
  }

  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/optionDistribution"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{renderFormatMsg("productSettings.Heading", ";l")}</span>
        </div>
        <div className="right-content select-box ml-auto">
          <DropDownCustom name={false} selectedData={get(selectedOption, "optionName", "Product")} dropdownData={false} handleData={false} />
        </div>
      </div> */}
      <PageHeading backURL="/optionDistribution" headingId={"productSettings.Heading"}>
        <div className="right-content select-box ml-auto">
          <DropDownCustom name={false} selectedData={get(selectedOption, "optionName", "Product")} dropdownData={false} handleData={false} />
        </div>
      </PageHeading>
      <div className="card p-3">
        <Form onSubmit={handleFormSubmit} initialValues={myInitData} validate={validation} >
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <>
                <form onSubmit={handleSubmit} >
                  <div className="form">

                    <div className="row">
                      {/* <div className="col-lg-6">
                        <Field name="clientOptionId">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.ProductId", "Product ID")} </label>
                              <input readOnly={!permissionForCategory1.write && permissionForCategory1.read}  {...input} type="text" placeholder="Product Id" className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                      <InputField
                        colClass="col-lg-6"
                        name={"clientOptionId"}
                        type="text"
                        id="productSettings.ProductId"
                        defaultLabel="Product ID"
                        placeholder={"Product Id"}
                        readOnly={!permissionForCategory1.write && permissionForCategory1.read}
                        className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                      />

                      <InputField
                        colClass="col-lg-6"
                        name={"optionName"}
                        type="text"
                        id="productSettings.ProductName"
                        defaultLabel="Product Name"
                        placeholder={"Product Name"}
                        readOnly={!permissionForCategory1.write && permissionForCategory1.read}
                        className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                      />

                      {/* <div className="col-lg-6">
                        <Field name="optionName">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.ProductName", "Product Name")}</label>
                              <input readOnly={!permissionForCategory1.write && permissionForCategory1.read} {...input} type="text" placeholder="Product Name" className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                    </div>

                    <div className="row">
                      <SelectField
                        colClass="col-12 col-lg"
                        name="brandId"
                        options={generateBrandOptions(brands)}
                        id="Brand"
                        disabled={(!permissionForCategory1.write) ? true : false}
                        defaultLabel={"Brand"}
                        className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                      />
                      {/* <div className="col-lg-6">
                        <Field name="brandId" component="select" options={brands}>
                          {({ input, meta, options }) => (
                            <div className="form-group">
                              {input.values}
                              <label>{renderFormatMsg("productSettings.Brand", "Brand")} </label>
                              <select disabled={(!permissionForCategory1.write) ? true : false} defaultValue={input.value} style={selectStyle(input)} name={options.name} onChange={(value) => input.onChange(value)} className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}>
                                <option value={0}>Select Brand</option>
                                {options.map((x) => {
                                  return (
                                    <option selected={input.value === x.brandId} key={x.brandId} value={x.brandId}>{x.name}
                                    </option>
                                  )
                                })}
                              </select>
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}


                      <SelectField
                        colClass="col-12 col-lg"
                        name="seasonId"
                        options={generateSeasonOptions(seaonDataStore)}
                        id="seasonId"
                        defaultLabel={"Season"}
                        disabled={(!permissionForCategory1.write) ? true : false}
                        className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                      />


                      {/* <div className="col-lg-6">
                        <Field name="seasonId" component="select" options={seaonDataStore}>
                          {({ input, meta, options }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("Season", "Season")} </label>
                              <select disabled={(!permissionForCategory1.write) ? true : false} style={selectStyle(input)} defaultValue={input.value} name={options.name} onChange={(value) => input.onChange(value)} className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}>
                                <option value={0}>Select season</option>
                                {options.map((x) => {
                                  return (
                                    <option selected={input.value === x.seasonId} key={x.seasonId} value={x.seasonId}>{x.name}
                                    </option>
                                  )
                                })}
                              </select>
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div>
                   */}
                    </div>

                    <div className="row">
                      {/* <div className="col-lg-6">
                        <Field name="capsule">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.Capsule", "Capsule")} </label>
                              <input readOnly={!permissionForCategory1.write} {...input} type="text" placeholder="Capsule" className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}


                      <InputField
                        colClass="col-lg-6"
                        name={"capsule"}
                        type="text"
                        id="productSettings.Capsule"
                        defaultLabel="Capsule"
                        placeholder={"Capsule"}
                        readOnly={!permissionForCategory1.write && permissionForCategory1.read}
                        className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                      />




                      <SelectField
                        colClass="col-12 col-lg"
                        name="categoryId"
                        options={generateProductCategoryOptions(productCategory)}
                        id="productSettings.ProductCategory"
                        defaultLabel={"Product Category"}
                        disabled={(!permissionForCategory1.write) ? true : false}
                        className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                      />



                      {/* <div className="col-lg-6">
                        <Field name="categoryId" component="select" options={category && category}>
                          {({ input, meta, options }) => <div className="form-group">
                            <label>{renderFormatMsg("productSettings.ProductCategory", "Product Category")}</label>
                            <select disabled={(!permissionForCategory1.write && permissionForCategory1.read) ? true : false} style={selectStyle(input)} name={options.name} onChange={(value) => {
                              input.onChange(value); handleCategory(value.target.value, form)
                            }} className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}>
                              {options.map((x, i) => <option selected={input.value === x.categoryId} key={i} value={x.categoryId}>{x.categoryName}</option>)}
                            </select>
                          </div>
                          }
                        </Field>
                      </div> */}
                    </div>

                    {sizeBowl && sizeBowl.size && sizeBowl.size.length > 0 && <div className="row">
                      <div className="col-lg-6">
                        <div className="form-group">
                          <label> {renderFormatMsg("SizeBowl", "Size Bowl")}</label>
                          <div className="radio-group justify-content-around" style={{ width: "520px" }}>
                            {
                              sizeBowl.size.map((heading, i) =>
                                <div className="radio-box" key={i}>
                                  <label for={heading}>
                                    <span>{heading}</span>
                                    <input readOnly={!permissionForCategory1.write} type="text" onChange={(e) => onChangeHandler(e, form)} defaultValue={sizeBowlList && sizeBowlList[heading]} name={`${heading}`} className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`} />
                                  </label>
                                </div>
                              )
                            }
                          </div>
                        </div>
                      </div>

                    </div>}

                    <div className="row">
                      <InputField
                        colClass="col-lg-6"
                        name={"firstPrice"}
                        type="number"
                        id="productSettings.FirstPrice"
                        defaultLabel="First Price"
                        placeholder={"First Price"}
                        iconText={renderFormatMsg("Rub.", "Rub.")}
                        readOnly={!permissionForCategory2.write && permissionForCategory2.read}
                        className={`form-control ${!permissionForCategory2.write && permissionForCategory2.read ? "read-only" : ""}`}
                      />
                      {/* <div className="col-lg-6">
                        <Field name="firstPrice">
                          {({ input, meta }) => (
                            <div className="form-group">
                              {
                                console.info(`input => `, input)
                              }
                              <label> {renderFormatMsg("productSettings.FirstPrice", "First Price")} </label>
                              <input readOnly={!permissionForCategory2.write}  {...input} type="number" placeholder="First Price" className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}

                      {/* <InputField
                        colClass="col-lg-6"
                        name={"basePrice"}
                        type="text"
                        id="productSettings.BasePrice"
                        defaultLabel="Base Price"
                        placeholder={"Base Price"}
                        readOnly={!permissionForCategory1.write && permissionForCategory1.read}
                        className={`form-control ${!permissionForCategory1.write && permissionForCategory1.read ? "read-only" : ""}`}
                      /> */}

                      <div className="col-lg-6">
                        <Field name="basePrice">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.BasePrice", "Base Price")} </label>
                              <InputGroup className="mb-2">
                                <input {...input} value={input.value.replace(/,/g, '').toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",")} readOnly={!permissionForCategory2.write} type="text" placeholder="Base Price" className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                                <InputGroup.Prepend>
                                  <InputGroup.Text>{renderFormatMsg("Rub.", "Rub.")}</InputGroup.Text>
                                </InputGroup.Prepend>
                              </InputGroup>


                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="row">
                      {/* <div className="col-lg-6">
                        <Field name="currentPrice">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.CurrentSalesPrice", "Current Sales Price")} </label>
                              <input readOnly={!permissionForCategory2.write} {...input} type="number" placeholder="Current Sales Price" className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                      <InputField
                        colClass="col-lg-6"
                        name={"currentPrice"}
                        type="number"
                        id="productSettings.CurrentSalesPrice"
                        defaultLabel="Current Sales Price"
                        placeholder={"Current Sales Price"}
                        iconText={renderFormatMsg("Rub.", "Rub.")}
                        readOnly={!permissionForCategory2.write && permissionForCategory2.read}
                        className={`form-control ${!permissionForCategory2.write && permissionForCategory2.read ? "read-only" : ""}`}
                      />

                    </div>

                    <div className="row">
                      {/* <div className="col-lg-6">
                        <Field name="hardMinimumDisplay">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.HardMinimumDisplay", "Hard minimum display")} </label>
                              <input readOnly={!permissionForCategory2.write} {...input} type="number" placeholder="Hard minimum display" className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                      <InputField
                        colClass="col-lg-6"
                        name={"hardMinimumDisplay"}
                        type="number"
                        id="productSettings.HardMinimumDisplay"
                        defaultLabel="Hard minimum display"
                        placeholder={"Hard minimum display"}
                        iconText={renderFormatMsg("pcs", "pcs")}
                        readOnly={!permissionForCategory2.write && permissionForCategory2.read}
                        className={`form-control ${!permissionForCategory2.write && permissionForCategory2.read ? "read-only" : ""}`}
                      />


                      <InputField
                        colClass="col-lg-6"
                        name={"preferredMinimumDisplay"}
                        type="number"
                        id="productSettings.preferredMinimumDisplay"
                        defaultLabel="Preferred minimum display"
                        placeholder={"Preferred minimum display"}
                        iconText={renderFormatMsg("pcs", "pcs")}
                        readOnly={!permissionForCategory2.write && permissionForCategory2.read}
                        className={`form-control ${!permissionForCategory2.write && permissionForCategory2.read ? "read-only" : ""}`}
                      />


                      {/* <div className="col-lg-6">
                        <Field name="preferredMinimumDisplay">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.PreferredMinimumDisplay", "Preferred minimum display")} </label>
                              <input readOnly={!permissionForCategory2.write} {...input} type="number" placeholder="Preferred minimum display" className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                    </div>
                    <div className="row">
                      <div className="col-lg-6">
                        <Field name="finalPushDate">
                          {({ input, meta }) => (
                            <div className="form-group calendar-group">
                              <label> {renderFormatMsg("productSettings.FinalPushDate", "Final push date")} </label>
                              <Calendar disabled={!permissionForCategory2.write} onSelect={input.onChange} name={input.name} selectedDate={input.value} className={`${!permissionForCategory2.write ? "read-only" : ""}`} />
                            </div>
                          )}
                        </Field>
                      </div>
                    </div>

                    <div className="row align-items-center">
                      <div className="col-lg-11">
                        <Field name={"productIds"}>
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label> {renderFormatMsg("productSettings.AnalogueProducts", "Analogue Products")} </label>
                              <CreatableSelect
                                isMulti
                                components={animatedComponents}
                                value={selectedPromoIds}
                                closeMenuOnSelect={false}
                                onChange={(value) => handleChange(value, input.onChange)}
                                onInputChange={(values) => handleInputChange(values, input.onChange)}
                                options={options}
                                styles={COLOR_STYLE_FOR_TAG_INPUT}
                                placeholder="Select/Paste Product Ids(s)"
                              />
                            </div>
                          )}
                        </Field>
                      </div>
                      <div className="col-lg-1">
                        <ProductIdsToolTip />
                      </div>
                    </div>
                  </div>
                  <div className="col-12 text-right">
                    <div className="submit_btn">
                      <button type="submit" disabled={submitting} className="btn btn-primary" > {renderFormatMsg("Save", "Save")} </button>
                    </div>
                  </div>
                </form>
              </>
            );
          }}
        </Form>
      </div>
    </div>
  )
}
export default React.memo(ProductSettings);
