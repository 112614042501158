import { PAGE_SIZE, SERVER_GRAPHQL_API_URL } from "../constant";

const SESSION_KEY = "token";
const ROOT_GRAPHQL_URL = SERVER_GRAPHQL_API_URL;

const headers = {
  "x-hasura-access-key":
    "8E2j4jOlt59V0t0WbKQJSeORjgBlk5p0NerrjIc4qXBe5RsEq9s6ElHA9YYSIhsD",
  "x-hasura-admin-secret":
    "2Um6PvYtSlWx8qiG7gK5BcuSqm5qY62C2Z9KVCo3HiXgqm2R2ubcNAFoCIyksTkD",
  "Content-Type": "application/json",
};

// const operationsDoc = `
//             query GetClientList {
//                 allclients(limit: ${PAGE_SIZE}, offset: 0) {
//                 ClientId
//                 ClientName
//                 ActiveFrom
//                 ActiveTo
//                 },
//                 allclients_aggregate {
//                     aggregate {
//                         count
//                     }
//                 }
//             }`;

export class GraphQLHttpService {
  // headers = {
  //   "x-hasura-access-key":
  //     "8E2j4jOlt59V0t0WbKQJSeORjgBlk5p0NerrjIc4qXBe5RsEq9s6ElHA9YYSIhsD",
  //   "x-hasura-admin-secret":
  //     "2Um6PvYtSlWx8qiG7gK5BcuSqm5qY62C2Z9KVCo3HiXgqm2R2ubcNAFoCIyksTkD",
  //   "Content-Type": "application/json",
  // };

  constructor(url_prefix = "") {
    this.url_prefix = url_prefix;
    this.getHeaders();
  }

  post(url, body, queryParams = null, operationsDoc, variables) {
    // let page = body.page - 1;
    // let search = body.search;

    // const operationsDoc = `
    //         query GetClientList {
    //             allclients(limit: ${PAGE_SIZE},
    //             offset: ${
    //               page * PAGE_SIZE
    //             }, where: {ClientName: {_like: "${search}%"}}) {
    //             ClientId
    //             ClientName
    //             ActiveFrom
    //             ActiveTo
    //             },
    //             allclients_aggregate {
    //                 aggregate {
    //                     count
    //                 }
    //             }
    //         }`;

    try {
      return fetch(ROOT_GRAPHQL_URL, {
        method: "POST",
        headers: headers,
        body: JSON.stringify({
          query: operationsDoc,
          // operationName: operationName,
          variables: variables,
        }),
      })
        .then(async (response) => {
          if (response.status >= 400 && response.status < 600) {
            throw new Error("Bad response from server");
          }
          return await response.json();
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  // put(url, body, queryParams = null) {
  //   try {
  //     return fetch(
  //       ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
  //       {
  //         method: "PUT",
  //         headers: this.headers,
  //         body: JSON.stringify(body),
  //       }
  //     )
  //       .then(async (response) => {
  //         if (response.status >= 400 && response.status < 600) {
  //           throw new Error("Bad response from server");
  //         }
  //         return await response.json();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         return err;
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     return null;
  //   }
  // }

  // async remove(url, queryParams = null) {
  //   try {
  //     return fetch(
  //       ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
  //       {
  //         method: "DELETE",
  //         headers: this.headers,
  //       }
  //     )
  //       .then(async (response) => {
  //         if (response.status >= 400 && response.status < 600) {
  //           throw new Error("Bad response from server");
  //         }
  //         return await response.json();
  //       })
  //       .catch((err) => {
  //         console.log(err);
  //         return err;
  //       });
  //   } catch (error) {
  //     console.log(error);
  //     return null;
  //   }
  // }

  // fetchMyQuery() {
  //   return fetchGraphQL(this.operationsDoc, "GetClientList", {});
  // }

  getUrl(url) {
    return this.url_prefix + url;
  }

  getHeaders() {
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (this.checkSession()) {
      let apiToken = this.getSession();
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${apiToken}`,
      };
    }
  }

  getSession() {
    return localStorage.getItem(SESSION_KEY);
  }

  checkSession() {
    return localStorage.getItem(SESSION_KEY) !== null;
  }

  mapQueryParams(queryParams) {
    return queryParams
      ? `?${Object.keys(queryParams)
          .map(function (key) {
            return key + "=" + queryParams[key];
          })
          .join("&")}`
      : "";
  }
}
