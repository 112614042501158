import { HttpService } from "./HttpService";
import { GraphQLHttpService } from "./GraphQLHttpService";

export class GraphQLBaseService {
  constructor(url_prefix = "") {
    this.http = new GraphQLHttpService(url_prefix);
  }

  async getAll(url, params, operationsDoc, variables) {
    return await this.http.post(url, params, null, operationsDoc, variables);
  }

  // async getAll(url, params) {
  //   return await this.http.get(url, params);
  // }

  // async get(id) {
  //   return await this.http.get(`/${id}`);
  // }

  // async create(body) {
  //   return await this.http.post(``, body);
  // }

  // async update(id, body) {
  //   return await this.http.put(`/${id}`, body);
  // }

  // async delete(id) {
  //   return await this.http.remove(`/${id}`);
  // }
}
