import React, { useState, useEffect, useContext } from 'react'
import PageHeading from '../../components/PageHeading/PageHeading';
import ReactTable from '../../components/ReactTable/ReactTable';
import Error from '../../components/Error/Error';
import { checkPermission, findPermission, renderFormatMsg } from '../../shared/funs';
import { getImportFileData } from '../../services/toolsAndSettingsPagesApi';
import { PAGE_SIZE } from '../../constant';
import { get } from 'lodash';
import { AppContext } from '../../context/AppContext';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';


const onHoverMoreData = () => {
    alert()
}

const FileInfo = (props) => {
    const [data, setData] = useState({})
    const [pageIndex, setPageIndex] = useState(0)
    const [error, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const columns = [{
        Header: <div className="text-left">{renderFormatMsg("Type", "Type")}</div>,
        accessor: 'type',
        width: 100,
        //resizable: false,
        style: { justifyContent: "flex-start" },
        Cell: props => (
            <OverlayTrigger
                placement="top"
                delay={{ show: 100, hide: 400 }}
                overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                        <div>
                            <span>
                                The colors indicate the following status of the option:
                            </span>
                        </div>
                    </Tooltip>
                )}
            >
                <div variant="success" className="customtooltip">{props.original.type}</div>
            </OverlayTrigger>
        )
    }, {
        Header: <div className="text-left">{renderFormatMsg("Line", "Line")}</div>,
        accessor: 'line',
        maxWidth: 100,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    },
    {
        Header: <div className="text-left">{renderFormatMsg("Message", "Message")}</div>,
        accessor: 'message',
        minWidth: 300,
        maxWidth: 350,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    },
    {
        Header: <div className="text-left">{renderFormatMsg("Reference", "Reference")}</div>,
        accessor: 'reference',
        maxWidth: 300,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    }
    ]

    const getData = () => {
        // let param = { "page": page + 1, "display": PAGE_SIZE }
        setLoading(true)

        getImportFileData(props.match.params.id).then(resp => {
            setLoading(false)
            setData(resp)
            console.info("getData resp", resp);

        }).catch(err => {
            console.info('err =>', err);

            setLoading(false)
            setError(err)
        })

    }







    useEffect(() => {
        getData()
        console.log("props",);
    }, [])

    console.info('data =>', data);

    const fetchData = (state, instance) => {
        //getting change page number from state
        getData()
        setPageIndex(state.page)
    }
    return (
        <div className="main-content">
            <PageHeading backURL="/overview" headingId={!isLoading ? `Import errors for ${get(data, "payload.filename", "")}` : " "} />

            <div className="card">

                {/* {
                    error ? <Error msg={error} /> :
                        <ReactTable className="prod_overview_dt" data={get(data, "payload.errors", [])} columns={columns} />} */}
                {error ? <Error msg={error} /> :
                    // <ReactTable className="prod_overview_dt" data={get(data, "payload.errors", [])} columns={columns} onFetchData={fetchData} pages={0} page={pageIndex} />

                    <table className="table table-striped">
                        <thead>
                            <th>{renderFormatMsg("Type", "Type")}</th>
                            <th>{renderFormatMsg("Line", "Line")}</th>
                            <th>{renderFormatMsg("Message", "Message")}</th>
                            <th>{renderFormatMsg("Reference", "Reference")}</th>
                        </thead>
                        <tbody>
                            {
                                get(data, "payload.errors", []).map(data =>

                                    <OverlayTrigger
                                        placement="top"
                                        delay={{ show: 100, hide: 0 }}
                                        overlay={(props) => (
                                            <Tooltip id="button-tooltip" {...props}>
                                                <div>
                                                    <ul style={{ textAlign: "start", paddingLeft: 25 }}>
                                                        <li >
                                                            Line of the error: {data.line}
                                                        </li>
                                                        <li >
                                                            Type: {data.type}
                                                        </li>
                                                        <li >
                                                            Error - Message: {data.message}
                                                        </li>
                                                        <li >
                                                            Reference: {data.reference}
                                                        </li>
                                                        <li >
                                                            Handling: {data.proceeding}
                                                        </li>
                                                    </ul>
                                                </div>
                                            </Tooltip>
                                        )}
                                    >
                                        <tr>
                                            <td>{data.type}</td>
                                            <td>{data.line}</td>
                                            <td>{data.message}</td>
                                            <td>{data.reference}</td>
                                        </tr>
                                    </OverlayTrigger>
                                )
                            }
                        </tbody>
                    </table>
                }

            </div>
        </div>
    )
}

export default FileInfo
