import React, { useEffect, useState } from 'react'
import { Form } from 'react-final-form';
import { get, find } from "lodash";
import { renderFormatMsg, toastSuccess, toastError } from '../../shared/funs';
import {
  // updatePromotionType, addPromotionType, getPromotionType
} from '../../services/toolsAndSettingsPagesApi';
import InputField from '../../components/Fields/InputField';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const HandlePromotionType = (props) => {
  const [promotionType, setPromotionType] = useState(null)
  // eslint-disable-next-line
  const [error, setError] = useState("")

  var heading = "AddPromotionType";
  var values = {
    name: "",
    coefficient: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "EditPromotionType.Heading";
    values = {
      name: get(promotionType, "name"),
      coefficient: get(promotionType, "coefficient")
    }
  }

  useEffect(() => {
    // getPromotionType().then(res => {
    //   let promotionType = find(res.promotionTypes, (data) => data.promotionTypeId === props.match.params.promotionTypeId);
    //   setPromotionType(promotionType)
    // }).catch(err => setError(err))
    async function fetchPromotionType() {
      try {
        let res = await (new BaseService('/PromotionTypes')).getAll('', { page: 1, display: 10, search: props.match.params.promotionTypeId })
        let promotionType = find(res.promotionTypes, (data) => data.promotionTypeId === props.match.params.promotionTypeId);
        setPromotionType(promotionType)
      } catch (error) {
        console.log('error => ', error)
        setError(error)
      }
    }
    fetchPromotionType();
  }, [props.match.params.promotionTypeId])

  const handleAddForm = async (data) => {
    let payload = {
      "name": data.name,
      "coefficient": data.coefficient
    }
    // await addPromotionType(payload).then(resp => {
    //   toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
    //   props.history.push("/promotionType")
    // }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))

    try {
      await (new BaseService('/PromotionTypes')).create(payload)
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/promotionType")
    } catch (error) {
      console.log('error =>', error);
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    }
  }

  const handleEditForm = async (data) => {
    const payload = {
      "promotionTypeId": props.match.params.promotionTypeId,
      "name": data.name,
      "coefficient": data.coefficient
    }

    // updatePromotionType(payload).then(resp => {
    //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    //   props.history.push("/promotionType")
    // }).catch(err => {
    //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    // })

    try {
      await (new BaseService('/PromotionTypes')).update(payload.promotionTypeId, payload)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/promotionType")
    } catch (error) {
      console.log('error =>', error);
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
  }

  const validation = (data) => {
    let errors = {}
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    var patt = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/);
    if (data && typeof data.coefficient !== "undefined") {
      if (patt.test(data.coefficient)) {
        errors.coefficient = undefined;
      } else {
        errors.coefficient = "Invalid Input"
      }
    } else {
      errors.coefficient = "Required"
    }
    return errors
  }
  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/promotionType"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/promotionType" headingId={heading} />


      <div className="card p-3">
        <Form onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
          initialValues={values} validate={(data) => validation(data)}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    <InputField
                      colClass="col-12 col-lg"
                      name={"name"}
                      type="text"
                      id="PromotionTypeName"
                      defaultLabel="Promotion Type Name"
                      placeholder={"Promotion Type Name"} />
                    {/* 
                      <Field name="name">
                      <div className="col-12 col-lg">
                        {({ input, meta }) => (
                          <div className="form-group mb-0">
                            <label> {renderFormatMsg("PromotionTypeName", "Promotion Type Name")} </label>
                            <input {...input} type="text" placeholder="Promotion Type Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field> 
                    </div>
                      */}
                    <InputField
                      colClass="col-12 col-lg"
                      name={"coefficient"}
                      type="text"
                      id="BaseCoefficient"
                      defaultLabel="Base Coefficient"
                      iconText={"%"}

                      placeholder={"Base Coefficient"} />
                    {/* <div className="col-12 col-lg">
                      <Field name="coefficient">
                        {({ input, meta }) =>
                          <div className="form-group mb-0">
                            <label> {renderFormatMsg("", "")}</label> <input type="text" {...input} placeholder="Base Coefficient" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        }
                      </Field> 
                    </div>*/}
                  </div>
                </div>
                <div className="row d-flex justify-content-end mt-2">
                  <div className="col-12 col-lg-auto text-right">
                    <div className="submit_btn">
                      <button type="submit" className="btn btn-primary" > {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                    </div>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </div>
  )
}
export default React.memo(HandlePromotionType)
