import React, { useState, useContext, useEffect } from 'react'
import { debounce } from "lodash"
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import { getTransportTypes, getTransportTimesCities, updateTransportTimesCity, deleteTransportTimesCity } from '../../services/toolsAndSettingsPagesApi';
import { Link } from 'react-router-dom';
import { AppContext } from "../../context/AppContext";
import { renderFormatMsg, toastSuccess, toastError, checkPermission, permissionErrorCheck, findPermission } from '../../shared/funs';
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';
import PageHeading from '../../components/PageHeading/PageHeading';

const TransportTimePerCity = (props) => {
    const { userDetails } = useContext(AppContext)
    const [transportTimePerCity, setTransportTimePerCity] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [searchValue, setSearchValue] = useState("")
    // eslint-disable-next-line
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [deletedId, setDeletedId] = useState(null)
    const [show, setShow] = useState(false);
    const [transportType, setTransportType] = useState([]);
    const [input, setInput] = useState({});
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

    const handleClose = () => setShow(!show);

    let dataArray = userDetails && userDetails.accesses

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("TRANSPORT_TIME_PER_CITY").screenId))
    }, [dataArray])

    useEffect(() => {
        getTransportTypes().then(res => setTransportType(res.transportTypes)).catch(err => {
            setError(permissionErrorCheck(err))
        })
    }, [])

    const sendQuery = (object) => {
        updateTransportTimesCity(object).then(res => {
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        }).catch(err => { toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")); setLoading(false) })
    };

    const delayedQuery = debounce(q => sendQuery(q), 500);

    const getData = (page = 0, search = "") => {
        setSearchValue(search)
        setLoading(true)
        let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
        getTransportTimesCities(param).then(res => {
            setLoading(false)
            setTransportTimePerCity(res.transportTimesCities.length > 0 ? res.transportTimesCities : [])
            setTotalPage(res.totalPage)
            res.transportTimesCities.map(dt => input[dt.transportTimesCitiesId] = dt.transportTime)
            setInput(input)
        }).catch(err => {
            setLoading(false)
            setError(permissionErrorCheck(err))
        })
    }
    const fetchData = (state, instance) => {
        //getting change page number from state
        getData(state.page, searchValue)
        setPageIndex(state.page)
    }

    const handleTransport = async (data, details, key) => {
        let object
        if (key === "transportTypeId") {
            object = {
                "transportTimesCitiesId": details.transportTimesCitiesId,
                "transportTypeId": data,
                "transportTime": details.transportTime
            }
        } else {
            input[details.transportTimesCitiesId] = Number(data);
            setInput(input);
            object = {
                "transportTimesCitiesId": details.transportTimesCitiesId,
                "transportTypeId": details.transportTypeId,
                "transportTime": data
            }
        }
        delayedQuery(object);
    };

    const columns = [{
        Header: <div className="text-left"> {renderFormatMsg("CityName", "City Name")} </div>,
        accessor: 'citeName',
        minWidth: 230,
        maxWidth: 250,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    }, {
        Header: renderFormatMsg("transportTimePerCityTable.TransportType", "Transport Type"),
        accessor: 'transportType',
        width: 180,
        //resizable: false,
        Cell: props => <select disabled={!pageReadOnly.write} className="form-control select-arrow"
            onChange={(e) => handleTransport(e.target.value, props.original, "transportTypeId")}
        >
            {transportType && transportType.map((opt, i) =>
                <option key={i} value={opt.transportTypeId} >{opt.name}</option>
            )}
        </select>
    }, {
        Header: renderFormatMsg("transportTimePerCityTable.TransportTime", "Transport Time"),
        accessor: 'transportTime',
        //resizable: false,
        width: 180,
        Cell: props => <input name="transportTime"
            defaultValue={input[props.original.transportTimesCitiesId]}
            className={`form-control ${!pageReadOnly.write && `read-only`}`}
            readOnly={!pageReadOnly.write}
            type="number"
            style={{ textAlign: "center" }}
            onChange={(e) => {
                handleTransport(e.target.value, props.original, "transportTime")
            }}
        />
    },

    ]

    if (pageReadOnly.write) {
        columns.push({
            Header: renderFormatMsg("Edit", "Edit"),
            accessor: 'Edit',
            //resizable: false,
            width: 100,
            Cell: (data) => <Link to={`/transportTimePerCity/Edit/${data.original.transportTimesCitiesId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
        }, {
            Header: renderFormatMsg("Delete", "Delete"),
            accessor: 'Delete',
            //resizable: false,
            width: 100,
            Cell: props => <Link href="#" className="delete-icon" onClick={() => { handleClose(); setDeletedId(props.original.transportTimesCitiesId) }} >
                {renderFormatMsg("Delete", "Delete")}
            </Link>
        })
    }


    const handleDelete = async () => {
        await deleteTransportTimesCity(deletedId).then(async res => {
            await getData()
            handleClose()
            toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
        }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
    }
    const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }
    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("transportTimePerCityTable.Heading", "Transport Time Per City")}
            </div> */}
            <PageHeading headingId="transportTimePerCityTable.Heading" isLoading={isLoading} />
            <div className="card">

                {error ? <Error msg={error} /> : <>
                    <div className="card-header">
                        <SearchBox searchHandler={SearchHandler} />
                        {pageReadOnly.write && <LinkButton to="transportTimePerCity/Add" id="transportTimePerCityTable.AddTransportTimePerCity" defaultText="Add Transport Time Per City" />}
                    </div>
                    <ReactTable data={transportTimePerCity} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
                </>
                }
            </div>
            <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
        </div>
    )
}
export default React.memo(TransportTimePerCity);
