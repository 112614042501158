
import React from 'react'
import { Field } from 'react-final-form';
import { renderFormatMsg } from '../../shared/funs';
import FieldErrorMessage from '../FieldErrorMessage';

const MasterUser = ({ submitting }) => {
  return (
    <div className="row">
      <div className="col-6">
        <Field name="username">
          {({ input, meta }) => (
            <div className="form-group">
              <label>
                {renderFormatMsg("UserName", "User Name")}
              </label>
              <input {...input} type="text" placeholder="User Name" className="form-control" />
              <FieldErrorMessage meta={meta} />
            </div>
          )}
        </Field>
      </div>
      <div className="col-6">
        <Field name="email">
          {({ input, meta }) => (
            <div className="form-group">
              <label>
                {renderFormatMsg("Email", "Email")}
              </label>
              <input {...input} type="text" placeholder="Email" className="form-control" />
              <FieldErrorMessage meta={meta} />
            </div>
          )}
        </Field>
      </div>
    </div>
  )
}

export default React.memo(MasterUser)
