import React, { useEffect, useState } from 'react'
import {
    Form,
    // Field
} from 'react-final-form';
import {
    get,
    // find
} from "lodash";
import { permissionErrorCheck, renderFormatMsg, toastError, toastSuccess } from '../../../shared/funs';
// import { Link } from 'react-router-dom';
// import BackIcon from "../../../images/back-icon.svg";
// import { addOutletCategories, updateOutletCategories, getOutletCategories } from '../../../services/toolsAndSettingsPagesApi';
import Error from '../../../components/Error/Error';
import InputField from '../../../components/Fields/InputField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';

const HandleStoreCategory = (props) => {
    const [outletCategory, setOutletCategory] = useState(null)
    const [error, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    var heading = "HandleStore.Add.Heading"
    var values = {
        customerOutletCategoryId: "",
        name: ""
    }

    if (props.location.pathname.includes("Edit")) {
        heading = "HandleStore.Edit.Heading"
        values = {
            customerOutletCategoryId: get(outletCategory, "customerOutletCategoryId"),
            name: get(outletCategory, "name")
        }
    }

    useEffect(() => {
        if (props.location.pathname.includes("Edit")) {

            async function fetchData() {
                try {
                    let res = await (new BaseService('/OutletCategories')).getAll('', { page: 1, display: 10, search: props.match.params.outletCategoryId })
                    setLoading(false)
                    setOutletCategory(res.outletCategories[0])
                } catch (error) {
                    setLoading(false)
                    setError(permissionErrorCheck(error))
                }
            }
            fetchData()

            // getOutletCategories().then(res => {
            //     let category = find(res.outletCategories, (data) => data.outletCategoryId === props.match.params.outletCategoryId)
            //     setOutletCategory(category && category)
            //     setLoading(false)
            // }).catch(err => {
            //     setLoading(false)
            //     setError(err)
            // })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleAddForm = async (data) => {
        let payload = {
            "customerOutletCategoryId": data.customerOutletCategoryId,
            "name": data.name
        }
        // await addOutletCategories(payload).then(res => {
        //     toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        //     props.history.push("/storeCategory")
        // }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))

        try {
            await (new BaseService('/OutletCategories')).create(payload)
            toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
            props.history.push("/storeCategory")
        } catch (error) {
            toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
        }
    }

    const handleEditForm = async (data) => {
        const payload = {
            "outletCategoryId": props.match.params.outletCategoryId,
            "customerOutletCategoryId": data.customerOutletCategoryId,
            "name": data.name
        }
        // await updateOutletCategories(payload).then(res => {
        //     toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        //     props.history.push("/storeCategory")
        // }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
        try {
            await (new BaseService('/OutletCategories')).update(props.match.params.outletCategoryId, payload)
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
            props.history.push("/storeCategory")
        } catch (error) {
            toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
        }
    }
    const validation = (data) => {
        let errors = {}

        if (!data.customerOutletCategoryId || data.customerOutletCategoryId === "") {
            errors.customerOutletCategoryId = "Required";
        }
        if (!data.name || data.name === "") {
            errors.name = "Required";
        }
        return errors
    }
    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                <div className="heading">
                    <Link className="back-btn" to="/storeCategory"><img src={BackIcon} alt="BackIcon" /></Link>
                    <span>{heading}</span>
                </div>
            </div> */}
            <PageHeading backURL="/storeCategory" headingId={heading} isLoading={isLoading} />
            <div className="card p-3">
                {error ? <Error msg={error} /> : <Form onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm} initialValues={values} validate={validation}>
                    {({ handleSubmit, form, submitting, pristine, values }) => {
                        return (
                            <form onSubmit={handleSubmit} className="align-items-end">
                                <div className="form">
                                    <div className="row">
                                        {/* <div className="col-lg-6">
                                            <Field name="customerOutletCategoryId">
                                                {({ input, meta }) => (
                                                    <div className="form-group mb-0">
                                                        <label>
                                                            {renderFormatMsg("HandleStore.StoreCategoryId", "Store Category ID")}
                                                        </label>
                                                        <input {...input} type="text" placeholder="Store Category ID" className="form-control" />
                                                        {meta.error && meta.touched && (<span>{meta.error}</span>)}
                                                    </div>
                                                )}
                                            </Field>
                                        </div> */}
                                        <InputField
                                            colClass="col-lg-6"
                                            name={"customerOutletCategoryId"}
                                            type="text"
                                            id="HandleStore.StoreCategoryId"
                                            defaultLabel="Store Category ID"
                                            placeholder={"Store Category ID"}
                                        />
                                        {/* <div className="col-lg-6">
                                            <Field name="name">
                                                {({ input, meta }) => (
                                                    <div className="form-group mb-0">
                                                        <label> {renderFormatMsg("HandleStore.CategoryName", "Category Name")} </label>
                                                        <input {...input} type="text" placeholder="Category Name" className="form-control" />
                                                        {meta.error && meta.touched && (<span>{meta.error}</span>)}
                                                    </div>
                                                )}
                                            </Field>
                                        </div> */}
                                        <InputField
                                            colClass="col-lg-6"
                                            name={"name"}
                                            type="text"
                                            id="HandleStore.CategoryName"
                                            defaultLabel="Category Name"
                                            placeholder={"Category Name"}
                                        />
                                    </div>
                                </div>
                                <div className="row d-flex justify-content-end mt-2 row">
                                    <div className="col-12 col-lg-auto">
                                        <div className="submit_btn">
                                            <button
                                                type="submit"
                                                disabled={submitting}
                                                className="btn btn-primary"
                                            >
                                                {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        );
                    }}
                </Form>}
            </div>
        </div>
    )
}

export default React.memo(HandleStoreCategory)
