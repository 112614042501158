import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
// import { getProductCategory, deleteProductCategory } from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { renderFormatMsg, toastError, toastSuccess, permissionErrorCheck, checkPermission, findPermission } from '../../shared/funs';
import Error from "../../components/Error/Error";
import LinkButton from '../../components/Buttons/LinkButton';
import { PAGE_SIZE } from '../../constant';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash'
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ProductCategory = (props) => {
  const [productCategory, setProductCategory] = useState([])
  const [error, setError] = useState(false)
  const [deletedId, setDeletedId] = useState(null)
  const [show, setShow] = useState(false);
  const [isLoading, setLoading] = useState(false)

  const { userDetails } = useContext(AppContext)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

  let dataArray = get(userDetails, "accesses")
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("PRODUCT_CATEGORIES").screenId))
  }, [dataArray])

  const handleClose = () => setShow(!show);
  const columns = [
    // {
    //   Header: <div className="text-left">{renderFormatMsg("productCategory.CategoryId", "Category ID")} </div>,
    //   accessor: 'categoryId',
    //   width: 320,
    //   maxWidth: 400,
    //   style: { justifyContent: "flex-start" }
    // },
    {
      Header: <div className="text-left">{renderFormatMsg("productCategory.CategoryName", "Category Name")} </div>,
      accessor: 'categoryName',
      width: 400,
      maxWidth: 450,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left"> {renderFormatMsg("SizeBowl", "Size Bowl")} </div>,
      accessor: 'sizeName',
      width: 280,
      maxWidth: 350,
      style: { justifyContent: "flex-start" }
    },

  ]

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      //resizable: false,
      width: 100,
      Cell: (data) => <Link to={`/productCategory/Edit/${data.original.categoryId}`} className="edit-icon" > {renderFormatMsg("Edit", "Edit")}
      </Link>
    }, {
      Header: renderFormatMsg("Delete", "Delete"),
      accessor: 'Delete',
      //resizable: false,
      width: 100,
      style: { textAlign: "right" },
      Cell: props => <Link to="#" className="delete-icon" onClick={() => { handleClose(); setDeletedId(props.original.categoryId) }}>{renderFormatMsg("Delete", "Delete")}</Link>
    })
  }
  const fetchData = (state, instance) => {
    //getting change page number from state
    getData({ page: state.page })
  }
  const getData = async ({ page = 0 }) => {
    let param = { "page": page + 1, "display": PAGE_SIZE }
    setLoading(true)
    // getProductCategory(param).then(res => {
    //   setLoading(false)
    //   setProductCategory(res.category.length > 0 ? res.category : [])
    // }).catch(err => {
    //   setLoading(false)
    //   setError(permissionErrorCheck(err))
    // })
    try {
      let res = await (new BaseService('/OptionCategories')).getAll('', param)
      setLoading(false)
      setProductCategory(res.category.length > 0 ? res.category : [])
    } catch (error) {
      setLoading(false)
      setError(permissionErrorCheck(error))
    }
  }
  const handleDelete = async () => {
    // deleteProductCategory(deletedId).then(async res => {
    //   await getData({ page: 0 })
    //   handleClose()
    //   toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
    // }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
    try {
      await (new BaseService('/OptionCategories')).delete(deletedId)
      getData({ page: 0 })
      handleClose()
      toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
      props.history.push("/seasons")
    } catch (error) {
      toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail"))
    }
  }
  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("productCategory.Heading", "Product Category")}
      </div> */}
      <PageHeading headingId="productCategory.Heading" isLoading={isLoading} />
      <div className="card">

        {error ? <Error msg={error} /> : <>
          <div className="card-header">
            {pageReadOnly.write && <LinkButton classes={"ml-auto"} to="/productCategory/Add" id="productCategory.AddProductCategory" defaultText="Add Product Category" />}
          </div>
          <ReactTable data={productCategory} columns={columns} onFetchData={fetchData} />
        </>}
      </div>
      <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
    </div>
  )
}
export default React.memo(ProductCategory);
