import React, { useState, useContext, useEffect } from 'react'
import { renderFormatMsg, toastError, toastSuccess, checkPermission, findPermission, } from '../../shared/funs';
import { LANGUAGES, SHORT_DATE_FORMAT_OPTIONS, LONG_DATE_FORMAT_OPTIONS, MINIMUN_ORDER_PRICE, MONTH_NAME, INFO } from '../../constant';
import "./style.scss"
import {
  // getClientData,
  updateClientData
} from '../../services/toolsAndSettingsPagesApi';
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash';
import PlusIcon from '../../images/plus.svg'
import {
  CSSTransition,
  TransitionGroup,
} from 'react-transition-group';
import moment from 'moment';
import { InputGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { BaseService } from '../../services/BaseService';

const Settings = () => {
  const [formValue, setFormValue] = useState(null)
  const [clientData, setClientData] = useState(null)
  const [formErrors, setFormErrors] = useState({})
  const [validForm, setValidForm] = useState(true)


  const { userDetails, setUserDetails } = useContext(AppContext);
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false });

  let dataArray = get(userDetails, "accesses");

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("SETTINGS").screenId))
  }, [dataArray])



  useEffect(() => {

    if (userDetails?.clientId) {
      let param = { "page": 1, "display": 1, "search": userDetails.clientId }

      async function fetchUserData() {
        try {
          let res = await (new BaseService('/Clients')).getAll('', param)
          // console.info('res =>', res);


          setClientData(res.clients[0])
          // console.info('res.clients[0] => ', res.clients[0]);

          var dates = get(res, "clients[0].holiDays", [])
          var holiDays = dates.map(date => {
            let _date = moment(date, 'YYYY/MM/DD');
            return { day: _date.format('D'), month: _date.format('M'), year: _date.format('YYYY'), }
          });

          setFormValue({
            language: get(res, "clients[0].payload.defaultLanguage", "en"),
            shortDateFormat: get(res, "clients[0].payload.shortDate", "DD.MM.YYYY"),
            longDateFormat: get(res, "clients[0].payload.longDate", "DD.MM.YYYY HH:mm a"),
            minimumOrderValue: get(res, "clients[0].payload.minimumOrderValue", "DD.MM.YYYY HH:mm a"),
            autumnWinterStartDay: get(res, "clients[0].payload.autumnWinterStart", "").split(".")[0],
            autumnWinterStartMonth: get(res, "clients[0].payload.autumnWinterStart", "").split(".")[1],
            springSummerStartDay: get(res, "clients[0].payload.springSummerStart", "").split(".")[0],
            springSummerStartMonth: get(res, "clients[0].payload.springSummerStart", "").split(".")[1],
            minimumOrderPrice: get(res, "clients[0].payload.minimumOrderPrice", ""),
            maxReplPieces: get(res, "clients[0].payload.maxReplPieces", ""),
            defaultMatrixDays: get(res, "clients[0].payload.defaultMatrixDays", ""),
            productMD: get(res, "clients[0].payload.productMD", ""),
            holiDays: holiDays || [],
          });
        } catch (error) {
          console.info('err => ', error)
        }
      }
      fetchUserData();

      // getClientData(param).then(res => {
      //   setClientData(res.clients[0])
      //   console.info('res.clients[0] => ', res.clients[0]);

      //   var dates = get(res, "clients[0].holiDays", [])
      //   var holiDays = dates.map(date => {
      //     let _date = moment(date, 'YYYY/MM/DD');
      //     return { day: _date.format('D'), month: _date.format('M'), year: _date.format('YYYY'), }
      //   });
      //   setFormValue({
      //     language: get(res, "clients[0].payload.defaultLanguage", "en"),
      //     shortDateFormat: get(res, "clients[0].payload.shortDate", "DD.MM.YYYY"),
      //     longDateFormat: get(res, "clients[0].payload.longDate", "DD.MM.YYYY HH:mm a"),
      //     minimumOrderValue: get(res, "clients[0].payload.minimumOrderValue", "DD.MM.YYYY HH:mm a"),
      //     autumnWinterStartDay: get(res, "clients[0].payload.autumnWinterStart", "").split(".")[0],
      //     autumnWinterStartMonth: get(res, "clients[0].payload.autumnWinterStart", "").split(".")[1],
      //     springSummerStartDay: get(res, "clients[0].payload.springSummerStart", "").split(".")[0],
      //     springSummerStartMonth: get(res, "clients[0].payload.springSummerStart", "").split(".")[1],
      //     minimumOrderPrice: get(res, "clients[0].payload.minimumOrderPrice", ""),
      //     maxReplPieces: get(res, "clients[0].payload.maxReplPieces", ""),
      //     productMD: get(res, "clients[0].payload.productMD", ""),
      //     holiDays: holiDays || [],
      //   });
      // }).catch(err => {
      //   console.info('err => ', err);
      // })
    }
  }, [userDetails]);


  const handleEditForm = async () => {
    var _holidays = formValue && formValue.holiDays && formValue.holiDays.map(h => { return `${h.year || new Date().getFullYear()}-${h.month || "1"}-${h.day || "1"}` })
    var _formValue = { ...clientData }
    _formValue.payload.defaultLanguage = formValue.language;
    _formValue.payload.shortDate = formValue.shortDateFormat;
    _formValue.payload.longDate = formValue.longDateFormat;
    _formValue.payload.minimumOrderValue = formValue.minimumOrderValue;
    _formValue.payload.minimumOrderPrice = formValue.minimumOrderPrice;
    _formValue.payload.productMD = formValue.productMD;
    _formValue.payload.maxReplPieces = formValue.maxReplPieces;
    _formValue.payload.defaultMatrixDays = formValue.defaultMatrixDays;

    if (formValue.autumnWinterStartDay && formValue.autumnWinterStartMonth) {
      _formValue.payload.autumnWinterStart = `${formValue.autumnWinterStartDay}.${formValue.autumnWinterStartMonth}.`
    }
    if (formValue.springSummerStartDay && formValue.springSummerStartMonth) {
      _formValue.payload.springSummerStart = `${formValue.springSummerStartDay}.${formValue.springSummerStartMonth}.`
    }

    _formValue.holiDays = _holidays

    // console.info('_formValue => ', _formValue);

    updateClientData(_formValue).then(res => {
      // console.info('res ==>', res);

      setUserDetails({ ...res, accesses: userDetails.accesses });
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    }).catch(error => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })
  }


  const handleForm = (e) => {
    if (e.target.name === "maxReplPieces") {
      var value = !isNaN(e.target.value) ? parseInt(e.target.value) : 0;
      console.info('isNaN(e.target.value) => ', value);
      if (value <= 0) {
        setValidForm(false)
        setFormErrors({ maxReplPieces: "Invalid value" })
        return false
      } else {
        setValidForm(true)
        setFormErrors({ maxReplPieces: "" })
      }
    }
    setFormValue({ ...formValue, [e.target.name]: e.target.value })
  }

  const drawSelectOptions = (start = 1, end = 12) => {
    var options = []
    for (let index = start; index <= end; index++) {
      options.push(<option value={index}  > {index} </option>)
    }
    return options
  }

  // [
  //   {
  //     key:"1",
  //     value:"1",
  //   },
  //   {
  //     key:"2",
  //     value:"5",
  //   },
  //   {
  //     key:"1",
  //     value:"1",
  //   }
  // ]

  const addHoliday = () => {
    var obj = { day: "", month: "", year: "" }
    var _holidays = [...formValue.holiDays];

    if (_holidays.length) {
      obj = { day: "", month: _holidays[_holidays.length - 1].month, year: _holidays[_holidays.length - 1].year }
    }

    setFormValue({ ...formValue, holiDays: [..._holidays, obj] })
  }

  const removeHoliday = (index) => {
    var _holidays = [...formValue.holiDays];
    _holidays.splice(index, 1)

    setFormValue({ ...formValue, holiDays: _holidays })
  }

  const changeHandler = (e, index) => {
    var _holidays = [...formValue.holiDays];
    _holidays[index][e.target.name] = e.target.value

    setFormValue({ ...formValue, holiDays: _holidays })
  }


  const drawOption = (start = 0, max = 12, selected = 0) => {
    var temp = []
    for (let index = start; index < max + 1; index++) {
      temp.push(<option key={index} selected={selected === index} value={index} >{index}</option>)
    }
    return temp
  }

  // console.info('formValue => ', formValue);

  const checkValidation = (e, field) => {

  }

  return (
    <div className="main-content">
      <div className="title">
        <div className="heading">
          <span>{renderFormatMsg("sidebar.Settings", "Settings")}</span>
        </div>
      </div>
      <div className="card p-3">
        <div className="content">
          <div className="form">

            <div className="row mt-2">
              <div className="col-5 form-group">
                <label>{renderFormatMsg("ShortDateFormat", "Short Date Format")}</label>
                <select disabled={!pageReadOnly.write}
                  name="shortDateFormat"
                  defaultValue={formValue && formValue.shortDateFormat}
                  className={`form-control ${!pageReadOnly.write && `read-only`}`}
                  onChange={e => handleForm(e)} >
                  {
                    SHORT_DATE_FORMAT_OPTIONS.map((opt, index) =>
                      <option key={index} value={opt.value} selected={formValue && formValue.shortDateFormat === opt.value}> {opt.label} </option>
                    )
                  }
                </select>

              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("shortDateFormatTooptip", INFO.SHORT_DATE_FORMAT)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>

              </div>
              <div className="col-5 form-group">
                <label>{renderFormatMsg("LongDateFormat", "Long Date Format")}</label>
                <select disabled={!pageReadOnly.write}
                  name="longDateFormat"
                  defaultValue={formValue && formValue.longDateFormat}
                  className={`form-control ${!pageReadOnly.write && `read-only`}`}
                  onChange={e => handleForm(e)} >
                  {
                    LONG_DATE_FORMAT_OPTIONS.map((opt, index) =>
                      <option key={index} value={opt.value} selected={formValue && formValue.longDateFormat === opt.value}> {opt.label} </option>
                    )
                  }
                </select>
              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("longDateFormatTooptip", INFO.LONG_DATE_FORMAT)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-5 form-group">
                <label>{renderFormatMsg("DefaultLanguage", "DefaultLanguage")}</label>
                <select disabled={!pageReadOnly.write} name="language" className={`form-control ${!pageReadOnly.write && `read-only`}`} onChange={e => handleForm(e)} >
                  {
                    LANGUAGES.map(lang =>
                      <option value={lang.value}
                        selected={formValue && formValue.language === lang.value} > {lang.label} </option>
                    )
                  }
                </select>
              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("defaultLanguageTooptip", INFO.DEFAULT_LANGUAGE)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-5 form-group">
                <label>{renderFormatMsg("minimumOrderPrice", "Minimum Order Price")}</label>
                <select disabled={!pageReadOnly.write}
                  name="minimumOrderPrice"
                  defaultValue={formValue && formValue.minimumOrderPrice}
                  className={`form-control ${!pageReadOnly.write && `read-only`}`}
                  onChange={e => handleForm(e)} >
                  {
                    MINIMUN_ORDER_PRICE.map((opt, index) =>
                      <option key={index} value={opt.value} selected={formValue && formValue.minimumOrderPrice === opt.value}> {opt.label} </option>
                    )
                  }
                </select>
              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("minimumOrderPriceTooltip", INFO.MINIMUM_ORDER_PRICE)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>
              <div className="col-5 form-group">
                <label>{renderFormatMsg("minimumOrderValue", "Minimum Order Value")}</label>
                <InputGroup className="mb-2">
                  <input type="text" readOnly={!pageReadOnly.write}
                    name="minimumOrderValue"
                    defaultValue={formValue && formValue.minimumOrderValue}
                    className={`form-control ${!pageReadOnly.write && `read-only`}`}
                    onChange={e => handleForm(e)} />
                  <InputGroup.Prepend>
                    <InputGroup.Text>{renderFormatMsg("Rub.", "Rub.")}</InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>
              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("minimumOrderValueTooltip", INFO.MINIMUM_ORDER_VALUE)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>
            </div>

            <div className="row mt-2">
              <div className="col-5 form-group">
                <label>{renderFormatMsg("autumnWinterStart", "Autumn Winter Start")}</label>
                <div className="row">
                  <div className="col-lg-6">
                    {/* <label>Day</label> */}
                    <select disabled={!pageReadOnly.write}
                      name="autumnWinterStartDay"
                      style={{ width: "47% !important" }}
                      defaultValue={formValue && formValue.autumnWinterStartDay}
                      className={`form-control ${!pageReadOnly.write && `read-only`}`}
                      onChange={e => handleForm(e)} >
                      {/* <option value="" disabled>Day</option> */}
                      {
                        drawOption(1, 31, formValue && formValue.autumnWinterStartDay)
                      }
                    </select>
                  </div>
                  <div className="col-lg-6">
                    {/* <label>Month</label> */}
                    <select disabled={!pageReadOnly.write}
                      name="autumnWinterStartMonth"
                      style={{ width: "47% !important" }}
                      defaultValue={formValue && formValue.autumnWinterStartMonth}
                      className={`form-control ${!pageReadOnly.write && `read-only`}`}
                      onChange={e => handleForm(e)} >
                      {/* <option value="" disabled>Month</option> */}
                      {
                        MONTH_NAME.map(month =>
                          <option value={month.value} selected={formValue && month.value === parseInt(formValue.autumnWinterStartMonth)}>{month.label}</option>
                        )
                      }
                      {/* {
                        drawOption(1, 12, formValue && formValue.autumnWinterStartMonth)
                      } */}
                    </select>
                  </div>
                </div>

              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("autumnWinterStartTooltip", INFO.AUTUMN_WINTER_START)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>

              <div className="col-5 form-group">
                <label>{renderFormatMsg("springSummerStart", "Spring Summer Start")}</label>
                <div className="row">
                  <div className="col-lg-6">
                    {/* <label>Day</label> */}
                    <select disabled={!pageReadOnly.write}
                      name="springSummerStartDay"
                      style={{ width: "47% !important" }}
                      defaultValue={formValue && formValue.springSummerStartDay}
                      className={`form-control ${!pageReadOnly.write && `read-only`}`}
                      onChange={e => handleForm(e)} >
                      {/* <option value="" disabled>Day</option> */}
                      {
                        drawOption(1, 31, formValue && formValue.springSummerStartDay)
                      }
                    </select>
                  </div>
                  <div className="col-lg-6">
                    {/* <label>Month</label> */}
                    <select disabled={!pageReadOnly.write}
                      name="springSummerStartMonth"
                      style={{ width: "47% !important" }}
                      defaultValue={formValue && formValue.springSummerStartMonth}
                      className={`form-control ${!pageReadOnly.write && `read-only`}`}
                      onChange={e => handleForm(e)} >
                      {/* <option value="" disabled>Month</option> */}
                      {
                        MONTH_NAME.map(month =>
                          <option value={month.value} selected={formValue && month.value === parseInt(formValue.springSummerStartMonth)}>{month.label}</option>
                        )
                      }
                      {/* {
                        drawOption(1, 12, formValue && formValue.springSummerStartMonth)
                      } */}
                    </select>
                  </div>
                </div>

              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("springSummerStartTooltip", INFO.SPRING_SUMMER_START)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>


            </div>

            <div className="row mt-2">
              <div className="col-5 form-group">
                <label>{renderFormatMsg("maxReplPieces", "Max. Repl Pieces")}</label>
                <InputGroup className="mb-2">
                  <input type="text" readOnly={!pageReadOnly.write}
                    name="maxReplPieces"
                    defaultValue={formValue && formValue.maxReplPieces}
                    className={`form-control ${!pageReadOnly.write && `read-only`}`}
                    onChange={e => { handleForm(e); checkValidation(e, "maxReplPieces") }} />
                  <InputGroup.Prepend>
                    <InputGroup.Text>{renderFormatMsg("pcs", "pcs")}</InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>

                {!validForm && <span>{formErrors['maxReplPieces']}</span>}

              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("maxReplPiecesTooltip", INFO.MAX_REPL_PIECES)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>


              {/* days */}

              <div className="col-5 form-group">
                <label>{renderFormatMsg("defaultMatrixTime", "Default Matrix Time")}</label>
                <InputGroup className="mb-2">
                  <input type="text" readOnly={!pageReadOnly.write}
                    name="defaultMatrixDays"
                    defaultValue={formValue && formValue.defaultMatrixDays}
                    className={`form-control ${!pageReadOnly.write && `read-only`}`}
                    onChange={e => { handleForm(e) }} />
                  <InputGroup.Prepend>
                    <InputGroup.Text>{renderFormatMsg("days", "Days")}</InputGroup.Text>
                  </InputGroup.Prepend>
                </InputGroup>

                {/* {!validForm && <span>{formErrors['defaultMatrixDays']}</span>} */}

              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("defaultMatrixDaysTooltip", INFO.DEFAULT_MATRIX_DAYS)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>

              {/* days */}


            </div>


            <div className="row mt-2">
              <div className="col-5">
                {formValue && formValue.holiDays && formValue.holiDays.length > 0 && <label>{renderFormatMsg("Holidays", "Holidays")}</label>}
                {formValue && formValue.holiDays && <TransitionGroup className="row todo-list">
                  {
                    formValue.holiDays.map((holiday, index) =>
                      <CSSTransition
                        key={index}
                        timeout={500}
                        classNames="item"
                      >
                        <div>
                          <div className="col-lg-3">
                            <label>{renderFormatMsg("Day", "Day")}</label>
                            <select disabled={!pageReadOnly.write} name="day" value={holiday.day || 1} onChange={(e) => changeHandler(e, index)} className={`form-control ${!pageReadOnly.write && `read-only`}`}  >
                              {drawSelectOptions(1, 31)}
                            </select>
                          </div>
                          <div className="col-lg-3">
                            <label>{renderFormatMsg("Month", "Month")}</label>
                            <select disabled={!pageReadOnly.write} name="month" value={holiday.month || 1} onChange={(e) => changeHandler(e, index)} className={`form-control ${!pageReadOnly.write && `read-only`}`}  >
                              {drawSelectOptions(1, 12)}
                            </select>
                          </div>
                          <div className="col-lg-3">
                            <label>{renderFormatMsg("Year", "Year")}</label>
                            <select disabled={!pageReadOnly.write} name="year" value={holiday.year || new Date().getFullYear()} onChange={(e) => changeHandler(e, index)} className={`form-control ${!pageReadOnly.write && `read-only`}`}  >
                              {drawSelectOptions(new Date().getFullYear(), new Date().getFullYear() + 1)}
                            </select>
                          </div>
                          <div className="col-lg-3">
                            {
                              <div>
                                <span
                                  className="icon-close closeBtn"
                                  onClick={() => removeHoliday(index)}
                                ></span>
                              </div>}
                          </div>
                        </div>
                      </CSSTransition>
                    )
                  }
                  <div className="col-7 mt-3">
                    <div onClick={() => addHoliday()} className="align-items-center btn btn-light d-flex" >
                      <span>{renderFormatMsg("addHoliday", "Add Holiday")}</span>
                      <img className="ml-2" src={PlusIcon} alt="PlusIcon" />
                    </div>
                  </div>
                </TransitionGroup>
                }
              </div>
              <div className="align-items-center col-1 d-flex p-0">
                <OverlayTrigger
                  placement="top"
                  delay={{ show: 100, hide: 400 }}
                  overlay={(props) => (
                    <Tooltip id="button-tooltip" {...props}>
                      {renderFormatMsg("holidaysTooptip", INFO.HOLIDAYS)}
                    </Tooltip>
                  )}
                >
                  <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                </OverlayTrigger>
              </div>
            </div>

          </div>

          <div className="d-flex justify-content-end">
            {pageReadOnly.write && <button disabled={!validForm ? true : false} className="btn_grey d-flex justify-content-end" onClick={() => handleEditForm()} >
              {renderFormatMsg("Save", "Save")}
            </button>}
          </div>
        </div>
      </div >
    </div >

  )
}
export default React.memo(Settings)
