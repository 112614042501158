import React, { useEffect, useContext, useState } from 'react'
import { Dropdown } from 'react-bootstrap'
// import { getUserDetails } from '../services/userAuthentication';
import { get } from 'lodash';
import { withRouter } from 'react-router-dom';
import { AppContext } from '../context/AppContext';
import { renderFormatMsg, toastError, toastSuccess } from "./../shared/funs"
import "../components/Layout/Header/Header.scss";
import "../components/Modal/Modal.scss";
import { updateUserProfile } from '../services/userApi';
import { SHORT_DATE_FORMAT_OPTIONS, LONG_DATE_FORMAT_OPTIONS, LANGUAGES } from '../constant'
import { BaseService } from '../services/BaseService';
const UserDetail = (props) => {
  const { userDetails, setUserDetails, handleLangauge } = useContext(AppContext)

  const [isLoading, setIsLoading] = useState(false)
  const [formValue, setFormValue] = useState(null)
  const [viewOpen, setViewOpen] = useState(false);
  const [error, setError] = useState(false);

  useEffect(() => {

    async function fetchData() {
      try {
        let response = await (new BaseService('/userdetails')).getAll('')
        var user = {
          firstName: response.firstName,
          lastName: response.lastName,
          mobileNumber: response.mobileNumber,
          language: response.language,
          ShortDate: response.shortDateFormat,
          LongDate: response.longDateFormat,
          userId: response.userId,
          email: response.email,
          username: response.username,
        }
        setUserDetails(response);
        setFormValue(user);
      } catch (error) {
        setError(error);
        console.info("error => ", error)
      }
    }
    fetchData();


    // getUserDetails()
    //   .then((response) => {
    //     var user = {
    //       firstName: response.firstName,
    //       lastName: response.lastName,
    //       mobileNumber: response.mobileNumber,
    //       language: response.language,
    //       ShortDate: response.shortDateFormat,
    //       LongDate: response.longDateFormat,
    //       userId: response.userId,
    //       email: response.email,
    //       username: response.username,
    //     }
    //     setUserDetails(response);
    //     setFormValue(user);
    //   })
    //   .catch((error) => {
    //     setError(error);
    //     console.info("error => ", error)
    //   });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  const handleLogout = () => {
    localStorage.clear();
    props.history.push("/login");
  };

  const handleEditUser = async () => {
    let payload = {
      "firstName": get(formValue, "firstName"),
      "lastName": get(formValue, "lastName"),
      "mobileNumber": get(formValue, "mobileNumber"),
      "Language": get(formValue, "language"),
      "userId": get(formValue, "userId"),
      "shortDateFormat": get(formValue, "ShortDate"),
      "longDateFormat": get(formValue, "LongDate"),
      "email": get(formValue, "email"),
      "username": get(formValue, "username"),

    }
    setIsLoading(true);
    // await updateUserProfile(payload).then(res => {
    //   setIsLoading(false);
    //   setUserDetails({ ...res, accesses: userDetails.accesses, });
    //   handleLangauge(formValue.language);
    //   removeOpenClass();
    //   setViewOpen(false);
    //   toastSuccess("User setting updated");
    // }).catch(err => {
    //   console.info(`err => `, err);
    //   toastError("User setting not updated");
    //   setViewOpen(false);
    //   setIsLoading(false);
    //   removeOpenClass();
    // })
    try {
      const res = await (new BaseService('/Users')).update(payload.userId, payload)
      setIsLoading(false);
      setUserDetails({ ...res, accesses: userDetails.accesses, });
      handleLangauge(formValue.language);
      removeOpenClass();
      setViewOpen(false);
      toastSuccess("User setting updated");
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record Updated succesfully"))
    } catch (error) {
      toastError("User setting not updated");
      setViewOpen(false);
      setIsLoading(false);
      removeOpenClass();
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
  }

  const checkValidation = () => {
    return (formValue === null || !formValue.firstName || !formValue.lastName || !formValue.mobileNumber || !formValue.language || !formValue.ShortDate || !formValue.LongDate);
  }
  const handleForm = (e) => {
    setFormValue({ ...formValue, [e.target.name]: e.target.value })
  }

  const openViewDetail = () => {
    var div_1 = document.querySelector(".user-setting-main");
    var div_2 = document.querySelector(".view-code-user");
    div_1.classList.add("open");
    div_2.classList.add("open");
    setViewOpen(true);
  };
  const removeOpenClass = () => {
    var div_1 = document.querySelector(".user-setting-main");
    var div_2 = document.querySelector(".view-code-user");
    div_1.classList.toggle("open");
    div_2.classList.toggle("open");
  };

  const closeBox = () => {
    setViewOpen(false);
    document.getElementById("user").click();
    var div_1 = document.querySelector(".user-setting-main");
    var div_2 = document.querySelector(".view-code-user");
    div_1.classList.remove("open");
    div_2.classList.remove("open");
  };


  return (
    <React.Fragment>
      <Dropdown className="user-box">
        <Dropdown.Toggle id="user" className="email-label">
          {!error ? <><p>
            {`${get(userDetails, "firstName", "")} ${get(userDetails, "lastName", "")}`}
            <span>{get(userDetails, "email")}</span>
          </p>
            <img src={get(userDetails, "profile.photoUrl")} alt="UserImage" /></> : <p>Admin</p>}
        </Dropdown.Toggle>
        <Dropdown.Menu alignRight className="notification">
          <div className="horizontal" style={{ height: `${viewOpen ? "515px" : "auto"}` }}>
            <div className="notification-main user-setting-main">
              <ul>

                <li>
                  <a href="javascript;" onClick={(e) => { openViewDetail(); e.preventDefault(); }}>
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.Settings", "Settings")}
                  </a>
                  <i className="icon-expand ml-auto"></i>
                </li>

                <li>
                  <a href="javascript;">
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.LeaveFeedback", "Leave Feedback")}
                  </a>
                </li>

                <li>
                  <a onClick={() => handleLogout()} href="javascript;">
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.Logout", "Logout")}
                  </a>
                </li>
              </ul>

              <ul className="worldwide-boxs">

                <li>
                  <a href="javascript;">
                    <i className="icon-humber-arrow"></i>
                    {renderFormatMsg("header.User.Menu.CustomerId", "Customer ID")}
                    : <span>493-057-1492</span>
                  </a>
                </li>

              </ul>
            </div>
            <div className="view-code view-code-user">
              {viewOpen &&
                <div className="notification-boxs">
                  <div className="notification-box-div">
                    <button
                      type="button"
                      className="icon-close close-btn"
                      onClick={closeBox}
                    ></button>
                    <h2>{renderFormatMsg("UserSettings", "User Settings")}</h2>
                  </div>
                  <div className="content">
                    <div className="frm_full">
                      <div className="frm_grp">
                        <label>{renderFormatMsg("FirstName", "First Name")}</label>
                        <input defaultValue={get(userDetails, "firstName")} type="text" name="firstName" onChange={(e) => handleForm(e)} />
                      </div>
                      <div className="frm_grp">
                        <label>{renderFormatMsg("LastName", "Last Name")}</label>
                        <input defaultValue={get(userDetails, "lastName")} type="text" name="lastName" onChange={(e) => handleForm(e)} />
                      </div>
                      <div className="frm_grp full-width">
                        <label>{renderFormatMsg("MobilePhone", "Mobile phone")}</label>
                        <input defaultValue={get(userDetails, "mobileNumber")} type="text" name="mobileNumber" onChange={(e) => handleForm(e)} />
                      </div>
                      <div className="frm_grp full-width">
                        <label>{renderFormatMsg("Language", "Language")}</label>
                        <select name="language" onChange={e => handleForm(e)} >
                          {
                            LANGUAGES.map(lang =>
                              <option value={lang.value} selected={formValue && formValue.language === lang.value}> {lang.label} </option>
                            )
                          }
                        </select>
                      </div>
                      <div className="frm_grp full-width">
                        <label>{renderFormatMsg("ShortDateFormat", "Short Date Format")}</label>
                        <select name="ShortDate" defaultValue={formValue && formValue.ShortDate} onChange={e => handleForm(e)} >
                          {
                            SHORT_DATE_FORMAT_OPTIONS.map((opt, index) =>
                              <option key={index} value={opt.value}> {opt.label} </option>
                            )
                          }
                        </select>
                      </div>
                      <div className="frm_grp full-width">
                        <label>{renderFormatMsg("LongDateFormat", "Long Date Format")}</label>
                        <select name="LongDate" defaultValue={formValue && formValue.LongDate} onChange={e => handleForm(e)} >
                          {
                            LONG_DATE_FORMAT_OPTIONS.map((opt, index) =>
                              <option key={index} value={opt.value}> {opt.label} </option>
                            )
                          }
                        </select>
                      </div>
                      <div className="btn_pop">
                        <button
                          className="btn_white"
                          onClick={() => {
                            removeOpenClass();
                            setViewOpen(false);
                          }
                          }>
                          {renderFormatMsg("Cancel", "Cancel")}
                        </button>
                        <button
                          className="btn_grey"
                          onClick={() => handleEditUser()}
                          disabled={checkValidation()}
                        >
                          {isLoading ? renderFormatMsg("Editing", "Editing") : renderFormatMsg("Edit", "Edit")}
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              }
            </div>
          </div>
        </Dropdown.Menu>
      </Dropdown>
    </React.Fragment >
  )
}

export default React.memo(withRouter(UserDetail));
