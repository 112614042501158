import React, { useState, useEffect, useContext } from 'react'
// import { Link } from "react-router-dom"
import { get } from "lodash"
// import { getSeasonsDetails, updateSeasonsDetails, addNewSeason, getSeasonType } from '../../../services/toolsAndSettingsPagesApi';
import moment from 'moment';
import { PAGE_SIZE, DATE_FORMAT, FIELD_ACCESS_FUNCTION, SEASON_TYPE } from '../../../constant';
import { renderFormatMsg, toastSuccess, toastError, checkPermission } from '../../../shared/funs';
// import BackIcon from "../../../images/back-icon.svg";
import {
  // Field,
  Form
} from 'react-final-form';
import ActiveFromAndToDate from '../../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import Error from '../../../components/Error/Error';
import { AppContext } from '../../../context/AppContext';
// import FieldErrorMessage from '../../../components/FieldErrorMessage';
import InputField from '../../../components/Fields/InputField';
import SelectField from '../../../components/Fields/SelectField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';


const HandleSeason = (props) => {
  const [season, setSeason] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [seasonType, setSeasonType] = useState([])

  const [permissionForCategory1, setPermissionCategory1] = useState({ read: true, write: true })
  const [permissionForCategory2, setPermissionCategory2] = useState({ read: true, write: true })

  const { userDetails } = useContext(AppContext)
  let dataArray = get(userDetails, "accesses");

  useEffect(() => {
    setPermissionCategory1(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.SEASON_FORM_CATEGORY_1))
    setPermissionCategory2(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.SEASON_FORM_CATEGORY_2))
  }, [dataArray])

  console.info(`permissionForCategory1 => `, permissionForCategory1);
  console.info(`permissionForCategory2 => `, permissionForCategory2);
  let heading = "AddSeason"
  var values = {
    customerSeasonId: "",
    year: "",
    activeFrom: "",
    activeTo: "",
    activePhase: "",
    name: "",
    daysToIncludeInMatrix: "",
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "EditSeason"

    values = {
      customerSeasonId: get(season, "customerSeasonId"),
      name: get(season, "name"),
      year: get(season, "year"),
      daysToIncludeInMatrix: get(season, "daysToIncludeInMatrix"),
      activePhase: get(season, "activePhase"),
      seasonType: get(season, "seasonTypeId", ""),
      activeFrom: get(season, "activeFrom"),
      activeTo: get(season, "activeTo"),
    }
  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {

      async function fetchData() {
        let param = { "page": 1, "display": PAGE_SIZE, "search": props.match.params.seasonId }
        try {
          let season = await (new BaseService('/Seasons')).getAll('', param)
          let seasonType = await (new BaseService('/SeasonTypes')).getAll('')
          setSeason(season.seasons[0])
          setSeasonType(seasonType.seasonTypes)
          setLoading(false)
        } catch (error) {
          setLoading(false)
          setError(error)
        }
      }
      fetchData();
      // getSeasonsDetails(param).then(res => {
      //   setSeason(res.seasons[0])
      //   setLoading(false)
      // }).catch(err => {
      //   setLoading(false)
      //   setError(err)
      // })
    }
    // getSeasonType().then(res => {
    //   setSeasonType(res.seasonTypes)
    //   console.info('res => ', res);
    // }).catch(err => {
    // })

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const validation = (data) => {
    let errors = {}
    if ((permissionForCategory1.write) && (!data.customerSeasonId || data.customerSeasonId === "")) {
      errors.customerSeasonId = "Required";
    }
    if ((permissionForCategory1.write) && (!data.name || data.name === "")) {
      errors.name = "Required";
    }
    if ((permissionForCategory1.write) && (!data.year || data.year === "")) {
      errors.year = "Required";
    }
    if ((permissionForCategory1.write) && (!data.daysToIncludeInMatrix || data.daysToIncludeInMatrix === "")) {
      errors.daysToIncludeInMatrix = "Required";
    }
    if (!data.activeFrom || data.activeFrom === "") {
      errors.activeFrom = "Required";
    }
    if (!data.activeTo || data.activeTo === "") {
      errors.activeTo = "Required";
    }
    if (!data.seasonType || data.seasonType === "") {
      errors.seasonType = "Required";
    }
    if (!data.activePhase || data.activePhase === "") {
      errors.activePhase = "Required";
    }

    return errors
  }

  const generateOptions = (options) => {
    var _options = [<option option value={""} > Select Season Type</option>]
    options.map(opt =>
      _options.push(<option value={opt.seasonTypeId}>{opt.name}</option>)
    )

    return _options;
  }

  const generateSeasonTypeOptions = (options) => {
    var _options = [<option option value={""} > Select Season Active Phase</option>]
    options.map(opt =>
      _options.push(<option value={opt.value}>{opt.label}</option>)
    )

    return _options;
  }


  const handleForm = async (data) => {
    let object = {
      name: ""
    };
    if (permissionForCategory1.write) {
      object.customerSeasonId = data.customerSeasonId;
      object.name = data.name;
      object.year = data.year;
      object.daysToIncludeInMatrix = data.daysToIncludeInMatrix;
      object.seasonTypeId = data.seasonType;
      object.activePhase = data.activePhase;
    }

    object.activeFrom = moment(data.activeFrom).format(DATE_FORMAT);
    object.activeTo = moment(data.activeTo).format(DATE_FORMAT);

    if (props.location.pathname.includes("Add")) {
      try {
        await (new BaseService('/Seasons')).create(object)
        toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        props.history.push("/seasons")
      } catch (error) {
        toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
      }

      // addNewSeason(object).then(resp => {
      //   toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      //   props.history.push("/seasons")
      // }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
    } else {
      object.seasonId = props.match.params.seasonId
      try {
        let season = await (new BaseService('/Seasons')).update(props.match.params.seasonId, object)
        console.log('season =>', season);

        props.history.push("/seasons")
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      } catch (error) {
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      }
      // updateSeasonsDetails(object).then(resp => {
      //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      //   props.history.push("/seasons")
      // }).catch(err => { toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")) })
    }
  }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <Link className="back-btn" to="/seasons"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/seasons" headingId={heading} isLoading={isLoading} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={handleForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="customerSeasonId">
                        {({ input, meta, }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("seasonsTable.SeasonCode", "Season Code")} </label>
                            <input readOnly={!permissionForCategory1.write}  {...input} type="text" placeholder="Season Code" className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> 
                    <div className="col-lg-6">
  <Field name="name">
    {({ input, meta }) => (
      <div className="form-group">
        <label>
          {renderFormatMsg("seasonsTable.SeasonName", "Season Name")}
        </label>
        <input readOnly={!permissionForCategory1.write} {...input} type="text" placeholder="Name" className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} />
        <FieldErrorMessage meta={meta} />
      </div>
    )}
  </Field>
</div> */}

                    <InputField
                      colClass="col-lg-6"
                      name={"customerSeasonId"}
                      type="text"
                      id="seasonsTable.SeasonCode"
                      defaultLabel="Season Code"
                      placeholder={"Season Code"}
                      readOnly={!permissionForCategory1.write}
                      className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id="seasonsTable.SeasonName"
                      defaultLabel="Season Name"
                      placeholder={"Season Name"}
                      readOnly={!permissionForCategory1.write}
                      className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`}
                    />



                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="year">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("seasonsTable.Year", "Year")}</label>
                            <input readOnly={!permissionForCategory1.write} {...input} type="text" placeholder="Year" className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`} />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}

                    <InputField
                      colClass="col-lg-6"
                      name={"year"}
                      type="text"
                      id="seasonsTable.Year"
                      defaultLabel="Year"
                      placeholder={"Season Year"}
                      readOnly={!permissionForCategory1.write}
                      className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`}
                    />

                    {/* <div className="col-lg-6">
                      <Field name="seasonType" component="select" options={seasonType}>
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <label> {renderFormatMsg("header.Tools.seasonType", "Season Type")}</label>
                            <select name="interaction" className="form-control" onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select Season Type</option>
                              {options && options.map(opt =>
                                <option selected={input.value === opt.seasonTypeId} value={opt.seasonTypeId}>{opt.name}</option>
                              )}</select>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        }
                      </Field>
                    </div> */}

                    <SelectField
                      colClass="col-lg-6"
                      name="seasonType"
                      options={generateOptions(seasonType)}
                      id="header.Tools.seasonType"
                      defaultLabel={"Season Type"}
                    />


                    {/* <SelectField
                      colClass="col-12 col-lg"
                      name="brandId"
                      options={generateBrandOptions(brands)}
                      id="Brand"
                      defaultLabel={"Brand"}
                    /> */}
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="activePhase">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("seasonsTable.ActivePhase", "Active Phase")}</label>

                            <select name="interaction" className="form-control" onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select Season Phase</option>
                              {SEASON_TYPE.map(opt =>
                                <option selected={input.value === opt.value} value={opt.value}>{opt.label}</option>
                              )}</select>

                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}

                    <SelectField
                      colClass="col-lg-6"
                      name="activePhase"
                      options={generateSeasonTypeOptions(SEASON_TYPE)}
                      id="seasonsTable.ActivePhase"
                      defaultLabel={"Active Phase"}
                    />

                    <InputField
                      colClass="col-lg-6"
                      name={"daysToIncludeInMatrix"}
                      type="number"
                      id="seasonsTable.daysIncludeInMatrix"
                      defaultLabel="Days Included In Matrix"
                      placeholder={"Days Included In Matrix"}
                      readOnly={!permissionForCategory1.write}
                      className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`}
                    />

                  </div>

                  <ActiveFromAndToDate className={`form-control`} />

                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                      ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}
export default React.memo(HandleSeason)
