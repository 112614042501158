import React, { useContext } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Switch, Route } from "react-router-dom";
import Routes from "./routes";
import "./App.scss";
import { IntlProvider } from "react-intl";
import { AppContext } from "./context/AppContext";

import Login from './view/Login/Login';

if (window.location.protocol === "https:") {
  console.log = () => { }
  console.info = () => { }
  console.error = () => { }
  console.warn = () => { }
}

const App = ({ messages }) => {
  const { language } = useContext(AppContext);
  return (
    <IntlProvider locale={language} messages={messages[language]}>
      <Switch>
        <Route exact path="/login" render={(props) => <Login {...props} />} />
        <Routes />
      </Switch>
    </IntlProvider>
  );
}

export default React.memo(App);
