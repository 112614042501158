import React from 'react'
import { Field } from 'react-final-form'
import FieldErrorMessage from '../FieldErrorMessage'
import { renderFormatMsg } from '../../shared/funs'

/**
 * 
 * @param {name} name of input
 * @param {placeholder} placeholder of input
 * @param {lable} lable of input
 */
export default function SelectField({ colClass = "col-12 col-lg", name, options = [], defaultLabel, id, ...rest }) {
    // console.log('rest =>', rest);

    return (
        <div className={colClass}>
            <Field name={name}>
                {({ input, meta }) => (
                    <div className="form-group">
                        {
                            // console.log('SelectField input =>', input)
                        }
                        <label> {renderFormatMsg(id, defaultLabel)} </label>
                        <select {...input} className="form-control" {...rest}>
                            {options}
                        </select>
                        <FieldErrorMessage meta={meta} />
                    </div>
                )}
            </Field>
        </div>
    )
}
