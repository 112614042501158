import React, { useEffect, useState, useContext } from 'react'
import { Form } from 'react-final-form';
import { get, find } from "lodash";
import {
	renderFormatMsg, toastSuccess, toastError, permissionErrorCheck,
	// selectStyle,
	checkPermission
} from '../../../shared/funs';
// import { Link } from 'react-router-dom';
// import BackIcon from "../../../images/back-icon.svg";
// import { getProductSizeName, getProductCategory, addProductCategory, updateProductCategory } from '../../../services/toolsAndSettingsPagesApi';
import Error from '../../../components/Error/Error';
import { AppContext } from '../../../context/AppContext';
import { FIELD_ACCESS_FUNCTION } from '../../../constant';
// import FieldErrorMessage from '../../../components/FieldErrorMessage';
import InputField from '../../../components/Fields/InputField';
import SelectField from '../../../components/Fields/SelectField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';

const HandleProductCategory = (props) => {
	const [productCategory, setProductCategory] = useState(null)
	const [sizeNameList, setSizeNameList] = useState([])
	const [sizeName, setSizeName] = useState("")
	const [error, setError] = useState(false)

	const { userDetails } = useContext(AppContext)
	const [permissionForCategory1, setPermissionCategory1] = useState({ read: true, write: true })
	const [permissionForCategory2, setPermissionCategory2] = useState({ read: true, write: true })
	let dataArray = get(userDetails, "accesses");
	useEffect(() => {
		setPermissionCategory1(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.PRODUCT_CATEGORIES_FORM_CATEGORY_1))
		setPermissionCategory2(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.PRODUCT_CATEGORIES_FORM_CATEGORY_2))
	}, [dataArray])

	let params = new URLSearchParams(props.location.search);
	let name = params.get('name')
	var heading = "AddProductCategory.Heading"
	var values = {
		categoryName: name,
		sizeId: ""
	}

	if (props.location.pathname.includes("Edit") && productCategory) {
		console.log('productCategory =>', productCategory);

		heading = "EditProductCategory.Heading"
		values = {
			categoryName: get(productCategory, "categoryName"),
			sizeId: sizeName && sizeName.optionSizeId
		}
		console.log('values =>', values);

	}

	useEffect(() => {
		// getProductCategory({ search: props.match.params.categoryId }).then(res => {
		// 	// let category = find(res.category, (data) => data.categoryId === props.match.params.categoryId);
		// 	console.log('res.category[0] =>', res.category[0]);
		// 	var category = (res.category && res.category[0]) || null
		// 	getProductSizeName().then(res => {
		// 		console.log('res =>', res);

		// 		setProductCategory(category);
		// 		setSizeNameList(res)
		// 		let sizeName = find(res, (data) => data.name === get(category, "sizeName"))
		// 		console.log('sizeName =>', sizeName);

		// 		setSizeName(sizeName)
		// 	}).catch(err => {
		// 		setProductCategory(category);
		// 	})
		// }).catch(err => {
		// 	setError(permissionErrorCheck(err))
		// })
		async function fetchData() {
			try {
				let res = await (new BaseService('/OptionCategories')).getAll('', { search: props.match.params.categoryId })
				let sizeList = await (new BaseService('/Size')).getAll('')
				var category = (res.category && res.category[0]) || null
				let sizeName = find(sizeList, (data) => data.name === get(category, "sizeName"))
				setProductCategory(category);
				setSizeNameList(sizeList)
				setSizeName(sizeName)
			} catch (error) {
				setError(permissionErrorCheck(error))
			}
		}

		fetchData()

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [])

	const handleAddForm = async (data) => {
		let payload = {
			"categoryName": data.categoryName,
			"optionSizeId": data.sizeId
		}
		// addProductCategory(object).then(resp => {
		// 	toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
		// 	props.history.push("/productCategory")
		// }).catch(err => {
		// 	toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
		// })
		try {
			(new BaseService('/OptionCategories')).create(payload)
			toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
			props.history.push("/productCategory")
		} catch (error) {
			toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
		}
	}

	const handleEditForm = async (data) => {
		const payload = {
			"categoryId": props.match.params.categoryId,
			"categoryName": data.categoryName,
			"optionSizeId": data.sizeId
		}
		// updateProductCategory(props.match.params.categoryId, payload).then(resp => {
		// 	toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
		// 	props.history.push("/productCategory")
		// }).catch(err => {
		// 	toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
		// })

		try {
			await (new BaseService('/OptionCategories')).update(props.match.params.categoryId, payload)
			toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
			props.history.push("/productCategory")
		} catch (error) {
			toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
		}
	}

	const validation = (data) => {
		console.log('data =>', data);

		let errors = {}
		if (permissionForCategory1.write) {
			if (!data.categoryName || data.categoryName === "") {
				errors.categoryName = "Required";
			}
		}
		console.log('permissionForCategory2.write =>', permissionForCategory2.write);

		if (permissionForCategory2.write) {
			if (!data.sizeId || data.sizeId === "") {
				errors.sizeId = "Required";
			}
		}
		return errors
	}


	const generateOptions = (options) => {
		var _options = []
		options.map((x, i) => _options.push(<option value={x.optionSizeId}>{x.name}</option>))
		return _options;
	}
	console.log('permissionForCategory2 =>', permissionForCategory2);

	return (
		<div className="main-content">
			{/* <div className="title">
				<div className="heading">
					<Link className="back-btn" to="/productCategory"><img src={BackIcon} alt="BackIcon" /></Link>
					<span>{heading}</span>
				</div>
			</div> */}
			<PageHeading backURL="/productCategory" headingId={heading} />
			<div className="card p-3">
				{error ? <Error msg={error} /> :
					<Form
						onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
						initialValues={values}
						validate={validation}>
						{({ handleSubmit, form, submitting, pristine, values }) => {
							return (
								<form onSubmit={handleSubmit} className="align-items-end">
									<div className="form">
										<div className="row">
											{/* <div className="col-lg-6">
											<Field name="categoryName">
												{({ input, meta }) => (
													<div className="form-group mb-0">
														<label>
															{renderFormatMsg("AddProductCategory.CategoryName", "Category Name")}
														</label>
														<input {...input} type="text" placeholder="Category Name" />
														<FieldErrorMessage meta={meta} />
													</div>
												)}
											</Field>
										</div> */}

											<InputField
												colClass="col-lg-6"
												name={"categoryName"}
												type="text"
												id="AddProductCategory.CategoryName"
												defaultLabel="Category Name"
												placeholder={"Category Name"}
												readOnly={!permissionForCategory1.write}
												className={`form-control ${!permissionForCategory1.write ? "read-only" : ""}`}
											/>
											<SelectField
												colClass="col-12 col-lg"
												name="sizeId"
												options={generateOptions(sizeNameList)}
												id="AddProductCategory.SizeName"
												defaultLabel={"Size Name"}
												className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`}
											/>


											{/* <div className="col-lg-6">
											<Field name="sizeId" component="select" options={sizeNameList && sizeNameList}>
												{({ input, meta, options }) =>
													<div className="form-group mb-0">
														<label> {renderFormatMsg("AddProductCategory.SizeName", "Size Name")}</label>
														<select disabled={!permissionForCategory2.write} className={`form-control ${!permissionForCategory2.write ? "read-only" : ""}`} style={selectStyle(input)}
															name={get(options, "name")}
															onChange={(value) => input.onChange(value)}>
															<option value={0}>Select Size</option>
															{options && options.map((x, i) => <option selected={input.value === x.optionSizeId} key={i} value={x.optionSizeId}>{x.name}</option>)}
														</select>
														<FieldErrorMessage meta={meta} />
													</div>
												}
											</Field>
										</div>
									 */}
										</div>
									</div>
									<div className="row d-flex justify-content-end mt-2 row">
										<div className="col-12 col-lg-auto">
											<div className="submit_btn">
												<button type="submit" className="btn btn-primary" >
													{props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
												</button>
											</div>
										</div>
									</div>
								</form>
							);
						}}
					</Form>
				}
			</div>
		</div >
	)
}

export default React.memo(HandleProductCategory)
