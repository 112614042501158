import React from "react"
import jwt from "jsonwebtoken";
import { find } from "lodash";
import { toast } from "react-toastify";
import { FormattedMessage } from "react-intl";
import { PERMISSION_LEVEL_READ_WRITE, PERMISSION_LEVEL_READ_ONLY, DATE_FORMAT, SCREEN_ACCESS_RIGHT } from "../constant";
import moment from "moment";
import _ from "lodash";

/**
 * Get Token from localstorage
 */
export const getToken = () => {
  return localStorage.getItem("token") ? localStorage.getItem("token") : false;
};

/**
 * Check if is auth user or not
 */
export const isAuth = () => {
  const token = localStorage.getItem("token") ? localStorage.getItem("token") : false;
  if (!token) return false
  var decodedToken = jwt.decode(token, { complete: true });
  var dateNow = new Date();
  return decodedToken && decodedToken.payload.exp < dateNow.getTime();
};

/**
 * Toast Error message
 */
export const toastError = (data) => toast.error(data)

/**
 * Toast info message
 */
export const toastInfo = (data) => toast.info(data)

/**
 * Toast Success message
 */
export const toastSuccess = (data) => toast.success(data)

/**
 *
 * @param {string} id id of language string
 * @param {string} msg default message
 * @returns
 */
export const renderFormatMsg = (id, msg) => <FormattedMessage id={id} defaultMessage={msg} />


/**
 * check user's permission
 * @param {Array} dataArray
 * @param {String} permissionVal
 * @returns
 */
export const checkPermission = (dataArray = [], permissionVal) => {
  var permission = { read: true, write: false }
  var givenPageAccessDetail = find(dataArray, { function: permissionVal });

  if (givenPageAccessDetail && givenPageAccessDetail.accessLevel === PERMISSION_LEVEL_READ_WRITE)
    permission.write = true;
  else if (givenPageAccessDetail && givenPageAccessDetail.accessLevel === PERMISSION_LEVEL_READ_ONLY)
    permission.write = false;
  return permission;
}


/**
 * Date range Status
 * @param {Date} dateOne
 * @param {Date} dateTwo
 * @returns {string} class name
 */
export const DateRangeStatus = (dateOne, dateTwo) => {
  return dateOne && (new Date(dateOne) < new Date() && new Date(dateTwo) > new Date() ? "greenBG" : "redBG")
}

/**
 *
 * @param {array} data array of table data
 * @param {string} key key of update
 * @param {date} date date of change in active from and active to
 * @param {object} obj object to be update in payload
 */
export const updateActiveFromAndToRow = (data, key, date, obj) => {
  let tempData = data && data.length > 0 ? [...data] : []
  tempData.forEach(currentData => {
    if (currentData[Object.keys(obj)[0]] === obj[Object.keys(obj)[0]]) {
      key === "activeFrom" ? currentData.activeFrom = moment(date).format() : currentData.activeTo = moment(date).format()
    }
  })
  let object = {
    ...obj,
    [key]: moment(date).format(DATE_FORMAT),
  }
  return { data: tempData, payload: object }
}




/**
 *
 * @param {int} value to be convert into short number
 */
export const intToString = (value) => {
  if (value < 10000)
    return value;

  let newValue = value;
  const suffixes = ["", "K", "M", "B", "T"];
  let suffixNum = 0;
  while (newValue >= 1000) {
    newValue /= 1000;
    suffixNum++;
  }
  newValue = newValue.toPrecision(3);
  newValue += suffixes[suffixNum];
  return newValue;
}

/**
 * Permission Error Check
 * @param {object} err
 * @returns
 */
export const permissionErrorCheck = (err) => {
  if (typeof err !== "undefined" && (err.status === 401 || err.status === 403)) {
    return "Access denied you don't have permission to access this page";
  } else {
    return "Internal Server Error";
  }
}

/**
 * Select Style
 * @param {object} input
 * @returns
 */
export const selectStyle = (input) => {
  return { color: `${input.value ? "#495057" : "#a0a0a0"}` }
}

//convert date format from string
export const displayMessage = (str, format) => {
  // eslint-disable-next-line no-unused-vars
  const formatDate = (date) => {
    return moment(date, 'DD/MM/YYYY').format(format)
  }
  var regex = /(\d{1,2})\/(\d{1,2})\/(\d{4})/g;
  // eslint-disable-next-line no-template-curly-in-string
  var convertedString = str.replace(regex, "${formatDate('$1/$2/$3')}");
  try {
    // eslint-disable-next-line no-eval
    return eval('`' + convertedString + '`')
  } catch (error) {
    return convertedString
  }
}

/**
 * 
 * @param {string} name permission name
 * @returns object
 */
export const findPermission = (name) => {
  return _.find(SCREEN_ACCESS_RIGHT, { name })
}