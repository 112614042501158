import React, { useEffect } from "react";
import { Field, Form } from "react-final-form";
import { userAuthentication } from "../../services/userAuthentication";
import { isAuth, toastError, renderFormatMsg } from "../../shared/funs";
import LogoIcon from "../../images/lab.svg";
import "./Login.scss";
import { Link } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import FieldErrorMessage from "../../components/FieldErrorMessage";
const Login = props => {

  const handleForm = async data => {
    userAuthentication(data)
      .then(resp => {
        let token = resp.data.access_token;
        localStorage.setItem("token", token);
        // props.history.push("/");
        const query = new URLSearchParams(window.location.search);
        const redirect = query.get('redirect')
        if (redirect) {
          props.history.push(redirect);
        } else {
          props.history.push("/");
        }
      })
      .catch(e => {
        toastError(renderFormatMsg("INVALID_CRED", "Invalid credential !"))
      });
  };

  useEffect(() => {
    document.body.classList.remove("body-main");
    if (isAuth()) {
      props.history.push("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validation = values => {
    const errors = {};
    if (!values.username) {
      errors.username = "Username Required";
    }
    if (!values.password) {
      errors.password = "Password Required";
    }
    return errors;
  };


  return (
    <div className="login-bg">
      <div className="container h-100">
        <div className="row h-100 justify-content-center">
          <div className="col-12 col-sm-6 col-lg-4">
            <div className="logo">
              <img src={LogoIcon} alt="LogoIcon" />
              <h3> {renderFormatMsg("loginPage.Heading", "COMMERCIAL LAB")} </h3>
            </div>
            <Form onSubmit={handleForm} validate={validation}>
              {({ handleSubmit, form, submitting, pristine, values }) => {
                return (
                  <form onSubmit={handleSubmit}>
                    <h2>
                      {renderFormatMsg("loginPage.Login", "Login")}
                    </h2>
                    <Field name="username">
                      {({ input, meta }) => (
                        <div className="form-group">
                          <label> {renderFormatMsg("loginPage.Username", "Username")} </label>
                          <input {...input} type="text" placeholder="Username" className="form-control" />
                          <FieldErrorMessage meta={meta} />
                        </div>
                      )}
                    </Field>
                    <Field name="password">
                      {({ input, meta }) => (
                        <div className="form-group">
                          <label> {renderFormatMsg("loginPage.Password", "Password")} </label>
                          <input {...input} type="password" placeholder="Password" className="form-control" />
                          <FieldErrorMessage meta={meta} />
                        </div>
                      )}
                    </Field>
                    <div className="text-forgot">
                      <Link to="#">
                        {renderFormatMsg("loginPage.ForgetPassword", "Forgot Password ?")}
                      </Link>
                    </div>
                    <div className="submit_btn">
                      <button type="submit" disabled={submitting || pristine} className="btn btn-primary" >
                        Login
                      </button>
                    </div>
                  </form>
                );
              }}
            </Form>
            <ToastContainer limit={1} position="top-right" autoClose={5000} hideProgressBar newestOnTop={false} closeOnClick pauseOnVisibilityChange draggable pauseOnHover />
          </div>
        </div>
      </div>
    </div>
  );
};

export default React.memo(Login);
