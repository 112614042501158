import React from 'react'
import { Field } from 'react-final-form'
import FieldErrorMessage from '../FieldErrorMessage'
import { renderFormatMsg } from '../../shared/funs'
import { InputGroup } from 'react-bootstrap'

/**
 * 
 * @param {name} name of input
 * @param {placeholder} placeholder of input
 * @param {lable} lable of input
 */
export default function InputField({ name, colClass = "col-12 col-lg", type = "text", iconText = false, placeholder, defaultLabel, id, ...rest }) {
    const inputFieldRender = (input) => {
        return <input {...input} className="form-control" {...rest} type={type} placeholder={placeholder} />
    }
    return (
        <div className={colClass}>
            <Field name={name}>
                {({ input, meta }) => (
                    <div className="form-group">
                        <label> {renderFormatMsg(id, defaultLabel)} </label>
                        {iconText ? <InputGroup className="mb-2">
                            {inputFieldRender(input)}
                            <InputGroup.Prepend>
                                <InputGroup.Text>{iconText}</InputGroup.Text>
                            </InputGroup.Prepend>
                        </InputGroup> : inputFieldRender(input)
                        }
                        < FieldErrorMessage meta={meta} />
                    </div>
                )}
            </Field>
        </div>
    )
}
