import React, { useState } from 'react'
import { Form } from 'react-final-form';
import { renderFormatMsg, toastSuccess, toastError } from '../../../shared/funs';
// import { Link } from 'react-router-dom';
// import BackIcon from "../../../images/back-icon.svg";
import { updateClientData, AddNewClient } from '../../../services/toolsAndSettingsPagesApi';
import CompanyInformation from "../../../components/AddClient/CompanyInformation"
import BrandInformation from '../../../components/AddClient/BrandInformation';
import StoreCities from '../../../components/AddClient/StoreCities';
import SizeBowl from '../../../components/AddClient/SizeBowl';
import MasterUser from '../../../components/AddClient/MasterUser';
import Warehouse from '../../../components/AddClient/Warehouse';
import PageHeading from '../../../components/PageHeading/PageHeading';

const HandleClientPage = (props) => {
  const [step, setStep] = useState(1)
  var heading = "AddNewClient"

  const handleAddForm = async (data) => {
    let object = {
      info: {
        clientName: data.clientName,
        country: data.country,
        language: data.language,
        shortDateFormat: data.shortDateFormat,
        logDateFormat: data.longDateFormat,
        minimumOrderPrice: 0,
        minimumOrderValue: 0
      },
      brands: data.brands,
      city: data.city,
      size: data.size,
      warehouses: data.warehouse,
      masterUser: {
        userName: data.username,
        email: data.email
      }
    }

    AddNewClient(object).then(resp => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/client")
    }).catch(err => {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    })
  }
  const handleEditForm = async (data) => {
    const object = {
      "clientId": props.match.params.clientId,
      "clientName": data.clientName,
      "activeFrom": data.activeFrom,
      "activeTo": data.activeTo,
    }
    updateClientData(object).then(resp => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/client")
    }).catch(err => {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    })
  }

  const checkValidationStep = (step, values) => {
    let errors = {}
    switch (step) {
      //step:1 = comppanyInfo
      case 1: if (!values.clientName || values.clientName === "") {
        errors.clientName = "Required";
      }
        if (!values.country || values.country === "") {
          errors.country = "Required";
        }
        if (!values.language || values.language === "") {
          errors.language = "Required";
        }
        if (!values.shortDateFormat || values.shortDateFormat === "") {
          errors.shortDateFormat = "Required";
        }
        if (!values.longDateFormat || values.longDateFormat === "") {
          errors.longDateFormat = "Required";
        }
        return errors
      // step:2= BrandInfo
      case 2:
        if (!values.brands || values.brands === "" || values.brands.length <= 0) {
          errors.brands = "Required";
        }
        return errors
      // step:3= city
      case 3: if (!values.city || values.city === "" || values.city.length <= 0) {
        errors.city = "Required";
      }
        return errors
      // step:4= Warehouse
      case 4: if (!values.warehouse || values.warehouse === "" || values.warehouse.length <= 0) {
        errors.warehouse = "Required";
      }
        return errors
      // step:5= Size
      case 5: if (!values.size || values.size === "") {
        errors.size = "Required";
      }
        return errors
      case 6: if (!values.username || values.username === "") {
        errors.username = "Required";
      }
        if (!values.email || values.email === "") {
          errors.email = "Required";
        }
        return errors
      default:
        return {}
    }
  }

  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/client"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/client" headingId={heading} />
      <div className="stepwizard">
        <div className="stepwizard-row setup-panel">
          <div className="stepwizard-step">
            <button onClick={() => setStep(1)} type="button" className={`btn ${step === 1 ? "btn-circle btn-success" : "btn-default"}`} disabled={step === 1 ? false : true} >1</button>
            <p><small>{renderFormatMsg("CompanyInformation", "Company Information")}</small></p>
          </div>
          <div className="stepwizard-step">
            <button onClick={() => setStep(2)} type="button" className={`btn ${step === 2 ? "btn-circle btn-success" : "btn-default"}`} disabled={step === 2 ? false : true}>2</button>
            <p><small>{renderFormatMsg("BrandInformation", "Brand Information")}</small></p>
          </div>
          <div className="stepwizard-step">
            <button type="button" className={`btn ${step === 3 ? "btn-circle btn-success" : "btn-default"}`} onClick={() => setStep(3)} disabled={step === 3 ? false : true} >3</button>
            <p><small>{renderFormatMsg("StoreCities", "Store Cities")}</small></p>
          </div>
          <div className="stepwizard-step">
            <button type="button" className={`btn ${step === 4 ? "btn-circle btn-success" : "btn-default"}`} onClick={() => setStep(4)} disabled={step === 4 ? false : true}>4</button>
            <p><small>{renderFormatMsg("Warehouse", "Warehouse")}</small></p>
          </div>
          <div className="stepwizard-step">
            <button type="button" className={`btn ${step === 5 ? "btn-circle btn-success" : "btn-default"}`} onClick={() => setStep(4)} disabled={step === 5 ? false : true}>5</button>
            <p><small>{renderFormatMsg("SizeBowl", "Size Bowl")}</small></p>
          </div>
          <div className="stepwizard-step">
            <button type="button" className={`btn ${step === 6 ? "btn-circle btn-success" : "btn-default"}`} onClick={() => setStep(5)} disabled={step === 6 ? false : true}>6</button>
            <p><small>{renderFormatMsg("MasterUser", "Master User")}</small></p>
          </div>
        </div>
      </div>
      <div className="card p-3">
        <Form onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className={`${step === 1 && "row"} align-items-end`}>
                {
                  console.info(`values => `, values)
                }
                {step === 1 && <CompanyInformation values={values} form={form} />}
                {step === 2 && <BrandInformation values={values} form={form} />}
                {step === 3 && <StoreCities values={values} form={form} />}
                {step === 4 && <Warehouse values={values} form={form} />}
                {step === 5 && <SizeBowl values={values} form={form} />}
                {step === 6 && <MasterUser values={values} form={form} />}
                <div className="d-flex flex-wrap mt-3 w-100 px-3">
                  {step > 1 && <button type="button" className="btn btn-primary" onClick={() => setStep(step - 1)}> {renderFormatMsg("Back", "Back")} </button>}
                  {step < 6 && <button type="button" className="btn btn-primary ml-auto"
                    disabled={Object.keys(checkValidationStep(step, values)).length > 0 ? true : false}
                    onClick={() => setStep(step + 1)}> {renderFormatMsg("Next", "Next")} </button>}
                  {step === 6 && <button type="submit" className="btn btn-primary ml-auto"
                    disabled={Object.keys(checkValidationStep(step, values)).length > 0 ? true : false}
                  >{renderFormatMsg("Confirm", "Confirm")}</ button>}
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </div >
  )
}

export default React.memo(HandleClientPage)
