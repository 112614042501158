import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { get } from "lodash";
import {
  renderFormatMsg, toastSuccess, toastError, permissionErrorCheck,
  // selectStyle
} from '../../shared/funs';
// import { Link } from 'react-router-dom';
// import BackIcon from "../../images/back-icon.svg";
import { COLOR_STYLE_FOR_TAG_INPUT, DATE_FORMAT, INFO } from '../../constant';
// import { addPromotion, updatePromotion, getPromotionById } from '../../services/sidebarApi';
import moment from 'moment';
import {
  getBrand,
  // getOutletCategories, getPromotionType,
  validateCategoryIds, validateProductIds,
} from '../../services/toolsAndSettingsPagesApi';
// import Error from '../../components/Error/Error';
import ActiveFromAndToDate from '../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import ProductIdsToolTip from '../../components/ProductIdsToolTip';
// import FieldErrorMessage from '../../components/FieldErrorMessage';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import InputField from '../../components/Fields/InputField';
import SelectField from '../../components/Fields/SelectField';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';


const HandlePromotionType = (props) => {
  const [promotionType, setPromotionType] = useState([])
  const [promotion, setPromotions] = useState(null)
  const [, setError] = useState(false)
  const [selectedPromoIds, setSelectedPromoIds] = useState([])
  const [selectedCategoriesIds, setSelectedCategoriesIds] = useState([])
  // eslint-disable-next-line no-unused-vars
  const [options, setOptions] = useState([])
  const [brandOptions, setBrandOptions] = useState([])
  const [categoryOptions, setCategoryOptions] = useState([])
  const animatedComponents = makeAnimated();


  var heading = "AddPromotion";
  var values = {
    name: "",
    promotionTypeId: "",
    brandId: "",
    initialCoefficient: "",
    coefficient: "",
    activeFrom: "",
    activeTo: "",
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "EditPromotion";
    values = {
      name: get(promotion, "name"),
      promotionTypeId: get(promotion, "promotionType.promotionTypeId"),
      brandId: get(promotion, "brandId"),
      initialCoefficient: get(promotion, "initialCoefficient"),
      coefficient: get(promotion, "coefficient"),
      activeFrom: get(promotion, "activeFrom"),
      activeTo: get(promotion, "activeTo"),
    }
  }

  useEffect(() => {


    // getPromotionType().then(res => 
    //   setPromotionType(res.promotionTypes)).catch(err => {
    //   setError(permissionErrorCheck(err))
    // })

    async function fetchPromotionType() {
      try {
        let res = await (new BaseService('/PromotionTypes')).getAll('?display=20')
        setPromotionType(res.promotionTypes)
      } catch (error) {
        console.log('error => ', error)
        setError(permissionErrorCheck(error))
      }
    }
    fetchPromotionType();





    let param = { "page": 1, "display": 999999 }
    // getOutletCategories(param).then(res => {
    //   console.info('getOutletCategories res => ', res);
    //   if (res.outletCategories.length > 0) {
    //     var data = res.outletCategories.map(({ name, outletCategoryId, customerOutletCategoryId }) => { return { customerOutletCategoryId, name, label: name, value: customerOutletCategoryId } })
    //     console.info('data => ', data);
    //     setCategoryOptions(data)
    //   }
    // }).catch(err => {
    //   setError(permissionErrorCheck(err))
    // })


    async function fetchOutletCategoriesData() {
      try {
        let res = await (new BaseService('/OutletCategories')).getAll('', param)
        console.info('getOutletCategories res => ', res);
        if (res.outletCategories.length > 0) {
          var data = res.outletCategories.map(({ name, outletCategoryId, customerOutletCategoryId }) => { return { customerOutletCategoryId, name, label: name, value: customerOutletCategoryId } })
          console.info('data => ', data);
          setCategoryOptions(data)
        }
      } catch (error) {
        console.log('error => ', error)
        setError(permissionErrorCheck(error))
      }
    }
    fetchOutletCategoriesData();


    async function fetchBrandData() {
      try {
        let res = await (new BaseService('/Brand')).getAll('')
        setBrandOptions(res.brands)
      } catch (error) {
        console.log('error => ', error)
      }
    }
    fetchBrandData();

    // getBrand().then(res => {
    //   setBrandOptions(res.brands)
    // }).catch(error => console.log('error => ', error))



    // getOutletCategories(param).then(res => {
    //   console.info('getOutletCategories res => ', res);
    //   if (res.outletCategories.length > 0) {
    //     var data = res.outletCategories.map(({ name, outletCategoryId, customerOutletCategoryId }) => { return { customerOutletCategoryId, name, label: name, value: customerOutletCategoryId } })
    //     console.info('data => ', data);
    //     setCategoryOptions(data)
    //     // setSelectedCategoriesIds(data)
    //   }
    // }).catch(err => {
    //   setError(permissionErrorCheck(err))
    // })

    if (props.location.pathname.includes("Edit")) {


      async function fetchData() {
        try {
          let res = await (new BaseService('/Promotions')).get(props.match.params.promotionId)
          var productIds = get(res, "productIds", []);
          var outletCategoryIds = get(res, "outletCategoryIds", []);
          var _productIds = productIds.map(v => { return { label: v.trim(), value: v.trim() }; });
          var _outletCategoryIds = outletCategoryIds.map(v => { return { label: v.trim(), value: v.trim() }; });
          // setSelectedPromoIds(_productIds)
          validateIds(_productIds, { brandId: res.brandId })
          validateCategoriesIds(_outletCategoryIds, { brandId: res.brandId })
          setPromotions(res)
        } catch (error) {
          setError(permissionErrorCheck(error))
        }
      }

      fetchData()

      // getPromotionById({
      //   promotionId: props.match.params.promotionId
      // }).then(res => {
      //   var productIds = get(res, "productIds", []);
      //   var outletCategoryIds = get(res, "outletCategoryIds", []);
      //   var _productIds = productIds.map(v => { return { label: v.trim(), value: v.trim() }; });
      //   var _outletCategoryIds = outletCategoryIds.map(v => { return { label: v.trim(), value: v.trim() }; });
      //   // setSelectedPromoIds(_productIds)
      //   validateIds(_productIds, { brandId: res.brandId })
      //   validateCategoriesIds(_outletCategoryIds, { brandId: res.brandId })
      //   setPromotions(res)
      // }).catch(err => {
      //   setError(permissionErrorCheck(err))
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleForm = async (data) => {
    var productIds = []
    selectedPromoIds && selectedPromoIds.forEach(({ status, value }) => {
      if (status !== 0) {
        productIds.push(value)
      }
    })
    var outletCategoryIds = []
    selectedCategoriesIds && selectedCategoriesIds.forEach(({ status, value }) => {
      if (status !== 0) {
        outletCategoryIds.push(value)
      }
    })



    let payload = {
      "promotionTypeId": data.promotionTypeId,
      "name": data.name,
      "initialCoefficient": data.initialCoefficient,
      "coefficient": data.coefficient,
      // "scope": data.scope,
      "brandId": data.brandId,
      "productIds": productIds,
      "outletCategoryIds": outletCategoryIds,
      "activeFrom": moment(data.activeFrom).format(DATE_FORMAT),
      "activeTo": moment(data.activeTo).format(DATE_FORMAT)
    }

    console.info('payload => ', payload);

    if (props.location.pathname.includes("Add")) {
      // addPromotion(payload).then(resp => {
      //   toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      //   props.history.push("/promotion")
      // }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
      try {
        var res = await (new BaseService('/Promotions')).create(payload)
        console.log('Promotions res =>', res);

        if (res) {
          toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
          props.history.push("/promotion")
        } else {
          toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
        }
      } catch (error) {
        console.log('Promotions error =>', error);
        toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
      }

    } else {
      payload.promotionId = props.match.params.promotionId
      // updatePromotion(payload).then(resp => {
      //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      //   props.history.push("/promotion")
      // }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
      try {
        await (new BaseService('/Promotions')).update(props.match.params.promotionId, payload)
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        props.history.push("/promotion")
      } catch (error) {
        console.log('error =>', error);
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      }
    }
  }




  const validation = (data) => {
    let errors = {}
    var patt = new RegExp(/^-?(?:\d+|\d{1,3}(?:,\d{3})+)(?:(\.|,)\d+)?$/);
    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (!data.promotionTypeId || data.promotionTypeId === "") {
      errors.promotionTypeId = "Required";
    }
    if (!data.brandId || data.brandId === "") {
      errors.brandId = "Required";
    }
    if (data && typeof data.initialCoefficient !== "undefined") {
      if (patt.test(data.initialCoefficient)) {
        errors.initialCoefficient = undefined;
      } else {
        errors.initialCoefficient = "Invalid Input";
      }
    } else {
      errors.initialCoefficient = "Required";
    }
    // if (data && typeof data.coefficient !== "undefined") {
    //   if (patt.test(data.coefficient)) {
    //     errors.coefficient = undefined;
    //   } else {
    //     errors.coefficient = "Invalid Input";
    //   }
    // } else {
    //   errors.coefficient = "Required";
    // }
    if (!data.activeFrom || data.activeFrom === "") {
      errors.activeFrom = "Required";
    }
    if (!data.activeTo || data.activeTo === "") {
      errors.activeTo = "Required";
    }
    return errors
  }




  const validateIds = (newValue, values) => {
    console.info('values => ', values);

    if (!values) {
      return
    }

    var temp = [];
    newValue && newValue.forEach(o => {
      // temp.push({ Value: o.value })
      temp.push(o.value)
    })

    var obj = {
      Value: temp
    }
    if (values.brandId) {
      obj.BrandId = values.brandId
    }


    console.info('obj => ', obj);

    validateProductIds(obj).then((result) => {
      console.info(`validateProductIds result => `, result);
      var temp = result.map((o, index) => {
        let color = "#846aff";
        switch (o.status) {
          case 0:
            // not exist
            color = "#ff00008c"
            break;
          case 1:
            // exits and not active
            color = "#ff98009c"
            break;
          case 2:
            // exits and active
            // color = "#4caf50"
            color = "#4caf50c2"
            break;
          default:
            break;
        }
        return {
          color: color,
          label: o.checked_Option,
          status: o.status,
          value: o.checked_Option
        }
      })

      setSelectedPromoIds(temp)
    }).catch((err) => {
      console.info(`validateProductIds err => `, err);
    });
  }

  const validateCategoriesIds = (newValue, values) => {
    console.info('newValue => ', newValue);
    console.info('values => ', values);


    var temp = [];
    newValue && newValue.forEach(o => {
      // temp.push({ Value: o.value })
      temp.push(o.value)
    })
    var obj = {
      Value: temp
    }
    if (values.brandId) {
      obj.BrandId = values.brandId
    }
    console.info('obj => ', obj);

    validateCategoryIds(obj).then((result) => {
      console.info(`validateCategoryIds result => `, result);
      var temp = result.map((o, index) => {
        let color = "#846aff";
        switch (o.status) {
          case 0:
            // not exist
            color = "#ff00008c"
            break;
          case 1:
            // exits and not active
            color = "#ff98009c"
            break;
          case 2:
            // exits and active
            // color = "#4caf50"
            color = "#4caf50c2"
            break;
          default:
            break;
        }
        return {
          color: color,
          label: o.checked_Option,
          status: o.status,
          value: o.checked_Option
        }
      })

      setSelectedCategoriesIds(temp)
    }).catch((err) => {
      console.info(`validateProductIds err => `, err);
    });
  }

  const handleChange = (newValue, onChange, values) => {
    onChange(newValue)
    setSelectedPromoIds(newValue)
    validateIds(newValue, values)
  };

  const handleChangeCategoriesIds = (newValue, onChange, values) => {
    console.info('newValue => ', newValue);

    onChange(newValue)
    setSelectedCategoriesIds(newValue)
    validateCategoriesIds(newValue, values)
  };

  const handleInputChange = (value, onChange) => {
    console.info('xxx handleInputChange value => ', value);
    if (value.includes(' ')) {
      console.info('selectedPromoIds => ', selectedPromoIds);

      const existingValues = selectedPromoIds || []
      // const existingValues = Object.assign([], selectedPromoIds) || []
      //handleChangeComment
      const splitValues = value.split(' ').map(v => { return { label: v.trim(), value: v.trim() }; });
      splitValues.forEach(v => {
        // Ignore currently selected values
        if (v.value && existingValues.findIndex(o => o.value === v.value) < 0) {
          existingValues.push(v);
        }
        // Add this value to our array that we'll send to change handler
      });

      var productIds = [...new Set(existingValues)];

      console.info(`productIds => `, productIds);
      // productIds.map((v, index) => {
      //   if (validIds.indexOf(v.value) >= 0) {
      //     return { color: `#4caf50`, label: v.label, value: v.value }
      //   } else {
      //     return { color: `#f44336`, label: v.label, value: v.value }
      //   }
      // })

      validateIds(productIds)

      return '';
    }
    // if there are no commas to process, just pass through the input value
    console.info('value => ', value);

    return value;
  }
  console.info('values => ', values);

  const generatePromotionTypeOptions = (options) => {
    var _options = [<option option value={""} > Select Promotion Type</option>]
    options.map(({ name, promotionTypeId }, index) =>
      _options.push(<option value={promotionTypeId}>{name}</option>)
    )

    return _options;
  }


  const generateBrandOptions = (options) => {
    var _options = [<option option value={""} > Select Brand</option>]
    options.map(({ name, brandId }, index) =>
      _options.push(<option value={brandId}>{name}</option>)
    )

    return _options;
  }
  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/promotion"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/promotion" headingId={heading} />
      <div className="card p-3">
        {<Form onSubmit={handleForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    {/* <div className="col-lg-5">
                      <Field name="name">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("PromotionName", "Promotion Name")} </label>
                            <input {...input} type="text" placeholder="Promotion Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <InputField
                      colClass="col-lg-5"
                      name={"name"}
                      type="text"
                      id="PromotionName"
                      defaultLabel="Promotion Name"
                      placeholder={"Promotion Name"}
                    />
                    <div className="col-lg-1">

                    </div>

                    <SelectField
                      colClass="col-12 col-lg"
                      name="promotionTypeId"
                      options={generatePromotionTypeOptions(promotionType)}
                      id="PromotionType"
                      defaultLabel={"Promotion Type"}
                    />
                    {/* <div className="col-lg-5">
                      <Field name="promotionTypeId" component="select" options={promotionType}>
                        {({ input, meta, options }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("PromotionType", "Promotion Type")} </label>
                            <select style={selectStyle(input)} defaultValue={input.value} className="form-control" onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select one</option>
                              {options && options.map((x) => {
                                return <option defaultValue={input.value === x.promotionTypeId} key={x.promotionTypeId} value={x.promotionTypeId}>{x.name}
                                </option>
                              })}
                            </select>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <div className="col-lg-1">

                    </div>
                  </div>
                  {/* {
                    JSON.stringify(values, null, 2)
                  } */}
                  <div className="row">
                    <SelectField
                      colClass="col-lg-5"
                      name="brandId"
                      options={generateBrandOptions(brandOptions)}
                      id="Brand"
                      defaultLabel={"Brand"}
                    />

                    {/* <div className="col-lg-5">
                      <Field name="brandId">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("HandleOutlet.brandId", "Brand")} </label>
                            <select style={selectStyle(input)} value={input.value} className="form-control" onChange={(value) => input.onChange(value)}>
                              <option value={""}>Select Brand</option>
                              {brandOptions && brandOptions.map((x) => {
                                return <option defaultValue={input.value === x.brandId} key={x.brandId} value={x.brandId}>{x.name}
                                </option>
                              })}
                            </select>

                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <div className="col-lg-1">

                    </div>
                  </div>
                  <div className="row d-flex align-items-center">
                    {/* <div className="col-lg-5 position-relative">
                      <Field name="initialCoefficient">
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <label> {renderFormatMsg("InitialCoefficient", "Initial Coefficient")}</label>
                            <input {...input} type="text" placeholder="Base Coefficient" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        }
                      </Field>
                    </div> */}
                    <InputField
                      colClass="col-lg-5 position-relative"
                      name={"initialCoefficient"}
                      type="text"
                      id="InitialCoefficient"
                      iconText="%"
                      defaultLabel="Initial Coefficient"
                      placeholder={"Initial Coefficient"}
                    />

                    <div className="col-lg-1 " style={{ right: -9, top: 0 }}>
                      <OverlayTrigger
                        placement="top"
                        delay={{ show: 100, hide: 400 }}
                        overlay={(props) => (
                          <Tooltip id="button-tooltip" {...props}>
                            {renderFormatMsg("initialCoefficientToolTip", INFO.INITIAL_COEFFICIENT)}
                          </Tooltip>
                        )}
                      >
                        <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                      </OverlayTrigger>
                    </div>
                    {/* <div className="col-lg-5">
                      <Field name="coefficient">
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <label> {renderFormatMsg("CurrentCoefficient", "Current Coefficient")}</label>
                            <input {...input} readOnly={true} type="text" placeholder="Current Coefficient" className="read-only form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        }
                      </Field>
                    </div> */}

                    <InputField
                      colClass="col-lg-5 position-relative"
                      name={"coefficient"}
                      type="text"
                      id="CurrentCoefficient"
                      defaultLabel="Current Coefficient"
                      placeholder={"Current Coefficient"}
                      readOnly={true}
                      iconText="%"
                      className="read-only form-control"
                    />


                    <div className="col-lg-1">
                      <div className="">
                        <OverlayTrigger
                          placement="top"
                          delay={{ show: 100, hide: 400 }}
                          overlay={(props) => (
                            <Tooltip id="button-tooltip" {...props}>
                              {renderFormatMsg("currentCoefficientToolTip", INFO.CURRENT_COEFFICIENT)}
                            </Tooltip>
                          )}
                        >
                          <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
                        </OverlayTrigger>
                      </div>
                    </div>
                  </div>

                  <div className="row d-flex align-items-center">
                    <div className="col-lg-11">
                      <Field name={"productIds"}>
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("Options", "Options")}</label>
                            <CreatableSelect
                              isMulti
                              components={animatedComponents}
                              value={selectedPromoIds}
                              closeMenuOnSelect={false}
                              onChange={(value) => handleChange(value, input.onChange, values)}
                              onInputChange={(values) => handleInputChange(values, input.onChange)}
                              options={options}
                              styles={COLOR_STYLE_FOR_TAG_INPUT}
                              placeholder="Select/Paste Product Ids(s)"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-lg-1">
                      <ProductIdsToolTip />
                    </div>
                  </div>
                  {/* <pre>  {
                    JSON.stringify(values, null, 2)
                  }</pre> */}

                  <div className="row align-items-center">
                    <div className="col-lg-11">
                      <Field name={"categoriesIds"}>
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("header.Tools.StoreCategory", "Store Categories")}</label>
                            <CreatableSelect
                              isMulti
                              components={animatedComponents}
                              value={selectedCategoriesIds}
                              closeMenuOnSelect={false}
                              onChange={(value) => handleChangeCategoriesIds(value, input.onChange, values)}
                              onInputChange={(values) => handleInputChange(values, input.onChange)}
                              options={categoryOptions}
                              styles={COLOR_STYLE_FOR_TAG_INPUT}
                              placeholder="Select/Paste Store Categories Ids(s)"
                            />
                          </div>
                        )}
                      </Field>
                    </div>
                    <div className="col-lg-1">
                      {/* <ProductIdsToolTip /> */}
                    </div>
                  </div>

                  <ActiveFromAndToDate />
                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn test">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                      ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}

export default React.memo(HandlePromotionType)
