import { SERVER_API_URL, SERVER_GRAPHQL_API_URL } from "../constant";

const ROOT_URL = SERVER_API_URL;
const SESSION_KEY = "token";

const ROOT_GRAPHQL_URL = SERVER_GRAPHQL_API_URL;

export class HttpService {
  headers = {};

  constructor(url_prefix = "") {
    this.url_prefix = url_prefix;
    this.getHeaders();
  }

  get(url, queryParams) {
    try {
      console.log(
        "ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams) =>",
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams)
      );

      return fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          headers: this.headers,
        }
      )
        .then(async (response) => {
          if (response.status >= 400 && response.status < 600) {
            throw new Error("Bad response from server");
          }
          return await response.json();
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
      // let jsonResponse = await response.json()
      // return jsonResponse
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  post(url, body, queryParams = null) {
    try {
      return fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: "POST",
          headers: this.headers,
          body: JSON.stringify(body),
        }
      )
        .then(async (response) => {
          if (response.status >= 400 && response.status < 600) {
            throw new Error("Bad response from server");
          }
          return await response.json();
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  put(url, body, queryParams = null) {
    try {
      return fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: "PUT",
          headers: this.headers,
          body: JSON.stringify(body),
        }
      )
        .then(async (response) => {
          if (response.status >= 400 && response.status < 600) {
            throw new Error("Bad response from server");
          }
          return await response.json();
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  async remove(url, queryParams = null) {
    try {
      return fetch(
        ROOT_URL + this.getUrl(url) + this.mapQueryParams(queryParams),
        {
          method: "DELETE",
          headers: this.headers,
        }
      )
        .then(async (response) => {
          if (response.status >= 400 && response.status < 600) {
            throw new Error("Bad response from server");
          }
          return await response.json();
        })
        .catch((err) => {
          console.log(err);
          return err;
        });
    } catch (error) {
      console.log(error);
      return null;
    }
  }

  getUrl(url) {
    return this.url_prefix + url;
  }

  getHeaders() {
    this.headers = {
      "Content-Type": "application/json",
      Accept: "application/json",
    };
    if (this.checkSession()) {
      let apiToken = this.getSession();
      this.headers = {
        ...this.headers,
        Authorization: `Bearer ${apiToken}`,
      };
    }
  }

  getSession() {
    return localStorage.getItem(SESSION_KEY);
  }

  checkSession() {
    return localStorage.getItem(SESSION_KEY) !== null;
  }

  mapQueryParams(queryParams) {
    return queryParams
      ? `?${Object.keys(queryParams)
          .map(function (key) {
            return key + "=" + queryParams[key];
          })
          .join("&")}`
      : "";
  }
}
