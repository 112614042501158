import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
import { Dropdown } from 'react-bootstrap'
import { renderFormatMsg } from "./../shared/funs"
import { PERMISSION_LEVEL_READ_ONLY, PERMISSION_LEVEL_READ_WRITE, PAGE_ACCESS_FUNCTION } from '../constant'
import { get, find } from 'lodash'
import { AppContext } from '../context/AppContext'

const ToolsAndSettingDetail = () => {
  const { userDetails } = useContext(AppContext)

  const closeTools = () => {
    document.getElementById("tools").click();
  };
  let dataArray = get(userDetails, "accesses", [])
  const CheckMenuPermission = (key) => {
    let permission = false
    let accessDetail = find(dataArray, (o) => PAGE_ACCESS_FUNCTION[key] === o.function)
    if (accessDetail && accessDetail.accessLevel === PERMISSION_LEVEL_READ_WRITE) {
      permission = true;

    } else if (accessDetail && accessDetail.accessLevel === PERMISSION_LEVEL_READ_ONLY) {
      permission = false;
    }
    return permission
  }

  return (
    <Dropdown className="tools">
      <Dropdown.Toggle id="tools">
        <i className="icon-tool-setting"></i>
        {renderFormatMsg("header.Tools.Heading", "Tools & Settings")}
      </Dropdown.Toggle>
      <Dropdown.Menu>

        <ul>
          {CheckMenuPermission("REPLENISHMENT_CALENDAR") && <li>
            <NavLink to="/replenishment-calendar" onClick={closeTools}>
              <i className="icon-billing"></i>
              {renderFormatMsg("header.Tools.ReplenishmentCalendar", "Replenishment Calendar")}
            </NavLink>
          </li>}
          {CheckMenuPermission("STORE_CATEGORY") && <li>
            <NavLink to="/storeCategory" onClick={closeTools}>
              <i className="icon-settings"></i>
              {renderFormatMsg("header.Tools.StoreCategory", "Store Category")}

            </NavLink>
          </li>}
          {CheckMenuPermission("SESSION") && <li>
            <NavLink to="/seasons" onClick={closeTools}>
              <i className="icon-measurment"></i>
              {renderFormatMsg("header.Tools.Seasons", "Seasons")}
            </NavLink>
          </li>}
          {CheckMenuPermission("OUTLET") && <li>
            <NavLink to="/outlet" onClick={closeTools}>
              <i className="icon-bulk-action"></i>
              {renderFormatMsg("header.Tools.Outlet", "Outlet")}
            </NavLink>
          </li>}
          {CheckMenuPermission("USER") && <li>
            <NavLink to="/user" onClick={closeTools}>
              <i className="icon-shared-liberary"></i>
              {renderFormatMsg("header.Tools.User", "User")}
            </NavLink>
          </li>}
          {CheckMenuPermission("USER_ROLES") && <li>
            <NavLink to="/user-roles" onClick={closeTools}>
              <i className="icon-planning"></i>
              {renderFormatMsg("header.Tools.Roles", "User Roles")}
            </NavLink>
          </li>}
          {CheckMenuPermission("TRANSPORT_TIME_PER_CITY") && <li>
            <NavLink to="/transportTimePerCity" onClick={closeTools}>
              <i className="icon-landing-pages"></i>
              {renderFormatMsg("header.Tools.transportTimePerCity", "Transport Time Per City")}
            </NavLink>
          </li>}
          {CheckMenuPermission("CLIENT_PAGE") && <li>
            <NavLink to="/client" onClick={closeTools}>
              <i className="icon-demographics"></i>
              {renderFormatMsg("header.Tools.client", "client")}
            </NavLink>
          </li>}
          {CheckMenuPermission("WAREHOUSES") && <li>
            <NavLink to="/warehouses" onClick={closeTools}>
              <i className="icon-product-groups"></i>
              {renderFormatMsg("header.Tools.Warehouses", "Warehouses")}
            </NavLink>
          </li>}
          <li>
            <NavLink to="/productCategory" onClick={closeTools}>
              <i className="icon-audiences"></i>
              {renderFormatMsg("header.Tools.ProductCategory", "Product Category")}
            </NavLink>
          </li>
          {CheckMenuPermission("PROMOTION_TYPES") && <li>
            <NavLink to="/promotionType" onClick={closeTools}>
              <i className="icon-ad-groups"></i>
              {renderFormatMsg("header.Tools.promotionType", "Promotion Type")}
            </NavLink>
          </li>}
          {CheckMenuPermission("PROMOTION_TYPES") && <li>
            <NavLink to="/seasonType" onClick={closeTools}>
              <i className="icon-ad-groups"></i>
              {renderFormatMsg("header.Tools.seasonType", "Season Type")}
            </NavLink>
          </li>}
        </ul>
      </Dropdown.Menu>
    </Dropdown>
  )
}

export default React.memo(ToolsAndSettingDetail)
