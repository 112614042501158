import { debounce, get, round } from 'lodash';
import React, { useEffect, useState } from 'react'
import { PAGE_SIZE } from '../../constant';
import { getOutletData } from '../../services/toolsAndSettingsPagesApi';
import { renderFormatMsg } from '../../shared/funs'
import { submitMatrixQuestion, submitNoReplenishmentQuestion } from '../../services/sidebarApi';
import Graph from './Graph';
import moment from 'moment';
import { AppContext } from "../../context/AppContext";
import { useContext } from 'react';
import { FormattedMessage } from 'react-intl';
import { createPopper } from '@popperjs/core';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';
import { Dropdown, Accordion, Card, Button } from 'react-bootstrap';


import SearchIcon from "../../images/search.svg";
const popcorn = document.querySelector('#popcorn');
const tooltip = document.querySelector('#tooltip');


createPopper(popcorn, tooltip);


export default function Why(props) {
  // console.info('props =>', props);

  const [questionData, setQuestionData] = useState([
    {
      questionId: 1,
      loading: false,
      answer1: "",
      answer2: "",
      options1: [],
      options2: [],
      searched1: "",
      searched2: "",
      open: "",
      submitted: false,
      summary: null,
      error: null
    }, {
      questionId: 2,
      loading: false,
      answer1: "",
      answer2: "",
      options1: [],
      options2: [],
      searched1: "",
      searched2: "",
      open: "",
      submitted: false,
      error: null,
      // dummy state till real API is not ready
      // summary: {
      //   // case: "NOT_IN_MATRIX",
      //   "case": "NO_DEMAND",
      //   // "case": "NO_RECEIVING_CAPACITY",
      //   // "case": "NO_SPACE_CATEGORY",
      //   // "case": "NO_MINIMUM_PRICE",
      //   // "case": "IN_ORDER",
      //   status: "Initial replenishment based on demand",
      //   orderProcessing: [{
      //     step: "Demand Calculation",
      //     salesPeriod: 21,
      //     orderQuantity: 0,
      //     targetQuantity: 1,
      //     daysReachForward: 1,
      //     salesProbability: 0.80118585
      //   }, {
      //     step: "Business Rule - Minimum Stock",
      //     salesPeriod: 21,
      //     orderQuantity: 0,
      //     targetQuantity: 5,
      //     daysReachForward: 65,
      //     salesProbability: 0.80118585
      //   }],
      //   demandParameters: {
      //     sales: 1,
      //     storeStock: 5,
      //     maxReplDays: 21,
      //     salesPerDay: 0.07692308,
      //     minimumStock: 5,
      //     nextReplDate: 7,
      //     transitStock: 0,
      //     daysWithStock: 13,
      //     minProbability: 60,
      //     startStockDate: "2021-05-20",
      //     initialReplDays: 21,
      //     demandMultiplier: 1,
      //     relevantSalesPeriod: 14,
      //     rateOfSales: 0.17
      //   },
      //   orderParameters: {
      //     receivingCapacity: 400,
      //     piecesInOrder: 451,
      //     minOrderValue: 7000,
      //     orderValue: 5430
      //   },
      //   categoryParameters: {
      //     name: "Shoes",
      //     outletCapacity: 25,
      //     storeStock: 23,
      //     transitStock: 1,
      //     orderQuantity: 1
      //   }
      // },
    }, {
      questionId: 3,
      loading: false,
      answer1: "",
      answer2: "",
      options1: [],
      options2: [],
      searched1: "",
      searched2: "",
      open: "",
      submitted: false,
      // hard coded summary for test since third question's API is not ready
      summary: {},
      error: null
    }, {
      questionId: 4,
      loading: false,
      answer1: "",
      answer2: "",
      options1: [],
      options2: [],
      searched1: "",
      searched2: "",
      open: "",
      submitted: false,
      // hard coded summary for test since third question's API is not ready
      summary: {},
      error: null
    }
  ])

  const [selectedOutlet, setSelectedOutlet] = useState(null)
  const [selectedSKU, setSelectedSKU] = useState(null)
  const [outlets, setOutlet] = useState([])
  const [SKU, setSKU] = useState([])
  const [error, setError] = useState(false)
  const [openAccordion, setOpenAccordion] = useState(-1)
  const { userDetails } = useContext(AppContext);


  const urlSearchParams = new URLSearchParams(window.location.search);
  const params = Object.fromEntries(urlSearchParams.entries());

  const fetchSKUData = async (param) => {

    try {
      let res = await (new BaseService('/SKUs')).getAll('', param)
      setSKU(res.skUs)
    } catch (error) {
    }
  }


  useEffect(() => {
    let param = { "page": 1, "display": PAGE_SIZE, "search": "" }
    getOutletData(param).then(res => {

      // console.info('res1 =>', res);

      questionData.map(element => {
        element.options2 = res.outlets
        return element
      });
    }).catch(err => {

    })

    fetchSKUData(param);

    getOutletData().then(res => {
      // console.info('res2 =>', res);

      setOutlet(res.outlets)
      props.location.pathname.includes("Add") && setSelectedOutlet(res.outlets[0])
    }).catch(error => console.log('error  => ', error))


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const showSecondAnswerSummary = (summary) => {

    // console.info('sumary =>', summary);


    var report = "N/A"
    if (!summary) {
      return report
    }


    switch (summary.case) {
      case "NOT_IN_MATRIX":
        report = <>
          <div><span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />
            <p>{renderFormatMsg("TheSKUIsNotInTheMatrixForThisStore", "The SKU is not in the matrix for this store.")}</p>
          </div></>
        break;


      case "NO_DEMAND":
        report = <>
          <div><span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />
            <p>{renderFormatMsg("TheStoreHasEnoughStockForTheDemand", "The store has enough stock for the demand")}</p>
            <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6>

            <aside className={`diamond`}><span className="round">1</span><span className="right">
              <FormattedMessage
                id="NO_DEMAND_LINE_1"
                // defaultMessage={`The SKU has sold {sales} in the last {relevantSalesPeriod} days.`}
                values={{
                  sales: `${summary.demandParameters.sales}`,
                  relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
                }}
              />

            </span></aside>
            <aside className={`diamond`}><span className="round">2</span><span className="right">
              <FormattedMessage
                id="NO_DEMAND_LINE_2"
                // defaultMessage={`The store had this SKU at {daysWithStock} days on stock in the last {relevantSalesPeriod} days.`}
                values={{
                  daysWithStock: `${summary.demandParameters.daysWithStock}`,
                  relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
                }}
              />

            </span></aside>
            <aside className={`diamond`}><span className="round">3</span><span className="right">
              <FormattedMessage
                id="NO_DEMAND_LINE_3"
                // defaultMessage={`The rate of sales is {rateOfSales} pieces per day.`}
                values={{
                  rateOfSales: `${summary.demandParameters.rateOfSales}`
                }}
              />

            </span></aside>
            <aside className={`diamond`}><span className="round">4</span><span className="right">
              <FormattedMessage
                id="NO_DEMAND_LINE_4"
                // defaultMessage={`The store has {storeStock} pieces of this SKU on stock and {transitStock} piece in transit.`}
                values={{
                  storeStock: `${summary.demandParameters.storeStock}`,
                  transitStock: `${summary.demandParameters.transitStock}`,
                }}
              />

            </span></aside>
            <aside className={`diamond`}><span className="round">5</span><span className="right">
              <FormattedMessage
                id="NO_DEMAND_LINE_5"
                // defaultMessage={`The probability to sell the current stock (3 pieces) within the next 4 weeks is 65%.`}
                values={{
                  storeStock: `${summary.demandParameters.storeStock}`,
                  relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
                  minProbability: `${summary.demandParameters.minProbability}`,
                }}
              />

            </span></aside>
            <aside className={`diamond`}><span className="round">6</span><span className="right">

              <FormattedMessage
                id="NO_DEMAND_LINE_6"
                // defaultMessage="The probability to sell the current stock plus one additional piece would be {salesProbability}%. This is below the acceptable limit of {minProbability}%. Here the salesProbability is the value of the step “Demand Calculation”"
                values={{
                  salesProbability: `${summary.demandParameters.salesProbability}`,
                  minProbability: `${summary.demandParameters.minProbability}`,
                }}
              />
            </span></aside>
          </div>
        </>
        break;

      case "NO_RECEIVING_CAPACITY":
        report = <>
          <div><span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />
            {renderFormatMsg("Details", "The store receiving capacity was exceeded and this SKU was taken out of the order.")}
            <br /><h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6>
            <aside className={`diamond`}><span className="round">1</span><span className="right">
              <FormattedMessage
                id="NO_RECEIVING_CAPACITY_LINE_1"
                // defaultMessage={`The store has a receiving capacity of {receivingCapacity} pieces per day.`}
                values={{
                  receivingCapacity: `${summary.outletParameters?.receivingCapacity}`,
                }}
              />
            </span></aside>

            <aside className={`diamond`}><span className="round">2</span><span className="right">
              <FormattedMessage
                id="NO_RECEIVING_CAPACITY_LINE_2"
                // defaultMessage={`The proposed order had {piecesInOrder} pieces.`}
                values={{
                  piecesInOrder: `${summary.orderParameters.piecesInOrder}`,
                }}
              />

            </span></aside>

            <aside className={`diamond`}><span className="round">3</span><span className="right">

              <FormattedMessage
                id="NO_RECEIVING_CAPACITY_LINE_3"
                // defaultMessage={`The store has {storeStock} pieces of this SKU on stock and {transitStock} piece in transit.`}
                values={{
                  storeStock: `${summary.demandParameters.storeStock}`,
                  transitStock: `${summary.demandParameters.transitStock}`,
                }}
              />


            </span></aside>

            <aside className={`diamond`}><span className="round">4</span><span className="right">
              <FormattedMessage
                id="NO_RECEIVING_CAPACITY_LINE_4"
                // defaultMessage={`The probability to sell the current stock (3 pieces) within the next 4 weeks is 65%.`}
                values={{
                  storeStock: `${summary.demandParameters.storeStock}`,
                  relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
                  minProbability: `${summary.demandParameters.minProbability}`,
                }}
              />
              {/* {renderFormatMsg("NO_RECEIVING_CAPACITY_LINE_4", "The probability to sell the current stock (3 pieces) within the next 4 weeks is 65%.")} */}
            </span></aside>

            <aside className={`diamond`}><span className="round">5</span><span className="right">
              <FormattedMessage
                id="NO_RECEIVING_CAPACITY_LINE_5"
                // defaultMessage="The probability to sell the current stock plus one additional piece would be {salesProbability}%. This is below the acceptable limit of {minProbability}%. Here the salesProbability is the value of the step “Demand Calculation”"
                values={{
                  salesProbability: `${summary.demandParameters.salesProbability}`,
                  minProbability: `${summary.demandParameters.minProbability}`,
                }}
              />
              {/* {renderFormatMsg("NO_RECEIVING_CAPACITY_LINE_5", "The probability to sell the current stock plus one additional piece would be 45%. This is below the acceptable limit of 60%.")} */}

            </span></aside>
          </div>
        </>
        break;

      case "NO_SPACE_CATEGORY":
        const lastStepOrderQuantity = summary.orderProcessing ? summary.orderProcessing[summary?.orderProcessing?.length - 1].orderQuantity : 0

        report = <>
          <div><span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />
            {renderFormatMsg("The store doesn’t have space for this SKU.", "The store doesn’t have space for this SKU.")}
            <br />
            <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6>
            <aside className={`diamond`}><span className="round">1</span><span className="right">
              <FormattedMessage
                id="NO_SPACE_CATEGORY_LINE_1"
                // defaultMessage={`For the category {name} the store has a capacity of {outletCapacity} pieces.`}
                values={{
                  name: `${summary.categoryParameters.name}`,
                  outletCapacity: `${summary.categoryParameters.outletCapacity}`,
                }}
              />

            </span></aside>
            <aside className={`diamond`}><span className="round">2</span><span className="right">
              <FormattedMessage
                id="NO_SPACE_CATEGORY_LINE_2"
                // defaultMessage={`Currently there are {storeStock} pieces of this category in the store and {transitStock} piece in transit.`}
                values={{
                  storeStock: `${summary.categoryParameters.storeStock}`,
                  transitStock: `${summary.categoryParameters.transitStock}`,
                }}
              />

            </span></aside>
            <aside className={`diamond`}><span className="round">3</span><span className="right">
              {/* {renderFormatMsg("NO_SPACE_CATEGORY_LINE_3", "In the replenishment order is 1 piece.")} */}

              <FormattedMessage
                id="NO_SPACE_CATEGORY_LINE_3"
                // defaultMessage={`Currently there are {storeStock} pieces of this category in the store and {transitStock} piece in transit.`}
                values={{
                  orderQuantity: `${lastStepOrderQuantity}`,
                }}
              />
            </span></aside>
          </div>
        </>
        break;

      case "NO_MINIMUM_PRICE":
        report = <>
          <div><span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />

            {renderFormatMsg("The minimum order price has not been reached", "The minimum order price has not been reached")}
            <br />
            <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6>
            <aside className={`diamond`}><span className="round">1</span><span className="right">
              <FormattedMessage
                id="NO_MINIMUM_PRICE_LINE_1"
                // defaultMessage={`The minimum order price of {minOrderValue} has not been reached for this order.`}
                values={{
                  minOrderValue: `${summary.orderParameters.minOrderValue}`,
                }}
              />
            </span></aside>
            <aside className={`diamond`}><span className="round">2</span><span className="right">
              <FormattedMessage
                id="NO_MINIMUM_PRICE_LINE_2"
                // defaultMessage={`The order price was {orderValue}.`}
                values={{
                  orderValue: `${summary.orderParameters.orderValue}`,
                }}
              />
            </span></aside>
          </div>
        </>
        break;

      case "IN_ORDER":
        report = <>

          <div><span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />
            <aside className={`diamond`}><span className="round">1</span><span className="right">
              {renderFormatMsg("IN_ORDER_LINE_1", "The SKU is part of the order.")}
            </span></aside>
          </div>
        </>
        break;
      case "NO_WAREHOUSE_STOCK":
        report = <>
          <div><span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />
            <p>{renderFormatMsg("NO_WAREHOUSE_STOCK_1", "The SKU is no longer available in the warehouse.")}</p>
          </div></>
        break;
      default:
        break;
    }
    return report
  }

  const showThirdAnswerSummary = (summary) => {
    // console.info('summary =>', summary);

    var report = "N/A"
    var lastStepOrderQuantity;
    if (!summary && !summary.case) {
      return report
    }

    switch (summary.case) {
      case "NOT_IN_MATRIX":
        report = <div className="">

          {/* <p>{renderFormatMsg("TheStoreHasEnoughStockForTheDemand", "The store has enough stock for the demand")}</p> */}
          {/* <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6> */}

          <aside className={`diamond blocked`}><span className="round">1</span><span className="right">
            {renderFormatMsg("NOT_IN_MATRIX_3_QUESTION_LINE_1", "The SKU is not in the replenishment matrix for the store")}
          </span></aside>

          <aside className={`diamond`}><span className="round">2</span><span className="right">
            {renderFormatMsg("MATRIX_3_QUESTION_LINE_2", "The stores is in the replenishment calendar of the day")}
          </span></aside>

          <div className="">
            <aside className="diamond">
              <div>
                <span className="round" style={{ marginTop: "-3px" }}>3</span><span className="right">
                  {renderFormatMsg("NoDataAvailableForGraphics", "No data available for graphics")}
                </span>
              </div>
            </aside>
            {/* <div className=""><Graph /></div> */}
          </div>
        </div>
        break;

      case "IN_ORDER":
        lastStepOrderQuantity = summary.orderProcessing ? summary.orderProcessing[summary?.orderProcessing?.length - 1].orderQuantity : 0
        report = <div className="">

          <aside className={`diamond`}><span className="round">1</span><span className="right">
            {renderFormatMsg("IN_MATRIX_3_QUESTION_LINE_1", "The SKU is in the replenishment matrix of the store.")}
          </span></aside>

          <aside className={`diamond`}><span className="round">2</span><span className="right">
            {renderFormatMsg("MATRIX_3_QUESTION_LINE_2", "The store is in the replenishment calendar of the day.")}
          </span></aside>

          <div className="section-with-graph">
            <aside className="diamond">
              <div>
                <span className="round" style={{ marginTop: "-3px" }}>3</span><span className="right">
                  <FormattedMessage
                    id="AnOrderOfNHasBeenGenerated"
                    defaultMessage={`An order of {pieces} has been generated`}
                    values={{
                      // pieces: get(summary, "orderParameters.piecesInOrder", "N/A")
                      pieces: `${lastStepOrderQuantity}`
                    }}
                  />
                </span>
              </div>
            </aside>
            <div className="">
              {summary.orderProcessing && summary.orderProcessing.length && <Graph data={summary} />}
            </div>
          </div>

          {
            summary && summary.analogueSKUs && summary.analogueSKUs.length >= 1 &&
            <aside className={`diamond`}>
              <span className="round">4</span>
              <span className="right">
                {summary.analogueSKUs.map(op => {
                  // console.info('op =>', op);

                  return <FormattedMessage
                    id="MATRIX_3_QUESTION_LINE_4"
                    defaultMessage={`Due to low warehouse stock {orderQuantity} pieces of the analogue product {name} have been replenished.`}
                    values={{
                      orderQuantity: `${op.orderQuantity}`,
                      name: `${op.name}`,
                    }}
                  />
                })}
              </span>
            </aside>
          }

        </div>
        break;

      default:
        lastStepOrderQuantity = summary.orderProcessing ? summary.orderProcessing[summary?.orderProcessing?.length - 1].orderQuantity : 0

        report = <div className="">
          {/* <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6> */}
          {/* <p>{renderFormatMsg("TheStoreHasEnoughStockForTheDemand", "The store has enough stock for the demand")}</p> */}

          <aside className={`diamond`}><span className="round">1</span><span className="right">
            {renderFormatMsg("IN_MATRIX_3_QUESTION_LINE_1", "The SKU is in the replenishment matrix of the store")}

          </span></aside>

          <aside className={`diamond`}><span className="round">2</span><span className="right">
            {renderFormatMsg("MATRIX_3_QUESTION_LINE_2", "The stores is in the replenishment calendar of the day")}
          </span></aside>

          <div className="section-with-graph">
            <aside className="diamond">
              <div>
                <span className="round" style={{ marginTop: "-3px" }}>3</span><span className="right">
                  <FormattedMessage
                    id="AnOrderOfNHasBeenGenerated"
                    defaultMessage={`An order of {pieces} has been generated`}
                    values={{
                      // pieces: get(summary, "orderParameters.piecesInOrder", "N/A")
                      pieces: `${lastStepOrderQuantity}`
                    }}
                  />
                </span>
              </div>
            </aside>

            <div className="">
              {summary.orderProcessing && summary.orderProcessing.length && <Graph data={summary} />}
            </div>
          </div>

          {
            summary && summary.analogueSKUs && summary.analogueSKUs.length >= 1 &&
            <aside className={`diamond`}>
              <span className="round">4</span>
              <span className="right">
                {summary.analogueSKUs.map(op => {
                  // console.info('op =>', op);

                  return <FormattedMessage
                    id="MATRIX_3_QUESTION_LINE_4"
                    defaultMessage={`Due to low warehouse stock {orderQuantity} pieces of the analogue product {name} have been replenished.`}
                    values={{
                      orderQuantity: `${op.orderQuantity}`,
                      name: `${op.name}`,
                    }}
                  />
                })}
              </span>
            </aside>
          }
        </div>
        break;
    }
    return report
  }

  const showFourthAnswerSummary = (summary) => {

    var report = "N/A"
    var lastStepOrderQuantity;
    if (!summary && !summary.case) {
      return report
    }

    switch (summary.case) {

      case "NOT_IN_MATRIX":
        report = <div className="">

          {/* <p>{renderFormatMsg("TheStoreHasEnoughStockForTheDemand", "The store has enough stock for the demand")}</p> */}
          {/* <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6> */}

          <aside className={`diamond blocked`}><span className="round">1</span><span className="right">
            {renderFormatMsg("SKUIsNotInReplenishment", "SKU is not in Replenishment")}
          </span></aside>

          {/* <aside className={`diamond blocked`}><span className="round">1</span><span className="right">
            {renderFormatMsg("NOT_IN_MATRIX_3_QUESTION_LINE_1", "The SKU is not in the replenishment matrix for the store")}
          </span></aside> */}

          {/* <aside className={`diamond`}><span className="round">2</span><span className="right">
            {renderFormatMsg("MATRIX_3_QUESTION_LINE_2", "The stores is in the replenishment calendar of the day")}
          </span></aside> */}

          {/* <div className="">
            <aside className="diamond">
              <div>
                <span className="round" style={{ marginTop: "-3px" }}>3</span><span className="right">
                  {renderFormatMsg("NoDataAvailableForGraphics", "No data available for graphics")}
                </span>
              </div>
            </aside>
          </div> */}

        </div>
        break;

      // case "NO_STORE_SKU_CAPACITY":
      //   lastStepOrderQuantity = summary.orderProcessing ? summary.orderProcessing[summary?.orderProcessing?.length - 1].orderQuantity : 0
      //   report = <div className="">

      //     <aside className={`diamond`}>
      //       <span className="round">1</span>
      //       <span className="right">
      //         <FormattedMessage
      //           id="TheSKUWasOnStockForDaysInTheLastRSPDays"
      //           // defaultMessage={`The SKU was on stock for {daysWithStock} days in the last {relevantSalesPeriod} days.`}
      //           values={{
      //             daysWithStock: `${summary.demandParameters.daysWithStock}`,
      //             relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
      //           }}
      //         />

      //       </span>
      //     </aside>

      //     <aside className={`diamond`}>
      //       <span className="round">2</span>
      //       <span className="right">

      //         <FormattedMessage
      //           id="TheSalesWasSalesPiecesInLastRSPDays"
      //           // defaultMessage={`The sales was {sales} piece(s) in the last {relevantSalesPeriod} days.`}
      //           values={{
      //             sales: `${summary.demandParameters.sales}`,
      //             relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
      //           }}
      //         />

      //       </span>
      //     </aside>

      //     <aside className={`diamond`}>
      //       <span className="round">3</span>
      //       <span className="right">

      //         {summary.demandParameters.promotion ?
      //           <FormattedMessage
      //             id="ThePromotionIsAddingToDemand"
      //             // defaultMessage={`The promotion {promotion} is adding {(demandMultiplier - 1)*100}% to the demand.`}
      //             values={{
      //               promotion: `${summary.demandParameters.promotion}`,
      //               demandMultiplier: `${(summary.demandParameters.demandMultiplier - 1) * 100}`,
      //             }}
      //           />
      //           :
      //           <FormattedMessage
      //             id="NoPromotionActive"
      //             defaultMessage={`There is no promotion active.`}
      //           />
      //         }
      //       </span>
      //     </aside>


      //     <aside className={`diamond`}>
      //       <span className="round">4</span>
      //       <span className="right">
      //         {summary.orderProcessing.map(op => {
      //           if (op.step === 'Demand Calculation') {
      //             return <FormattedMessage
      //               id="WeHaveDemandOfPiecesWithSalesProbability"
      //               values={{
      //                 targetQuantity: `${op.targetQuantity}`,
      //                 salesProbability: `${round(op.salesProbability * 100).toFixed(1)}`,
      //                 salesPeriod: `${op.salesPeriod}`,
      //               }}
      //             />
      //           }
      //         })}

      //       </span>
      //     </aside>
      //   </div>
      //   break;

      default:

        lastStepOrderQuantity = summary.orderProcessing ? summary.orderProcessing[summary?.orderProcessing?.length - 1].orderQuantity : 0
        report = <div className="">

          <aside className={`diamond`}>
            <span className="round">1</span>
            <span className="right">
              <FormattedMessage
                id="TheSKUWasOnStockForDaysInTheLastRSPDays"
                // defaultMessage={`The SKU was on stock for {daysWithStock} days in the last {relevantSalesPeriod} days.`}
                values={{
                  daysWithStock: `${summary.demandParameters.daysWithStock}`,
                  relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
                }}
              />

            </span>
          </aside>

          <aside className={`diamond`}>
            <span className="round">2</span>
            <span className="right">

              <FormattedMessage
                id="TheSalesWasSalesPiecesInLastRSPDays"
                // defaultMessage={`The sales was {sales} piece(s) in the last {relevantSalesPeriod} days.`}
                values={{
                  sales: `${summary.demandParameters.sales}`,
                  relevantSalesPeriod: `${summary.demandParameters.relevantSalesPeriod}`,
                }}
              />

            </span>
          </aside>

          <aside className={`diamond`}>
            <span className="round">3</span>
            <span className="right">

              {summary.demandParameters.promotion ?
                <FormattedMessage
                  id="ThePromotionIsAddingToDemand"
                  // defaultMessage={`The promotion {promotion} is adding {(demandMultiplier - 1)*100}% to the demand.`}
                  values={{
                    promotion: `${summary.demandParameters.promotion}`,
                    demandMultiplier: `${(summary.demandParameters.demandMultiplier - 1) * 100}`,
                  }}
                />
                :
                <FormattedMessage
                  id="NoPromotionActive"
                  defaultMessage={`There is no promotion active.`}
                />
              }
            </span>
          </aside>


          <aside className={`diamond`}>
            <span className="round">4</span>
            <span className="right">
              {summary.orderProcessing.map(op => {
                if (op.step === 'Demand Calculation') {
                  return <FormattedMessage
                    id="WeHaveDemandOfPiecesWithSalesProbability"
                    values={{
                      targetQuantity: `${op.targetQuantity}`,
                      salesProbability: `${round(op.salesProbability * 100).toFixed(1)}`,
                      salesPeriod: `${op.salesPeriod}`,
                    }}
                  />
                }
              })}

            </span>
          </aside>
        </div>
        break;
    }
    return report
  }


  const showLoaderContent = () => {
    return <>
      <h6 style={{ height: 30, width: 220 }} className={`shine d-flex`}>&nbsp;</h6>
      <h6 style={{ height: 30, width: 450 }} className={`shine d-flex`}>&nbsp;</h6>
      <aside className={`diamond shine`}></aside>
      <aside className={`diamond shine`}></aside>
      <aside className={`diamond shine`}></aside>
    </>
  }


  const getQuestionAnswer = (index) => {
    if (selectedOutlet && selectedSKU) {
      setOpenAccordion(openAccordion === index ? -1 : index)
      if (openAccordion !== index) {
        setError("")
        var _questionData = [...questionData];

        _questionData[index].loading = true
        // setQuestionData(_questionData)

        var urlStr = window.location.search;
        var url = new URLSearchParams(urlStr);
        var date = url.get("date");


        var skuId = get(selectedSKU, "skuId")
        var outletId = get(selectedOutlet, "outletId")


        // var skuId = "24aa3090-f16c-46df-8b6e-e43ea3e06c90"
        // var outletId = "9ecbcdd5-12c3-4045-aece-13e7f040b053"


        switch (index) {
          case 0:
            submitMatrixQuestion({
              skuId,
              outletId,
            }).then(response => {
              _questionData[index].loading = false
              _questionData[index].error = false
              _questionData[index].summary = response
              _questionData[index].submitted = true

              setQuestionData(_questionData)
            }).catch(err => {
              _questionData[index].loading = false
              _questionData[index].error = true
              _questionData[index].summary = null
              _questionData[index].submitted = true
              setQuestionData(_questionData)
            })
            break;
          case 1:
            submitNoReplenishmentQuestion({
              skuId,
              outletId,
              date
            }).then(response => {
              _questionData[index].loading = false
              _questionData[index].error = false
              _questionData[index].summary = response
              _questionData[index].submitted = true
              setQuestionData(_questionData)
            }).catch(err => {
              _questionData[index].loading = false
              _questionData[index].error = true
              _questionData[index].summary = null
              _questionData[index].submitted = true
              setQuestionData(_questionData)
            })
            break;
          case 2:
            submitNoReplenishmentQuestion({
              skuId,
              outletId,
              date
            }).then(response => {
              _questionData[index].loading = false
              _questionData[index].error = false
              _questionData[index].summary = response
              _questionData[index].submitted = true
              setQuestionData(_questionData)
            }).catch(err => {
              _questionData[index].loading = false
              _questionData[index].error = true
              _questionData[index].summary = null
              _questionData[index].submitted = true
              setQuestionData(_questionData)
            })
            break;
          case 3:
            submitNoReplenishmentQuestion({
              skuId,
              outletId,
              date
            }).then(response => {
              // console.info('response =>', response);

              _questionData[index].loading = false
              _questionData[index].error = false
              _questionData[index].summary = response
              _questionData[index].submitted = true
              setQuestionData(_questionData)
            }).catch(err => {
              _questionData[index].loading = false
              _questionData[index].error = true
              _questionData[index].summary = null
              _questionData[index].submitted = true
              setQuestionData(_questionData)
            })
            break;

          default:
            break;
        }
      }
    } else {
      setError("Please select SKU and Outlet")
    }

  }

  useEffect(() => {
    if (selectedOutlet && selectedSKU) {
      setError("")
    }
  }, [selectedOutlet, selectedSKU])

  const handleData = (data, key) => {
    setOpenAccordion(-1)
    key === "sku" ? setSelectedSKU(data) : setSelectedOutlet(data)
  }

  const sendOutletQuery = (q) => {
    let param = { "page": 1, "display": PAGE_SIZE, "search": q }
    getOutletData(param).then(res => {
      setOutlet(res.outlets)
    }).catch(err => {
      setOutlet([])
    })
  };

  const sendSKUQuery = async (q) => {
    let param = { "page": 1, "display": PAGE_SIZE, "search": q }
    try {
      let data = await (new BaseService('/SKUs')).getAll('', param)
      setSKU(data.skUs)
    } catch (error) {
      setSKU([])
    }
  };

  const delayedOutletQuery = debounce((q) => sendOutletQuery(q), 500);

  const delayedSKUQuery = debounce((q) => sendSKUQuery(q), 500);

  const searchStoreHandler = (value) => {
    delayedOutletQuery(value);
  }

  const searchSKUHandler = (value) => {
    delayedSKUQuery(value);
  }

  const filterData = (searchVal, type) => {
    if (type === "sku") {
      searchSKUHandler(searchVal)
    } else {
      searchStoreHandler(searchVal)
    }
  }

  return (
    <div>
      <div className="main-content">
        <PageHeading headingId="Why" />


        <div className="card p-3 why-card">
          <div className="card-body">
            <div className="row">
              <div className="col-12">
                <div className="order-block">
                  <div className="col-1">
                    <p className="mb-0">{renderFormatMsg("SKU", "SKU")}</p>
                  </div>
                  <div className="col-6">
                    <Dropdown alignRight className="custom-dropdown">
                      <Dropdown.Toggle variant="Secondary" className={`outletDropdown w-100 form-control scrollableDropdown`}>{selectedSKU !== null ? get(selectedSKU, "clientSKUId", "SKU") : "Select SKU"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ paddingTop: 0, minWidth: "305px" }}>
                        {/* <form className="search-boxs undefined w-100" autoComplete={"off"}> */}
                        <input placeholder="Search SKU" name="SKU" type="text" className="form-control mb-2" autoFocus onChange={(e) => filterData(e.target.value, "sku")} />
                        {/* <button type="button" className="btn btn-light search_button">
                            <img src={SearchIcon} alt="SearchIcon" />
                          </button> */}
                        {/* </form> */}
                        {SKU && SKU.map((data, i) =>
                          <Dropdown.Item key={i}
                            // title={data.option.optionName}
                            className={`d-flex flex-column ${get(selectedSKU, "skuId")} - ${get(selectedSKU, "clientSKUId") == data.clientSKUId ? `active` : data.clientSKUId}`}
                            onClick={() => handleData(data, "sku")}>
                            <span className="_3kCWm">{data.clientSKUId} </span>
                            <span className={`font-weight-lighter text-secondary text-overflow ${get(selectedSKU, "clientSKUId") == data.clientSKUId ? `text-reset` : ``}`} style={{ fontSize: 13 }}> {data.option.optionName}</span></Dropdown.Item>)}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2">
                <div className="order-block">
                  <div className="col-1">
                    <p className="mb-0">{renderFormatMsg("Outlet", "Outlet")}</p>
                  </div>
                  <div className="col-6">
                    <Dropdown alignRight className="custom-dropdown">
                      <Dropdown.Toggle variant="Secondary" className={`outletDropdown w-100 form-control scrollableDropdown`}>{selectedOutlet !== null ? `${get(selectedOutlet, "outletName", "Store")}` : "Select Store"}
                      </Dropdown.Toggle>
                      <Dropdown.Menu style={{ paddingTop: 0, minWidth: "305px" }}>
                        {/* <form className="search-boxs undefined w-100" autoComplete={"off"}> */}
                        <input placeholder="Search Store" name="outletName" type="text" className="form-control mb-2" autoFocus onChange={(e) => filterData(e.target.value, "outlet")} />
                        {/* <button type="button" className="btn btn-light search_button">
                            <img src={SearchIcon} alt="SearchIcon" />
                          </button> */}
                        {/* </form> */}
                        {outlets && outlets.map((data, i) =>
                          <Dropdown.Item key={i}
                            className={`${get(selectedOutlet, "outletName") === data.outletName ? `active` : ``}`}
                            onClick={() => handleData(data, "outlet")}>{data.outletName}</Dropdown.Item>)}
                      </Dropdown.Menu>
                    </Dropdown>
                  </div>
                </div>
              </div>
              <div className="col-12 mt-2"><div className="col-6 text-danger">{error}</div></div>
            </div>
            <div className="row"><div className="col-sm-12 pl-0 pr-0"><hr /></div></div>

            <Accordion>
              <Card className="mb-0 accordion-card">
                <Card.Header>
                  <Accordion.Toggle onClick={(e) => { getQuestionAnswer(0) }}>
                    {renderFormatMsg("WhyIsTheSKUNotInTheMatrixForStore", "Why is the SKU  not in the matrix for Store ?")}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse className={openAccordion === 0 && "show"}>
                  <Card.Body>
                    {<div className={`answer-wrapper mt-4 q-row ${questionData[0].submitted && 'active'}`}>
                      {
                        questionData[0].loading ? showLoaderContent() :
                          !questionData[0].error ?
                            <div>
                              <h6>{renderFormatMsg("Summary", "Summary")}</h6>
                              <p>{questionData[0].summary && questionData[0].summary.blocking ?
                                <FormattedMessage
                                  id="question1Status"
                                  defaultMessage={`The SKU is not in the matrix because {status}`}
                                  values={{
                                    status: questionData[0].summary.status
                                  }}
                                />
                                :
                                <FormattedMessage
                                  id="question1StatusBlocked"
                                  defaultMessage={`The SKU is included in the matrix`}
                                  values={{
                                    status: questionData[0].summary && questionData[0].summary.status
                                  }}
                                />
                              }</p>

                              <h6>{renderFormatMsg("Details", "Details")}</h6>
                              {questionData[0].summary && questionData[0].summary.outlet &&
                                <aside className={`diamond ${questionData[0].summary.outlet.blocking && `blocked`}`}>
                                  <span className={`round ${questionData[0].summary.outlet.blocking && `blocked`}`}>1</span><span className="right">
                                    {
                                      questionData[0].summary.outlet.blocking ? <FormattedMessage
                                        id="outletIsCurrentlyNotOpen"
                                        defaultMessage={`The outlet is currently not open.`}
                                      /> : <FormattedMessage
                                        id="outletIsCurrentlyOpen"
                                        defaultMessage={`The outlet is currently open.`}
                                      />
                                    }
                                  </span>
                                </aside>}


                              {/* {console.info('questionData[0] >', questionData[0])
                              } */}

                              {/* {console.info('questionData[0] >', userDetails)} */}

                              {questionData[0].summary && questionData[0].summary.skuLifecycle &&
                                <aside className={`diamond ${questionData[0].summary.skuLifecycle.blocking && `blocked`}`}>
                                  <span className={`round ${questionData[0].summary.skuLifecycle.blocking && `blocked`}`}>2</span><span className="right">

                                    <FormattedMessage
                                      id="question1SkuLifecycle"
                                      defaultMessage={`The SKU has a lifecycle from {start} to {end}.`}
                                      values={{
                                        start: moment(questionData[0].summary.skuLifecycle.start).format(userDetails?.shortDateFormat),
                                        end: moment(questionData[0].summary.skuLifecycle.end).format(userDetails?.shortDateFormat)
                                      }}
                                    />
                                    { }

                                  </span>
                                </aside>}
                              {questionData[0].summary && questionData[0].summary.optionLifecycle &&
                                <aside className={`diamond ${questionData[0].summary.optionLifecycle.blocking && `blocked`}`}>
                                  <span className={`round ${questionData[0].summary.optionLifecycle.blocking && `blocked`}`}>3</span><span className="right">

                                    <FormattedMessage
                                      id="question1OptionLifecycle"
                                      defaultMessage={`The Option {name} has a lifecycle from {start} to {end}.`}
                                      values={{
                                        name: questionData[0].summary.optionLifecycle.name,
                                        start: moment(questionData[0].summary.skuLifecycle.start).format(userDetails?.shortDateFormat),
                                        end: moment(questionData[0].summary.skuLifecycle.end).format(userDetails?.shortDateFormat)
                                      }}
                                    />

                                  </span>
                                </aside>}
                              {questionData[0].summary && questionData[0].summary.seasonLifecycle &&
                                <aside className={`diamond ${questionData[0].summary.seasonLifecycle.blocking && `blocked`}`}>
                                  <span className={`round ${questionData[0].summary.seasonLifecycle.blocking && `blocked`}`}>4</span><span className="right">

                                    <FormattedMessage
                                      id="question1SeasonLifecycle"
                                      defaultMessage={`The Season {name} has a lifecycle from {start} to {end}.`}
                                      values={{
                                        name: questionData[0].summary.seasonLifecycle.name,
                                        start: moment(questionData[0].summary.seasonLifecycle.start).format(userDetails?.shortDateFormat),
                                        end: moment(questionData[0].summary.seasonLifecycle.end).format(userDetails?.shortDateFormat)
                                      }}
                                    />

                                  </span>
                                </aside>}
                              {questionData[0].summary && questionData[0].summary.availability &&
                                <aside className={`diamond ${questionData[0].summary.availability.blocking && `blocked`}`}>
                                  <span className={`round ${questionData[0].summary.availability.blocking && `blocked`}`}>5</span><span className="right">
                                    {

                                      questionData[0].summary.availability.blocking ? <FormattedMessage
                                        id="question1NotAvailability"
                                        // defaultMessage={`During the time from {start} to {end} the SKU had no availability in the store.`}
                                        values={{
                                          start: moment(questionData[0].summary.availability.start).format(userDetails?.shortDateFormat),
                                          end: moment(questionData[0].summary.availability.end).format(userDetails?.shortDateFormat)
                                        }}
                                      />
                                        : <FormattedMessage
                                          id="question1Availability"
                                          // defaultMessage={`During the time from {start} to {end} the SKU had availability in the store.`}
                                          values={{
                                            start: moment(questionData[0].summary.availability.start).format(userDetails?.shortDateFormat),
                                            end: moment(questionData[0].summary.availability.end).format(userDetails?.shortDateFormat)
                                          }}
                                        />}

                                  </span>
                                </aside>}
                            </div> :
                            <div>
                              <p> {renderFormatMsg("NoRecordFound", "No Record Found")} </p>
                            </div>
                      }
                    </div>}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="mb-0 accordion-card">
                <Card.Header>
                  <Accordion.Toggle eventKey="1" onClick={() => getQuestionAnswer(1)}>
                    {renderFormatMsg("WhyWasThereNoReplenishmentOfSKUToStore", "Why was there no replenishment of SKU to Store ?")}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse className={openAccordion === 1 && "show"}>
                  <Card.Body>
                    {<div className={`second-question answer-wrapper mt-4 q-row ${questionData[1].submitted && 'active'}`}>
                      <div>
                        <p>{
                          questionData[1].loading ? showLoaderContent() :
                            !questionData[1].error && questionData[1].summary && Object.keys(questionData[1].summary).length ?
                              showSecondAnswerSummary(questionData[1].summary) :
                              "No data found"}</p>
                      </div>
                      {/* <div><Graph /></div> */}
                    </div>}
                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              <Card className="mb-0 accordion-card">
                <Card.Header>
                  <Accordion.Toggle eventKey="2" onClick={() => getQuestionAnswer(2)}>
                    {renderFormatMsg("WhyDidTheSystemReplenishACertainQuantityOfSKUToStore", "Why did the system replenish a certain quantity of SKU to Store ?")}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse className={openAccordion === 2 && "show"}>
                  <Card.Body>
                    {
                      <div className={`last-question mt-4 q-row ${questionData[2].submitted && 'active'}`}>
                        {!questionData[2].error && questionData[2].summary && Object.keys(questionData[2].summary).length ? <div className="">
                          <span className="summary-text">{renderFormatMsg("Summary", "Summary")}</span><br />
                          <p>
                            {questionData[2].summary.case === "IN_ORDER" ?
                              renderFormatMsg("IN_ORDER_1_QUESTION_LINE_1", "The SKU has been included into the replenishment order to the store") :
                              questionData[2].summary.case === "NOT_IN_MATRIX" ? renderFormatMsg("NOT_IN_MATRIX_SUMMARY_3", "The SKU is not in the matrix for this store.") :
                                renderFormatMsg("DEFAULT_SUMMARY_3", "The order has an unknown status.")
                            }
                          </p>
                          <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6>
                          {
                            questionData[2].loading ? showLoaderContent() :
                              showThirdAnswerSummary(questionData[2].summary)
                          }
                        </div> : <p>No data found</p>}
                      </div>
                    }
                  </Card.Body>
                </Accordion.Collapse>
              </Card>

              {/* {window.location.search === '?test=1' && */}
              {/* {props.location.search === '?test=1' && */}
              {/* {params.test === '1' && */}

              <Card className="mb-0 accordion-card">
                <Card.Header>
                  <Accordion.Toggle eventKey="3" onClick={() => getQuestionAnswer(3)}>
                    {renderFormatMsg("ExplainTheDemandForThisSKUForThisStore", "Explain the demand for this SKU for this store")}
                  </Accordion.Toggle>
                </Card.Header>
                <Accordion.Collapse className={openAccordion === 3 && "show"}>
                  <Card.Body>
                    {

                      <div className={`last-question mt-4 q-row ${questionData[3].submitted && 'active'}`}>
                        {!questionData[3].error && questionData[3].summary && Object.keys(questionData[3].summary).length ?
                          <div className="">

                            <h6 className="mt-3">{renderFormatMsg("Details", "Details")}</h6>
                            {
                              questionData[3].loading
                                ? showLoaderContent()
                                : showFourthAnswerSummary(questionData[3].summary)
                            }
                          </div> : <p>No data found</p>}
                      </div>
                    }

                  </Card.Body>
                </Accordion.Collapse>
              </Card>
              {/* } */}
            </Accordion>


          </div>
        </div>
      </div>
    </div >
  )
}
