import React, { useState, useEffect } from 'react'
import { getWarehouseData, updateWarehouseData, AddNewWarehouse } from '../../../services/toolsAndSettingsPagesApi';
import moment from 'moment';
// import BackIcon from "../../../images/back-icon.svg";
import { renderFormatMsg, toastError, toastSuccess } from "../../../shared/funs";
import { DATE_FORMAT } from '../../../constant';
// import { Link } from 'react-router-dom';
import Error from '../../../components/Error/Error';
import {
  // Field,
  Form
} from 'react-final-form';
import ActiveFromAndToDate from '../../../components/ActiveFromAndToDate/ActiveFromAndToDate';
// import FieldErrorMessage from '../../../components/FieldErrorMessage';
import InputField from '../../../components/Fields/InputField';
import PageHeading from '../../../components/PageHeading/PageHeading';


const HandleEditWarehouses = (props) => {
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false)
  const [warehouse, setWarehouse] = useState(null)
  const [error, setError] = useState(false)

  let heading = "AddWarehouse"
  var values = {
    name: "",
    activeFrom: "",
    activeTo: "",
  }
  if (props.location.pathname.includes("Edit")) {
    heading = "EditWarehouse"
    values = {
      name: warehouse && warehouse.name,
      activeFrom: warehouse && warehouse.activeFrom,
      activeTo: warehouse && warehouse.activeTo
    }
  }
  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      let param = { "page": 1, "display": 1, "search": props.match.params.warehouseId }
      getWarehouseData(param).then(res => {
        var data = res.warehouses.find(data => data.warehouseId === props.match.params.warehouseId)
        if (data) {
          setWarehouse(data)
        } else {
          setError("Invalid warehouse Id")
        }
      }).catch(err => {
        setError(err)
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const validation = (data) => {
    let errors = {}

    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (!data.activeFrom || data.activeFrom === "") {
      errors.activeFrom = "Required";
    }
    if (!data.activeTo || data.activeTo === "") {
      errors.activeTo = "Required";
    }
    return errors
  }

  const handleForm = async (data) => {
    let object = {
      "name": data.name,
      "payload": { email: "" },
      "activeFrom": moment(data.activeFrom).format(DATE_FORMAT),
      "activeTo": moment(data.activeTo).format(DATE_FORMAT)
    }

    if (props.location.pathname.includes("Add")) {
      await AddNewWarehouse(object).then(resp => {
        toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        props.history.push("/warehouses")
      }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
    } else {
      object.warehouseId = props.match.params.warehouseId
      await updateWarehouseData(object).then(resp => {
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        props.history.push("/warehouses")
      }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
    }
  }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <Link className="back-btn mr-1" to="/warehouses"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/warehouses" headingId={heading} isLoading={isLoading} />
      <div className="card p-3">
        {!error ? <Form onSubmit={handleForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="name">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("WarehouseName", "Warehouse Name")} </label>
                            <input {...input} type="text" placeholder="Warehouse Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <InputField
                      colClass="col-lg-6"
                      name={"name"}
                      type="text"
                      id="WarehouseName"
                      defaultLabel="Warehouse Name"
                      placeholder={"Warehouse Name"}
                    />
                    <div className="col-lg-6"></div>
                  </div>

                  <ActiveFromAndToDate />
                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                      ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form> : <Error msg={error} />
        }
      </div>
    </div >
  )
}
export default React.memo(HandleEditWarehouses)
