
import React, { useState } from 'react'
import { renderFormatMsg, toastSuccess } from '../../shared/funs';
import ReactTable from '../ReactTable/ReactTable';
import Error from '../Error/Error';
import { getCity } from '../../services/toolsAndSettingsPagesApi';

const StoreCities = ({ values, form }) => {
	const [cities, setCities] = useState([])
	// eslint-disable-next-line
	const [city, setCity] = useState(values.City ? values.City : [])
	const [error, setError] = useState(false)

	const handleChecked = (name, data) => {
		city.findIndex(d => d.cityId === data.cityId) >= 0 ? city.splice(city.findIndex(item =>
			item.cityId === data.cityId), 1) : city.push({ [name]: data.cityId })
		form.change("city", [...new Set([...city])]);
	}
	const columns = [
		{
			Header: <div className="text-left"> {renderFormatMsg("CityName", "City Name")} </div>,
			accessor: 'name',
			minWidth: 200,
			style: { justifyContent: "flex-start" }
		},
		{
			Header: renderFormatMsg("Include", "Include"),
			//resizable: false, width: 130,
			Cell: (props) => <div className="checkbox">
				<input type="checkbox" name="cityId"
					checked={city.findIndex(d => d.cityId === props.original.cityId) >= 0 ? true : false}
					onChange={(e) => handleChecked(e.target.name, props.original)}
				/>
				<label for="checkbox"></label>
			</div>
		}
	]

	const fetchData = (state, instance) => {
		//getting change page number from state
		let param = { "country": values.country, "fromDefault": true }
		getCity(param).then(res => {
			setCities(res.length > 0 ? res : [])
			res.length <= 0 && toastSuccess("No city found for selected country !")
		}).catch(err => { setError(err) })
	}
	return (
		<div className="card">
			{error ? <Error /> : <ReactTable data={cities} columns={columns} onFetchData={fetchData} />}
		</div>
	)
}

export default React.memo(StoreCities)
