import React, { useState } from 'react'
import { Field } from 'react-final-form';
import { renderFormatMsg } from '../../shared/funs';
import ReactTable from '../ReactTable/ReactTable';
import Modal from "../Modal/index"
import CustomButton from "../Buttons/CustomButton"
import FieldErrorMessage from '../FieldErrorMessage';

const Warehouse = ({ values, form }) => {
  // eslint-disable-next-line
  const [warehouse, setWarehouse] = useState(values.warehouse ? values.warehouse : [])
  const [openModal, setOpenModal] = useState(false)

  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("WarehouseName", "Warehouse Name")} </div>,
      accessor: 'name',
      style: { justifyContent: "flex-start" }
    }
  ]

  const handleBrand = (input) => {
    warehouse.push({ [input.name]: input.value })
    setOpenModal(!openModal)
    input.onChange("")
    form.change("warehouse", warehouse)
  }
  const addButtonHandler = () => setOpenModal(!openModal)
  return (
    <div className="card">
      <div className="card-header">
        <CustomButton onClick={addButtonHandler} id="AddWarehouse" defaultText="Add Warehouse" />
      </div>
      <ReactTable data={warehouse} columns={columns} showPagination={false} />
      <Modal openModal={openModal} setOpenModal={setOpenModal} heading={renderFormatMsg("AddNewWarehouse", "Add New Warehouse")} classes="">
        <div className="col-12">
          <Field name="name">
            {({ input, meta }) => (
              <div className="form-group">
                <label> {renderFormatMsg("WarehouseName", "Warehouse Name")} </label>
                <input {...input} type="text" placeholder="Warehouse Name" className="form-control" onChange={(e) => input.onChange(e)} />
                <FieldErrorMessage meta={meta} />
                <div className="btn_pop p-0">
                  <button className="btn_white" onClick={() => setOpenModal(!openModal)}>{renderFormatMsg("Cancel", "Cancel")}</button>
                  <button className="btn_grey" onClick={() => handleBrand(input)}> {renderFormatMsg("AddWarehouse", "Add Warehouse")}</button>
                </div>
              </div>
            )}
          </Field>
        </div>
      </Modal>
    </div>
  )
}
export default React.memo(Warehouse)
