import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import { renderFormatMsg, toastSuccess, toastError, permissionErrorCheck, checkPermission, findPermission } from '../../shared/funs';
import Error from "../../components/Error/Error";
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import {
    // getSeasonType,
    deletePromotionType
} from '../../services/toolsAndSettingsPagesApi';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const SeasonType = () => {
    const [seasonType, setSeasonType] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [deletedId,] = useState(null)
    const [show, setShow] = useState(false);
    const { userDetails } = useContext(AppContext)
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
    let dataArray = get(userDetails, "accesses")

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("PROMOTION_TYPES").screenId))
    }, [dataArray])

    const handleClose = () => setShow(!show);

    const getData = async ({ page = 0, search = "" }) => {
        setSearchValue(search)
        setLoading(true)
        try {
            let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
            let res = await (new BaseService('/SeasonTypes')).getAll('', param)
            setSeasonType(res.seasonTypes.length > 0 ? res.seasonTypes : [])
            setTotalPage(res.totalPage)
            setLoading(false)
        } catch (error) {
            setLoading(false)
            setError(permissionErrorCheck(error))
        }
        // let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
        // getSeasonType(param).then(res => {
        //     setLoading(false)
        //     setSeasonType(res.seasonTypes.length > 0 ? res.seasonTypes : [])
        //     setTotalPage(res.totalPage)
        // }).catch(err => {
        //     setError(permissionErrorCheck(err))
        //     setLoading(false)
        //     setSeasonType([])
        //     // setError(err)
        // })
    }
    const handleDelete = async () => {
        await deletePromotionType("seasonTypeId", deletedId).then(async res => {
            await getData({ page: 0, search: "" })
            handleClose()
            toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
        }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
    }
    const columns = [
        {
            Header: <div className="text-left">{renderFormatMsg("Name", "Name")}</div>,
            accessor: 'name',
            width: 380,
            maxWidth: 400,
            //resizable: false,
            style: { justifyContent: "flex-start" },
        },
        {
            Header: <div className="text-left">{renderFormatMsg("BrandName", "Brand Name")}</div>,
            accessor: 'brand',
            width: 200,
            maxWidth: 200,
            //resizable: false,
            style: { justifyContent: "flex-start" },
        }
    ]

    if (pageReadOnly.write) {
        columns.push({
            Header: renderFormatMsg("Edit", "Edit"),
            accessor: 'Edit',
            //resizable: false,
            width: 110,
            // Cell: props => <Link to={`/seasonType/Edit/${props.original.seasonTypeId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
            // Cell: props => <Link to={`/seasonType/salesChannel/${props.original.seasonTypeId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
            Cell: props => <Link to={`/seasonType/${props.original.seasonTypeId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
        })
    }
    const fetchData = (state, instance) => {
        //getting change page number from state
        getData({ page: state.page, search: searchValue })
        setPageIndex(state.page)
    }
    const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, search: e.target.value }) }
    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("SeasonTypes.Heading", "Season Types")}
            </div> */}
            <PageHeading headingId="SeasonTypes.Heading" isLoading={isLoading} />
            <div className="card">

                {error ? <Error msg={error} /> :
                    <>
                        <div className="card-header">
                            <SearchBox searchHandler={SearchHandler} />
                            {pageReadOnly.write && <LinkButton to="/seasonType/Add" id="AddSeasonType" defaultText="Add Season Type2" />}
                        </div>
                        <ReactTable data={seasonType} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
                    </>
                }
            </div>
            <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
        </div>
    )
}
export default React.memo(SeasonType)
