import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import ModalHeader from 'react-bootstrap/ModalHeader'
import "./Modal.scss"

/**
 * Common Modal Popup for modal
 * @returns
 */
const ModalPopup = ({ children, openModal, setOpenModal, heading = "", classes = "" }) => {
    return (
        <Modal
            show={openModal}
            toggle={() => setOpenModal(!openModal)}
            onHide={() => setOpenModal(!openModal)}
            className={`modal_new_seo ${classes}`}
        >
            <ModalHeader toggle={() => setOpenModal(!openModal)} closeButton>
                {heading}
            </ModalHeader>
            <ModalBody>
                {children}
            </ModalBody>
        </Modal>
    )
}
export default React.memo(ModalPopup);
