import React from 'react'
import PlusIcon from "../../images/plus.svg";
import { renderFormatMsg } from '../../shared/funs';

/**
 *
 * @param {fun} onClick handler
 * @param {string} Id of language translate text
 * @param {string} defaultText of text when translate failed to find in json
 * @param {string} classes custom overridable classname
 * @param {boolean} icon icon visible or not
 */
export default function CustomButton({ disabled, onClick, id, classes = "", defaultText, icon = true }) {
  return (
    <button className={`btn btn-light d-flex align-items-center ml-auto ${classes}`}
      onClick={(e) => { e.preventDefault(); onClick() }} disabled={disabled}>
      <span>{renderFormatMsg(id, defaultText)}</span>
      {icon && <img className="ml-2" src={PlusIcon} alt="PlusIcon" />}
    </button>
  )
}
