import React, { useEffect, useState } from 'react'
import {
  Form,
  // Field
} from 'react-final-form';
import { get } from "lodash";
import { renderFormatMsg, toastError, toastSuccess } from '../../../shared/funs';
// import { Link } from 'react-router-dom';
// import BackIcon from "../../../images/back-icon.svg";
import {
  getClientRolesData,
  // updateUserRoles,
  // getScreenList,
  addUserRoles,
} from '../../../services/toolsAndSettingsPagesApi';
import Error from '../../../components/Error/Error';
import ScreenDragNDrop from '../../../components/ScreenDragNDrop';
import { SCREEN_ACCESS_RIGHT } from '../../../constant';
// import FieldErrorMessage from '../../../components/FieldErrorMessage';
import InputField from '../../../components/Fields/InputField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';


const HandleUserRoles = (props) => {
  const [userRoles, setUserRoles] = useState(null)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [screenAssigned, setScreenAssigned] = useState({
    notAssigned: [],
    readOnly: [],
    readWrite: []
  })

  var heading = "handleUserRole.Add.Heading"
  var values = {
    name: "",
    description: ""
  }
  if (props.location.pathname.includes("Edit")) {
    heading = "handleUserRole.Edit.Heading"
    values = {
      name: get(userRoles, "name"),
      description: get(userRoles, "description")
    }
  }

  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      getClientRolesData({ search: props.match.params.roleId }).then(res => {
        console.log('resdata =>', res, props.location.pathname);
        var scrrenLists = get(res.clientRoleResponse[0], "scrrenLists", [])
        console.log('scrrenLists =>', scrrenLists);
        var notAssigned = scrrenLists.filter(o => o.readOnly === false && o.readWrite === false)
        var readOnly = scrrenLists.filter(o => o.readOnly === true && o.readWrite === false)
        var readWrite = scrrenLists.filter(o => (o.readOnly === true && o.readWrite === true) || (o.readOnly === false && o.readWrite === true))

        setScreenAssigned({
          notAssigned,
          readOnly,
          readWrite
        })

        setUserRoles(res.clientRoleResponse.length > 0 ? res.clientRoleResponse[0] : null)
        setLoading(false)
      }).catch(err => {
        setLoading(false)
        setError(err)
      })
    } else {
      async function fetchUserData() {
        try {
          let res = await (new BaseService('/ScreenMaster')).getAll('', { page: 1, display: 100 })
          console.log('fetchUserData res =>', res);
          setScreenAssigned({
            notAssigned: res.screenMasters || SCREEN_ACCESS_RIGHT,
            readOnly: [],
            readWrite: []
          })
        } catch (error) {
          setScreenAssigned({
            notAssigned: SCREEN_ACCESS_RIGHT,
            readOnly: [],
            readWrite: []
          })
          setError(error)
        }
      }
      fetchUserData();

      // getScreenList({ page: 1, display: 100 }).then(res => {
      //   console.info('res => ', res.screenMasters);
      //   setScreenAssigned({
      //     notAssigned: res.screenMasters || SCREEN_ACCESS_RIGHT,
      //     readOnly: [],
      //     readWrite: []
      //   })
      // }).catch(err => {
      //   setScreenAssigned({
      //     notAssigned: SCREEN_ACCESS_RIGHT,
      //     readOnly: [],
      //     readWrite: []
      //   })
      //   setError(err)
      // })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])



  const handleAddForm = async (data) => {


    var accessRight = []
    console.info('screenAssigned => ', screenAssigned);

    screenAssigned.notAssigned.forEach(screen => {
      accessRight.push({
        ScreenId: screen.screenId,
        Readonly: false,
        ReadWrite: false
      })
    });

    screenAssigned.readOnly.forEach(screen => {
      accessRight.push({
        ScreenId: screen.screenId,
        Readonly: true,
        ReadWrite: false
      })
    });

    screenAssigned.readWrite.forEach(screen => {
      accessRight.push({
        ScreenId: screen.screenId,
        Readonly: true,
        ReadWrite: true
      })
    });
    console.info('accessRight => ', accessRight);


    let object = {
      name: get(data, "name"),
      description: get(data, "description"),
      accessRight
    }
    console.info('handleAddForm object => ', object);

    addUserRoles(object).then(re => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/user-roles")
    }).catch(err => {
      toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
    })
  }

  const handleEditForm = async (data) => {
    var accessRight = []
    console.info('screenAssigned => ', screenAssigned);

    screenAssigned.notAssigned.forEach(screen => {
      accessRight.push({
        ScreenId: screen.screenId,
        Readonly: false,
        ReadWrite: false
      })
    });

    screenAssigned.readOnly.forEach(screen => {
      accessRight.push({
        ScreenId: screen.screenId,
        Readonly: true,
        ReadWrite: false
      })
    });

    screenAssigned.readWrite.forEach(screen => {
      accessRight.push({
        ScreenId: screen.screenId,
        Readonly: false,
        ReadWrite: true
      })
    });
    console.info('accessRight => ', accessRight);

    const payload = {
      roleId: props.match.params.roleId,
      name: get(data, "name"),
      description: get(data, "description"),
      accessRight
    }
    console.info('payload => ', payload);

    // updateUserRoles(payload).then(resp => {
    //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"));
    //   props.history.push("/user-roles")
    // }).catch(err => {
    //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    // })
    try {
      await (new BaseService('/ClientRoles')).update("", payload)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"));
      props.history.push("/user-roles")
    } catch (error) {
      console.log('error =>', error);
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
  }

  const validation = (data) => {
    let errors = {}

    if (!data.name || data.name === "") {
      errors.name = "Required";
    }
    if (!data.description || data.description === "") {
      errors.description = "Required";
    }
    return errors
  }



  const handleScreenAssign = (data) => {
    setScreenAssigned(data)
  }
  console.info('screenAssigned => ', screenAssigned);

  return (
    <div className="main-content updatePage">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <Link className="back-btn" to="/user-roles"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/user-roles" headingId={heading} isLoading={isLoading} />
      <div className={`card p-3`}>
        {error ? <Error msg={error} /> :
          <Form
            onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
            initialValues={values}
            validate={validation}
          >
            {({ handleSubmit, form, submitting, pristine, values }) => {
              return (
                <form onSubmit={handleSubmit} className="align-items-end">
                  <div className="form">
                    <div className="row">
                      {/* <div className="col-lg-6">
                        <Field name="name">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label>
                                {renderFormatMsg("rolesTable.Name", "Name")}
                              </label>
                              <input {...input} type="text" placeholder="Name" className="form-control" />
                              {meta.error && meta.touched && (
                                <span>{meta.error}</span>
                              )}
                            </div>
                          )}
                        </Field>
                      </div> */}
                      <InputField
                        colClass="col-lg-6"
                        name={"name"}
                        type="text"
                        id="rolesTable.Name"
                        defaultLabel="Name"
                        placeholder={"Name"}
                      />

                      <InputField
                        colClass="col-lg-6"
                        name={"description"}
                        type="text"
                        id="rolesTable.Description"
                        defaultLabel="Category"
                        placeholder={"Description"}
                      />
                      {/* <div className="col-lg-6">
                        <Field name="description">
                          {({ input, meta }) => (
                            <div className="form-group">
                              <label>
                                {renderFormatMsg("rolesTable.Description", "Description")}
                              </label>
                              <input {...input} type="text" placeholder="Description" className="form-control" />
                              <FieldErrorMessage meta={meta} />
                            </div>
                          )}
                        </Field>
                      </div> */}
                    </div>
                    <div className="row card-body justify-content-between">
                      <ScreenDragNDrop handleScreenAssign={handleScreenAssign} screenAssigned={screenAssigned} />
                    </div>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-3">
                    <div className="submit_btn">
                      <button type="submit" disabled={submitting} className="btn btn-primary" >
                        {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}
                      </button>
                    </div>
                  </div>
                </form>
              );
            }}
          </Form>
        }

      </div>
    </div >
  )

}

export default React.memo(HandleUserRoles)
