import React from "react";
import { Route, Switch } from "react-router-dom";

import HandleStoreCategory from "./view/ToolsAndSettings/UpdatingPage/HandleStoreCategory";
import HandleClientPage from "./view/ToolsAndSettings/UpdatingPage/HandleClientPage";
import ManualOrder from "./view/ManualOrder/ManualOrder";
import HandleManualOrder from "./view/ManualOrder/HandleManualOrder";
import Promotion from "./view/Promotion/Promotion";
import PromotionType from "./view/PromotionType/PromotionType";
import HandlePromotionType from "./view/PromotionType/HandlePromotionType";
import SeasonType from "./view/SeasonType/SeasonType";
import HandleSeasonType from "./view/SeasonType/HandleSeasonType";
import HandlePromotion from "./view/Promotion/HandlePromotion";
import HandleTransportTimePerCity from "./view/ToolsAndSettings/UpdatingPage/HandleTransportTimePerCity";

import NotificationsSettings from "./view/NotificationSettings/NotificationSettings";
import Notifications from "./view/NotificationSettings/index";
import Overview from "./view/OverView/Overview";
import ReplenishmentOrdersOverview from "./view/ReplenishmentOrdersOverview/index";
import ReplenishmentOrderSku from "./view/ReplenishmentOrdersOverview/ReplenishmentOrderSku";
import ReplenishmentOrderDetail from "./view/ReplenishmentOrdersOverview/ReplenishmentOrderDetail";
import ReplenishmentQuantities from "./view/ReplenishmentOrdersOverview/ReplenishmentQuantities";
import ReplenishmentCalendar from "./view/ToolsAndSettings/ReplenishmentCalendar";
import StoreCategory from "./view/ToolsAndSettings/StoreCategory";
import Layout from "./components/Layout/Layout";
import Seasons from "./view/ToolsAndSettings/Seasons";
import HandleSeason from "./view/ToolsAndSettings/UpdatingPage/HandleSeason";
import Outlet from "./view/ToolsAndSettings/Outlet";
import User from "./view/ToolsAndSettings/User";
import HandleUser from "./view/ToolsAndSettings/UpdatingPage/HandleUser";
import UserRoles from "./view/ToolsAndSettings/UserRoles";
import TransportTimePerCity from "./view/ToolsAndSettings/TransportTimePerCity";
import ClientPage from "./view/ToolsAndSettings/ClientPage";
import EditClient from "./view/ToolsAndSettings/EditClient";
import Warehouses from "./view/ToolsAndSettings/Warehouses";
import HandleWarehouse from "./view/ToolsAndSettings/UpdatingPage/HandleWarehouse";
import UserRoleAssignment from "./view/ToolsAndSettings/UpdatingPage/UserRoleAssignment";
import ProductDistributionOverview from "./view/Products/ProductDistributionOverview";
import ProductDistributionDetail from "./view/Products/ProductDistributionDetail";
import ProductSettings from "./view/ToolsAndSettings/UpdatingPage/ProductSettings";
import ProductCategory from "./view/ToolsAndSettings/ProductCategory";
import HandleProductCategory from "./view/ToolsAndSettings/UpdatingPage/HandleProductCategory";
import HandleOutlet from "./view/ToolsAndSettings/UpdatingPage/HandleOutlet";
import HandleUserRoles from "./view/ToolsAndSettings/UpdatingPage/HandleUserRoles";
import SpecialReplenishment from "./view/SpecialReplenishment/index";
import Settings from "./view/Settings";
import ProductPerformance from "./view/ProductPerformance";
import PageNotFound from "./components/PageNotFound";
import Why from "./view/Why/Why";
import SalesChannels from "./view/SalesChannels";
import HandleSalesChannels from "./view/SalesChannels/HandleSalesChannels";
import FileInfo from "./view/FileInfo/FileInfo";
import SeasonTypeSalesChannels from "./view/SalesChannels/SeasonTypeSalesChannels";
import ClientPageWithGraphQL from "./view/ToolsAndSettings/ClientPageWithGraphQL";
import LicensePage from "./view/ToolsAndSettings/LicensePage";

export const routesCode = [
  { path: "/", exact: true, component: Overview },
  {
    path: "/notifications-settings",
    exact: true,
    component: NotificationsSettings,
  },
  { path: "/why", exact: true, component: Why },
  { path: "/notifications", exact: true, component: Notifications },
  { path: "/overview", exact: true, component: Overview },
  { path: "/orders", exact: true, component: ReplenishmentOrdersOverview },
  {
    path: "/orders/sku/exports/:exportPackageId",
    exact: true,
    component: ReplenishmentOrderSku,
  },
  {
    path: "/orders/sku/exports/:exportPackageId/options/:optionId",
    exact: true,
    component: ReplenishmentOrderDetail,
  },
  {
    path: "/orders/sku/exports/:exportPackageId/options/:optionId/outletId/:outletId/quantity",
    exact: true,
    component: ReplenishmentQuantities,
  },
  {
    path: "/replenishment-calendar",
    exact: true,
    component: ReplenishmentCalendar,
  },
  { path: "/storeCategory", exact: true, component: StoreCategory },
  { path: "/seasons", exact: true, component: Seasons },
  { path: "/seasons/Edit/:seasonId", exact: true, component: HandleSeason },
  { path: "/seasons/Add", exact: true, component: HandleSeason },
  { path: "/outlet", exact: true, component: Outlet },
  { path: "/user", exact: true, component: User },
  { path: "/sales-channels", exact: true, component: SalesChannels },
  { path: "/sales-channels/Add", exact: true, component: HandleSalesChannels },
  {
    path: "/sales-channels/Edit/:channelId",
    exact: true,
    component: HandleSalesChannels,
  },
  { path: "/user/Edit/:userId", exact: true, component: HandleUser },
  { path: "/user/Add", exact: true, component: HandleUser },
  {
    path: "/transportTimePerCity",
    exact: true,
    component: TransportTimePerCity,
  },
  { path: "/warehouses", exact: true, component: Warehouses },
  { path: "/warehouses/Add", exact: true, component: HandleWarehouse },
  {
    path: "/warehouses/Edit/:warehouseId",
    exact: true,
    component: HandleWarehouse,
  },
  { path: "/client", exact: true, component: ClientPage },
  { path: "/client-graphql", exact: true, component: ClientPageWithGraphQL },
  { path: "/license/:ClientId", exact: true, component: LicensePage },
  {
    path: "/user/userRoleAssignment/:userId",
    exact: true,
    component: UserRoleAssignment,
  },
  {
    path: "/optionDistribution",
    exact: true,
    component: ProductDistributionOverview,
  },
  {
    path: "/optionDistribution/optionDistributionDetail/:optionId/:seasonId?",
    exact: true,
    component: ProductDistributionDetail,
  },
  {
    path: "/optionDistribution/productSettings/:optionId",
    exact: true,
    component: ProductSettings,
  },
  { path: "/productCategory", exact: true, component: ProductCategory },
  {
    path: "/specialReplenishment",
    exact: true,
    component: SpecialReplenishment,
  },
  {
    path: "/productCategory/Add",
    exact: true,
    component: HandleProductCategory,
  },
  {
    path: "/productCategory/Edit/:categoryId",
    exact: true,
    component: HandleProductCategory,
  },
  { path: "/outlet/Add", exact: true, component: HandleOutlet },
  { path: "/outlet/Edit/:outletId", exact: true, component: HandleOutlet },
  { path: "/user-roles", exact: true, component: UserRoles },
  { path: "/user-roles/Add", exact: true, component: HandleUserRoles },
  { path: "/user-roles/Edit/:roleId", exact: true, component: HandleUserRoles },
  { path: "/storeCategory/Add", exact: true, component: HandleStoreCategory },
  {
    path: "/storeCategory/Edit/:outletCategoryId",
    exact: true,
    component: HandleStoreCategory,
  },
  { path: "/client/Add", exact: true, component: HandleClientPage },
  { path: "/client/Edit/:clientId", exact: true, component: EditClient },
  { path: "/manualOrder", exact: true, component: ManualOrder },
  { path: "/manualOrder/Add", exact: true, component: HandleManualOrder },
  {
    path: "/manualOrder/Edit/:orderId",
    exact: true,
    component: HandleManualOrder,
  },
  { path: "/promotion", exact: true, component: Promotion },
  { path: "/settings", exact: true, component: Settings },
  {
    path: "/product-performance/:optionId",
    exact: true,
    component: ProductPerformance,
  },
  {
    path: "/promotion/Edit/:promotionId",
    exact: true,
    component: HandlePromotion,
  },
  { path: "/promotion/Add", exact: true, component: HandlePromotion },
  { path: "/promotionType", exact: true, component: PromotionType },
  { path: "/promotionType/Add", exact: true, component: HandlePromotionType },
  {
    path: "/promotionType/Edit/:promotionTypeId",
    exact: true,
    component: HandlePromotionType,
  },
  { path: "/seasonType", exact: true, component: SeasonType },
  { path: "/salesChannels", exact: true, component: SalesChannels },
  {
    // path: "/seasonType/salesChannel/:seasonTypeId",
    path: "/seasonType/:seasonTypeId",
    exact: true,
    component: SeasonTypeSalesChannels,
  },
  {
    path: "/seasonType/Edit/:seasonTypeId/:salesChannelSeasonTypeId",
    // path: "/seasonType/Edit/:seasonTypeId",
    // path: "/salesChannelSeasonType/Edit/:channelId",
    exact: true,
    component: HandleSeasonType,
  },
  { path: "/seasonType/Add", exact: true, component: HandleSeasonType },
  {
    path: "/transportTimePerCity/Add",
    exact: true,
    component: HandleTransportTimePerCity,
  },
  {
    path: "/transportTimePerCity/Edit/:id",
    exact: true,
    component: HandleTransportTimePerCity,
  },
  { path: "/fileInfo/:id", exact: true, component: FileInfo },
  { path: "*", component: PageNotFound },
];

class Routes extends React.PureComponent {
  render() {
    return (
      <Layout>
        <Switch>
          {routesCode.map((route, i) => (
            <Route {...route} key={i} />
          ))}
        </Switch>
      </Layout>
    );
  }
}

export default Routes;
