import React, { useEffect, useState } from 'react'
import { get, uniqBy, remove, findIndex, find, pick } from "lodash";
import { renderFormatMsg, toastError, toastSuccess, } from '../../shared/funs';
import { Link } from 'react-router-dom';
// import BackIcon from "../../images/back-icon.svg";
import { INTERACTION_CODE_LIST, PAGE_SIZE } from '../../constant';
import SearchBox from '../../components/SearchBox/SearchBox';
import CustomButton from '../../components/Buttons/CustomButton';
import ReactTable from '../../components/ReactTable/ReactTable';
import Error from '../../components/Error/Error';
import Modal from '../../components/Modal/index';
import { FormattedMessage } from 'react-intl';
import { getSKU, addManualOrder, getManualOrderDtail, updateManualOrder } from '../../services/sidebarApi';
import "./style.scss"
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import { getSeasonsDetails, getOutletData } from '../../services/toolsAndSettingsPagesApi';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const HandleManualOrder = (props) => {
  const [error, setError] = useState(false)
  const [pageIndex, setPageIndex] = useState(0)
  // eslint-disable-next-line
  const [searchSku, setSearchSku] = useState("")
  const [openModal, setOpenModal] = useState(false)
  const [skusData, setSkusData] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false)
  const [inputData, setInputData] = useState({ viewData: [], skuWithQuantity: [] })
  const [seasons, setSeaons] = useState([])
  const [outlets, setOutlet] = useState([])
  const [selectedSeason, setSelectedSeason] = useState("")
  const [selectedOutlet, setSelectedOutlet] = useState(null)
  const [collectSKUQTY, setCollectSKUQTY] = useState({})
  const [isEdit, setEdit] = useState(false)

  let heading = "addManualOrder.Heading"
  if (props.location.pathname.includes("Edit")) {
    heading = "editManualOrder.Heading"
  }

  useEffect(() => {
    let order
    if (props.location.pathname.includes("Edit")) {
      getManualOrderDtail().then(async res => {
        order = find(res.orders, (data) => data.orderId === props.match.params.orderId);
        setEdit(order.isShipped)
        var data = { orderDescription: order.description, skuWithQuantity: order.orderDetail, viewData: order.orderDetail, interaction: order.interactionCode };

        setInputData(data)
        order.orderDetail.map(dt => collectSKUQTY[dt.skuId] = dt.quantity)
        setCollectSKUQTY(collectSKUQTY)

        getSeasonsDetails({ search: order.seasonId })
          .then(res => setSelectedSeason(res.seasons[0]))
          .catch(error => console.log('error => ', error))

        // getOutletData({ search: order.outletId }).then(res => setSelectedOutlet(res.outlets[0])).catch(error => console.log('error => ', error))

        try {
          let res = await (new BaseService('/Outlets')).getAll('', { search: order.outletId })
          setSelectedOutlet(res.outlets[0])
        } catch (error) {
          console.log('error => ', error)
        }


      }).catch(err => { setLoading(false); setError(err) })
    } else {
      setInputData({ ...inputData, interaction: INTERACTION_CODE_LIST[0].value })
    }
    getSeasonsDetails().then(res => {
      setSeaons(res.seasons);
      props.location.pathname.includes("Add") && setSelectedSeason(res.seasons[0])
    }).catch(error => console.log('error  => ', error))

    getOutletData().then(res => {
      setOutlet(res.outlets)
      props.location.pathname.includes("Add") && setSelectedOutlet(res.outlets[0])
    }).catch(error => console.log('error  => ', error))

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const getData = ({ page = 0, search = "" }) => {
    setSearchSku(search)
    setLoading(true)
    let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
    getSKU(param).then(res => {
      setSkusData(res.skUs)
      setTotalPage(res.totalPage)
      setLoading(false)
    }).catch(err => { setLoading(false); setError(err) })
  }
  const changeHandler = (e, skuId = false) => {
    if (skuId !== false) {
      collectSKUQTY[skuId] = Number(e.target.value);
      setCollectSKUQTY(collectSKUQTY);
      var index = findIndex(inputData.viewData, { skuId });
      if (index >= 0) {
        inputData.viewData[index].quantity = Number(e.target.value);
      } else {
        inputData.viewData.push({ skuId: skuId, quantity: e.target.value })
      }
      setInputData(inputData)
    } else {
      setInputData({ ...inputData, [e.target.name]: e.target.value })
    }
  }

  const handleDeleteSku = (skuId) => {
    let updateData = remove(inputData.viewData, (d) => d.skuId !== skuId)
    setInputData({ ...inputData, viewData: updateData })
  }
  const columnsForView = [{
    Header: <div className="text-left">{renderFormatMsg("SkuId", "SKU ID")}</div>,
    accessor: 'clientSkuId',
    //resizable: false,
    width: 200,
    style: { justifyContent: "flex-start" },
  }, {
    Header: <div className="text-left">{renderFormatMsg("SkuName", "SKU Name")}</div>,
    accessor: 'clientSkuId',
    //resizable: false,
    minwidth: 200,
    style: { justifyContent: "flex-start" },
  },
  {
    Header: renderFormatMsg("Quantity", "Quantity"),
    accessor: 'quantity',
    width: 150,
    //resizable: false,
    Cell: (props) => <input
      key={`quantity-${props.original.skuId}`}
      name={`quantity-${props.original.skuId}`}
      type="number"
      className="form-control sku-control"
      onChange={(e) => changeHandler(e, props.original.skuId)}
      defaultValue={collectSKUQTY[props.original.skuId]}
      readOnly={isEdit ? true : false}
    />
  }, {
    Header: renderFormatMsg("Delete", "Delete"),
    accessor: 'Delete',
    //resizable: false,
    width: 100,
    Cell: props => <Link to="#" className="delete-icon" onClick={() => handleDeleteSku(props.original.skuId)}>{renderFormatMsg("Delete", "Delete")}</Link>
  }]
  const SearchHandler = (e) => setPageIndex(0)
  const handleAddedSkus = (data) => {
    const tempData = Object.assign({}, inputData);
    let obj = { skuId: data.skuId, clientSkuId: data.clientSKUId, quantity: 1 }
    tempData.viewData.push(obj)
    tempData.viewData = uniqBy(tempData.viewData, 'skuId')
    collectSKUQTY[data.skuId] = 1;
    setCollectSKUQTY(collectSKUQTY);
    setInputData(tempData)
    toastSuccess(renderFormatMsg("ADD_MSG", "Added succesfully"))
  }

  const fetchDataForSku = (state, instance) => {
    getData({ page: state.page })
    setPageIndex(state.page)
  }
  const columnsForSku = [{
    Header: <div className="text-left">{renderFormatMsg("OptionName", "option Name")}</div>,
    accessor: 'option.optionName',
    //resizable: false,
    width: 130,
    style: { justifyContent: "flex-start" },
  }, {
    Header: <div className="text-left">{renderFormatMsg("SkuId", "SKU ID")}</div>,
    accessor: 'clientSKUId',
    width: 200,
    //resizable: false,
    style: { justifyContent: "flex-start" },
  }, {
    Header: <div className="text-left"> {renderFormatMsg("Size", "Size")}</div>,
    accessor: 'size',
    width: 120,
    //resizable: false,
    style: { justifyContent: "flex-start" },
  }, {
    Header: renderFormatMsg("Add", "Add"),
    accessor: 'Add',
    //resizable: false,
    width: 100,
    Cell: props => <button onClick={() => handleAddedSkus(props.original)} className="edit-icon addbtn-style">{renderFormatMsg("Add", "Add")}</button>
  }]
  const SearchHandlerForSku = (e) => {
    setPageIndex(0); getData({ page: 0, search: e.target.value })
  }
  const handleData = (data, key) => {
    key === "season" ? setSelectedSeason(data) : setSelectedOutlet(data)
  }

  const addManualOrderData = async () => {
    let object = {
      "outletId": selectedOutlet.outletId,
      "seasonId": selectedSeason.seasonId,
      "description": inputData.orderDescription,
      "interactionCode": inputData.interaction,
      "orderDetail": inputData.viewData.map(dt => pick(dt, ["skuId", "quantity"]))
    }
    if (props.location.pathname.includes("Edit")) {
      object.orderId = props.match.params.orderId
      updateManualOrder(object).then(res => {
        toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        props.history.push("/manualOrder")
      }).catch()
    } else {
      await addManualOrder(object).then(res => {
        toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        props.history.push("/manualOrder")
      }).catch(err => { console.log('err =>', err); toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")) })
    }
  }
  const checkValidation = () => {
    return (inputData === null || !inputData.orderDescription || inputData.viewData.length <= 0)
  }


  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn mr-1" to="/manualOrder"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
        <div className="right-content select-box ml-auto">
          <DropDownCustom dropdownData={seasons} selectedData={get(selectedSeason, "name", "Season")} handleData={(data) => handleData(data, "season")} name="name" />
        </div>
      </div> */}
      <PageHeading backURL="/manualOrder" headingId={heading} >
        <div className="right-content select-box ml-auto">
          <DropDownCustom dropdownData={seasons} selectedData={get(selectedSeason, "name", "Season")} handleData={(data) => handleData(data, "season")} name="name" />
        </div>
      </PageHeading>

      <div className="card manual-order">
        <div className="card-header manualhead-order d-block">
          <div className="row">
            <div className="col-xl-6">
              <div className="order-block">
                <div className="col-xl-5">
                  <p className="mb-0">{renderFormatMsg("Outlet", "Outlet")}</p>
                </div>
                <div className="col-xl-8">{isEdit ?
                  <p className="form-control mb-2" readOnly={isEdit ? true : false}>{selectedOutlet ? get(selectedOutlet, "outletName", "Store") : "Select outlet"}</p> :
                  <DropDownCustom removeDownArrow={false} classes={"outletDropdown w-100 form-control scrollableDropdown"} dropdownData={outlets} selectedData={selectedOutlet !== null ? get(selectedOutlet, "outletName", "Store") : "Select Store"} isSearchable={true} handleData={(data) => handleData(data, "outlet")} name="outletName" />
                }
                </div>
              </div>
              <div className="order-block">
                <div className="col-xl-5">
                  <p className="mb-0">{renderFormatMsg("OrderDescriptions", "Order Descriptions")}</p>
                </div>
                <div className="col-xl-8">
                  <input className="form-control w-100 text-left order-inputbox" placeholder="Edit here...." name="orderDescription" onChange={(e) => changeHandler(e)} defaultValue={inputData.orderDescription} readOnly={isEdit ? true : false} />
                </div>
              </div>
            </div>
            <div className="align-items-end col-lg-6 col-sm-12 col-xl-6 d-flex">
              <div className="Interaction-block">
                <div className="col-xl-5"><p className="mb-0">{renderFormatMsg("InteractionWithAutoReplenishment", "Interaction with auto Replenishment")}</p></div>
                <div className="col-xl-8">{isEdit ?
                  <p className="form-control mb-0 Interaction-txt" readOnly={isEdit ? true : false}>{inputData.interaction}</p> :
                  <select name="interaction" className="form-control select-arrow"
                    onChange={(e) => changeHandler(e)} defaultValue={inputData.interaction}>
                    {INTERACTION_CODE_LIST.map(opt =>
                      <FormattedMessage id={opt.id} defaultMessage={opt.name} key={opt.id}>
                        {(message) => <option selected={inputData.interaction === opt.value} value={opt.value}>{message}</option>}
                      </FormattedMessage>
                    )}</select>}
                </div>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col-sm-12 pl-0 pr-0"><hr /></div>
          </div>
          <div className="row mt-3">
            <div className="col-sm-12 col-lg-6 col-xl-6">
              <SearchBox searchHandler={SearchHandler} className="manual-srh" />
            </div>
            <div className="col-sm-12 col-lg-6 col-xl-6"><CustomButton classes="addsku-btn" onClick={() => setOpenModal(!openModal)} id="AddSKU" defaultText="Add SKU" disabled={isEdit ? true : false} /></div>
          </div>
          <Modal openModal={openModal} setOpenModal={setOpenModal} heading={renderFormatMsg("AddSku", "Add SKUs")} classes="">
            <div className="main-content">
              <div className="card">
                <div className="card-header"> <SearchBox searchHandler={SearchHandlerForSku} /></div>
                {error ? <Error /> : <ReactTable data={skusData} columns={columnsForSku} onFetchData={fetchDataForSku} pages={totalPage} page={pageIndex} />}
              </div>
            </div>
          </Modal>
        </div>
        {error ? <Error /> :
          <form >
            <ReactTable data={inputData.viewData} columns={columnsForView} showPagination={false} />
          </form>
        }
        <div className="row">
          <div className="col-sm-12">
            <div className="col-sm-12">
              <div className="submit_btn mb-3 float-right">
                <button type="submit" className="btn btn-primary" disabled={checkValidation() || isEdit ? true : false} onClick={addManualOrderData} >{props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")}</button></div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}
export default React.memo(HandleManualOrder)
