import React, { useState, useContext, useEffect } from 'react'
import { getspecialReplenishment, updatespecialReplenishment } from '../../services/sidebarApi';
import moment from 'moment';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE, DATE_FORMAT } from '../../constant';
import Calendar from "../../components/Calendar"
import { renderFormatMsg, toastSuccess, toastError, permissionErrorCheck, checkPermission, findPermission } from "../../shared/funs"
import Error from "../../components/Error/Error";
import './style.scss'
import SearchBox from '../../components/SearchBox/SearchBox';
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash';
import { BaseService } from '../../services/BaseService';

const SpecialReplenishment = () => {
  const [replenishment, setReplenishmentData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [selectedDate, setSelectedDate] = useState(new Date(new Date().setDate(new Date().getDate() + 1)))
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)

  const { userDetails } = useContext(AppContext);
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  let dataArray = get(userDetails, "accesses");
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("SPECIAL_REPLENISHMENT").screenId))
  }, [dataArray])

  const getData = async ({ page = 0, date = false, search = "" }) => {
    setSearchValue(search)
    setLoading(true)

    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
      "search": search,
      "date": moment(date ? date : selectedDate).format(DATE_FORMAT)
    }

    try {
      let res = await (new BaseService('/Replenishment/specialReplenishment')).getAll('', param)
      setLoading(false)
      setReplenishmentData(res.outlets)
      setTotalPage(res.totalPage)
    } catch (error) {
      setLoading(false)
      setError(permissionErrorCheck(error))
    }


    // getspecialReplenishment(param).then(res => {
    //   setLoading(false)
    //   setReplenishmentData(res.outlets)
    //   setTotalPage(res.totalPage)
    // }).catch(err => {
    //   setLoading(false)
    //   setError(permissionErrorCheck(err))
    // })

  }
  const handleChecked = async (e, outletId, row) => {
    var data = {
      "outletId": outletId,
      "status": e.target.checked,
      "date": moment(selectedDate).format(DATE_FORMAT)
    }

    // updatespecialReplenishment(data).then(res => {
    //   let _replenishment = [...replenishment];
    //   _replenishment.forEach(currentData => {
    //     if (currentData.outletId === outletId) {
    //       currentData.status = currentData.status === "Yes" ? "No" : "Yes"
    //     } else {
    //     }
    //   })
    //   setReplenishmentData(_replenishment);
    //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    // }).catch(err => {
    //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    // })

    try {
      await (new BaseService('/Replenishment/UpdateSpecialReplenishment')).update('', data)
      let _replenishment = [...replenishment];
      _replenishment.forEach(currentData => {
        if (currentData.outletId === outletId) {
          currentData.status = currentData.status === "Yes" ? "No" : "Yes"
        }
      })
      setReplenishmentData(_replenishment);
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    } catch (error) {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
  }

  const fetchData = (state, instance) => {
    getData({ page: state.page, date: selectedDate, search: searchValue });
    setPageIndex(state.page)
  }
  const dateHandler = (date) => {
    setSelectedDate(moment(date));
    setPageIndex(0);
    getData({ page: 0, date, search: searchValue });
  }
  const dateChangeHandler = (val) => {
    var newDate = moment(selectedDate).add('day', val);
    var date = new Date(newDate);
    setSelectedDate(date)
    setPageIndex(0);
    getData({ page: 0, date })
  }

  const columns = [
    {
      Header: <div className="text-left"> {renderFormatMsg("OutletID", "Outlet ID")}  </div>,
      accessor: 'outletId',
      width: 324,
      maxWidth: 350,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left"> {renderFormatMsg("OutletName", "Outlet Name")} </div>,
      accessor: 'outletName',
      //resizable: false,
      width: 300,
      maxWidth: 350,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: renderFormatMsg("Status", "Status"),
      //resizable: false,
      width: 100,
      Cell: (props) => <div
        className="checkbox"
        style={{ cursor: `${!pageReadOnly.write && `not-allowed`}`, opacity: `${(props.original.status === "Scheduled" || !moment().isBefore(moment(selectedDate))) && "0.6"}` }}
      >
        <input
          type="checkbox"
          name="status"
          style={{ cursor: `${!pageReadOnly.write && `not-allowed`}` }}
          disabled={((props.original.status === "Scheduled" || !moment().isBefore(moment(selectedDate)))) ? true : pageReadOnly.write ? false : true}
          checked={(props.original.status === "Scheduled" || props.original.status === "Yes") ? true : false}
          onChange={(e) => handleChecked(e, props.original.outletId, props.original)} />
        <label></label>
      </div>
    }
  ]

  const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, search: e.target.value }); }

  return (
    <div className="main-content">
      <div style={{
        display: "flex",
        alignItems: "center"
      }} className={"replenishment title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("specialReplenishment.Heading", "Replenishment")}
        {!error && <div className="ml-auto right-content" style={{ alignItems: "center" }}>
          <span className="text">
            {renderFormatMsg("Custom", "Custom")} : &nbsp;
          </span>
          <div className="calendarBox">
            <Calendar update={true} popperPlacement="top-end" name="activeFrom" selectedDate={selectedDate} onSelect={data => dateHandler(data)} />
          </div>
          <span className="arrow-icon" style={{ transform: 'rotate(180deg)', cursor: "pointer" }} onClick={() => dateChangeHandler(-1)}><i className="icon-right-arrow"></i></span>&nbsp;&nbsp;
          <span className="arrow-icon" style={{ cursor: "pointer" }} onClick={() => dateChangeHandler(1)}><i className="icon-right-arrow"></i></span>
        </div>}
      </div>
      <div className="card">
        {
          error ?
            <Error msg={error} /> : <><div className="card-header">
              <SearchBox searchHandler={SearchHandler} />
            </div><ReactTable data={replenishment} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} /></>
        }
      </div>
    </div >
  )
}
export default React.memo(SpecialReplenishment);
