import React, { useState, useEffect, useContext } from 'react'
import { Accordion, Card } from 'react-bootstrap';
import { find, get } from "lodash"
// import { Link } from 'react-router-dom';
import moment from 'moment';
import ReactTable from '../../components/ReactTable/ReactTable';
// import { getProductDistributionDetail, updateProductDistributionDetail, getOptions } from '../../services/sidebarApi';
import { getSeasonsDetails } from '../../services/toolsAndSettingsPagesApi';
import Calendar from "../../components/Calendar";
import { PAGE_SIZE, DATE_FORMAT } from '../../constant';
// import BackIcon from "../../images/back-icon.svg";
import { renderFormatMsg, toastSuccess, toastError, DateRangeStatus, checkPermission, findPermission } from '../../shared/funs';
import Error from '../../components/Error/Error';
import SearchBox from '../../components/SearchBox/SearchBox';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import { AppContext } from '../../context/AppContext';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ProductDistributionDetail = (props) => {
  const [productDistributionDetail, setProductDistributionDetail] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [searchValue, setSearchValue] = useState("")
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [seasons, setSeaons] = useState([])
  const [options, setOptions] = useState([])
  const [selectedSeason, setSelectedSeason] = useState(null)
  const [selectedOption, setSelectedOption] = useState(null)

  const { userDetails } = useContext(AppContext);
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  let dataArray = get(userDetails, "accesses");
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("PRODUCT_BLOCKING").screenId))
  }, [dataArray])

  const getData = async ({ page = 0, search = "", season = "", option = "" }) => {
    setSearchValue(search)
    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
      "search": search,
      "option": option ? option : props.match.params.optionId,
      "season": season ? season : get(selectedSeason, "seasonId")
    }
    try {
      let res = await (new BaseService('/DistributionDetail')).getAll('', param)
      setProductDistributionDetail(res.outlets.length > 0 ? res.outlets : [])
      setTotalPage(res.totalPage)
      setError(false);
    } catch (error) {
      console.log('error =>', error);

      setError(error);
    }
    // getProductDistributionDetail(param).then(res => {
    //   setProductDistributionDetail(res.outlets.length > 0 ? res.outlets : [])
    //   setTotalPage(res.totalPage)
    // }).catch(err => {
    //   setError(error);
    // })
  }
  useEffect(() => {
    var div_1 = document.querySelector(".card");
    div_1.classList.add("test");
    // if (typeof props.match.params.seasonId === "undefined" || props.match.params.seasonId === "undefined") {
    //   props.history.push("/optionDistribution");
    // }
    getSeasonsDetails({ "activeOnly": true, display: 9999999 }).then(res => {
      setSeaons(res.seasons)
      let seasonData = find(res.seasons, (data) => data.seasonId === props.match.params.seasonId)
      console.log('seasonData =>', seasonData);
      setSelectedSeason(seasonData)
    }).catch(error => {
      console.log('error  => ', error);
    })
    // getOptions().then(res => {
    //   setOptions(res.options)
    // }).catch(error => {
    //   console.log('error  => ', error);
    // })

    async function fetchData() {
      try {
        let res = await (new BaseService('/Seasons')).getAll('')
        console.log('res.options =>', res.options);

        // setSeaons()
        setOptions(res.options)
      } catch (error) {

      }

    }
    fetchData();
    async function fetchSelectedOptionData() {
      try {
        // getOptions({ search: props.match.params.optionId }).then(res => {
        //   setSelectedOption(res.options.length > 0 ? res.options[0] : "")
        // }).catch(error => {
        //   console.log('error  => ', error);
        // })
        let res = await (new BaseService('/Options')).getAll('', { search: props.match.params.optionId })
        console.log('fetchSelectedOptionData res =>', res);

        setSelectedOption(res.options.length > 0 ? res.options[0] : "")
      } catch (error) {

      }

    }
    fetchSelectedOptionData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const columns = [{
    Header: <div className="text-left">{renderFormatMsg("productDistributionDetailTable.OutletId", "Outlet ID")}</div>,
    accessor: 'category',
    //resizable: false,
    width: 250,
    maxWidth: 280,
    style: { justifyContent: "flex-start" }
  }, {
    Header: <div className="text-left">
      {renderFormatMsg("productDistributionDetailTable.OutletName", "Outlet Name")}</div>,
    width: 270,
    maxWidth: 300,
    //resizable: false,
    style: { justifyContent: "flex-start" }
  },
  {
    Header: <div className="text-left">
      {renderFormatMsg("productDistributionDetailTable.LastReplenishment", "Last replenishment")}</div>,
    width: 180,
    maxWidth: 180,
    //resizable: false,
    style: { justifyContent: "flex-start" }
  },
  {
    Header: renderFormatMsg("productDistributionDetailTable.BlockedFrom", "Blocked From"),
    //resizable: false,
    width: 170,
  }, {
    Header: renderFormatMsg("productDistributionDetailTable.BlockedTo", "Blocked To"),
    //resizable: false,
    width: 170
  }
  ]

  const fetchData = (state, instance) => {
    //getting change page number from state
    getData({ page: state.page, searchValue })
    setPageIndex(state.page)
  }
  const handleOptionData = (option) => {
    setSelectedOption(option)
    let param = {
      "option": option.optionId,
      "season": get(selectedSeason, "seasonId") ? get(selectedSeason, "seasonId") : props.match.params.seasonId
    }
    getData(param)
  }
  const handleSeasonData = (season) => {
    setSelectedSeason(season)
    let param = {
      "option": get(selectedSeason, "optionId") ? get(selectedSeason, "optionId") : props.match.params.optionId,
      "season": season.seasonId
    }
    getData(param)
    props.history.push(`/optionDistribution/optionDistributionDetail/${props.match.params.optionId}/${season.seasonId}`)
  }

  const handleChangeDate = async (date, details, key) => {
    if (pageReadOnly.write) {
      details[key] = date
      setLoading(true)
      let payload = {
        "optionId": props.match.params.optionId,
        "outletId": details.outletId,
        "blockedFrom": moment(details.blockedFrom).format(DATE_FORMAT),
        "blockedTo": moment(details.blockedTo).format(DATE_FORMAT)
      }
      if (get(details, "blockedFrom", false) && get(details, "blockedTo", false)) {
        // await updateProductDistributionDetail(payload).then(res => {
        //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        //   setLoading(false)
        // }).catch(error => {
        //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
        //   console.debug("error => ", error);
        // })

        try {
          await (new BaseService('/UpdateOutletOptionsBlock')).update(payload.optionId, payload)
          toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
          setLoading(false)
        } catch (error) {
          toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
          console.debug("error => ", error);
        }
      }
    } else {
      toastError("You have no pageReadOnly to update this value");
    }
  }

  const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, search: e.target.value }); }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <Link className="back-btn" to="/optionDistribution"><img src={BackIcon} alt="BackIcon" />
          </Link>
          <span>{renderFormatMsg("productDistributionOverviewTable.Heading", "Product Distribution")}</span>
        </div>
        <div className="right-content select-box ml-auto">
          <DropDownCustom selectedData={get(selectedSeason, "name", "Season")} classes={"p-2"} dropdownData={seasons} handleData={handleSeasonData} name="name" />
          <DropDownCustom selectedData={get(selectedOption, "optionName", "Product")} classes={"p-2"} dropdownData={options} handleData={handleOptionData} name="optionName" />
        </div>
      </div> */}
      <PageHeading backURL="/optionDistribution" headingId={"productDistributionOverviewTable.Heading"} isLoading={isLoading}>
        <div className="right-content select-box ml-auto">
          <DropDownCustom selectedData={get(selectedSeason, "name", "Season")} classes={"p-2"} dropdownData={seasons} handleData={handleSeasonData} name="name" />
          <DropDownCustom selectedData={get(selectedOption, "optionName", "Product")} classes={"p-2"} dropdownData={options} handleData={handleOptionData} name="optionName" />
        </div>
      </PageHeading>
      <div className="card">
        <div className="card-header">
          <SearchBox searchHandler={SearchHandler} />
        </div>
        <div>
          {error ? <Error /> : <ReactTable
            collapseOnDataChange={false}
            data={productDistributionDetail}
            columns={columns}
            onFetchData={fetchData}
            pages={totalPage}
            page={pageIndex}
            SubComponent={row => {
              const columns = [
                {
                  accessor: "outletId",
                  //resizable: false,
                  style: { justifyContent: "flex-start" },
                  Cell: props => <div>{props.original.outletId || "N/A"}</div>
                },
                {
                  accessor: "outletName",
                  width: 300,
                  //resizable: false,
                  style: { justifyContent: "flex-start" },
                  Cell: props => <div>{props.original.outletName || "N/A"}</div>
                },
                {
                  accessor: "lastReplenishment",
                  id: "lastReplenishment",
                  width: 200,
                  //resizable: false,
                  style: { justifyContent: "flex-start" },
                  Cell: props => <div>{props.original.lastReplenishment || "N/A"}</div>
                },
                {
                  accessor: "blockedFrom",
                  id: "blockedFrom",
                  //resizable: false,
                  width: 158,
                  Cell: props => <div width="170" className={DateRangeStatus(props.original.blockedFrom, props.original.blockedTo)}>
                    <Calendar
                      disabled={!pageReadOnly.write ? true : false}
                      className={`${!pageReadOnly.write ? "read-only" : ""}`}
                      name="blockedFrom" selectedDate={props.original.blockedFrom ? new Date(props.original.blockedFrom) : ""}
                      maxDate={props.original.blockedTo && new Date(props.original.blockedTo)}
                      onSelect={(date) => handleChangeDate(date, props.original, "blockedFrom")}
                    />
                  </div>
                },
                {
                  accessor: "blockedTo",
                  id: "blockedTo",
                  //resizable: false,
                  width: 158,
                  Cell: props => <div width="170" className={DateRangeStatus(props.original.blockedFrom, props.original.blockedTo)}
                  >
                    <Calendar
                      disabled={!pageReadOnly.write ? true : false}
                      className={`${!pageReadOnly.write ? "read-only" : ""}`}
                      name="blockedTo" selectedDate={props.original.blockedTo ? new Date(props.original.blockedTo) : ""}
                      minDate={props.original.blockedFrom && new Date(props.original.blockedFrom)}
                      onSelect={date => handleChangeDate(date, props.original, "blockedTo")}
                    />
                  </div>
                },
              ];
              return (
                <div className="distribution-bg">
                  <Accordion defaultActiveKey="0">
                    <Card>
                      <Accordion.Collapse eventKey="0">
                        <Card.Body>
                          <ReactTable
                            minRows={0}
                            data={row.original.outlets || []}
                            columns={columns}
                            showPagination={false} />
                        </Card.Body>
                      </Accordion.Collapse>
                    </Card>
                  </Accordion>
                </div>
              )
            }}
          />}
        </div>
      </div >
    </div >
  )
}
export default React.memo(ProductDistributionDetail);
