import React, { useEffect, useState } from "react";
import { sortBy } from "lodash"
import { updateSeverityStatus, getAllNotificationType } from "../../services/notificationAPI";
import { FormattedMessage } from "react-intl";
import { SEVERITY_SELECT_OPTION } from "../../constant";
import ReactTable from "../../components/ReactTable/ReactTable";
import { renderFormatMsg } from "../../shared/funs";
import Error from "../../components/Error/Error";
import SearchBox from "../../components/SearchBox/SearchBox";
import PageHeading from "../../components/PageHeading/PageHeading";

const Notifications = (props) => {
  const [ntofication, setNotification] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isLoading, setLoading] = useState(false);
  const [error, setError] = useState(false)

  useEffect(() => {
    getAllNotificationType()
      .then((data) => {
        setNotification(data);
        setFilteredData(data);
      })
      .catch((err) => {
        setError(err)
      });
  }, []);
  const globalSearch = (searchBoxValue) => {
    var filteredData = ntofication;
    if (searchBoxValue.length) {
      filteredData = ntofication.filter((value) => {
        return (
          value.notificationTypeId.toLowerCase().includes(searchBoxValue.toLowerCase()) ||
          value.name.toLowerCase().includes(searchBoxValue.toLowerCase()) ||
          value.description.toLowerCase().includes(searchBoxValue.toLowerCase()) ||
          value.category.toLowerCase().includes(searchBoxValue.toLowerCase())
        );
      });
    }
    setFilteredData(filteredData);
  };

  const handleSeverity = async (status, notificationTypeId) => {
    setLoading(true)
    let tempData = filteredData && filteredData.length > 0 ? [...filteredData] : []
    await updateSeverityStatus(status, notificationTypeId)
      .then(res => {
        tempData.forEach(currentData => {
          if (currentData.notificationTypeId === notificationTypeId) {
            currentData.severity = parseInt(status)
          }
        })
        let data = sortBy(tempData, 'severity');
        setFilteredData(data)
        setLoading(false)
      })
      .catch((err) => {
        setLoading(false)
      });
  };


  const columns = [{
    Header: renderFormatMsg("notificationTable.Name", "Name"),
    accessor: 'name',

  }, {
    Header: renderFormatMsg("notificationTable.Description", "Description"),
    accessor: 'description',
  }, {
    Header: renderFormatMsg("notificationTable.Category", "Category"),
    accessor: 'category',

  }, {
    Header: 'Severity',
    accessor: 'severity',
    Cell: props => (
      <select
        className="form-control select-arrow"
        value={props.original.severity}
        onChange={(e) => handleSeverity(e.target.value, props.original.notificationTypeId)}
      >
        {
          SEVERITY_SELECT_OPTION.map(opt =>
            <FormattedMessage id={opt.id} defaultMessage={opt.label} key={opt.id}>
              {(message) => <option value={opt.value} selected={props.severity === opt.value ? true : false}>{message}</option>}
            </FormattedMessage>
          )
        }
      </select>)
  }]

  const SearchHandler = (e) => { globalSearch(e.target.value) }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("notificationTable.Heading", "My Notification Settings")}
      </div> */}
      <PageHeading headingId="notificationTable.Heading" isLoading={isLoading} />
      <div className="card">
        <div className="card-header"> <SearchBox searchHandler={SearchHandler} /> </div>
        {
          error ? <Error /> : <ReactTable data={filteredData} columns={columns} />
        }
      </div>
    </div >
  );
};

export default React.memo(Notifications);
