import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import Error from "../../components/Error/Error"
import { renderFormatMsg, checkPermission, findPermission } from '../../shared/funs';
import SearchBox from '../../components/SearchBox/SearchBox';
import { get } from 'lodash';
import DropDownCustom from "../../components/DropDowns/DropDownCustom"
import { AppContext } from '../../context/AppContext';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ReplenishmentOrderSku = (props) => {
  const [orderSkus, setOrderSkus] = useState([])
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)

  const { userDetails } = useContext(AppContext);
  // eslint-disable-next-line
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  let dataArray = get(userDetails, "accesses");
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("REPLENISHMENT_ORDER_OVERVIEW").screenId))
  }, [dataArray])

  const columns = [
    {
      Header: <div className="text-left"> {renderFormatMsg("ClientOptionId", "ClientOption ID")} </div>,
      accessor: 'clientOptionId',
      width: 170,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left"> {renderFormatMsg("OptionName", "Option Name")} </div>,
      accessor: 'optionName',
      minWidth: 120,
      maxWidth: 180,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Warehouse", "Warehouse")}</div>,
      accessor: 'warehouse',
      width: 150,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left">{renderFormatMsg("Stores", "# Stores")} </div>,
      accessor: 'numberOfStores',
      width: 90,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left">{renderFormatMsg("SkuOrdered", "# SKU Ordered")} </div>,
      accessor: 'numberOfSKUOrdered',
      width: 120,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left"> {renderFormatMsg("SkuShipped", "# SKU Shipped")} </div>,
      accessor: 'numberOfSKUShipped',
      width: 120,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: <div className="text-left"> {renderFormatMsg("Errors", "Errors")} </div>,
      accessor: 'errors',
      width: 60,
      //resizable: false,
      style: { justifyContent: "flex-start" }
    },
    {
      Header: renderFormatMsg("Details", "Details"),
      accessor: 'Details',
      //resizable: false,
      width: 100,
      Cell: (data) => <Link to={`/orders/sku/exports/${props.match.params.exportPackageId}/options/${data.original.optionId}`}
        className="edit-icon"> {renderFormatMsg("Details", "Details")} </Link>
    }
  ]
  const getData = async ({ page = 0, clientOptionId = "" }) => {
    let param = { "page": page + 1, "display": PAGE_SIZE, "exportPackageId": props.match.params.exportPackageId, clientOptionId: clientOptionId }
    setLoading(true)

    try {
      let res = await (new BaseService('/Replenishment/orderSKUs')).getAll('', param)
      setLoading(false)
      setOrderSkus(res.skuOrderOverviewRes.length > 0 ? res.skuOrderOverviewRes : [])
      setTotalPage(res.totalPage)
    } catch (error) {
      setLoading(false)
      setError(error)
    }
  }
  const fetchData = (state, instance) => {
    //getting change page number from state
    getData({ page: state.page })
    setPageIndex(state.page)
  }
  const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, clientOptionId: e.target.value }) }

  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <Link className="back-btn" to="/orders"><img src={BackIcon} alt="BackIcon" /></Link>
          <span> {renderFormatMsg("replenishmentOrderSKU.Heading", "Replenishment Order SKU")}</span>
        </div>
        <div className="right-content select-box ml-auto">
          <DropDownCustom name={false} selectedData={get(orderSkus[0], "orderName", "Export Package")} dropdownData={false} handleData={false} />
        </div>
      </div> */}
      <PageHeading backURL="/orders" headingId={"replenishmentOrderSKU.Heading"} isLoading={isLoading}>
        <div className="right-content select-box ml-auto">
          <DropDownCustom name={false} selectedData={get(orderSkus[0], "orderName", "Export Package")} dropdownData={false} handleData={false} />
        </div>
      </PageHeading>
      <div className="card">
        <div className="card-header">
          <SearchBox searchHandler={SearchHandler} />
        </div>
        {error ? <Error /> : <ReactTable data={orderSkus} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />}
      </div>
    </div>
  )
}
export default React.memo(ReplenishmentOrderSku);
