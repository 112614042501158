import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import Error from "../../components/Error/Error"
import { renderFormatMsg, permissionErrorCheck, checkPermission, findPermission } from '../../shared/funs';
import moment from 'moment';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ReplenishmentOrdersOverview = () => {
    const [orderOverviews, setOrderOverviews] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [error, setError] = useState(false)
    const [brands, setBrands] = useState([])
    const [selectedBrand, setSelectedBrand] = useState({ brandId: "all", name: "All Brands" })
    const [isLoading, setLoading] = useState(false)



    const { userDetails } = useContext(AppContext);
    // eslint-disable-next-line
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
    let dataArray = get(userDetails, "accesses");
    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("REPLENISHMENT_ORDER_OVERVIEW").screenId))
    }, [dataArray])


    useEffect(() => {
        async function fetchBrandData() {
            try {
                let res = await (new BaseService('/Brand')).getAll('')
                setBrands([{ brandId: "all", name: "All Brands" }, ...res.brands])
            } catch (error) {
                console.log('error => ', error)
            }
        }
        fetchBrandData();
    }, [])

    const getData = async ({ page = 0, brand = false }) => {
        let param;
        setLoading(true)
        brand === false || brand.brandId === "all" ? param = { "page": page + 1, "display": PAGE_SIZE } : param = { "page": page + 1, "display": PAGE_SIZE, "brand": brand.brandId }
        try {
            let res = await (new BaseService('/Replenishment/orderOverview')).getAll('', param)
            setLoading(false)
            setOrderOverviews(res.replenishmentOrderOverviewRes.length > 0 ? res.replenishmentOrderOverviewRes : [])
            setTotalPage(res.totalPage)
        } catch (error) {
            console.log('error =>', error);
            setLoading(false)
            setError(permissionErrorCheck(error))
        }
    }
    const columns = [
        {
            Header: <div className="text-left"> {renderFormatMsg("OrderName", "Order Name")} </div>,
            accessor: 'orderName',
            width: 250,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("Brand", "Brand")} </div>,
            accessor: 'brand',
            minWidth: 100,
            maxWidth: 200,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("Date", "Date")} </div>,
            accessor: 'date',
            width: 100,
            //resizable: false,
            style: { justifyContent: "flex-start" },
            Cell: props => <div>{moment(props.original.date).format(userDetails.shortDateFormat)}</div>
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("Stores", "# Stores")} </div>,
            accessor: 'numberOfStores',
            width: 90,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left">{renderFormatMsg("SkuOrdered", "# SKU Ordered")} </div>,
            accessor: 'numberOfSKUOrdered',
            width: 120,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("SkuShipped", "# SKU Shipped")} </div>,
            accessor: 'numberOfSKUShipped',
            width: 120,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("Errors", "Errors")} </div>,
            accessor: 'errors',
            width: 60,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: renderFormatMsg("Details", "Details"),
            accessor: 'Details',
            //resizable: false,
            width: 100,
            Cell: (data) => <Link to={`/orders/sku/exports/${data.original.exportPackageId}`}
                className="edit-icon">
                {renderFormatMsg("Details", "Details")}
            </Link>
        }
    ]
    const fetchData = (state, instance) => {
        //getting change page number from state
        getData({ page: state.page, "display": PAGE_SIZE, brand: selectedBrand });
    }
    const handleBrandData = (data) => {
        setSelectedBrand(data)
        getData({ "brand": data });
    }
    return (
        <div className="main-content">
            <PageHeading headingId="replenishmentOrderOverview.Heading" isLoading={isLoading} >
                {!error && <div className="right-content select-box ml-auto">
                    <DropDownCustom name="name" classes={"p-2"} selectedData={(selectedBrand && selectedBrand.name) || "Brand"} dropdownData={brands} handleData={handleBrandData} />
                </div>}
            </PageHeading>
            <div className="card">
                {error ? <Error msg={error} /> : <ReactTable data={orderOverviews} columns={columns} onFetchData={fetchData} pages={totalPage} />}
            </div>
        </div>
    )
}
export default React.memo(ReplenishmentOrdersOverview);
