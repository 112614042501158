import React, { useState, useContext, useEffect } from 'react'
import { renderFormatMsg } from '../../shared/funs';
import "./style.scss"
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash';
import {
  // getPerformance, getOptions
} from '../../services/sidebarApi';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import moment from 'moment';
import Error from '../../components/Error/Error';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const Settings = (props) => {
  const { userDetails } = useContext(AppContext);
  const [option, setOption] = useState("")
  const [options, setOptions] = useState([])
  const [performance, setPerformance] = useState(null)
  const [error, setError] = useState(false)
  const [isLoading, setLoading] = useState(false)

  console.info('userDetails => ', userDetails);

  let dataArray = get(userDetails, "accesses");

  useEffect(() => {
    // setPageReadOnly(checkPermission(dataArray, findPermission(SETTINGS).screenId))

  }, [dataArray])



  useEffect(() => {
    console.info('props.match.params.optionId => ', props.match.params.optionId);
    getData(props.match.params.optionId)
    async function fetchData() {
      let res = await (new BaseService('/Options')).getAll('')
      setOptions(res.options)
    }
    fetchData();
    // getOptions().then(res => {
    //   setOptions(res.options)
    // }).catch(error => {
    //   console.log('error  => ', error);
    // })
  }, [props.match.params.optionId])

  const getData = async (option_id) => {
    setLoading(true)
    try {
      let res = await (new BaseService('/performance')).getAll('', { optionId: option_id })
      setLoading(false)
      setPerformance(res)
      console.info('getPerformance res => ', res);
    } catch (error) {
      setLoading(false)
      setError(true)
    }

    // getPerformance({ optionId: option_id }).then(res => {
    //   setLoading(false)
    //   setPerformance(res)
    //   console.info('getPerformance res => ', res);
    // }).catch(e => {
    //   console.info('e => ', e);
    //   setLoading(false)
    //   setError(true)
    // });
  }

  const handleOptionData = (option) => {
    setOption(option)
    getData(option.optionId)
    // console.info('handleOptionData called => ', option);
  }
  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <span>{renderFormatMsg("sidebar.ProductPerformance", "Product Performance")}</span>
        </div>
        <div className="right-content select-box ml-auto">
          <DropDownCustom selectedData={option && option.optionName ? option.optionName : "Select Option"} dropdownData={options} handleData={handleOptionData} name="optionName" />
        </div>
      </div> */}
      <PageHeading headingId="sidebar.ProductPerformance" isLoading={isLoading} >
        <div className="right-content select-box ml-auto">
          <DropDownCustom selectedData={option && option.optionName ? option.optionName : "Select Option"} dropdownData={options} handleData={handleOptionData} name="optionName" />
        </div>
      </PageHeading>
      <div className="card p-3">
        <div className="content">
          {performance ? <div className="form">
            <div className="row custom_row">
              <div className="col-lg-6 d-flex flex-column custom_column">
                <label>{renderFormatMsg("FirstSalesDate", "First sales date")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.firstSalesDate !== "undefined" ? moment(performance.firstSalesDate).format(userDetails.shortDateFormat) : "N/A"} />
              </div>
              <div className="col-lg-6 custom_column">
                <label>{renderFormatMsg("LastSalesDate", "Last sales date")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.lastSalesDate !== "undefined" ? moment(performance.lastSalesDate).format(userDetails.shortDateFormat) : "N/A"} />
              </div>
            </div>

            <div className="row custom_row">
              <div className="col-lg-6 d-flex flex-column custom_column">
                <label>{renderFormatMsg("WeeklySalesAvg", "Weekly sales [avg. of last 6 weeks]")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.weeklySales !== "undefined" ? (performance.weeklySales).toFixed(2) : "N/A"} />
              </div>
              <div className="col-lg-6 custom_column">
                <label>{renderFormatMsg("WeeklyAdjustedSalesAvgLast6Weeks", "Weekly adjusted sales [avg. of last 6 weeks]")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.weeklyAdjustedSales !== "undefined" ? performance.weeklyAdjustedSales : "N/A"} />
              </div>
            </div>

            <div className="row custom_row">
              <div className="col-lg-6 d-flex flex-column custom_column">
                <label>{renderFormatMsg("WarehouseStock", "Warehouse Stock")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.warehouseStock !== "undefined" ? performance.warehouseStock : "N/A"} />
              </div>
              <div className="col-lg-6 d-flex flex-column custom_column">
                <label>{renderFormatMsg("StoreStock", "Store Stock")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.storeStock !== "undefined" ? performance.storeStock : "N/A"} />
              </div>
            </div>

            <div className="row custom_row">
              <div className="col-lg-6 d-flex flex-column custom_column">
                <label>{renderFormatMsg("GoodsInTransit", "Goods in transit")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.goodsInTransit !== "undefined" ? performance.goodsInTransit : "N/A"} />
              </div>
            </div>

            <div className="row custom_row">
              <div className="col-lg-6 d-flex flex-column custom_column">
                <label>{renderFormatMsg("NoOfStoresWithAvailability", "No. of stores with availability")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.noOfStoresWithAvailability !== "undefined" ? performance.noOfStoresWithAvailability : "N/A"} />
              </div>
              <div className="col-lg-6 custom_column">
                <label>{renderFormatMsg("StockReachForward", "Stock reach forward")}</label>
                <input type="text" className="form-control read-only" readOnly value={performance && typeof performance.stockReachForward !== "undefined" ? (performance.stockReachForward) : "N/A"} />
              </div>
            </div>
          </div> : error && <Error />
          }
        </div>
      </div>
    </div >

  )
}
export default React.memo(Settings)
