import axios from "axios";
import { HOST_API } from "../constant";
import { getToken } from "../shared/funs";
import axiosInstance from "./axiosInstance";

export const userAuthentication = async data => {
  let bodyFormData = new URLSearchParams();
  bodyFormData.append("grant_type", "password");
  bodyFormData.append("username", data.username);
  bodyFormData.append("password", data.password);
  bodyFormData.append("client_id", "spa");

  return await axios
    .post(`${HOST_API}/connect/token`, bodyFormData)
    .then(response => {
      return response;
    })
    .catch(error => {
      return error;
    });
};

export const getUserDetails = async () => {
  if (getToken()) {
    return await axiosInstance
      .get(`${HOST_API}/api/v1/userdetails`)
      .then(response => {
        return response;
      })
      .catch(error => {
        return error;
      });
  } else {
    return "No data found";
  }
};
