import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import { renderFormatMsg, toastSuccess, toastError, permissionErrorCheck, checkPermission, findPermission } from '../../shared/funs';
import Error from "../../components/Error/Error";
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import {
    // getPromotionType, deletePromotionType
}
    from '../../services/toolsAndSettingsPagesApi';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';
import { AppContext } from '../../context/AppContext';
import { get } from 'lodash';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const PromotionType = () => {
    const [promotionType, setPromotionType] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [deletedId, setDeletedId] = useState(null)
    const [show, setShow] = useState(false);
    const { userDetails } = useContext(AppContext)
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

    let dataArray = get(userDetails, "accesses")

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("PROMOTION_TYPES").screenId))
    }, [dataArray])

    const handleClose = () => setShow(!show);

    const getData = async ({ page = 0, search = "" }) => {
        setSearchValue(search)
        setLoading(true)
        let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
        // getPromotionType(param).then(res => {
        //     setLoading(false)
        //     setPromotionType(res.promotionTypes.length > 0 ? res.promotionTypes : [])
        //     setTotalPage(res.totalPage)
        // }).catch(err => {
        //     setError(permissionErrorCheck(err))
        //     setLoading(false)
        //     setPromotionType([])
        //     // setError(err)
        // })
        async function fetchPromotionType() {
            try {
                let res = await (new BaseService('/PromotionTypes')).getAll('', param)
                console.info('res =>', res);

                setLoading(false)
                setPromotionType(res.promotionTypes.length > 0 ? res.promotionTypes : [])
                setTotalPage(res.totalPage)
            } catch (error) {
                setError(permissionErrorCheck(error))
                setLoading(false)
                setPromotionType([])

                console.info('error =>', error);

                // setError(error)
            }
        }
        fetchPromotionType();
    }
    const handleDelete = async () => {
        // await deletePromotionType("promotionTypeId", deletedId).then(async res => {
        //     await getData({ page: 0, search: "" })
        //     handleClose()
        //     toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
        // }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
        try {
            await (new BaseService('/PromotionTypes')).delete(deletedId)
            handleClose()
            await getData({ page: 0, search: "" })
            toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
        } catch (error) {
            toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail"))
        }
    }
    const columns = [
        {
            Header: <div className="text-left">{renderFormatMsg("PromotionTypeName", "Promotion Type Name")}</div>,
            accessor: 'name',
            width: 350,
            maxWidth: 450,
            //resizable: false,
            style: { justifyContent: "flex-start" },
        },
        {
            Header: <div className="text-left">{renderFormatMsg("BaseCoefficient", "Base Coefficient")}</div>,
            style: { justifyContent: "flex-start" },
            accessor: 'coefficient',
            width: 200,
            maxWidth: 200,
            //resizable: false,
        },
    ]

    if (pageReadOnly.write) {
        columns.push({
            Header: renderFormatMsg("Edit", "Edit"),
            accessor: 'Edit',
            //resizable: false,
            width: 110,
            Cell: props => <Link to={`/promotionType/Edit/${props.original.promotionTypeId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
        }, {
            Header: renderFormatMsg("Delete", "Delete"),
            accessor: 'Delete',
            //resizable: false,
            width: 110,
            Cell: props => <Link to="#" className="delete-icon" onClick={() => { handleClose(); setDeletedId(props.original.promotionTypeId) }} >{renderFormatMsg("Delete", "Delete")}
            </Link>
        })
    }
    const fetchData = (state, instance) => {
        //getting change page number from state

        getData({ page: state.page, search: searchValue })
        setPageIndex(state.page)
    }
    const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, search: e.target.value }) }
    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("PromotionTypes.Heading", "Promotion Types")}
            </div> */}
            <PageHeading headingId="PromotionTypes.Heading" isLoading={isLoading} />
            <div className="card">

                {error ? <Error msg={error} /> :
                    <>
                        <div className="card-header">
                            <SearchBox searchHandler={SearchHandler} />
                            {pageReadOnly.write && <LinkButton to="/promotionType/Add" id="AddPromotionType" defaultText="Add Promotion Type" />}
                        </div>
                        <ReactTable data={promotionType} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
                    </>
                }
            </div>
            <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
        </div>
    )
}
export default React.memo(PromotionType)
