import React from 'react'
import Calendar from '../Calendar'
import { Field } from 'react-final-form';
import { renderFormatMsg } from '../../shared/funs';
import FieldErrorMessage from '../FieldErrorMessage';

const ActiveFromAndToDate = ({ readOnly = false, className }) => {
  return (
    <div className="row active-from-to-date">
      <Field name="activeFrom">
        {({ input, meta }) => (
          <div className="frm_grp form-group date-wrapper">
            <label>{renderFormatMsg("ActiveFrom", "ActiveFrom")}</label>
            <Calendar disabled={readOnly} className={className} placeholderText="Active from" onSelect={input.onChange} name={input.name} selectedDate={input.value} />
            <FieldErrorMessage meta={meta} />
          </div>
        )}
      </Field>
      <Field name="activeTo">
        {({ input, meta }) => (
          <div className="frm_grp form-group date-wrapper">
            <label> {renderFormatMsg("ActiveTo", "activeTo")} </label>
            <Calendar disabled={readOnly} className={className} placeholderText="Active to" onSelect={input.onChange} name={input.name} selectedDate={input.value} />
            <FieldErrorMessage meta={meta} />
          </div>
        )}
      </Field>
    </div>
  )
}

export default ActiveFromAndToDate
