import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import {
  getBrand,
  // getOutletData, updateOutletData
} from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE, FIELD_ACCESS_FUNCTION } from '../../constant';
import Calendar from "../../components/Calendar"
import { AppContext } from "../../context/AppContext";
import {
  renderFormatMsg,
  // toastSuccess,
  toastError, checkPermission, updateActiveFromAndToRow, DateRangeStatus, permissionErrorCheck, findPermission
} from '../../shared/funs';
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import { get, debounce } from 'lodash';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const Outlet = () => {
  const { userDetails } = useContext(AppContext)
  const [outletData, setOutletData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  const [permissionForCategory2, setPermissionCategory2] = useState({ read: true, write: true })
  const [selectedBrand, setSelectedBrand] = useState({
    brandId: "all",
    name: "All Brands"
  })
  const [brands, setBrands] = useState([])

  let dataArray = get(userDetails, "accesses");
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("OUTLET").screenId))
    setPermissionCategory2(checkPermission(dataArray, FIELD_ACCESS_FUNCTION.OUTLET_FORM_CATEGORY_2))
  }, [dataArray])

  const getData = async (page = 0, search = "", brand = false) => {
    setSearchValue(search)
    setLoading(true)
    let param = { "page": page + 1, "display": PAGE_SIZE, "search": search, brandId: (brand !== false && brand.brandId !== "all") ? brand.brandId : "" }

    try {
      let res = await (new BaseService('/Outlets')).getAll('', param)
      setLoading(false)
      setOutletData(res.outlets.length > 0 ? res.outlets : [])
      setTotalPage(res.totalPage)
    } catch (error) {
      setLoading(false)
      setOutletData([])
      setError(permissionErrorCheck(error))
    }
    // getOutletData(param).then(res => {
    //   setLoading(false)
    //   setOutletData(res.outlets.length > 0 ? res.outlets : [])
    //   setTotalPage(res.totalPage)
    // }).catch(err => {
    //   setLoading(false)
    //   setOutletData([])
    //   setError(permissionErrorCheck(err))
    // })
  }

  useEffect(() => {
    // getBrand().then(res => {
    //   setBrands([{ brandId: "all", name: "All Brands" }, ...res.brands])
    // }).catch(error => console.log('error => ', error))
    async function fetchBrandData() {
      try {
        let res = await (new BaseService('/Brand')).getAll('')
        setBrands([{ brandId: "all", name: "All Brands" }, ...res.brands])
      } catch (error) {
        console.log('error => ', error)
      }
    }
    fetchBrandData();
  }, [])

  const sendQuery = async (object, data) => {
    // updateOutletData(object).then(res => {
    //   setOutletData(data.data)
    //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    // }).catch(error => {
    //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    // })

    try {
      let res = await (new BaseService('/Outlets')).update(object.outletId, data)
      setOutletData(res.data)
    } catch (error) {
      console.log('error => ', error)
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
    }
  };

  const delayedQuery = debounce((q, data) => sendQuery(q, data), 1000);

  const handleChangeDate = async (date, details, key) => {
    let object = {
      "outletId": details.outletId,
      "outletName": details.outletName,
      "city": details.city,
      "salesChannel": {
        "channelId": details.salesChannel.channelId,
        "name": details.salesChannel.name,
      },
      "payload": {
        "maxPiecesForReceiving": details.payload.maxPiecesForReceiving,
        "maxiumPiecesForSprintSummerSeason": details.payload.maxiumPiecesForSprintSummerSeason,
        "maximumCapacityForAutumnWinterSeason": details.payload.maximumCapacityForAutumnWinterSeason
      }
    }
    var data = updateActiveFromAndToRow(outletData, key, date, object);
    delayedQuery(data.payload, data)
  }

  const columns = [
    // {
    //   Header: <div className="text-left"> {renderFormatMsg("outletTable.OutletId", "Outlet Id")}</div>,
    //   accessor: 'customerOutletId',
    //   //resizable: false,
    //   width: 300,
    //   style: { justifyContent: "flex-start" }
    // },
    {
      Header: <div className="text-left"> {renderFormatMsg("outletTable.OutletName", "Outlet Name")}</div>,
      accessor: 'outletName',
      maxWidth: 200,
      width: 260,
      //resizable: false,
      style: { justifyContent: "flex-start" },
    },
    // {
    //   Header: <div className="text-left">
    //     {renderFormatMsg("outletTable.City", "City")}</div>,
    //   style: { justifyContent: "flex-start" },
    //   accessor: 'city',
    //   width: 130,
    //   //resizable: false,
    // },
    {
      Header: <div className="text-left">
        {renderFormatMsg("outletTable.Category", "Category")}</div>,
      accessor: 'outletCategory.name',
      //resizable: false,
      width: 120,
      style: { justifyContent: "center" },
    },
    {
      Header: <div className="text-left">
        {renderFormatMsg("outletTable.salesChannel.name", "Sales Channel Name")}</div>,
      accessor: 'salesChannel.name',
      //resizable: false,
      // maxWidth: 200,
      width: 250,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: renderFormatMsg("ActiveFrom", "Active From"),
      accessor: 'activeFrom',
      //resizable: false,
      width: 158,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar disabled={(pageReadOnly.write && permissionForCategory2.write) ? false : true} className={!(permissionForCategory2.write && pageReadOnly.write) && "read-only"} name="activeFrom" selectedDate={new Date(props.original.activeFrom)} maxDate={new Date(props.original.activeTo)} onSelect={data => handleChangeDate(data, props.original, "activeFrom")} /></div>
    }, {
      Header: renderFormatMsg("ActiveTo", "Active to"),
      accessor: 'activeTo',
      //resizable: false,
      width: 158,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar disabled={(pageReadOnly.write && permissionForCategory2.write) ? false : true} className={!(permissionForCategory2.write && pageReadOnly.write) && "read-only"} name="activeTo" selectedDate={new Date(props.original.activeTo)} minDate={new Date(props.original.activeFrom)} onSelect={data => handleChangeDate(data, props.original, "activeTo")} /></div>
    },
  ]

  const handleBrandData = (data) => {
    setSelectedBrand(data)
    getData(0, searchValue, data)
  }

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      //resizable: false,
      width: 100,
      Cell: props => <Link to={`/outlet/Edit/${props.original.outletId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    })
  }
  const fetchData = (state, instance) => {
    //getting change page number from state
    getData(state.page, searchValue)
    setPageIndex(state.page)
  }

  const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value, selectedBrand) }

  return (
    <div className="main-content outlet">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("outletTable.Heading", "Outlet")}
        {<div className="right-content select-box ml-auto">
          <DropDownCustom name="name" classes={"p-2"} selectedData={(selectedBrand && selectedBrand.name) || "Brand"} dropdownData={brands} handleData={handleBrandData} />
        </div>}
      </div> */}
      <PageHeading headingId="outletTable.Heading" isLoading={isLoading} >
        {<div className="right-content select-box ml-auto">
          <DropDownCustom name="name" classes={"p-2"} selectedData={(selectedBrand && selectedBrand.name) || "Brand"} dropdownData={brands} handleData={handleBrandData} />
        </div>}
      </PageHeading>

      <div className="card">

        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={SearchHandler} />
              {pageReadOnly.write && <LinkButton disabled={!pageReadOnly.write} to="/outlet/Add" id="outletTable.AddOutlet" defaultText="Add Outlet" />}
            </div>

            <ReactTable
              data={outletData}
              columns={columns}
              isLoading={isLoading}
              onFetchData={fetchData}
              pages={totalPage}
              page={pageIndex}
            />
          </>
        }
      </div>
    </div>
  )
}
export default React.memo(Outlet)
