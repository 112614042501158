import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

export default function ProductIdsToolTip() {
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 100, hide: 400 }}
      overlay={(props) => (
        <Tooltip id="button-tooltip" {...props}>
          <div>
            <span>
              The colors indicate the following status of the option:
                                </span>
            <ul style={{ textAlign: "start", paddingLeft: 25 }}>
              <li >
                <span style={{ color: "rgb(76, 175, 0)" }}>Green</span> : the option is valid and has an active lifecycle
                                  </li>
              <li >
                <span style={{ color: "rgb(255 152 0)" }}>Orange</span> : the option is valid but is not active
                                  </li>
              <li >
                <span style={{ color: "rgb(255 0 0)" }}>Red</span> : the option is not know to the system. Unknown options will not be saved
                                  </li>
            </ul>
          </div>
        </Tooltip>
      )}
    >
      <div variant="success" className="customtooltip"><i className="icon-question"></i></div>
    </OverlayTrigger>
  )
}
