
import React, { useState, useEffect, useContext } from 'react'
import { Link } from "react-router-dom"
import { get } from "lodash"
import { getClientRolesData, deleteUserRoles } from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import { AppContext } from "../../context/AppContext";
import { renderFormatMsg, checkPermission, toastSuccess, toastError, permissionErrorCheck, findPermission } from '../../shared/funs';
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';
import PageHeading from '../../components/PageHeading/PageHeading';
const UserRoles = () => {
  const { userDetails } = useContext(AppContext)
  const [data, setData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [error, setError] = useState(false)
  const [deletedId, setDeletedId] = useState(null)
  const [show, setShow] = useState(false);
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })
  const [isLoading, setLoading] = useState(false)

  const handleClose = () => setShow(!show);
  let dataArray = get(userDetails, "accesses")

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("USER_ROLES").screenId))
  }, [dataArray])

  const getData = (page = 0, search = "") => {
    setSearchValue(search)
    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
      "search": search
    }
    setLoading(true)
    getClientRolesData(param).then(res => {
      setData(res.clientRoleResponse.length > 0 ? res.clientRoleResponse : [])
      setTotalPage(res.totalPage)
      setLoading(false)
    }).catch(err => {
      setLoading(false)
      setError(permissionErrorCheck(err))
    })
  }

  const columns = [{
    Header: <div className="text-left">{renderFormatMsg("rolesTable.Name", "Name")}</div>,
    accessor: 'name',
    //resizable: false,
    width: 150,
    maxWidth: 150,
    style: { justifyContent: "flex-start" }
  }, {
    Header: <div className="text-left"> {renderFormatMsg("rolesTable.Description", "Description")}</div>,
    accessor: 'description',
    maxWidth: 400,
    minWidth: 300,
    style: { justifyContent: "flex-start" }
  },]
  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      //resizable: false,
      maxWidth: 100,
      Cell: props => <Link to={`/user-roles/Edit/${props.original.roleId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    },
      {
        Header: renderFormatMsg("Delete", "Delete"),
        accessor: 'Delete',
        //resizable: false,
        maxWidth: 100,
        // width: 100,
        Cell: props => <Link to="#" className="delete-icon" onClick={() => { handleClose(); setDeletedId(props.original.roleId) }}>{renderFormatMsg("Delete", "Delete")}</Link>
      })
  }
  const fetchData = (state, instance) => {
    //getting change page number from state
    getData(state.page, searchValue)
    setPageIndex(state.page)
  }
  const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }
  const handleDelete = async () => {
    await deleteUserRoles(deletedId).then(res => {
      getData()
      handleClose()
      toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
    }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
  }
  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("rolesTable.Heading", "Roles")}
      </div> */}
      <PageHeading headingId="rolesTable.Heading" isLoading={isLoading} />
      <div className="card">

        {error ? <Error msg={error} /> :
          <>
            <div className="card-header">
              <SearchBox searchHandler={SearchHandler} />
              {pageReadOnly.write && <LinkButton classes={"ml-auto"} to="/user-roles/Add" id="rolesTable.AddRoles" defaultText="Add Roles" />}
            </div>
            <ReactTable data={data} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
          </>
        }
      </div>
      <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
    </div>
  )
}
export default React.memo(UserRoles)
