import { sortBy } from "lodash";
import { toastError } from "../shared/funs";
import { get, put } from "./index";

export const getAllNotifications = async (params) => {
  return await get('api/v1/Notifications', params);
};


export const addUpdateUserNotification = async (id) => {
  return await put('api/v1/Notifications/UpdateUserNotification', {
    notificationId: id,
    status: false,
  });
};

export const getHasUpdateNotification = async (id) => {
  return await get('api/v1/Notifications/HasUpdate');
};

export const updateSeverityStatus = async (status, notificationTypeId) => {
  return await put('api/v1/Notifications/UpdateStatus', { notificationId: notificationTypeId, status: false });
};



export const getAllNotificationType = async () => {
  return get('api/v1/Notifications/NotificationType')
    .then((response) => {
      let data = sortBy(response.notificationSettings, 'severity');
      return data
    })
    .catch((error) => {
      toastError(error.statusText)
      return error;
    });
};

export const blockedNotification = async (notificationTypeId) => {
  return await put(`Notifications/BlockNotification/${notificationTypeId}`);
};
