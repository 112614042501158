import React, { useState, useEffect } from 'react'
// import { Link } from "react-router-dom"
import { get } from "lodash"
// import { getUserData, addNewUser, updateUser } from '../../../services/toolsAndSettingsPagesApi';
import moment from 'moment';
import { PAGE_SIZE, DATE_FORMAT } from '../../../constant';
import { renderFormatMsg, toastSuccess, toastError } from '../../../shared/funs';
// import BackIcon from "../../../images/back-icon.svg";
import {
  // Field,
  Form
} from 'react-final-form';
import ActiveFromAndToDate from '../../../components/ActiveFromAndToDate/ActiveFromAndToDate';
import Error from '../../../components/Error/Error';
// import FieldErrorMessage from '../../../components/FieldErrorMessage';
import InputField from '../../../components/Fields/InputField';
import PageHeading from '../../../components/PageHeading/PageHeading';
import { BaseService } from '../../../services/BaseService';


const HandleUser = (props) => {
  const [isLoading, setLoading] = useState(false)
  const [user, setUser] = useState(null)
  const [error, setError] = useState(false)

  let heading = "AddNewUser"
  var values = {
    userName: "",
    firstName: "",
    lastName: "",
    email: "",
    mobileNumber: "",
    activeFrom: "",
    activeTo: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "EditUser"
    values = {
      userName: get(user, "userName"),
      name: get(user, "name"),
      firstName: get(user, "firstName"),
      lastName: get(user, "lastName"),
      email: get(user, "email"),
      mobileNumber: get(user, "mobileNumber"),
      activeFrom: get(user, "activeFrom"),
      activeTo: get(user, "activeTo")
    }
  }


  useEffect(() => {
    if (props.location.pathname.includes("Edit")) {
      let param = { "page": 1, "display": PAGE_SIZE, "search": props.match.params.userId }
      // getUserData(param).then(res => {
      //   setUser(res.users[0])
      //   setLoading(false)
      // }).catch(err => {
      //   setLoading(false)
      //   setError(err)
      // })

      async function fetchUserData() {
        try {
          let res = await (new BaseService('/Users')).getAll('', param)
          setUser(res.users[0])
          setLoading(false)
        } catch (error) {
          setLoading(false)
          setError(error)
        }
      }
      fetchUserData();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])


  const validation = (data) => {
    let errors = {}

    if (!data.userName || data.userName === "") {
      errors.userName = "Required";
    }
    if (!data.firstName || data.firstName === "") {
      errors.firstName = "Required";
    }
    if (!data.lastName || data.lastName === "") {
      errors.lastName = "Required";
    }
    if (!data.email || data.email === "") {
      errors.email = "Required";
    }
    if (!data.mobileNumber || data.mobileNumber === "") {
      errors.mobileNumber = "Required";
    }

    if (!data.activeFrom || data.activeFrom === "") {
      errors.activeFrom = "Required";
    }
    if (!data.activeTo || data.activeTo === "") {
      errors.activeTo = "Required";
    }
    return errors
  }

  const handleForm = async (data) => {

    let payload = {
      "userName": get(data, "userName"),
      "firstName": get(data, "firstName"),
      "lastName": get(data, "lastName"),
      "email": get(data, "email"),
      "mobileNumber": get(data, "mobileNumber"),
      "activeFrom": moment(get(data, "activeFrom")).format(DATE_FORMAT),
      "activeTo": moment(get(data, "activeTo")).format(DATE_FORMAT)
    }
    if (props.location.pathname.includes("Add")) {
      try {
        (new BaseService('/Users')).create(payload)
        toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
        props.history.push("/user")
      } catch (error) {
        toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail"))
      }
      // addNewUser(payload).then(resp => {
      //   toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      //   props.history.push("/user")
      // }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
    } else {
      payload.userId = props.match.params.userId
      try {
        (new BaseService('/Users')).update(props.match.params.userId, payload)
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        props.history.push("/user")
      } catch (error) {
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      }
      // updateUser(payload).then(resp => {
      //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      //   props.history.push("/user")
      // }).catch(err => { toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")) })
    }
  }
  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        <div className="heading">
          <Link className="back-btn" to="/user"><img src={BackIcon} alt="BackIcon" /></Link>
          <span>{heading}</span>
        </div>
      </div> */}
      <PageHeading backURL="/user" headingId={heading} isLoading={isLoading} />
      <div className="card p-3">
        {error ? <Error msg={error} /> : <Form onSubmit={handleForm} initialValues={values} validate={validation}>
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="userName">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("UserName", "User Name")}</label>
                            <input {...input} type="text" placeholder="User Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}
                    <InputField
                      colClass="col-lg-6"
                      name={"userName"}
                      type="text"
                      id="UserName"
                      defaultLabel="User Name"
                      placeholder={"User Name"}
                    />
                    <div className="col-lg-6"> </div>
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="firstName">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("FirstName", "First Name")}</label>
                            <input {...input} type="text" placeholder="First Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}

                    <InputField
                      colClass="col-lg-6"
                      name={"firstName"}
                      type="text"
                      id="FirstName"
                      defaultLabel="First Name"
                      placeholder={"First Name"}
                    />


                    {/* <div className="col-lg-6">
                      <Field name="lastName">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>
                              {renderFormatMsg("LastName", "Last Name")}
                            </label>
                            <input {...input} type="text" placeholder="Last Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}

                    <InputField
                      colClass="col-lg-6"
                      name={"lastName"}
                      type="text"
                      id="LastName"
                      defaultLabel="Last Name"
                      placeholder={"Last Name"}
                    />
                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="email">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>
                              {renderFormatMsg("Email", "Email")}
                            </label>
                            <input {...input} type="text" placeholder="Email" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}

                    <InputField
                      colClass="col-lg-6"
                      name={"email"}
                      type="text"
                      id="Email"
                      defaultLabel="Email"
                      placeholder={"Email"}
                    />


                    {/* <div className="col-lg-6">
                      <Field name="mobileNumber">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label>{renderFormatMsg("MobilePhone", "Mobile phone")}</label>
                            <input {...input} type="text" placeholder="Mobile phone" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}


                    <InputField
                      colClass="col-lg-6"
                      name={"mobileNumber"}
                      type="text"
                      id="MobilePhone"
                      defaultLabel="Mobile phone"
                      placeholder={"Mobile phone"}
                    />


                    <div className="col-lg-6"></div>
                  </div>
                </div>
                <ActiveFromAndToDate />
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit")
                      ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>}
      </div>
    </div>
  )
}
export default React.memo(HandleUser)
