import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE, DATE_FORMAT } from '../../constant';
import Calendar from "../../components/Calendar";
import { renderFormatMsg, toastSuccess, toastError, DateRangeStatus, permissionErrorCheck, checkPermission, findPermission } from '../../shared/funs';
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
// import { getPromotion, updatePromotion, deletePromotion } from '../../services/sidebarApi';
import moment from 'moment';
import { get } from 'lodash';
import DeleteConfirmationBox from '../../components/DeleteConfirmationBox/DeleteConfirmationBox';
import { AppContext } from '../../context/AppContext';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const Promotion = () => {
  const [promotion, setOpromotion] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [deletedId, setDeletedId] = useState(null)
  const [show, setShow] = useState(false);

  const { userDetails } = useContext(AppContext);
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false });
  let dataArray = get(userDetails, "accesses");

  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("PROMOTION").screenId))
  }, [dataArray])

  const handleClose = () => setShow(!show);
  const getData = async ({ page = 0, search = "" }) => {
    setSearchValue(search)
    setLoading(true)
    let param = { "page": page + 1, "display": PAGE_SIZE, "search": search }
    try {
      var res = await (new BaseService('/Promotions')).getAll(``, param)
      setLoading(false)
      setOpromotion(res.promotions.length > 0 ? res.promotions : [])
      setTotalPage(res.totalPage)
    } catch (error) {
      console.info('err => ', error.status);
      setError(permissionErrorCheck(error))
      setLoading(false)
      setOpromotion([])
    }

    // getPromotion(param).then(res => {
    //   setLoading(false)
    //   setOpromotion(res.promotions.length > 0 ? res.promotions : [])
    //   setTotalPage(res.totalPage)
    // }).catch(err => {
    //   console.info('err => ', err.status);
    //   setError(permissionErrorCheck(err))
    //   setLoading(false)
    //   setOpromotion([])
    // })
  }
  const handleChangeDate = async (date, details, key) => {
    details[key] = date
    setLoading(true)
    let payload = {
      "promotionId": details.promotionId,
      "promotionTypeId": details.promotionType.promotionTypeId,
      "name": details.name,
      "initialCoefficient": details.initialCoefficient,
      "coefficient": details.coefficient,
      "scope": details.scope,
      "activeFrom": moment(details.activeFrom).format(DATE_FORMAT),
      "activeTo": moment(details.activeTo).format(DATE_FORMAT)
    }

    if (get(details, "activeFrom", false) && get(details, "activeTo", false)) {
      // await updatePromotion(payload).then(res => {
      //   toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      //   setLoading(false)
      // }).catch(error => {
      //   toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      // })
      try {
        await (new BaseService('/Promotions')).update(payload.promotionId, payload)
        toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        setLoading(false)
      } catch (error) {
        console.log('error =>', error);
        toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      }
    }
  }
  const handleDelete = async () => {
    // await deletePromotion(deletedId).then(async res => {
    //   await getData({ page: 0, search: "" })
    //   handleClose()
    //   toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
    // }).catch(err => { console.log('err => ', err); toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail")) })
    try {
      await (new BaseService('/Promotions')).delete(deletedId)
      getData({ page: 0, search: "" })
      handleClose()
      toastSuccess(renderFormatMsg("DELETE_SUCCESS_MSG", "Record deleted succesfully"))
    } catch (error) {
      toastError(renderFormatMsg("DELETE_FAILED_MSG", "Record deleted fail"))
    }
  }

  const columns = [
    {
      Header: <div className="text-left">{renderFormatMsg("Name", "Name")}</div>,
      accessor: 'name',
      minWidth: 70,
      maxWidth: 200,
      //resizable: false,
      style: { justifyContent: "flex-start" },
    }, {
      Header: <div className="text-left">{renderFormatMsg("Type", "Type")}</div>,
      style: { justifyContent: "flex-start" },
      accessor: 'promotionType.name',
      width: 150,
      //resizable: false,
    }, {
      Header: <div className="text-left">{renderFormatMsg("Scope", "Scope")}</div>,
      accessor: 'scope',
      //resizable: false,
      width: 150,
      maxWidth: 200,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("InitialCoeff", "Initial Coeff.")}</div>,
      accessor: 'initialCoefficient',
      //resizable: false,
      width: 120,
      maxWidth: 200,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: <div className="text-left">{renderFormatMsg("CurrentCoeff", "Current Coeff.")}</div>,
      accessor: 'coefficient',
      //resizable: false,
      width: 120,
      maxWidth: 200,
      style: { justifyContent: "flex-start" },
    },
    {
      Header: renderFormatMsg("ActiveFrom", "Active From"),
      accessor: 'activeFrom',
      //resizable: false,
      width: 158,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar
          name="activeFrom"
          className={`${!pageReadOnly.write ? "read-only" : ""}`}
          disabled={!pageReadOnly.write}
          selectedDate={new Date(props.original.activeFrom)}
          maxDate={new Date(props.original.activeTo)}
          onSelect={data => handleChangeDate(data, props.original, "activeFrom")} />
      </div>
    }, {
      Header: renderFormatMsg("ActiveTo", "Active to"),
      accessor: 'activeTo',
      //resizable: false,
      width: 158,
      Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
        <Calendar name="activeTo"
          className={`${!pageReadOnly.write ? "read-only" : ""}`}
          disabled={!pageReadOnly.write}
          selectedDate={new Date(props.original.activeTo)}
          minDate={new Date(props.original.activeFrom)}
          onSelect={data => handleChangeDate(data, props.original, "activeTo")} />
      </div>
    },
  ]

  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      //resizable: false,
      width: 70,
      Cell: props => <Link to={`/promotion/Edit/${props.original.promotionId}`} className={`edit-icon ${!"disabled-text"}`}>{renderFormatMsg("Edit", "Edit")}</Link>
    }, {
      Header: renderFormatMsg("Delete", "Delete"),
      accessor: 'Delete',
      //resizable: false,
      width: 80,
      Cell: props => <Link to="#" onClick={() => { handleClose(); setDeletedId(props.original.promotionId) }} className={`delete-icon ${!pageReadOnly.write && " disabled-text"}`} >{renderFormatMsg("Delete", "Delete")}
      </Link >
    })
  }
  const fetchData = (state, instance) => {
    //getting change page number from state
    getData({ page: state.page, search: searchValue })
    setPageIndex(state.page)
  }
  const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, search: e.target.value }) }
  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>{renderFormatMsg("Promotion", "Promotion")}</div> */}
      <PageHeading headingId="Promotion" isLoading={isLoading} />
      <div className="card">

        {error ? <Error msg={error} /> : <>
          <div className="card-header">
            <SearchBox searchHandler={SearchHandler} />
            {pageReadOnly.write && <LinkButton to={`/promotion/Add`} disabled={!pageReadOnly.write} id="AddPromotion" defaultText="Add Promotion" />}
          </div>
          <ReactTable data={promotion} columns={columns} isLoading={isLoading} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
        </>}
      </div>
      <DeleteConfirmationBox show={show} handleClose={handleClose} classes="" handleDelete={handleDelete} />
    </div>
  )
}
export default React.memo(Promotion)
