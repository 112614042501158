import React from "react";
import { ToastContainer, Zoom } from "react-toastify";
import { withRouter } from "react-router-dom";
import 'react-toastify/dist/ReactToastify.css';
import "./Header.scss";
import { renderFormatMsg } from "./../../../shared/funs"
import UserDetail from "../../UserDetail";
import NotificationDetail from "../../NotificationDetail";
import ToolsAndSettingDetail from "../../ToolsAndSettingDetail";


/**
 * Header component
 * @param {object} props
 * @returns
 */
const Header = (props) => {

  return (
    <header>
      <h1>
        {renderFormatMsg("header.Header", "Header")}
      </h1>
      <div className="reports-list">
        <ToolsAndSettingDetail />

      </div>
      <div className="user-list">
        <NotificationDetail />
        <UserDetail />
      </div>
      <ToastContainer
        limit={1}
        position="bottom-right"
        transition={Zoom}
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />
    </header>
  );
};
export default React.memo(withRouter(Header));
