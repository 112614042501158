import React from 'react'
import { renderFormatMsg, selectStyle } from '../../shared/funs';
import { LANGUAGES, SHORT_DATE_FORMAT_OPTIONS, LONG_DATE_FORMAT_OPTIONS, COUNTRY_LIST } from '../../constant';
import { Field } from 'react-final-form';
import { get } from 'lodash';
import { FormattedMessage } from 'react-intl';
import FieldErrorMessage from '../FieldErrorMessage';
import InputField from '../Fields/InputField';

const CompanyInformation = ({ values, form }) => {
    return (
        <>
            {/* <div className="col-12 col-lg-6">
                <Field name="clientName">
                    {({ input, meta }) => (
                        <div className="form-group">
                            <label> {renderFormatMsg("clientPageTable.ClientName", "Client Name")} </label>
                            <input {...input} type="text" placeholder="Client Name" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                        </div>
                    )}
                </Field>
            </div> */}
            <InputField
                colClass="col-12 col-lg-6"
                name={"clientName"}
                type="text"
                id="clientPageTable.ClientName"
                defaultLabel="Client Name"
                placeholder="Client Name"
            />
            <div className="col-12 col-lg-6">
                <Field name="country" component="select" options={COUNTRY_LIST}>
                    {({ input, meta, options }) =>
                        <div className="form-group">
                            <label> {renderFormatMsg("Country", "Country")}</label>
                            <select name="interaction" style={selectStyle(input)} className="form-control" onChange={(value) => input.onChange(value)}>
                                <option value={0}>Select Country</option>
                                {options && options.map(opt =>
                                    <FormattedMessage id={opt.id} defaultMessage={opt.name} key={opt.id}>
                                        {(message) => <option selected={input.value === opt.name} value={opt.name}>{message}</option>}
                                    </FormattedMessage>
                                )}</select>
                            <FieldErrorMessage meta={meta} />
                        </div>
                    }
                </Field>
            </div>
            <div className="col-12 col-lg-6">
                <Field name="language" component="select" options={LANGUAGES}>
                    {({ input, meta, options }) =>
                        <div className="form-group">
                            <label> {renderFormatMsg("Language", "Language")}</label>
                            <select className="form-control" style={selectStyle(input)}
                                name={get(options, "name", "")}
                                onChange={(value) => input.onChange(value)}>
                                <option value={0}>Select Language</option>
                                {options && options.map((x, i) => {
                                    return (
                                        <option value={x.value} selected={input.value === x.value}>{x.label}</option>
                                    )
                                })}
                            </select>
                            <FieldErrorMessage meta={meta} />
                        </div>
                    }
                </Field>
            </div>
            <div className="col-12 col-lg-6"></div>
            <div className="col-12 col-lg-6">
                <Field name="shortDateFormat" component="select" options={SHORT_DATE_FORMAT_OPTIONS}>
                    {({ input, meta, options }) =>
                        <div className="form-group">
                            <label>{renderFormatMsg("ShortDateFormat", "Short Date Format")}</label>
                            <select className="form-control" style={selectStyle(input)}
                                name={get(options, "name", "")}
                                onChange={(value) => input.onChange(value)}>
                                <option value={0}>Select Short Date Format</option>
                                {options && options.map((x, i) => {
                                    return (
                                        <option key={i} selected={input.value === x.value} value={x.value}>{x.label}</option>
                                    )
                                })}
                            </select>
                            <FieldErrorMessage meta={meta} />
                        </div>
                    }
                </Field>
            </div>
            <div className="col-12 col-lg-6">
                <Field name="longDateFormat" component="select" options={LONG_DATE_FORMAT_OPTIONS}>
                    {({ input, meta, options }) =>
                        <div className="form-group">
                            <label>{renderFormatMsg("LongDateFormat", "Long Date Format")}</label>
                            <select className="form-control" style={selectStyle(input)}
                                name={get(options, "name", "")}
                                onChange={(value) => input.onChange(value)}>
                                <option value={0}>Select Long Date Format</option>
                                {options && options.map((x, i) => {
                                    return (
                                        <option key={i} selected={input.value === x.value} value={x.value}>{x.label} </option>
                                    )
                                })}
                            </select>
                            <FieldErrorMessage meta={meta} />
                        </div>
                    }
                </Field>
            </div>
        </>
    )
}

export default React.memo(CompanyInformation)
