import React from "react";
import Sidebar from "../../Sidebar/Sidebar";
import Header from "./Header/Header";
import { withRouter } from "react-router-dom";
import { isAuth } from "../../shared/funs";
class Layout extends React.PureComponent {
  state = {
    open: false,
    isLogin: true
  };

  handleopen = value => {
    this.setState({ open: value });
  };
  render() {
    return (
      <div className={this.state.open ? "main open" : "main"}>
        <Sidebar open={this.state.open} handleopen={this.handleopen} />
        <div className="layout">
          <Header />
          {this.props.children}
        </div>
      </div>
    );
  }
  componentDidUpdate() {
    // uncomment for login
    this.redirectUrl()
  }

  /**
   * Redirect URL to login if not authorized
   */
  redirectUrl = () => {
    if (!isAuth()) {
      var redirect = `${window.location.pathname}${window.location.search}`
      this.props.history.push(`/login?redirect=${redirect}`);
    }
  }
  componentDidMount() {
    document.body.classList.add("body-main");
    // uncomment for login
    this.redirectUrl()
  }
}

export default withRouter(Layout);
