import React from "react";
import { NavLink } from "react-router-dom";
import { Scrollbars } from "react-custom-scrollbars";
import { Navbar } from "react-bootstrap";
import LogoIcon from "../images/lab.svg";
import { withRouter } from "react-router-dom";
import "./Sidebar.scss";
import { renderFormatMsg } from "../shared/funs";

const Sidebar = (props) => {
  // console.info("props =>", props);

  const { open, handleopen } = props;
  // const { userDetails } = useContext(AppContext);
  // let dataArray = get(userDetails, "accesses");

  const CheckMenuPermission = (key) => {
    // let permission = false
    // let accessDetail = find(dataArray, (o) => SIDEBAR_MENU_ACCESS_FUNCTION[key] === o.function)
    // if (accessDetail && accessDetail.accessLevel === PERMISSION_LEVEL_READ_WRITE) {
    //   permission = true;

    // } else if (accessDetail && accessDetail.accessLevel === PERMISSION_LEVEL_READ_ONLY) {
    //   permission = false;
    // }
    return true;
  };

  const MENU_LIST = [
    {
      to: "/overview",
      id: "sidebar.overview",
      default: "Overview",
      clasNames: "icon-overview",
      isVisible: CheckMenuPermission("OVERVIEW"),
    },
    {
      to: "/why",
      id: "sidebar.why",
      default: "Why",
      clasNames: "icon-settings",
      isVisible: true,
    },
    {
      to: "/optionDistribution",
      id: "sidebar.Products",
      default: "Products",
      clasNames: "icon-planning",
      isVisible: CheckMenuPermission("PRODUCTS"),
    },
    // {
    //   to: "/recommendations",
    //   id: "sidebar.recommendations",
    //   default: "Recommendations",
    //   clasNames: "icon-overview",
    //   isVisible: CheckMenuPermission("RECOMMENDATIONS")
    // },
    {
      to: "/orders",
      id: "sidebar.Orders",
      default: "Orders",
      clasNames: "icon-compaigns",
      isVisible: CheckMenuPermission("REPLENISHMENT_ORDER_OVERVIEW"),
    },
    {
      to: "/specialReplenishment",
      id: "sidebar.special",
      default: "Special Replenishment",
      clasNames: "icon-add-extensions",
      isVisible: CheckMenuPermission("SPECIAL_REPLENISHMENT"),
    },
    {
      to: "/promotion",
      id: "sidebar.promotion",
      default: "Promotion",
      clasNames: "icon-keyword",
      isVisible: CheckMenuPermission("PROMOTION"),
    },
    {
      to: "/manualOrder",
      id: "sidebar.ManualOrder",
      default: "Manual Order",
      clasNames: "icon-recommendations",
      isVisible: CheckMenuPermission("MANUAL_ORDER"),
    },
    {
      to: "/settings",
      id: "sidebar.Settings",
      default: "Settings",
      clasNames: "icon-settings",
      isVisible: CheckMenuPermission("SETTINGS"),
    },
    // {
    //   to: "/product-performance",
    //   id: "sidebar.ProductPerformance",
    //   default: "Product Performance",
    //   clasNames: "icon-settings",
    //   isVisible: true
    //   // isVisible: CheckMenuPermission("PRODUCT_PERFORMANCE")
    // },
  ];

  if (props.location.search === "?test=1") {
    MENU_LIST.push({
      to: "/client-graphql?test=1",
      id: "sidebar.TestScreen",
      default: "Test Screen",
      clasNames: "icon-settings",
      isVisible: CheckMenuPermission("TESTSCREEN"),
    });
  }

  return (
    <Navbar expand="true" className="sliderbar">
      <Navbar.Toggle
        aria-controls="basic-navbar-nav"
        onClick={() => handleopen(!open)}
      />
      <NavLink className="nav-link" to="/">
        <Navbar.Brand href="#">
          <img src={LogoIcon} alt="LogoIcon" />
          <h3> {renderFormatMsg("sidebar.Heading", "Commercial lab")} </h3>
        </Navbar.Brand>
      </NavLink>
      <Navbar.Collapse id="basic-navbar-nav">
        <Scrollbars
          autoHide
          // style={{ width: "100%" }}
        >
          <ul>
            {MENU_LIST.map(
              (menu, index) =>
                menu.isVisible && (
                  <li key={index}>
                    <NavLink className="nav-link" to={menu.to}>
                      <i className={menu.clasNames}></i>
                      <span> {renderFormatMsg(menu.id, menu.default)} </span>
                    </NavLink>
                  </li>
                )
            )}
          </ul>
        </Scrollbars>
      </Navbar.Collapse>
    </Navbar>
  );
};

export default React.memo(withRouter(Sidebar));
