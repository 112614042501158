import { get, put, post, deleted } from "./index";

// Repl3nishment page APIs
export const getspecialReplenishment = async (params) => {
  return await get("api/v1/Replenishment/specialReplenishment", params);
};

export const updatespecialReplenishment = async (data) => {
  return await put("api/v1/Replenishment/UpdateSpecialReplenishment", data);
};

export const getDashboadData = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=discSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForSaleUnit = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=unitSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForSalePrice = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=priceSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForRealizedMargin = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

export const getDashboadDataForSellOut = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForRealOOS = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealStockout10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForSpeedOfSales = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=TurnoverSpeed10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForCoverage = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForAbsoluteOOS = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=RealizedMarginLose10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};
export const getDashboadDataForDiscountedProducts = async ({
  dateRange = "week",
  brandId = null,
}) => {
  var URL = `api/v1/KPI?kpi=DiscountedSales10D&dateRange=${dateRange}`;
  if (brandId) {
    URL += `&brandId=${brandId}`;
  }
  return await get(URL);
};

// Replanihsmnet Order overview API
export const getReplenishmentOrder = async (params) => {
  return await get(`api/v1/Replenishment/orderOverview`, params);
};
export const getReplenishmentOrderSku = async (params) => {
  return await get(`api/v1/Replenishment/orderSKUs`, params);
};
export const getReplenishmentOrderDetail = async (params) => {
  return await get(`api/v1/Replenishment/OrderDetails`, params);
};

export const getReplenishmentQuantities = async (params) => {
  return await get(`api/v1/Replenishment/OrderQuantity`, params);
};

export const getPerformance = async (params) => {
  return await get(`api/v1/performance`, params);
};

//ProductDistribution page APIs
export const getOptions = async (params) => {
  return await get(`api/v1/Options`, params);
};
export const getProductDistributionOverview = async (params) => {
  return await get(`api/v1/distribution`, params);
};

export const getSKU = async (params) => {
  return await get(`api/v1/SKUs`, params);
};

export const submitMatrixQuestion = async (params) => {
  return await get(`api/v1/Matrix`, params);
};

export const submitNoReplenishmentQuestion = async (params) => {
  return await get(`api/v1/Replenishment/orderSKUDetails`, params);
};

export const getProductDistributionDetail = async (params) => {
  return await get(`api/v1/DistributionDetail`, params);
};

export const updateProductDistributionDetail = async (updatingData) => {
  return await put(`api/v1/UpdateOutletOptionsBlock`, updatingData);
};

export const updateOptions = async (addData) => {
  return await put(`api/v1/Options/${addData.optionId}`, addData);
};
export const getOptionCategory = async (id) => {
  return await get(`api/v1/OptionCategories?categoryId=${id}`);
};
//Manual order page API
export const getManualOrderDtail = async (params) => {
  return await get(`api/v1/Orders`, params);
};

export const addManualOrder = async (params) => {
  return await post(`api/v1/Orders/`, params);
};
export const updateManualOrder = async (params) => {
  return await put(`api/v1/Orders/`, params);
};

export const deleteManualOrder = async (key, id) => {
  return await deleted(`api/v1/Orders?${key}=${id}`);
};

//Promotion API
export const getPromotion = async (params) => {
  return await get(`api/v1/promotions`, params);
};
export const getPromotionById = async (params) => {
  return await get(`api/v1/Promotion`, params);
};
export const updatePromotion = async (params) => {
  return await put(`api/v1/Promotions`, params);
};
export const addPromotion = async (params) => {
  return await post(`api/v1/Promotions`, params);
};
export const deletePromotion = async (id) => {
  return await deleted(`api/v1/Promotions/${id}`);
};
