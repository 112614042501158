import React from 'react'
import { renderFormatMsg } from '../../shared/funs';
import classes from './style.module.scss';
/**
 *
 * @param {string} msg message to display for error
 * @returns
 */
const Error = ({ msg }) => {
  return (
    <div className={classes.errorPage}>
      <div className={classes.imgWrap}><img src={require('../../images/lab.svg')} alt="test" /></div>
      <div className={classes.error_text}>
        {msg ? <p dangerouslySetInnerHTML={{ __html: msg }} /> :
          <p>
            <div>
              <p>{renderFormatMsg("ErrorLine1", "An unexpected error occurred. The information you have requested cannot be shown. We have automatically generated a ticket to our support staff.")}</p>
              <p>{renderFormatMsg("ErrorLine2", "They will take care of the error. Once the error has been resolved, you will be sent a notification.")}</p>
              <p>{renderFormatMsg("ErrorLine3", "We apologise for the inconvenience.")}</p>

            </div>
          </p>
        }
      </div>
    </div>
  )
}

export default Error
