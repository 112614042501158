import React from "react";
import { Modal, Button } from "react-bootstrap";
import { renderFormatMsg } from "../../shared/funs";
/**
 * Delete confirm box component
 */
const DeleteConfirmationBox = ({ show, handleClose, handleDelete, classes = "" }) => {
  return (
    <>
      <Modal show={show} onHide={handleClose} animation={false} className={`modal_new_seo ${classes}`}>
        <Modal.Header closeButton>
          <Modal.Title>{renderFormatMsg("Warning", "Warning")}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{renderFormatMsg("DELETE_CONFIRMATION_MSG", "Do you really want to delete this record ?")}</Modal.Body>
        <Modal.Footer>
          <Button className="btn_grey" onClick={handleDelete}>{renderFormatMsg("Ok", "Ok")}</Button> <Button className="btn_white" onClick={handleClose}>{renderFormatMsg("Cancel", "Cancel")}
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default DeleteConfirmationBox
