import React, { useState } from 'react'
import { Link } from "react-router-dom";
// import { getReplenishmentOrderDetail } from '../../services/sidebarApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import Error from "../../components/Error/Error"
import { renderFormatMsg } from '../../shared/funs';
// import BackIcon from "../../images/back-icon.svg";
import SearchBox from '../../components/SearchBox/SearchBox';
import { get } from 'lodash';
import DropDownCustom from '../../components/DropDowns/DropDownCustom';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const ReplenishmentOrderDetail = (props) => {
    const [orderDetail, setOrderDetail] = useState([])
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [error, setError] = useState(false)
    const [isLoading, setLoading] = useState(false)

    const getData = async ({ page = 0, search = "" }) => {
        let param = { "page": page + 1, "display": PAGE_SIZE, "exportPackageId": props.match.params.exportPackageId, "optionId": props.match.params.optionId, search: search }
        setLoading(true)

        try {
            let res = await (new BaseService('/Replenishment/OrderDetails')).getAll('', param)
            setLoading(false)
            setOrderDetail(res.replenishmentOrderDetailsRes.length > 0 ? res.replenishmentOrderDetailsRes : [])
            setTotalPage(res.totalPage)
        } catch (error) {
            setError(error)
            setLoading(false)
        }

        // getReplenishmentOrderDetail(param).then(res => {
        //     setLoading(false)
        //     setOrderDetail(res.replenishmentOrderDetailsRes.length > 0 ? res.replenishmentOrderDetailsRes : [])
        //     setTotalPage(res.totalPage)
        // }).catch(err => {
        //     setError(err)
        //     setLoading(false)
        // })
    }
    const columns = [
        {
            Header: <div className="text-left">{renderFormatMsg("ClientOptionId", "Option ID")} </div>,
            accessor: 'clientOptionId',
            width: 115,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left">{renderFormatMsg("OptionName", "OptionName")} </div>,
            accessor: 'optionName',
            width: 120,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left">{renderFormatMsg("Warehouse", "Warehouse")} </div>,
            accessor: 'warehouse',
            width: 100,
            //resizable: false,
            style: { justifyContent: "flex-start" },
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("Store", "Store")} </div>,
            accessor: 'store',
            minWidth: 180,
            maxWidth: 200,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("Stock Before", "Stock Before")} </div>,
            accessor: 'stockBefore',
            width: 115,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left">{renderFormatMsg("SkuOrdered", "# SKU Ordered")} </div>,
            accessor: 'numberOfSKUOrdered',
            width: 115,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left"> {renderFormatMsg("SkuShipped", "# SKU Shipped")} </div>,
            accessor: 'numberOfSKUShipped',
            width: 115,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left">{renderFormatMsg("WarehouseStockBefore", "Warehouse Stock Before")}</div>,
            accessor: 'warehouseStockBefore',
            width: 192,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: <div className="text-left">{renderFormatMsg("WarehouseStockAfter", "Warehouse Stock After")} </div>,
            accessor: 'warehouseStockAfter',
            width: 181,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        },
        {
            Header: renderFormatMsg("Details", "Details"),
            accessor: 'Details',
            //resizable: false,
            width: 90,
            Cell: (data) => <Link className="edit-icon" to={`/orders/sku/exports/${props.match.params.exportPackageId}/options/${data.original.optionId}/outletId/${data.original.outletId}/quantity`}> {renderFormatMsg("Details", "Details")} </Link>
        }]
    const fetchData = (state, instance) => {
        //getting change page number from state
        getData({ page: state.page })
        setPageIndex(state.page)
    }
    const SearchHandler = (e) => { setPageIndex(0); getData({ page: 0, search: e.target.value }) }
    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                <div className="heading">
                    <Link className="back-btn" onClick={() => props.history.goBack()}><img src={BackIcon} alt="BackIcon" /></Link>
                    <span>{renderFormatMsg("replenishmentOrderDetail.Heading", "Replenishment Order Detail")}</span>
                </div>
                <div className="right-content select-box ml-auto">
                    <DropDownCustom name={false} selectedData={get(orderDetail[0], "orderName", "Export Package")} dropdownData={false} handleData={false} />
                    <DropDownCustom name={false} selectedData={get(orderDetail[0], "optionName", "Option Name")} dropdownData={false} handleData={false} />
                </div>
            </div> */}
            <PageHeading backURL="/optionDistribution" onClick={() => props.history.goBack()} headingId={"replenishmentOrderDetail.Heading"} isLoading={isLoading}>
                <div className="right-content select-box ml-auto">
                    <DropDownCustom name={false} selectedData={get(orderDetail[0], "orderName", "Export Package")} dropdownData={false} handleData={false} />
                    <DropDownCustom name={false} selectedData={get(orderDetail[0], "optionName", "Option Name")} dropdownData={false} handleData={false} />
                </div>
            </PageHeading>
            <div className="card">
                <div className="card-header"><SearchBox searchHandler={SearchHandler} /> </div>
                {error ? <Error /> : <ReactTable data={orderDetail} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />}
            </div>
        </div>
    )
}
export default React.memo(ReplenishmentOrderDetail);
