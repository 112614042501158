import React, { createContext, useReducer } from "react";
let ToolsAndSettingContext;
const { Provider, Consumer } = (ToolsAndSettingContext = createContext());

export const initialState = {
    addedProductCategory: null,
};

export default function reducer(state, action) {
    switch (action.type) {

        default:
            return state;
    }
}

function ToolsAndSettingProvider({ children, ...props }) {
    // eslint-disable-next-line
    const [state, dispatch] = useReducer(reducer, initialState);

    return (
        <Provider
            value={{
                ...state,
                ...props,
            }}
        >
            {children}
        </Provider>
    );
}

export { ToolsAndSettingProvider, Consumer as ToolsAndSettingConsumer, ToolsAndSettingContext };
