import moment from 'moment'
import React, { useContext } from 'react'
import { NavLink } from 'react-router-dom'
// import { SHORT_DATE_FORMAT_OPTIONS } from '../constant'
import { AppContext } from '../context/AppContext'
import { displayMessage, renderFormatMsg } from '../shared/funs'
import "../view/NotificationSettings/style.scss"


export default function NotificationCard({ notification, dismissNotification, handleBlockedNotification }) {
  const { userDetails } = useContext(AppContext)



  return (
    <div className="alert notification-main notification-list" role="alert">
      <div className="d-flex justify-content-between date" >
        <span className="calendar font-italic"><i className="icon-calendar">&nbsp;</i>
          {moment(notification.timestamp).format(userDetails && userDetails.longDateFormat)}
        </span>

      </div>
      <span className="alert-heading heading mt-2">
        {displayMessage(notification.headline, userDetails && userDetails.shortDateFormat)}</span>
      <p
        className="notifiation-body"
        dangerouslySetInnerHTML={{
          __html:
            notification &&
            displayMessage(notification.payload.messageToDisplay, userDetails && userDetails.shortDateFormat),
        }}
      ></p>
      <div className="detail-box">
        <span className="view"><NavLink to="/notifications"><i className="icon-view"></i>&nbsp;{renderFormatMsg("header.NotificationsOverview.View", "View")}</NavLink></span>
        <span
          className="dismiss"
          onClick={() => dismissNotification(notification.notificationId)}
        >
          <i className="icon-dislike"></i>&nbsp;
          {renderFormatMsg("header.NotificationDetail.Dismiss", "Dismiss")}
        </span>
        <span href="#" className="dismiss"
          onClick={() =>
            handleBlockedNotification(notification.notificationTypeId)
          }
        >{renderFormatMsg("header.NotificationDetail.DontReportAgain", "DON´T REPORT THIS AGAIN ")}</span>
      </div>
    </div>
  )
}
