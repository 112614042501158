import React from 'react'
import { renderFormatMsg } from '../../shared/funs'
import Calendar from '../Calendar'
import { ResponsiveContainer, AreaChart, XAxis, YAxis, Tooltip, Area } from 'recharts'

export default function DiscountCardChart({ data, content }) {
  return (
    <div className="coverage-box card p-3">
      <div className="heading">
        <div className="left">
          <h3>{renderFormatMsg("Overview.Coverage", "Coverage")} <span>{renderFormatMsg("Overview.Details", "Details")}</span></h3>
        </div>
        <div className="right">
          <div>Saturday, Oct 8 2.2 & -37.50% vs. {renderFormatMsg("Overview.Average", "Average")}</div>
        </div>
      </div>
      <div className="search-cond d-flex justify-content-between">
        <div className="left">
          <button className="active">6m</button>
          <button>YTD</button>
          <button>1y</button>
          <button>All</button>
        </div>
        <div className="right d-flex align-items-center w-25">
          <span className="label">
            {renderFormatMsg("Overview.From", "From")}</span> <Calendar /> <span className="label">{renderFormatMsg("Overview.To", "To")} </span><Calendar />
        </div>
      </div>
      <div style={{ width: '100%', height: '350px' }}>

        <ResponsiveContainer>
          <AreaChart
            data={data}
            margin={{
              top: 10, right: 30, left: 0, bottom: 0,
            }}
          >
            <XAxis dataKey="name" />
            <YAxis label={{ value: 'Weeks', angle: -90, position: 'insideLeft' }} />
            <Tooltip cursor={{ fill: 'transparent' }} content={content} />
            <Area type="monotone" dataKey="value" stroke="#8884d8" fill="#8884d8" />
          </AreaChart>
        </ResponsiveContainer>
      </div>
    </div>
  )
}
