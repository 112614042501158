import React from 'react'
import Error from './Error/Error'

export default function PageNotFound() {
  return (
    <div>
      <Error msg={`<b>Page not found</b>`} />
    </div>
  )
}
