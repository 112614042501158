import { get, post, put, deleted } from "./index";

// Repl3nishment Calendar page APIs
export const getOutletCalendars = async (params) => {
  return await get("api/v1/OutletCalendars", params);
};

export const updateOutletCalendar = async (data) => {
  return await post("api/v1/addUpdateOutletCalendar", data);
};

//Store page APIs

export const getOutletCategories = async (params) => {
  return await get("api/v1/OutletCategories", params);
};

export const updateOutletCategories = async (data) => {
  return await put("api/v1/Outlet/UpdateOutletCategory", data);
};
export const addOutletCategories = async (data) => {
  return await post(`api/v1/OutletCategories`, data);
};

export const deleteOutletCategory = async (id) => {
  return await deleted(`api/v1/OutletCategories/${id}`);
};
//Season page APIs
export const getSeasonsDetails = async (params) => {
  return await get(`api/v1/Seasons`, params);
};
export const updateSeasonsDetails = async (data) => {
  return await put(`api/v1/Seasons`, data);
};

export const addNewSeason = async (data) => {
  return await post(`api/v1/Seasons`, data);
};

//outlet page APIs
export const getOutletData = async (params) => {
  return await get(`api/v1/Outlets`, params);
};
export const addOutletData = async (data) => {
  return await post(`api/v1/Outlets`, data);
};
export const updateOutletData = async (data) => {
  return await put(`api/v1/Outlets`, data);
};

//User Page APIs
export const getUserData = async (params) => {
  return await get(`api/v1/Users`, params);
};

//get user's roles
export const getUserRoles = async (params) => {
  return await get(`api/v1/userroles`, params);
};

export const addNewUser = async (data) => {
  return await post(`api/v1/Admin/AddUser`, data);
};
export const updateUser = async (updatingData) => {
  return await put(`api/v1/UpdateUser`, updatingData);
};
//Roles page APIs
export const getClientRolesData = async (params) => {
  return await get(`api/v1/ClientRoles`, params);
};
export const getScreenList = async (params) => {
  return await get(`api/v1/ScreenMaster`, params);
};
export const updateUserRolesScreebPermission = async (updatingData) => {
  return await put(
    `api/v1/Client/UpdateClientRoleScreenPermission`,
    updatingData
  );
};
export const addUserRolesScreebPermission = async (addingData) => {
  return await post(`api/v1/Client/AddClientRoleScreenPermission`, addingData);
};

export const updateUserRoles = async (updatingData) => {
  return await put(`api/v1/Client/UpdateClientRole`, updatingData);
};
export const addUserRoles = async (addingData) => {
  return await post(`api/v1/ClientRoles`, addingData);
};

export const deleteUserRoles = async (id) => {
  return await deleted(`api/v1/ClientRoles/${id}`);
};
//transportTimesCities page APIs
export const getTransportTimesCities = async (params) => {
  return await get(`api/v1/Transport/transportTimesCities`, params);
};

export const updateTransportTimesCity = async (params) => {
  return await put(`api/v1/Transport/UpdateTransportTimesCity`, params);
};
export const addTransportTimesCity = async (data) => {
  return await post(`api/v1/Transport/AddTransportTimesCity`, data);
};
export const deleteTransportTimesCity = async (id) => {
  return await deleted(`api/v1/Transport/transportTimesCity/${id}`);
};

export const getTransportTypes = async (params) => {
  return await get(`api/v1/Transport/transportTypes`, params);
};
//client page APIs
export const getClientData = async (params) => {
  return await get(`api/v1/Clients`, params);
};

export const AddNewClient = async (updatingData) => {
  return await post(`api/v1/Clients`, updatingData);
};

export const updateClientData = async (data) => {
  return await put(`api/v1/Clients/${data.clientId}`, data);
};

//Warehouse page APIs
export const getWarehouseData = async (params) => {
  return await get(`api/v1/Warehouses`, params);
};

export const updateWarehouseData = async (data) => {
  return await put(`api/v1/Warehouses/${data.warehouseId}`, data);
};
export const AddNewWarehouse = async (newData) => {
  return await post(`api/v1/Warehouses`, newData);
};
//userRoleAssign Page
export const getUserRoleAssignData = async (params) => {
  return await get(`api/v1/clientRoles`, params);
};
export const updateUserRoleAssignment = async (updatingData) => {
  return await put(`api/v1/UpdateUserRole`, updatingData);
};

// Product Category API
export const getProductCategory = async (params) => {
  return await get(`api/v1/OptionCategories`, params);
};
export const getProductSizeName = async (params) => {
  return await get(`api/v1/Size`, params);
};
export const updateProductCategory = async (id, updatingData) => {
  return await put(`api/v1/OptionCategories/${id}`, updatingData);
};
export const addProductCategory = async (addingData) => {
  return await post(`api/v1/OptionCategories`, addingData);
};
export const deleteProductCategory = async (id) => {
  return await deleted(`api/v1/OptionCategories/${id}`);
};
// /api/v1/City
export const getCity = async (params) => {
  return await get(`api/v1/City`, params);
};
export const getBrand = async () => {
  return await get(`api/v1/Brand`);
};

//Promotion Type API
export const getPromotionType = async (params) => {
  return await get(`api/v1/PromotionTypes`, params);
};
export const updatePromotionType = async (params) => {
  return await put(`api/v1/PromotionTypes`, params);
};
export const addPromotionType = async (params) => {
  return await post(`api/v1/PromotionTypes`, params);
};
export const deletePromotionType = async (key, id) => {
  return await deleted(`api/v1/PromotionTypes/${id}`);
};

// export const getSeasonType = async (params) => {
//     return await get(`api/v1/Season/SeasonTypes`, params)
// };
// export const addSeasonType = async (params) => {
//     return await post(`api/v1/Season/AddSeasonType`, params)
// };
// export const updateSeasonType = async (params) => {
//     return await put(`api/v1/Season/UpdateSeasonType`, params)
// };

export const validateProductIds = async (params) => {
  return await post(`api/v1/CheckOptions`, params);
};

export const validateCategoryIds = async (params) => {
  return await post(`api/v1/CheckOutletCategory`, params);
};

export const getImportFileData = async (id) => {
  return await get(`api/v1/Import/${id}`);
};
