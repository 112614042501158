import { HttpService } from "./HttpService";

export class BaseService {
  constructor(url_prefix = "") {
    this.http = new HttpService(url_prefix);
  }

  async getAll(url, params) {
    return await this.http.get(url, params);
  }

  async get(id) {
    return await this.http.get(`/${id}`);
  }

  async create(body) {
    return await this.http.post(``, body);
  }

  async update(id, body) {
    return await this.http.put(`/${id}`, body);
  }

  async delete(id) {
    return await this.http.remove(`/${id}`);
  }
}
