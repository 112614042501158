import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { debounce, get } from "lodash"
import { getWarehouseData, updateWarehouseData } from '../../services/toolsAndSettingsPagesApi';
import { renderFormatMsg, toastError, toastSuccess, updateActiveFromAndToRow, checkPermission, DateRangeStatus, permissionErrorCheck, findPermission } from "../../shared/funs";
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import Calendar from "../../components/Calendar"
import { AppContext } from "../../context/AppContext";
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import PageHeading from '../../components/PageHeading/PageHeading';

const Warehouses = (props) => {
    const { userDetails } = useContext(AppContext)
    const [warehousesData, setWarehousesData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    // eslint-disable-next-line no-unused-vars
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

    let dataArray = get(userDetails, "accesses")

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("WAREHOUSES").screenId))
    }, [dataArray])


    const sendQuery = (object, data) => {
        updateWarehouseData(object).then(res => {
            setWarehousesData(data.data)
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        }).catch(error => {
            toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
        })
    };

    const delayedQuery = debounce((q, data) => sendQuery(q, data), 1000);


    const getData = (page = 0, search = "") => {
        setSearchValue(search)
        let param = {
            "page": page + 1,
            "display": PAGE_SIZE,
            "search": search
        }
        getWarehouseData(param).then(res => {
            setWarehousesData(res.warehouses.length > 0 ? res.warehouses : [])
            setTotalPage(res.totalPage)
        }).catch(err => {
            setError(permissionErrorCheck(err))
        })
    }
    const handleChangeDate = async (date, details, key) => {
        var data = updateActiveFromAndToRow(warehousesData, key, date, { "warehouseId": details.warehouseId });
        setWarehousesData(data.data)
        delayedQuery(data.payload, data);

    }

    const columns = [
        {
            Header: <div className="text-left">{renderFormatMsg("WarehouseName", "Warehouse Name")}</div>,
            accessor: 'name',
            width: 250,
            maxWidth: 350,
            //resizable: false,
            style: { justifyContent: "flex-start" }
        }, {
            Header: renderFormatMsg("ActiveFrom", "Active From"),
            width: 180,
            //resizable: false,
            Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
                <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="activeFrom" selectedDate={new Date(props.original.activeFrom)} maxDate={new Date(props.original.activeTo)} onSelect={data => handleChangeDate(data, props.original, "activeFrom")} />
            </div>
        }, {
            Header: renderFormatMsg("ActiveTo", "Active to"),
            width: 180,
            //resizable: false,
            accessor: 'activeTo',
            Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
                <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="activeTo" selectedDate={new Date(props.original.activeTo)} minDate={new Date(props.original.activeFrom)} onSelect={data => handleChangeDate(data, props.original, "activeTo")} />
            </div>
        },]

    if (pageReadOnly.write) {
        columns.push({
            Header: renderFormatMsg("Edit", "Edit"),
            accessor: 'Edit',
            width: 100,
            //resizable: false,
            Cell: props => <Link to={`/warehouses/Edit/${props.original.warehouseId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
        })
    }
    const fetchData = (state, instance) => {
        //getting change page number from state
        getData(state.page, searchValue)
        setPageIndex(state.page)
    }

    const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }

    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("warehousesTable.Heading", "Warehouses")}
            </div> */}
            <PageHeading headingId="warehousesTable.Heading" isLoading={isLoading} />
            <div className="card">

                {error ? <Error msg={error} /> : <>
                    <div className="card-header">
                        <SearchBox searchHandler={SearchHandler} />
                        {pageReadOnly.write && <LinkButton to="/warehouses/Add" id="AddWarehouse" defaultText="Add Warehouses" />}
                    </div>
                    <ReactTable data={warehousesData} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} /></>}
            </div>
        </div >
    )
}
export default React.memo(Warehouses)
