
import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { get, debounce } from "lodash"
import {
    // getClientData,
    updateClientData
} from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import Calendar from "../../components/Calendar"
import { AppContext } from "../../context/AppContext";
import { renderFormatMsg, toastSuccess, toastError, checkPermission, updateActiveFromAndToRow, DateRangeStatus, permissionErrorCheck, findPermission } from "../../shared/funs"
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';
import { GraphQLBaseService } from '../../services/GraphQLBaseService';

const LicensePage = (props) => {
    // console.info('props =>', props);

    const ClientId = props.match.params.ClientId;
    let isKeyVal;

    const { userDetails } = useContext(AppContext)
    const [clientData, setClientData] = useState([])
    const [clientGData, setClientGData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [totalGPage, setTotalGPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    const [key, setKey] = useState(false)
    // eslint-disable-next-line
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

    let dataArray = get(userDetails, "accesses")

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("CLIENT_PAGE").screenId))
    }, [dataArray])

    const getData = (page = 0, search = "") => {
        setSearchValue(search)
        let param = {
            "page": page + 1,
            "display": PAGE_SIZE,
            "search": search
        }
        console.info('param =>', param);


        // GraphQL Query Start

        // const operationsDoc = `
        //     query GetLicensesForClient {
        //         ClientLicenses(where: { ClientId: { _eq: "${ClientId}" } }) {
        //         ClientId
        //         LicenseId
        //         ClientLicenseId
        //         ActiveFrom
        //         ActiveTo
        //         },
        //         Licenses {
        //         LicenseId
        //         Description
        //     }
        //     }`;

        // async function fetchGraphQL(operationsDoc, operationName) {

        //     const result = await fetch(
        //         "https://noted-tuna-42.hasura.app/v1/graphql",
        //         {
        //             method: "POST",
        //             headers: {
        //                 'x-hasura-access-key': '8E2j4jOlt59V0t0WbKQJSeORjgBlk5p0NerrjIc4qXBe5RsEq9s6ElHA9YYSIhsD',
        //                 'x-hasura-admin-secret': '2Um6PvYtSlWx8qiG7gK5BcuSqm5qY62C2Z9KVCo3HiXgqm2R2ubcNAFoCIyksTkD',
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify({
        //                 query: operationsDoc,
        //                 // variables: variables,
        //                 operationName: operationName
        //             })
        //         }
        //     );
        //     return await result.json();
        // }

        // function fetchMyQuery() {
        //     return fetchGraphQL(
        //         operationsDoc,
        //         "GetLicensesForClient",
        //         {}
        //     );
        // }

        // async function startFetchMyQuery() {
        //     const { errors, data } = await fetchMyQuery();
        //     console.info('data =>', data);

        //     const licencesValues = data.Licenses;

        //     const clientLicensesValues = data.ClientLicenses;

        //     const merge = licencesValues.map((val, i) => Object.assign({}, val, clientLicensesValues[i]));
        //     console.info('merge =>', merge);

        //     let pages = Math.ceil(merge.length / PAGE_SIZE);

        //     setTotalGPage(pages);

        //     if (errors) {
        //         console.error(errors);
        //     }

        //     // setClientData(data);
        //     setClientGData(merge);

        // }
        // startFetchMyQuery();

        // GraphQL Query End

        const operationsDoc = `
            query GetLicensesForClient($ClientId: uuid!) {
                ClientLicenses(where: { ClientId: { _eq: $ClientId } }) {
                ClientId
                LicenseId
                ClientLicenseId
                ActiveFrom
                ActiveTo
                },
                Licenses {
                LicenseId
                Description
            }
            }`;

        const variables = {
            "ClientId": ClientId,
        }

        // const operationsDoc = `
        //     query GetLicensesForClient {
        //         ClientLicenses(where: { ClientId: { _eq: "${ClientId}" } }) {
        //         ClientId
        //         LicenseId
        //         ClientLicenseId
        //         ActiveFrom
        //         ActiveTo
        //         },
        //         Licenses {
        //         LicenseId
        //         Description
        //     }
        //     }`;

        async function fetchUserData() {
            try {
                let res = await (new GraphQLBaseService(`/license/${ClientId}/?test=1`)).getAll('', param, operationsDoc, variables)

                console.info('res =>', res);

                const licencesValues = res.data.Licenses;

                const clientLicensesValues = res.data.ClientLicenses;

                const merge = licencesValues.map((val, i) => Object.assign({}, val, clientLicensesValues[i]));
                console.info('merge =>', merge);

                merge.map(val => {
                    // console.info('val =>', val);
                    if (val.ClientId) {
                        isKeyVal = true;
                        // console.log("he")
                        setKey(isKeyVal)
                    } else {
                        isKeyVal = false;
                        // console.log("nahi")
                        setKey(isKeyVal)
                    }
                })

                let pages = Math.ceil(merge.length / PAGE_SIZE);


                // let checkKey = (key) => merge.some(obj => Object.keys(obj).includes(key));
                // let isKey = checkKey('ClientId');
                // console.info('checkKey(ClientId) =>', checkKey('ClientId'));


                setClientData(merge.length > 0 ? merge : [])
                setTotalPage(pages)
            } catch (error) {
                setError(permissionErrorCheck(error))
            }
        }
        fetchUserData();
    }

    // console.info('key** =>', key);


    let checkKey = (key) => clientData.some(obj => Object.keys(obj).includes(key));
    let isKey = checkKey('ClientId');

    console.info('clientData =>', clientData);

    const sendQuery = (object, data) => {
        updateClientData(object).then(res => {
            setClientData(data.data)
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        }).catch(error => {
            toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
        })
    };

    const delayedQuery = debounce((q, data) => sendQuery(q, data), 1000);

    const handleChangeDate = async (date, details, key) => {

        var data = updateActiveFromAndToRow(clientData, key, date, { "clientId": details.clientId });

        delayedQuery(data.payload, data)
    }

    const columns = [{
        Header: <div className="text-left">{renderFormatMsg("Description", "Description")}</div>,
        accessor: 'Description',
        width: 324,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    },
    {
        Header: renderFormatMsg("ActiveFrom", "Active From"),
        accessor: 'ActiveFrom',
        //resizable: false,
        width: 158,
        Cell: props => <div className={DateRangeStatus(props.original.ActiveFrom, props.original.ActiveTo)}>
            <Calendar disabled={!pageReadOnly.write || isKey === false} className={(!pageReadOnly.write || isKey === false) && "read-only"} name="ActiveFrom" selectedDate={new Date(props.original.ActiveFrom)}
                maxDate={new Date(props.original.ActiveTo)}
                onSelect={data => handleChangeDate(data, props.original, "ActiveFrom")}
            />
        </div>
    },
    {
        Header: renderFormatMsg("ActiveTo", "Active to"),
        //resizable: false,
        width: 158,
        accessor: 'ActiveTo',
        Cell: props => <div className={DateRangeStatus(props.original.ActiveFrom, props.original.ActiveTo)}>
            <Calendar disabled={!pageReadOnly.write || isKey === false} className={(!pageReadOnly.write || isKey === false) && "read-only"} name="ActiveTo" selectedDate={new Date(props.original.ActiveTo)} minDate={new Date(props.original.ActiveFrom)} onSelect={data => handleChangeDate(data, props.original, "ActiveTo")} /></div>
    },
    ]

    const fetchData = (state, instance) => {

        // console.info('state =>', state);

        //getting change page number from state
        getData(state.page, searchValue)
        setPageIndex(state.page)
    }

    const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }

    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("clientPageTable.Heading", "Client")}
            </div> */}
            <PageHeading headingId="licensePageTable.Heading" isLoading={isLoading} backURL="/client-graphql?test=1" />
            <div className="card">

                {error ? <Error msg={error} /> : <>
                    <div className="card-header">
                        <SearchBox searchHandler={SearchHandler} />
                    </div>
                    {/* <ReactTable data={clientGData} columns={columns} onFetchData={fetchData} pages={totalGPage} page={pageIndex} /></>} */}
                    <ReactTable data={clientData} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} /></>}
            </div>
        </div >
    )
}
export default React.memo(LicensePage);
