export const HOST_API = "https://repo.commercial-lab.com";
export const SERVER_API_URL = "https://repo.commercial-lab.com/api/v1";

//graphql start
export const SERVER_GRAPHQL_API_URL =
  "https://noted-tuna-42.hasura.app/v1/graphql";
//graphql end

export const NOTIFICATIONS_INTERVAL = 600000;
export const PAGE_SIZE = 10;

export const SEVERITY_SELECT_OPTION = [
  {
    id: "notificationTable.SeverityHigh",
    label: "High",
    value: 1,
  },
  {
    id: "notificationTable.SeverityMedium",
    label: "Medium",
    value: 2,
  },
  {
    id: "notificationTable.SeverityLow",
    label: "Low",
    value: 3,
  },
  {
    id: "notificationTable.SeverityIgnore",
    label: "Ignore",
    value: 4,
  },
];

export const MINIMUN_ORDER_PRICE = [
  {
    label: "Base Price",
    value: "basePrice",
  },
  {
    label: "Sales Price",
    value: "salesPrice",
  },
  {
    label: "Purchase Price",
    value: "purchasePrice",
  },
];

export const SHORT_DATE_FORMAT_OPTIONS = [
  {
    label: "DD.MM.YYYY",
    value: "DD.MM.YYYY",
  },
  {
    label: "MM/DD/YYYY",
    value: "MM/DD/YYYY",
  },
];

export const LONG_DATE_FORMAT_OPTIONS = [
  {
    label: "dd.MM.YYYY HH:mm am/pm",
    value: "DD.MM.YYYY HH:mm a",
  },
  {
    label: "MM/DD/YYYY HH:mm am/pm",
    value: "MM/DD/YYYY HH:mm a",
  },
  {
    label: "DD.MM.YYYY HH:mm:ss",
    value: "DD.MM.YYYY HH:mm:ss",
  },
];

export const LANGUAGES = [
  {
    label: "English",
    value: "en",
  },
  {
    label: "German",
    value: "de",
  },
  {
    label: "Russian",
    value: "ru",
  },
];

export const TRANSPORT_TIME_CITY_SELECT_OPTION = [
  {
    id: "transportTimePerCityTable.TransportType_1",
    label: "Type 1",
    value: "Type 1",
  },
  {
    id: "transportTimePerCityTable.TransportType_2",
    label: "Type 2",
    value: "Type 2",
  },
  {
    id: "transportTimePerCityTable.TransportType_3",
    label: "Type 3",
    value: "Type 3",
  },
];

export const DATE_FORMAT = "YYYY-M-D";
export const DEFAULT_DATE_FORMAT = "D.M.YYYY";

export const SIZE_BOWL = [
  {
    id: "1",
    label: "XXS",
    value: "XXS",
  },
  {
    id: "2",
    label: "XS",
    value: "XS",
  },
  {
    id: "3",
    label: "S",
    value: "S",
  },
  {
    id: "4",
    label: "M",
    value: "M",
  },
  {
    id: "5",
    label: "L",
    value: "L",
  },
  {
    id: "6",
    label: "XL",
    value: "XL",
  },
  {
    id: "7",
    label: "XXL",
    value: "XXL",
  },
];

export const COUNTRY_LIST = [
  { id: "Belgium", name: "Belgium" },
  { id: "France", name: "France" },
  { id: "Germany", name: "Germany" },
  { id: "Italy", name: "Italy" },
  { id: "Portugal", name: "Portugal" },
  { id: "Russia", name: "Russia" },
  { id: "Spain", name: "Spain" },
  { id: "United Kingdom", name: "United Kingdom" },
  { id: "United States", name: "United States" },
];

export const INTERACTION_CODE_LIST = [
  { id: "Independant", name: "Independant", value: "Independant" },
  { id: "Dependant", name: "Dependant", value: "Dependant" },
];

export const MONTH_NAME = [
  {
    label: "January",
    value: 1,
  },
  {
    label: "February",
    value: 2,
  },
  {
    label: "March",
    value: 3,
  },
  {
    label: "April",
    value: 4,
  },
  {
    label: "May",
    value: 5,
  },
  {
    label: "June",
    value: 6,
  },
  {
    label: "July",
    value: 7,
  },
  {
    label: "August",
    value: 8,
  },
  {
    label: "September",
    value: 9,
  },
  {
    label: "October",
    value: 10,
  },
  {
    label: "November",
    value: 11,
  },
  {
    label: "December",
    value: 12,
  },
];
export const PAGE_ACCESS_FUNCTION = {
  USER: "52322d02-68b5-4934-9ee2-007236411704",
  OUTLET: "8c434c19-96f5-475b-b8b4-70b120276933",
  SESSION: "8a147f5a-f501-43a1-94ad-78a7ec836494",
  SETTINGS: "9bcb795f-23ee-4529-b1b9-2f4c74734c0c",
  PROMOTION: "e2d1f1a9-8b99-4766-9f81-e355fd4ca10c",
  USER_ROLES: "bb684e41-a280-4c21-a753-236a7efa96f6",
  WAREHOUSES: "6e841f7c-343e-4537-a7d0-487291fd1a07",
  PROMOTIONS: "6d66af5f-6a97-45eb-9a98-d29aa2c7c17d",
  CLIENT_PAGE: "e4ed8613-be81-4c0d-b768-39fd3a7ea327",
  MANUAL_ORDER: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
  VIEW_OVERVIEW: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
  STORE_CATEGORY: "187280a3-c1ce-4073-910c-dc2941eb1e92",
  PROMOTION_TYPES: "eb50a708-1403-4da2-b75e-8dd27675725b",
  PRODUCT_SETTING: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
  PRODUCT_BLOCKING: "d9bdc283-73be-4f0b-9467-755cc420cc3b",
  PRODUCT_CATEGORIES: "aae81a76-3ea9-44d4-af8f-ebb3b1e9795f",
  PRODUCT_DISTRIBUTION: "64bd67e7-07eb-4383-8451-9896d514afbf",
  REPLENISHMENT_CALENDAR: "0bb332cb-7e84-4ef7-bc76-52beb8a613bc",
  TRANSPORT_TIME_PER_CITY: "72e9e2e0-a90f-4a3c-b00c-5ed47749be30",
  TRANSPORT_TIME_PER_STORE: "14371ecb-68d8-4a58-99bc-7d5db1379097",
  AASIGN_MASTER_USER_FLAGE: "eb50a708-1403-4aa2-b75e-8dd27675725b",
  REPLENISHMENT_ORDER_OVERVIEW: "3f369fc7-c422-40f1-b166-ffb17d71d3a1",
  EDIT_PRODUCT_SETTINGS_STEERING_PARAMETER:
    "770cf10a-7d92-4ce9-9dad-5cce146151ea",
};

export const FIELD_ACCESS_FUNCTION = {
  OUTLET_FORM_CATEGORY_1: "1a602548-a965-4a94-b9de-239405c96430",
  OUTLET_FORM_CATEGORY_2: "1a07e5e1-7152-4cc9-ac69-6607e53305b5",
  PRODUCT_FORM_CATEGORY_1: "c458191f-85ee-4c9f-b4cd-88270b25344b",
  PRODUCT_FORM_CATEGORY_2: "770cf10a-7d92-4ce9-9dad-5cce146151ea",
  PRODUCT_CATEGORIES_FORM_CATEGORY_1: "15b63a2b-c92c-4d6c-a975-2e2052c4ed5a",
  PRODUCT_CATEGORIES_FORM_CATEGORY_2: "aae81a76-3ea9-44d4-af8f-ebb3b1e9795f",
  SEASON_FORM_CATEGORY_1: "8a147f5a-f501-43a1-94ad-78a7ec836494",
  SEASON_FORM_CATEGORY_2: "0c845ef2-e76d-4b79-badd-cbc4738a4dcd",
};

export const SIDEBAR_MENU_ACCESS_FUNCTION = {
  PROMOTION: "e2d1f1a9-8b99-4766-9f81-e355fd4ca10c",
  SPECIAL_REPLENISHMENT: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
  MANUAL_ORDER: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
  EDIT_PRODUCT_SETTING: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
  PRODUCTS: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
  REPLENISHMENT_ORDER_OVERVIEW: "3f369fc7-c422-40f1-b166-ffb17d71d3a1",
  RECOMMENDATIONS: "e9b298e8-c510-4239-9371-7ff5923464d3",
  SETTINGS: "9bcb795f-23ee-4529-b1b9-2f4c74734c0c",
  OVERVIEW: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
};

// export const SCREEN_ACCESS_RIGHT = [
//   {
//     screenId: "52322d02-68b5-4934-9ee2-007236411704",
//     name: "USER",
//     screenName: "User",
//     icon: ""
//   },
//   {
//     screenId: "8c434c19-96f5-475b-b8b4-70b120276933",
//     name: "OUTLET",
//     screenName: "Outlet",
//     icon: ""
//   },
//   {
//     screenId: "8a147f5a-f501-43a1-94ad-78a7ec836494",
//     name: "SESSION",
//     screenName: "Session",
//     icon: ""
//   },
//   {
//     screenId: "bb684e41-a280-4c21-a753-236a7efa96f6",
//     name: "USER_ROLES",
//     screenName: "User_roles",
//     icon: ""
//   },
//   {
//     screenId: "6e841f7c-343e-4537-a7d0-487291fd1a07",
//     name: "WAREHOUSES",
//     screenName: "Warehouses",
//     icon: ""
//   },
//   {
//     screenId: "6d66af5f-6a97-45eb-9a98-d29aa2c7c17d",
//     name: "PROMOTIONS",
//     screenName: "Promotions",
//     icon: ""
//   },
//   {
//     screenId: "e4ed8613-be81-4c0d-b768-39fd3a7ea327",
//     name: "CLIENT_PAGE",
//     screenName: "Client_page",
//     icon: ""
//   },
//   {
//     screenId: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
//     name: "MANUAL_ORDER",
//     screenName: "Manual_order",
//     icon: ""
//   },
//   {
//     screenId: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
//     name: "VIEW_OVERVIEW",
//     screenName: "View_overview",
//     icon: ""
//   },
//   {
//     screenId: "187280a3-c1ce-4073-910c-dc2941eb1e92",
//     name: "STORE_CATEGORY",
//     screenName: "Store_category",
//     icon: ""
//   },
//   {
//     screenId: "eb50a708-1403-4da2-b75e-8dd27675725b",
//     name: "PROMOTION_TYPES",
//     screenName: "Promotion_types",
//     icon: ""
//   },
//   {
//     screenId: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
//     name: "PRODUCT_SETTING",
//     screenName: "Product_setting",
//     icon: ""
//   },
//   {
//     screenId: "d9bdc283-73be-4f0b-9467-755cc420cc3b",
//     name: "PRODUCT_BLOCKING",
//     screenName: "Product_blocking",
//     icon: ""
//   },
//   {
//     screenId: "aae81a76-3ea9-44d4-af8f-ebb3b1e9795f",
//     name: "PRODUCT_CATEGORIES",
//     screenName: "Product_categories",
//     icon: ""
//   },
//   {
//     screenId: "64bd67e7-07eb-4383-8451-9896d514afbf",
//     name: "PRODUCT_DISTRIBUTION",
//     screenName: "Product_distribution",
//     icon: ""
//   },
//   {
//     screenId: "0bb332cb-7e84-4ef7-bc76-52beb8a613bc",
//     name: "REPLENISHMENT_CALENDAR",
//     screenName: "Replenishment_calendar",
//     icon: ""
//   },
//   {
//     screenId: "72e9e2e0-a90f-4a3c-b00c-5ed47749be30",
//     name: "TRANSPORT_TIME_PER_CITY",
//     screenName: "Transport_time_per_city",
//     icon: ""
//   },
//   {
//     screenId: "14371ecb-68d8-4a58-99bc-7d5db1379097",
//     name: "TRANSPORT_TIME_PER_STORE",
//     screenName: "Transport_time_per_store",
//     icon: ""
//   },
//   {
//     screenId: "eb50a708-1403-4aa2-b75e-8dd27675725b",
//     name: "AASIGN_MASTER_USER_FLAGE",
//     screenName: "Aasign_master_user_flage",
//     icon: ""
//   },
//   {
//     screenId: "3f369fc7-c422-40f1-b166-ffb17d71d3a1",
//     name: "REPLENISHMENT_ORDER_OVERVIEW",
//     screenName: "Replenishment_order_overview",
//     icon: ""
//   },
//   {
//     screenId: "770cf10a-7d92-4ce9-9dad-5cce146151ea",
//     name: "EDIT_PRODUCT_SETTINGS_STEERING_PARAMETER",
//     screenName: "Edit_product_settings_steering_parameter",
//     icon: ""
//   },
//   {
//     screenId: "1a602548-a965-4a94-b9de-239405c96430",
//     name: "OUTLET_FORM_CATEGORY_1",
//     screenName: "Outlet_form_category_1",
//     icon: ""
//   },
//   {
//     screenId: "1a07e5e1-7152-4cc9-ac69-6607e53305b5",
//     name: "OUTLET_FORM_CATEGORY_2",
//     screenName: "Outlet_form_category_2",
//     icon: ""
//   },
//   {
//     screenId: "c458191f-85ee-4c9f-b4cd-88270b25344b",
//     name: "PRODUCT_FORM_CATEGORY_1",
//     screenName: "Product_form_category_1",
//     icon: ""
//   },
//   {
//     screenId: "770cf10a-7d92-4ce9-9dad-5cce146151ea",
//     name: "PRODUCT_FORM_CATEGORY_2",
//     screenName: "Product_form_category_2",
//     icon: ""
//   },
//   {
//     screenId: "15b63a2b-c92c-4d6c-a975-2e2052c4ed5a",
//     name: "PRODUCT_CATEGORIES_FORM_CATEGORY_1",
//     screenName: "Product_categories_form_category_1",
//     icon: ""
//   },
//   {
//     screenId: "aae81a76-3ea9-44d4-af8f-ebb3b1e9795f",
//     name: "PRODUCT_CATEGORIES_FORM_CATEGORY_2",
//     screenName: "Product_categories_form_category_2",
//     icon: ""
//   },
//   {
//     screenId: "8a147f5a-f501-43a1-94ad-78a7ec836494",
//     name: "SEASON_FORM_CATEGORY_1",
//     screenName: "Season_form_category_1",
//     icon: ""
//   },
//   {
//     screenId: "0c845ef2-e76d-4b79-badd-cbc4738a4dcd",
//     name: "SEASON_FORM_CATEGORY_2",
//     screenName: "Season_form_category_2",
//     icon: ""
//   },
//   {
//     screenId: "e2d1f1a9-8b99-4766-9f81-e355fd4ca10c",
//     name: "PROMOTION",
//     screenName: "Promotion",
//     icon: ""
//   },
//   {
//     screenId: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
//     name: "SPECIAL_REPLENISHMENT",
//     screenName: "Special_replenishment",
//     icon: ""
//   },
//   {
//     screenId: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
//     name: "EDIT_PRODUCT_SETTING",
//     screenName: "Edit_product_setting",
//     icon: ""
//   },
//   {
//     screenId: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
//     name: "PRODUCTS",
//     screenName: "Products",
//     icon: ""
//   },
//   {
//     screenId: "e9b298e8-c510-4239-9371-7ff5923464d3",
//     name: "RECOMMENDATIONS",
//     screenName: "Recommendations",
//     icon: ""
//   },
//   {
//     screenId: "9bcb795f-23ee-4529-b1b9-2f4c74734c0c",
//     name: "SETTINGS",
//     screenName: "Settings",
//     icon: ""
//   },
//   {
//     screenId: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
//     name: "OVERVIEW",
//     screenName: "Overview",
//     icon: ""
//   },
// ]

export const SCREEN_ACCESS_RIGHT = [
  {
    screenId: "e4ed8613-be81-4c0d-b768-39fd3a7ea327",
    name: "CLIENT_PAGE",
    icon: "",
    screenName: "Create & Edit Client",
  },
  {
    screenId: "8a147f5a-f501-43a1-94ad-78a7ec836494",
    name: "SESSION",
    icon: "",
    screenName: "Create & Edit Seasons",
  },
  {
    screenId: "187280a3-c1ce-4073-910c-dc2941eb1e92",
    name: "STORE_CATEGORY",
    icon: "",
    screenName: "Create & Edit Store Categories",
  },
  {
    screenId: "8c434c19-96f5-475b-b8b4-70b120276933",
    name: "OUTLET",
    icon: "",
    screenName: "Create & Edit Outlets",
  },
  {
    screenId: "52322d02-68b5-4934-9ee2-007236411704",
    name: "USER",
    icon: "",
    screenName: "Create & Edit Users",
  },
  {
    screenId: "bb684e41-a280-4c21-a753-236a7efa96f6",
    name: "USER_ROLES",
    icon: "",
    screenName: "Create & Edit Roles",
  },
  {
    screenId: "6d66af5f-6a97-45eb-9a98-d29aa2c7c17d",
    name: "PROMOTIONS",
    icon: "",
    screenName: "Create & Edit Promotions",
  },
  {
    screenId: "eb50a708-1403-4da2-b75e-8dd27675725b",
    name: "PROMOTION_TYPES",
    icon: "",
    screenName: "Create & Edit Promotion Types",
  },
  {
    screenId: "6e841f7c-343e-4537-a7d0-487291fd1a07",
    name: "WAREHOUSES",
    icon: "",
    screenName: "Create & Edit Warehouses",
  },
  {
    screenId: "64bd67e7-07eb-4383-8451-9896d514afbf",
    name: "PRODUCT_DISTRIBUTION",
    icon: "",
    screenName: "Create & Edit Product distribution",
  },
  {
    screenId: "eb50a708-1403-4aa2-b75e-8dd27675725b",
    name: "AASIGN_MASTER_USER_FLAGE",
    icon: "",
    screenName: "Assign Master User Flag",
  },
  {
    screenId: "b74301cd-bd97-45f7-8135-7a32ff483caa",
    name: "",
    icon: "",
    screenName: "Create & Edit Clients Masterdata",
  },
  {
    screenId: "ac7920bb-e3b2-47b6-88ed-6fee82c142d0",
    name: "",
    icon: "",
    screenName: "Edit Clients General Parameters",
  },
  {
    screenId: "1a602548-a965-4a94-b9de-239405c96430",
    name: "OUTLET_FORM_CATEGORY_1",
    icon: "",
    screenName: "Create & Edit Outlets Masterdata",
  },
  {
    screenId: "1a07e5e1-7152-4cc9-ac69-6607e53305b5",
    name: "OUTLET_FORM_CATEGORY_2",
    icon: "",
    screenName: "Edit Outlets Steering Parameters",
  },
  {
    screenId: "1b2f8c8f-f038-498c-b36d-f1baa0c7614c",
    name: "",
    icon: "",
    screenName: "Create & Edit Warehouses Masterdata",
  },
  {
    screenId: "debcc4af-0966-49a6-aae9-aff1f4298bad",
    name: "",
    icon: "",
    screenName: "Edit Warehouse Steering Parameters",
  },
  {
    screenId: "aae81a76-3ea9-44d4-af8f-ebb3b1e9795f",
    name: "PRODUCT_CATEGORIES",
    icon: "",
    screenName: "Create & Edit Product Categories",
  },
  {
    screenId: "3ad5660a-4711-4cf4-9775-7c8a599964bf",
    name: "PRODUCT_SETTING",
    icon: "",
    screenName: "Edit Product Settings",
  },
  {
    screenId: "60265835-4ece-4427-96a6-56687aede1e2",
    name: "",
    icon: "",
    screenName:
      "Edit Product Settings Steering Parameters (minimum display and final push date)",
  },
  {
    screenId: "3f369fc7-c422-40f1-b166-ffb17d71d3a1",
    name: "REPLENISHMENT_ORDER_OVERVIEW",
    icon: "",
    screenName: "Replenishment Order Overview",
  },
  {
    screenId: "a88a1b0c-8017-49e5-ab92-ecfc69f95a14",
    name: "",
    icon: "",
    screenName: "Replenishment Order SKU",
  },
  {
    screenId: "ed51903b-b792-4861-bed4-2b0d0896e970",
    name: "",
    icon: "",
    screenName: "Replenishment Order Details",
  },
  {
    screenId: "c7764b11-ea5b-4796-b4a2-12c50adfab94",
    name: "",
    icon: "",
    screenName: "Replenishment Quantities",
  },
  {
    screenId: "ca1d39e1-b802-4c8a-828b-cc267984c981",
    name: "",
    icon: "",
    screenName: "Create & Edit Cities",
  },
  {
    screenId: "29583f4b-8ad5-4f0b-8980-25f08e8681fb",
    name: "",
    icon: "",
    screenName: "Create & Edit Brands",
  },
  {
    screenId: "b4a405c6-411c-4395-8e40-ba2499034710",
    name: "",
    icon: "",
    screenName: "View Overview Screen",
  },
  {
    screenId: "f6da437c-06e1-4f99-85e8-f5cf6765b05d",
    name: "",
    icon: "",
    screenName: "Block Products",
  },
  {
    screenId: "0bb332cb-7e84-4ef7-bc76-52beb8a613bc",
    name: "REPLENISHMENT_CALENDAR",
    icon: "",
    screenName: "Maintain Replenishment Calendar",
  },
  {
    screenId: "72e9e2e0-a90f-4a3c-b00c-5ed47749be30",
    name: "TRANSPORT_TIME_PER_CITY",
    icon: "",
    screenName: "Maintain Transport times per city",
  },
  {
    screenId: "14371ecb-68d8-4a58-99bc-7d5db1379097",
    name: "TRANSPORT_TIME_PER_STORE",
    icon: "",
    screenName: "Maintain Transport times per store",
  },
  {
    screenId: "01e3b0c9-3cb8-457e-8653-f91efa8b06c6",
    name: "OVERVIEW",
    icon: "",
    screenName: "View Overview",
  },
  {
    screenId: "770cf10a-7d92-4ce9-9dad-5cce146151ea",
    name: "EDIT_PRODUCT_SETTINGS_STEERING_PARAMETER",
    icon: "",
    screenName: "Edit Product Settings Steering Parameter",
  },
  {
    screenId: "d9bdc283-73be-4f0b-9467-755cc420cc3b",
    name: "PRODUCT_BLOCKING",
    icon: "",
    screenName: "Product Blocking",
  },
  {
    screenId: "c458191f-85ee-4c9f-b4cd-88270b25344b",
    name: "PRODUCT_FORM_CATEGORY_1",
    icon: "",
    screenName: "Product Settings Base Parameters",
  },
  {
    screenId: "0c845ef2-e76d-4b79-badd-cbc4738a4dcd",
    name: "SEASON_FORM_CATEGORY_2",
    icon: "",
    screenName: "Season Settings Base Parameters",
  },
  {
    screenId: "15b63a2b-c92c-4d6c-a975-2e2052c4ed5a",
    name: "PRODUCT_CATEGORIES_FORM_CATEGORY_1",
    icon: "",
    screenName: "Product Categories Settings Base Parameters",
  },
  {
    screenId: "d01fd838-6c08-4028-b194-c2e027ec478d",
    name: "MANUAL_ORDER",
    icon: "",
    screenName: "Manual Order",
  },
  {
    screenId: "e195cbe6-bfe8-4c32-abd4-9572064169bf",
    name: "SPECIAL_REPLENISHMENT",
    icon: "",
    screenName: "Special Replenishment",
  },
  {
    screenId: "e2d1f1a9-8b99-4766-9f81-e355fd4ca10c",
    name: "PROMOTION",
    icon: "",
    screenName: "Promotions",
  },
  {
    screenId: "e9b298e8-c510-4239-9371-7ff5923464d3",
    name: "RECOMMENDATIONS",
    icon: "",
    screenName: "Recommendations",
  },
  {
    screenId: "9bcb795f-23ee-4529-b1b9-2f4c74734c0c",
    name: "SETTINGS",
    icon: "",
    screenName: "Settings",
  },
];

export const PERMISSION_LEVEL_READ_ONLY = "RO";
export const PERMISSION_LEVEL_READ_WRITE = "RW";

export const INFO = {
  MAX_REPLENISHMENT_DAYS: `The maximum days of replenishment represents the maximum number of days the system will take to determine it a product can be sold. So if the system is not sure that an item it wants to dispatch can be sold which a given degree of certainty within this time period, it will not dispatch this product.`,
  MIN_SALES_PROBABILITY: `The minimum sales probability represents the chance to sell the product within a given time period. If we have a minimum sales probability of 60% and a max. replenishment days of 21, then that means that the system will calculate the probability of selling a product within this three weeks. If this probability is higher than 60%, it will dispatch the products.`,
  SALES_PERIOD_FOR_FORECAST: `The sales period for the forecast defines the number of days the system will look back to calculate the forecast. So with a period of 28 days, it will take the sales of the last 28 days to determine the forecast. The faster a product will sell, the lower this period can be.Usually it should be between 28 and 42 days.A longer time will result in a smoothening of the forecast and thus it will not have big fluctuations.By defining a short period, single sales events gain more importance and will result in fluctuations.`,
  INITIAL_REPL_PERIOD: `The initial replenishment period is the initial number of days the system will take to calculate the sales probability. The system will try to later reduce the number of replenishment days in order to not commit excessive inventory to a store. As long as the minimum order value is high enough, the initial replenishment period is the maximum replenishment time. In case the minimum order value for a store is not reached, number of replenishment days can be increase until it reaches the maximum replenishment days.`,
  SHORT_DATE_FORMAT: `Represents a short date format without time.Please adjust this to your local format. This format represents the default format of the system which will be the format shown to new users.A user can himself change to another format.`,
  LONG_DATE_FORMAT: `Represents a long format which includes date and time.Please adjust this to your local format. This format represents the default format of the system which will be the format shown to new users.A user can himself change to another format.`,
  DEFAULT_LANGUAGE: `This language represents the default language of the system which will be shown to new users.A user can himself change to another language.`,
  MINIMUM_ORDER_PRICE: `The business rule which cancels orders which do not reach a certain minimum value needs two input parameters. This value determines, which price is taken to decide about the value of an order.It is possible to select between the Sales Price, the Base Price and the Purchase Price.`,
  MINIMUM_ORDER_VALUE: `This business rule which cancels orders which do not reach a certain minimum value need two input parameters.The order value determined the threshold from which on an order will be sent to a store.`,
  AUTUMN_WINTER_START: `The date from this the AW seasons are usually starting.This value is taken to configure the business rule which determines the available space in a store.`,
  SPRING_SUMMER_START: `The date from this the SS seasons are usually starting.This value is taken to configure the business rule which determines the available space in a store.`,
  HOLIDAYS: `Vacation days of the central warehouse`,
  MAX_REPL_PIECES:
    "The maximum number of pieces which can be replenished of one product to one store in a replenishment run. This allows to limit the influence of wrong data to the replenishment orders by setting a maximum limit.",
  DEFAULT_MATRIX_DAYS:
    "The default matrix time represents the number of days the system looks backwards to automatically determine, if a product is included into the store matrix. When there was no stock in the store during the complete time, than the product is excluded.",
  INITIAL_COEFFICIENT: `The initial coefficient is the percentage uplift in sales which is expected to happen during the promotion.A value of 40 in this field indicates a 40% higher sales.`,
  CURRENT_COEFFICIENT: `The current coefficient is the percentage uplift which was used for this promotion for the last replenishment run.A value of 39, 5 in this field indicates a 39, 5% higher sales.`,
};

export const COLOR_STYLE_FOR_TAG_INPUT = {
  multiValue: (styles, { data, ...rest }) => {
    const backgroundColor = data.color || "transparent";
    return {
      ...styles,
      color: "#fff",
      backgroundColor: backgroundColor,
    };
  },
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color: "#000",
  }),
  multiValueRemove: (styles, { data }) => ({
    ...styles,
    color: "#000",
  }),
};

export const SCREEN_LIST = [
  {
    icon: "icon-overview",
    content: "Overview",
    id: "overview",
  },
  {
    icon: "icon-planning",
    content: "Products",
    id: "products",
  },
  {
    icon: "icon-overview",
    content: "Recommendations",
    id: "recommendations",
  },
  {
    icon: "icon-compaigns",
    content: "Orders",
    id: "orders",
  },
  {
    icon: "icon-add-extensions",
    content: "Special Replenishment",
    id: "special_replenishment",
  },
  {
    icon: "icon-keyword",
    content: "Promotion",
    id: "promotion",
  },
  {
    icon: "icon-recommendations",
    content: "Manual Order",
    id: "manual_order",
  },
  {
    icon: "icon-settings",
    content: "Settings",
    id: "settings",
  },
  {
    icon: "icon-billing",
    content: "Replenishment Calendar",
    id: "replenishment-calendar",
  },
  {
    icon: "icon-settings",
    content: "Store Category",
    id: "store_category",
  },
  {
    icon: "icon-measurment",
    content: "Seasons",
    id: "seasons",
  },
  {
    icon: "icon-bulk-action",
    content: "Outlet",
    id: "outlet",
  },
  {
    icon: "icon-shared-liberary",
    content: "User",
    id: "user",
  },
  {
    icon: "icon-planning",
    content: "User Roles",
    id: "user_roles",
  },
  {
    icon: "icon-landing-pages",
    content: "Transport Time Per City",
    id: "transport_time_per_city",
  },
  {
    icon: "icon-demographics",
    content: "Client",
    id: "client",
  },
  {
    icon: "icon-product-groups",
    content: "Warehouses",
    id: "warehouses",
  },
  {
    icon: "icon-audiences",
    content: "Product Category",
    id: "product_category",
  },
  {
    icon: "icon-ad-groups",
    content: "Promotion Type",
    id: "promotion_type",
  },
  {
    icon: "icon-ad-groups",
    content: "Season Type",
    id: "season_type",
  },
];

export const SEASON_TYPE = [
  {
    label: "Main Season",
    value: "MainSeason",
  },
  {
    label: "Post Season",
    value: "PostSeason",
  },
  {
    label: "Pre Season",
    value: "PreSeason",
  },
];

export const URL_PATH = {
  BRAND: "/Brand",
  SALESCHANNELS: "/SalesChannels",
  OUTLET: "/Outlets",
  CITY: "/City",
  OUTLET_CATEGORY: "/OutletCategories",
};
