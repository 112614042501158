import React from 'react'
import { Link } from 'react-router-dom'
import { renderFormatMsg } from '../../shared/funs'
import PlusIcon from "../../images/plus.svg";


/**
 *
 * @param {string} to path to redirect
 * @param {string} Id of language translate text
 * @param {string} defaultText of text when translate failed to find in json
 * @param {string} classes custom overridable classname
 * @param {boolean} icon icon visible or not
 */
export default function LinkButton({ disabled = false, to, id, defaultText, classes = "", icon = true }) {
  return (
    <Link className={`btn btn-light align-items-center d-flex ${classes} ${disabled && "read-only"}`} to={disabled ? "#" : to}>
      <span>{renderFormatMsg(id, defaultText)}</span>
      {icon && <img className="ml-2" src={PlusIcon} alt="PlusIcon" />}
    </Link>
  )
}
