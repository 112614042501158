import React, { useEffect, useState } from 'react'
import { Form, Field } from 'react-final-form';
import { get, find } from "lodash";
import { renderFormatMsg, toastError, toastSuccess, selectStyle } from '../../../shared/funs';
// import { Link } from 'react-router-dom';
// import BackIcon from "../../../images/back-icon.svg";
import { getCity, getTransportTypes, getTransportTimesCities, addTransportTimesCity, updateTransportTimesCity } from '../../../services/toolsAndSettingsPagesApi';
import FieldErrorMessage from '../../../components/FieldErrorMessage';
import SelectField from '../../../components/Fields/SelectField';
import InputField from '../../../components/Fields/InputField';
import PageHeading from '../../../components/PageHeading/PageHeading';

const HandleTransportTimePerCity = (props) => {
  const [transportTimesCity, setTransportTimesCity] = useState(null)
  // eslint-disable-next-line no-unused-vars
  const [error, setError] = useState([])
  const [city, setCity] = useState([])
  const [transportType, setTransportType] = useState([])

  var heading = "AddTransportTimePerCity"
  var values = {
    cityId: "",
    transportTypeId: "",
    transportTime: ""
  }

  if (props.location.pathname.includes("Edit")) {
    heading = "EditTransportTimePerCity"
    values = {
      cityId: get(transportTimesCity, "citeName"),
      transportTypeId: get(transportTimesCity, "transportTypeId"),
      transportTime: get(transportTimesCity, "transportTime"),
    }
  }

  useEffect(() => {
    getTransportTimesCities().then(res => {
      let transportTimesCity = find(res.transportTimesCities, (data) => data.transportTimesCitiesId === props.match.params.id)
      setTransportTimesCity(transportTimesCity)
      getCity().then(res => setCity(res)).catch(error => console.log('error => ', error))
      getTransportTypes().then(res => setTransportType(res.transportTypes)).catch(err => console.log('err => ', err))
    }).catch(error => setError(error))
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleAddForm = async (data) => {
    let object = {
      "transportTypeId": data.transportTypeId,
      "cityId": data.cityId,
      "transportTime": data.transportTime
    }
    await addTransportTimesCity(object).then(res => {
      toastSuccess(renderFormatMsg("ADD_SUCCESS_MSG", "Record added succesfully"))
      props.history.push("/transportTimePerCity")
    }).catch(err => toastError(renderFormatMsg("ADD_FAILED_MSG", "Record added fail")))
  }

  const handleEditForm = async (data) => {
    const object = {
      "transportTimesCitiesId": props.match.params.id,
      "transportTypeId": data.transportTypeId,
      "transportTime": data.transportTime
    }
    await updateTransportTimesCity(object).then(res => {
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
      props.history.push("/transportTimePerCity")
    }).catch(err => toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail")))
  }
  const validation = (data) => {
    let errors = {}
    if (!data.cityId || data.cityId === "") {
      errors.cityId = "Required";
    }
    if (!data.transportTypeId || data.transportTypeId === "") {
      errors.transportTypeId = "Required";
    }
    if (!data.transportTime || data.transportTime === "") {
      errors.transportTime = "Required";
    }
    return errors
  }


  const generateOptions = (options) => {
    var _options = [<option option value={""} > Select Type</option>]
    options.map(opt =>
      _options.push(<option value={opt.transportTypeId}>{opt.name}</option>)
    )

    return _options;
  }
  return (
    <div className="main-content">
      {/* <div className="title">
        <div className="heading">
          <Link className="back-btn" to="/transportTimePerCity"><img src={BackIcon} alt="BackIcon" /></Link><span>{heading}</span></div>
      </div> */}
      <PageHeading backURL="/transportTimePerCity" headingId={heading} />
      <div className="card p-3">
        <Form onSubmit={props.location.pathname.includes("Add") ? handleAddForm : handleEditForm}
          initialValues={values} validate={validation}
        >
          {({ handleSubmit, form, submitting, pristine, values }) => {
            return (
              <form onSubmit={handleSubmit} className="">
                <div className="form">
                  <div className="row">

                    <div className="col-lg-6">
                      <Field name="cityId" component="select" options={city && city}>
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <label>{renderFormatMsg("CityName", "City Name")}</label>
                            <select className="form-control" style={selectStyle(input)} name={get(options, "name")} onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select City</option>
                              {options && options.map((x, i) =>
                                <option selected={input.value === x.name} key={i} value={x.cityId}>
                                  {x.name}</option>)}
                            </select>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        }
                      </Field>
                    </div>

                    {/* <div className="col-lg-6">
                      <Field name="transportTypeId" component="select" options={transportType && transportType}>
                        {({ input, meta, options }) =>
                          <div className="form-group">
                            <label> {renderFormatMsg("TransportType", "Transport Type")}</label>
                            <select className="form-control" style={selectStyle(input)} name={get(options, "name")} onChange={(value) => input.onChange(value)}>
                              <option value={0}>Select Type</option>
                              {options && options.map((x, i) =>
                                <option selected={input.value === x.transportTypeId} key={i} value={x.transportTypeId}>{x.name}</option>)}
                            </select>
                            <FieldErrorMessage meta={meta} />
                          </div>
                        }
                      </Field>
                    </div> */}


                    <SelectField
                      colClass="col-12 col-lg"
                      name="transportTypeId"
                      options={generateOptions(transportType)}
                      id="TransportType"
                      defaultLabel={"Transport Type"}
                    />


                  </div>
                  <div className="row">
                    {/* <div className="col-lg-6">
                      <Field name="transportTime">
                        {({ input, meta }) => (
                          <div className="form-group">
                            <label> {renderFormatMsg("TransportTime", "Transport Time")} </label>
                            <input {...input} type="number" placeholder="Transport Time" className="form-control" />
                            <FieldErrorMessage meta={meta} />
                          </div>
                        )}
                      </Field>
                    </div> */}

                    <InputField
                      colClass="col-lg-6"
                      name={"transportTime"}
                      type="number"
                      id="TransportTime"
                      defaultLabel="Transport Time"
                      placeholder={"Transport Time"}
                    />
                  </div>
                </div>
                <div className="col-12 text-right">
                  <div className="submit_btn">
                    <button type="submit" className="btn btn-primary"> {props.location.pathname.includes("Edit") ? renderFormatMsg("Save", "Save") : renderFormatMsg("Add", "Add")} </button>
                  </div>
                </div>
              </form>
            );
          }}
        </Form>
      </div>
    </div >
  )
}

export default React.memo(HandleTransportTimePerCity)
