import React, { useEffect, useState } from "react";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  // ResponsiveContainer,
  // Legend,
  Text,
} from "recharts";

const CustomizedAxisTick = () => ({
  render() {
    const { x, y, payload } = this.props;

    return (
      <Text x={x} y={y} width={75} textAnchor="middle" verticalAnchor="start">
        {payload.value}
      </Text>
    );
  },
});
export default function Graph(props) {
  const [state, setState] = useState(null);

  useEffect(() => {
    var obj = props.data ? props.data : null;
    let data;
    if (obj) {
      var graphData = obj.orderProcessing;
      data = [
        {
          name: "Store Stock",
          "Store Stock": obj.demandParameters.storeStock,
        },
        {
          name: "Transit Stock",
          "Transit Stock": obj.demandParameters.transitStock,
          Empty: obj.demandParameters.storeStock,
        },
      ];

      var total =
        obj.demandParameters.storeStock + obj.demandParameters.transitStock;
      graphData.forEach((o) => {
        data.push({
          name: o.step,
          Quantity: o.orderQuantity,
          Empty:
            obj.demandParameters.storeStock + obj.demandParameters.transitStock,
        });
      });

      total += graphData[graphData.length - 1].orderQuantity;

      data.push({
        name: "Total",
        total,
      });
    }
    setState(data);
  }, []);

  return (
    <>
      {/* <ResponsiveContainer width={700} height="80%"> */}
      {state && state.length > 0 && (
        <BarChart
          width={900}
          height={300}
          data={state}
          // margin={{ top: 20, right: 80, bottom: 20, left: 20 }}
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 5,
          }}
          // margin={{ top: 0, right: 0, bottom: 0, left: 0 }}
        >
          {/* <CartesianGrid strokeDasharray="2 2" /> */}
          <XAxis
            dataKey="name"
            tick={<CustomizedAxisTick />}
            interval={0}
            angle={-30}
            dx={0}
            dy={50}
          />
          <YAxis />
          <Tooltip />
          {/* <Legend /> */}
          <Bar dataKey="Empty" stackId="a" fill="#ff000000" />
          <Bar dataKey="Quantity" stackId="a" fill="#b4dfc4" />
          <Bar dataKey="Store Stock" stackId="a" fill="#b8b5e8" />
          <Bar dataKey="Transit Stock" stackId="a" fill="#b8b5e8" />
          <Bar dataKey="total" stackId="a" fill="#8884d8" strokeWidth={5} />
        </BarChart>
      )}
      {/* </ResponsiveContainer> */}
    </>
  );
}
