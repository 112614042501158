import React from "react";
import ReactDOM from "react-dom";
import * as serviceWorker from "./serviceWorker";
import "./index.css";
import App from "./App";
import "./App.scss";
import en_data from "./languageFile/english.json";
import de_data from "./languageFile/german.json";
import ru_data from "./languageFile/russian.json";
import { MainProvider } from "./context";
import { BrowserRouter } from "react-router-dom";
import history from "./shared/history";
import 'react-table/react-table.css'
import "./components/ReactTable/Table.scss"
import 'react-datepicker/dist/react-datepicker.css'
import "./view/ToolsAndSettings/UpdatingPage/forms.scss"

const messages = {
  en: en_data,
  de: de_data,
  ru: ru_data,
};

ReactDOM.render(
  <BrowserRouter history={history}>
    <MainProvider>
      <App messages={messages} />
    </MainProvider>
  </BrowserRouter>,
  document.getElementById("root")
);

serviceWorker.unregister();
