
import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { get, debounce } from "lodash"
import {
    // getClientData,
    updateClientData
} from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE } from '../../constant';
import Calendar from "../../components/Calendar"
import { AppContext } from "../../context/AppContext";
import { renderFormatMsg, toastSuccess, toastError, checkPermission, updateActiveFromAndToRow, DateRangeStatus, permissionErrorCheck, findPermission } from "../../shared/funs"
import Error from "../../components/Error/Error"
import SearchBox from '../../components/SearchBox/SearchBox';
import LinkButton from '../../components/Buttons/LinkButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';
import { GraphQLBaseService } from '../../services/GraphQLBaseService';

const ClientPageWithGraphQL = (props) => {
    const { userDetails } = useContext(AppContext)
    const [clientData, setClientData] = useState([])
    const [clientGData, setClientGData] = useState([])
    const [searchValue, setSearchValue] = useState("")
    const [totalPage, setTotalPage] = useState(0)
    const [totalGPage, setTotalGPage] = useState(0)
    const [pageIndex, setPageIndex] = useState(0)
    // eslint-disable-next-line
    const [isLoading, setLoading] = useState(false)
    const [error, setError] = useState(false)
    const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

    let dataArray = get(userDetails, "accesses")

    useEffect(() => {
        setPageReadOnly(checkPermission(dataArray, findPermission("CLIENT_PAGE").screenId))
    }, [dataArray])

    const getData = (page = 0, search = "") => {

        setSearchValue(search)
        let param = {
            "page": page + 1,
            "display": PAGE_SIZE,
            "search": search
        }


        // GraphQL Query Start

        // console.info('param =>', param);

        // const operationsDoc = `
        //     query GetClientList {
        //         allclients(limit: ${PAGE_SIZE}, offset: ${page * PAGE_SIZE}, where: {ClientName: {_like: "${search}%"}}) {
        //         ClientId
        //         ClientName
        //         ActiveFrom
        //         ActiveTo
        //         },
        //         allclients_aggregate {
        //             aggregate {
        //                 count
        //             }
        //         }
        //     }`;

        // async function fetchGraphQL(operationsDoc, operationName) {

        //     const result = await fetch(
        //         "https://noted-tuna-42.hasura.app/v1/graphql",
        //         {
        //             method: "POST",
        //             headers: {
        //                 'x-hasura-access-key': '8E2j4jOlt59V0t0WbKQJSeORjgBlk5p0NerrjIc4qXBe5RsEq9s6ElHA9YYSIhsD',
        //                 'x-hasura-admin-secret': '2Um6PvYtSlWx8qiG7gK5BcuSqm5qY62C2Z9KVCo3HiXgqm2R2ubcNAFoCIyksTkD',
        //                 'Content-Type': 'application/json'
        //             },
        //             body: JSON.stringify({
        //                 query: operationsDoc,
        //                 // variables: variables,
        //                 operationName: operationName
        //             })
        //         }
        //     );
        //     return await result.json();
        // }

        // function fetchMyQuery() {
        //     return fetchGraphQL(
        //         operationsDoc,
        //         "GetClientList",
        //         {}
        //     );
        // }

        // async function startFetchMyQuery() {
        //     const { errors, data } = await fetchMyQuery();
        //     let pages = Math.ceil(data.allclients_aggregate.aggregate.count / PAGE_SIZE);

        //     // console.info('pages =>', pages);

        //     setTotalGPage(pages);

        //     if (errors) {
        //         console.error(errors);
        //     }

        //     console.info('data =>', data);
        //     // setClientData(data);
        //     setClientGData(data.allclients);

        // }
        // startFetchMyQuery();

        // GraphQL Query End


        const operationsDoc = `
            query GetClientList($limit: Int!, $offset: Int!, $search: String) {
                allclients(limit: $limit, offset: $offset, where: {ClientName: {_ilike: $search}}) {
                    ClientId
                    ClientName
                    ActiveFrom           
                    ActiveTo
                }
                allclients_aggregate {
                    aggregate {
                        count
                    }
                }
            }`;

        const variables = {
            "limit": PAGE_SIZE,
            "offset": page * PAGE_SIZE,
            "search": `${search}%`
        }

        // const operationsDoc = `
        //     query GetClientList {
        //         allclients(limit: ${PAGE_SIZE}, offset: ${page * PAGE_SIZE}, where: {ClientName: {_like: "${search}%"}}) {
        //         ClientId
        //         ClientName
        //         ActiveFrom
        //         ActiveTo
        //         },
        //         allclients_aggregate {
        //             aggregate {
        //                 count
        //             }
        //         }
        //     }`;

        async function fetchUserData() {
            // let pages = 0;
            // let clientDataObj = {
            //     clients: [],
            //     totalCount: 0,
            //     totalPage: 0,
            // };
            try {
                let res = await (new GraphQLBaseService('/client-graphql?test=1')).getAll('', param, operationsDoc, variables)
                console.info('res =>', res);
                // const valuesClients = res.data.allclients;


                // if (res && res.data && res.data.allclients) {
                //     valuesClients.map(value => {
                //         clientDataObj.clients.push(value)
                //     })
                // } else {
                //     console.info('No data found !!');
                // }
                // console.info('clientDataObj =>', clientDataObj);

                // res && res.data && res.data.allclients && valuesClients.map(value => {
                //     clientDataObj.clients.push(value)
                // })
                // console.info('clientDataObj =>', clientDataObj);


                setClientData(res.data.allclients.length > 0 ? res.data.allclients : [])

                // if (search !== '') {
                //     pages = Math.ceil(res.data.allclients.length / PAGE_SIZE);
                // } else {
                //     pages = Math.ceil(res.data.allclients_aggregate.aggregate.count / PAGE_SIZE);
                // }

                // let count = Math.ceil(res.data.allclients.length);
                // console.info('count =>', count);

                // if (count < PAGE_SIZE) {
                //     pages = pages + 1;
                // } else {
                //     pages = Math.ceil(res.data.allclients_aggregate.aggregate.count / PAGE_SIZE);
                // }

                let pages = Math.ceil(res.data.allclients_aggregate.aggregate.count / PAGE_SIZE);

                setTotalPage(pages);

            } catch (error) {
                setError(permissionErrorCheck(error))
            }
        }
        fetchUserData();
    }

    const sendQuery = (object, data) => {
        updateClientData(object).then(res => {
            setClientData(data.data)
            toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
        }).catch(error => {
            toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
        })
    };

    const delayedQuery = debounce((q, data) => sendQuery(q, data), 1000);

    const handleChangeDate = async (date, details, key) => {
        var data = updateActiveFromAndToRow(clientData, key, date, { "clientId": details.clientId });
        delayedQuery(data.payload, data)
    }

    const columns = [{
        Header: <div className="text-left">
            {renderFormatMsg("clientPageTable.ClientID", "Client ID")}</div>,
        accessor: 'ClientId',
        width: 324,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    }, {
        Header: <div className="text-left">
            {renderFormatMsg("clientPageTable.ClientName", "Client Name")}</div>,
        accessor: 'ClientName',
        minWidth: 200,
        //resizable: false,
        style: { justifyContent: "flex-start" }
    }, {
        Header: renderFormatMsg("ActiveFrom", "Active From"),
        accessor: 'ActiveFrom',
        //resizable: false,
        width: 158,
        Cell: props => <div className={DateRangeStatus(props.original.ActiveFrom, props.original.ActiveTo)}>
            <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="ActiveFrom" selectedDate={new Date(props.original.ActiveFrom)}
                maxDate={new Date(props.original.ActiveTo)}
                onSelect={data => handleChangeDate(data, props.original, "ActiveFrom")}
            />
        </div>
    },
    {
        Header: renderFormatMsg("ActiveTo", "Active to"),
        //resizable: false,
        width: 158,
        accessor: 'ActiveTo',
        Cell: props => <div className={DateRangeStatus(props.original.ActiveFrom, props.original.ActiveTo)}>
            <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="ActiveTo" selectedDate={new Date(props.original.ActiveTo)} minDate={new Date(props.original.ActiveFrom)} onSelect={data => handleChangeDate(data, props.original, "ActiveTo")} /></div>
    },
        // {
        // Header: <div className="text-left">
        //     {renderFormatMsg("clientPageTable.ClientName", "Client Name")}</div>,
        // accessor: 'ClientName',
        // minWidth: 200,
        // //resizable: false,
        // style: { justifyContent: "flex-start" }
        // }
    ]

    if (pageReadOnly.write) {
        columns.push({
            Header: renderFormatMsg("Edit", "Edit"),
            accessor: 'Edit',
            //resizable: false,
            width: 150,
            Cell: props => <Link to={`/client/Edit/${props.original.ClientId}/?test=1`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
        })
    }

    if (props.location.search === "?test=1") {
        columns.push({
            Header: <div className="text-left">{renderFormatMsg("clientPageTable.License", "License")}</div>,
            accessor: 'License',
            minWidth: 100,
            style: { justifyContent: "flex-start" },
            Cell: props => <Link to={`/license/${props.original.ClientId}/?test=1`}>{renderFormatMsg("License", "License")}</Link>
        })
    }

    const fetchData = (state, instance) => {

        //getting change page number from state
        getData(state.page, searchValue)
        setPageIndex(state.page)
    }

    const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }


    return (
        <div className="main-content">
            {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
                {renderFormatMsg("clientPageTable.Heading", "Client")}
            </div> */}
            <PageHeading headingId="clientPageTable.Heading" isLoading={isLoading} />
            <div className="card">

                {error ? <Error msg={error} /> : <>
                    <div className="card-header">
                        <SearchBox searchHandler={SearchHandler} />
                        {pageReadOnly.write && <LinkButton disabled={!pageReadOnly.write} to="/client/Add" id="AddClient" defaultText="Add Client" />}
                    </div>
                    {/* <ReactTable data={clientGData} columns={columns} onFetchData={fetchData} pages={totalGPage} page={pageIndex} /></>} */}
                    <ReactTable data={clientData} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} /></>}
            </div>
        </div >
    )
}
export default React.memo(ClientPageWithGraphQL);
