import React, { useState, useContext, useEffect } from 'react'
import { Link } from "react-router-dom"
import { get, debounce } from "lodash"
import {
  // getUserData,
  // updateUser
} from '../../services/toolsAndSettingsPagesApi';
import ReactTable from '../../components/ReactTable/ReactTable';
import { PAGE_SIZE, DATE_FORMAT } from '../../constant';
import Calendar from "../../components/Calendar"
import { AppContext } from '../../context/AppContext';
import moment from 'moment';
import { renderFormatMsg, toastError, toastSuccess, checkPermission, updateActiveFromAndToRow, DateRangeStatus, permissionErrorCheck, findPermission } from '../../shared/funs';
import Error from '../../components/Error/Error';
import SearchBox from '../../components/SearchBox/SearchBox';
import CustomButton from '../../components/Buttons/CustomButton';
import PageHeading from '../../components/PageHeading/PageHeading';
import { BaseService } from '../../services/BaseService';

const User = (props) => {
  const { userDetails } = useContext(AppContext)
  const [userData, setUserData] = useState([])
  const [searchValue, setSearchValue] = useState("")
  const [totalPage, setTotalPage] = useState(0)
  const [pageIndex, setPageIndex] = useState(0)
  // eslint-disable-next-line
  const [isLoading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [pageReadOnly, setPageReadOnly] = useState({ read: true, write: false })

  let dataArray = get(userDetails, "accesses");
  useEffect(() => {
    setPageReadOnly(checkPermission(dataArray, findPermission("USER").screenId))
  }, [dataArray])

  const getData = (page = 0, search = "") => {
    setSearchValue(search)
    let param = {
      "page": page + 1,
      "display": PAGE_SIZE,
      "search": search
    }

    async function fetchCityData() {
      try {
        let res = await (new BaseService('/Users')).getAll('', param)
        setUserData(res.users.length > 0 ? res.users : [])
        setTotalPage(res.totalPage)
      } catch (error) {
        setError(permissionErrorCheck(error))
        console.log('error => ', error)
      }
    }
    fetchCityData();
    // getUserData(param).then(res => {
    //   setUserData(res.users.length > 0 ? res.users : [])
    //   setTotalPage(res.totalPage)
    // }).catch(err => {
    //   setError(permissionErrorCheck(err))
    // })
  }

  const columns = [{
    Header: <div className="text-left">
      {renderFormatMsg("userTable.FirstName", "First Name")}
    </div>,
    accessor: 'firstName',
    style: { justifyContent: "flex-start" },
    width: 180,
    maxWidth: 200,
    //resizable: false,
  }, {
    Header: <div className="text-left">
      {renderFormatMsg("userTable.LastName", "Last Name")}
    </div>,
    accessor: 'lastName',
    style: { justifyContent: "flex-start" },
    width: 180,
    maxWidth: 200,
    //resizable: false,
  }, {
    Header: renderFormatMsg("ActiveFrom", "Active From"),
    accessor: 'activeFrom',
    //resizable: false,
    width: 158,
    Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
      <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="activeFrom" selectedDate={new Date(props.original.activeFrom)} onSelect={data => handleChangeDate(data, props.original, "activeFrom")} maxDate={new Date(props.original.activeTo)} /></div>
  },
  {
    Header: renderFormatMsg("ActiveTo", "Active to"),
    accessor: 'activeTo',
    //resizable: false,
    width: 158,
    Cell: props => <div className={DateRangeStatus(props.original.activeFrom, props.original.activeTo)}>
      <Calendar disabled={!pageReadOnly.write} className={!pageReadOnly.write && "read-only"} name="activeTo" minDate={new Date(props.original.activeFrom)} selectedDate={new Date(props.original.activeTo)} onSelect={data => handleChangeDate(data, props.original, "activeTo")} /></div>
  },]
  if (pageReadOnly.write) {
    columns.push({
      Header: renderFormatMsg("Edit", "Edit"),
      accessor: 'Edit',
      //resizable: false,
      width: 130,
      Cell: props => <Link to={`/user/Edit/${props.original.userId}`} className="edit-icon">{renderFormatMsg("Edit", "Edit")}</Link>
    }, {
      Header: renderFormatMsg("userTable.AssignRoles", "Assign Roles"),
      accessor: 'Assign Roles',
      //resizable: false,
      width: 130,
      Cell: props => <Link to={`/user/userRoleAssignment/${props.original.userId}`} className="edit-icon"> {renderFormatMsg("userTable.AssignRoles", "Assign Roles")} </Link>
    })
  }
  const fetchData = (state, instance) => {
    //getting change page number from state
    getData(state.page, searchValue)
    setPageIndex(state.page)
  }


  const sendQuery = async (payload, data) => {

    try {
      (new BaseService('/Users')).update(payload.userId, payload)
      setUserData(data.data)
      toastSuccess(renderFormatMsg("UPDATE_SUCCESS_MSG", "Record updated succesfully"))
    } catch (error) {
      toastError(renderFormatMsg("UPDATE_FAILED_MSG", "Record updated fail"))
      console.log('error => ', error)
    }

  };

  const delayedQuery = debounce((q, data) => sendQuery(q, data), 1000);


  const handleChangeDate = async (date, details, key) => {
    let object = {
      "userId": details.userId,
      "firstName": details.firstName,
      "lastName": details.lastName,
      "email": details.email,
      "mobileNumber": details.mobileNumber,
      "userName": details.userName,
      "activeFrom": moment(details.activeFrom).format(DATE_FORMAT),
      "activeTo": moment(details.activeTo).format(DATE_FORMAT)
    }
    var data = updateActiveFromAndToRow(userData, key, date, object);
    delayedQuery(data.payload, data)
  }
  const SearchHandler = (e) => { setPageIndex(0); getData(0, e.target.value) }
  const addButtonHandler = () => { props.history.push('/user/Add') }
  return (
    <div className="main-content">
      {/* <div className={"title" + (isLoading ? " severityChangedColor" : "")}>
        {renderFormatMsg("userTable.Heading", "User")}
      </div> */}
      <PageHeading headingId="userTable.Heading" isLoading={isLoading} />
      <div className="card">

        {error ? <Error msg={error} /> : <>
          <div className="card-header">
            <SearchBox searchHandler={SearchHandler} />
            {pageReadOnly.write && <CustomButton onClick={addButtonHandler} id="AddUser" defaultText="Add User" />}
          </div>
          <ReactTable data={userData} columns={columns} onFetchData={fetchData} pages={totalPage} page={pageIndex} />
        </>
        }
      </div>
    </div>
  )
}
export default React.memo(User)
